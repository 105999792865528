<div class="under-maintenance">
  <div class="under-maintenance_icon">
    <mat-icon> engineering </mat-icon>
  </div>
  <div class="under-maintenance_text">
    <h2>We're Currently Under Maintenance</h2>
    <p>
      Organic is temporarily down for maintenance, but we’ll be back up and
      running soon. Thank you for your patience!
    </p>
  </div>
</div>
