// mixpanel.service.ts
import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor() {
    mixpanel.init('e3854035b40bfd25554a2cac2bb935f7');
  }

  // Track an event
  track(eventName: string, properties?: object) {
    mixpanel.track(eventName, properties);
  }

  // Identify a user
  identify(userId: string) {
    mixpanel.identify(userId);
  }

  // Set user profile properties
  setProfile(properties: object) {
    mixpanel.people.set(properties);
  }
}
