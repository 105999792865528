import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SeoService } from '../seo.service';
import { Router } from '@angular/router';
import { contriesWithLangs } from '../onboarding/languages';
import { suggestedLanguages } from '../onboarding/languages';
import { catchError, map, Observable, of, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatMenu } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from 'src/app/authentication/session.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { SidenavService } from 'src/app/sidenav/sidenav.service';

@Component({
  selector: 'app-onboarding-freemium',
  templateUrl: './onboarding-freemium.component.html',
  styleUrls: [
    './onboarding-freemium.component.css',
    '../onboarding/onboarding.component.css',
  ],
})
export class OnboardingFreemiumComponent implements OnInit, OnDestroy {
  constructor(
    private seoService: SeoService,
    private router: Router,
    public snackBar: MatSnackBar,
    private sessionService: SessionService,
    private translate: TranslateService,
    private titleService: Title,
    private sidenavService: SidenavService
  ) {
    this.titleService.setTitle('Onboarding - Organic');
  }
  onboardNewTargetSub: Subscription;

  saveConfiguration: any = {
    url: '',
    country: 'United States',
    language: 'English',
  };

  finalConfiguration: any = {
    competitor: '',
    keywords: [],
  };

  keywordMethodSelection: any = 'Automatic';

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement> | undefined;
  keywordFileName: string = 'Upload .csv file here';
  fileUploaded: boolean = false;

  suggestedCountries: any = contriesWithLangs.map((c: any) => {
    return c.country;
  });

  competitorForm = new FormControl([]);
  keywordMethod = new FormControl([]);
  showKeywords: boolean = false;
  competitorButtonText: string = 'Next';

  suggestedCompetitors: any = [];
  filteredOptions: any;
  maximumKeywords: number = 1000;
  initialKeywordCount: number;

  filteredLanguages: any = suggestedLanguages;

  competitorsUrl: any = [];
  competitor: any = '';

  keywords: any = [];
  keyword: any = '';

  gatheringData = false;

  darkMode: boolean = true;
  maxSteps: number = 5;
  stepsIndex: number = 0;

  listOfKeywords = [];

  totalCount: number = 0;
  keywordsLoading: boolean = false;
  referral: boolean = false;
  uploadedFileCorrect = false;
  uploadErrorMessage = '';
  seoSubscription: any;
  isMasterAccount: boolean = false;
  skippedCompetitor: boolean = false;

  seo_privileges: string[] = [
    'seo_getting_started',
    'seo_overall',
    'seo_projects',
    'seo_pages',
    'seo_ai-analysis',
    'seo_backlinks',
    'seo_content',
    'seo_backlinks_prospect-analysis',
    'seo_backlinks_ai-profile',
    'seo_backlinks_backlinks-list',
    'seo_backlinks_anchors',
    'seo_backlinks_referring-ips',
    'seo_backlinks_referring-domains',
    'seo_position-tracking',
    'seo_technicals',
    'seo_social',
    'seo_on-page',
    'seo_automations',
    'seo_scripts',
    'userPermissions',
  ];
  available_paths: string[] = [];

  dark_mode_active: boolean = false;

  hide_first_back_button: boolean = false;

  redirect_from_web: boolean = false;

  loading_redirect_from_web: boolean = false;

  ngOnInit() {
    this.sidenavService.triggerPageChange();
    if (sessionStorage.getItem('onboarding')) {
      this.saveConfiguration = JSON.parse(
        sessionStorage.getItem('onboarding') || '{}'
      );
      sessionStorage.removeItem('onboarding');
    }

    if (localStorage.getItem('role') == 'master') {
      this.isMasterAccount = true;
    }

    if (localStorage.getItem('organic_signup')) {
      this.hide_first_back_button = true;
    }

    this.seoSubscription =
      localStorage.getItem('seoSubscription') || 'Freemium';

    if (localStorage.getItem('fromProjects')) {
      this.referral = true;
    } else {
      this.referral = false;
    }

    const role = localStorage.getItem('privileges')?.split(',') || [];

    role.forEach((priv: any) => {
      if (!this.seo_privileges.includes(priv)) {
        const path = this.sessionService.routesWithPrivileges.find(
          (route: any) => priv.includes(route.privileges)
        )?.path;

        if (path && !path.includes('billing')) this.available_paths.push(path);
      }
    });

    if (
      localStorage.getItem('organic_onboarding_redirect') &&
      this.available_paths?.length === 0
    ) {
      this.available_paths.push('/seo/projects');
    }

    if (document.body.classList.contains('dark-theme')) {
      this.dark_mode_active = true;
      document.body.classList.remove('dark-theme');
    }

    if (localStorage.getItem('seo_url_from_web')) {
      this.redirect_from_web = true;
      this.skippedCompetitor = true;
      // this.saveConfiguration.url = localStorage.getItem('seo_url_from_web');
      this.saveConfiguration = {
        url: localStorage.getItem('seo_url_from_web'),
        country: 'United Kingdom',
        language: 'English',
      };
      this.stepsIndex = 1;
    }

    if (this.seoSubscription == 'Freemium' || this.redirect_from_web) {
      this.maxSteps = 2;
    }
  }

  savedCompetitorValue: any;

  onCheckboxChange(event: any) {
    if (event.checked) {
      this.skippedCompetitor = true;
    } else {
      this.skippedCompetitor = false;
    }
  }

  saveCompetitor(value: any) {
    this.savedCompetitorValue = value;
  }
  ngOnDestroy(): void {
    this.onboardNewTargetSub?.unsubscribe();

    localStorage.removeItem('organic_signup');
    localStorage.removeItem('seo_url_from_web');
    if (this.dark_mode_active) {
      document.body.classList.add('dark-theme');
    }
  }

  backToProjects() {
    localStorage.removeItem('fromProjects');
    this.router.navigate(['/seo/projects']);
  }
  backToAvailablePath() {
    this.router.navigate([this.available_paths[0]]);
  }
  countryInputChanged(event: any) {
    let selectedValue = event?.value;
    let lang = contriesWithLangs?.find((obj: any) => {
      return obj.country.name === selectedValue;
    })?.lang?.name;

    let tmpLang = suggestedLanguages.find((langObj: any) => {
      return langObj.name === lang;
    })?.name;

    if (!tmpLang) lang = 'English';

    this.saveConfiguration['language'] = lang;
  }
  filterLanguages(event: any) {
    this.filteredLanguages = suggestedLanguages.filter(
      (language: any) =>
        language.value
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        language.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
  }
  filterCountries(event: any) {
    this.filteredOptions = this.suggestedCountries.filter(
      (country: any) =>
        country.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        country.code.toLowerCase().includes(event.target.value.toLowerCase())
    );
  }

  competitorInputChanged(event: any) {
    this.finalConfiguration.competitor = event.target.value;
  }
  onboardNewTarget() {
    let tmpObj = {
      target: 'behamicsTestTarget',
      url: this.saveConfiguration.url,
      country: this.saveConfiguration.country,
      language: suggestedLanguages.filter(
        (language: any) => language.name === this.saveConfiguration.language
      )[0].value,
      ...(this.stepsIndex + 1 === 2 || this.stepsIndex + 1 === 3
        ? { step: this.stepsIndex + 1 }
        : {}),
    };

    this.gatheringData = true;

    if (this.seoSubscription == 'Freemium' || this.redirect_from_web) {
      this.loading_redirect_from_web = true;
    }

    this.onboardNewTargetSub = this.seoService
      .onboardNewTarget(tmpObj)
      .subscribe({
        next: (res: any) => {
          this.gatheringData = false;

          this.suggestedCompetitors = res?.competitors?.[0]?.competitors;
          this.finalConfiguration.competitor =
            this.suggestedCompetitors?.[0]?.Domain;

          if (
            !this.suggestedCompetitors ||
            this.suggestedCompetitors.length == 0
          ) {
            this.skippedCompetitor = true;
          }

          this.initialKeywordCount = res.total_organic_keywords;

          if (this.initialKeywordCount > this.maximumKeywords) {
            this.showKeywords = true;
            this.competitorButtonText = 'Next';
          } else {
            this.competitorButtonText = 'Confirm';
            this.showKeywords = false;
          }

          if (this.seoSubscription == 'Freemium' || this.redirect_from_web) {
            this.goToKeywords();
          } else if (this.skippedCompetitor) {
            this.carouselFunction(44);
          } else {
            this.carouselFunction(3);
          }
        },
        error: (err: any) => {
          console.log(err.error);
          if (
            err.error.error ==
            'You have reached the maximum number of projects allowed for your subscription.'
          ) {
            this.gatheringData = false;
            this.carouselFunction(-1);

            this.getTranslatedText(
              'you_have_reached_the_maximum_number_of_projects_allowed_for_your_subscription',
              'phrases'
            ).subscribe((translatedText) => {
              this.openSnackBar(translatedText);
            });
          }
        },
      });
  }

  getTranslatedText(text: string, type: string = 'words'): Observable<string> {
    return this.translate.get(`${type}.${text}`).pipe(
      map((translated: string) =>
        translated === `${type}.${text}` ? text : translated
      ),
      catchError(() => of(text))
    );
  }

  openSnackBar(a: string) {
    this.snackBar.open(a, 'x', {
      duration: 6000,
      panelClass: ['snackbar-class-error'],
    });
  }

  updateCountryAndGoNext() {
    this.updateCountryBasedOnExtension();
    setTimeout(() => {
      this.carouselFunction(1);
    }, 50);
  }

  updateCountryBasedOnExtension(): void {
    const url = this.saveConfiguration.url.trim();
    if (!url) return;

    const domainExtension = url.split('.').pop()?.toLowerCase();

    const extensionToCountryLangMap: {
      [key: string]: { country: string; language: string };
    } = {
      at: { country: 'Austria', language: 'German' },
      ch: { country: 'Switzerland', language: 'German' },
      de: { country: 'Germany', language: 'German' },
      fr: { country: 'France', language: 'French' },
      it: { country: 'Italy', language: 'Italian' },
      pl: { country: 'Poland', language: 'Polish' },
      cz: { country: 'Czechia', language: 'Czech' },
      es: { country: 'Spain', language: 'Spanish' },
      ca: { country: 'Canada', language: 'English' },
      jp: { country: 'Japan', language: 'Japanese' },
    };

    const countryLang = extensionToCountryLangMap[domainExtension || ''];
    if (countryLang) {
      this.saveConfiguration.country = countryLang.country;
      this.saveConfiguration.language = countryLang.language;
    }
  }

  clearFilter() {
    this.selectedOptionPos = 'Show All';
    this.selectedOptionDiff = 'Top Traffic';
    this.selectedOptionVol = 'Show All';
    this.getOrganicKeywords();
  }

  goToKeywordSelection() {
    this.carouselFunction(4);
  }

  goBackOneOrTwo() {
    if (this.seoSubscription == 'Premium') {
      if (this.skippedCompetitor) {
        this.carouselFunction(-11);
      } else {
        this.carouselFunction(-1);
      }
    } else {
      this.carouselFunction(-11);
    }
  }

  goToKeywords() {
    let tmpObj = {
      url: this.saveConfiguration.url,
      competitor: this.finalConfiguration.competitor,
    };

    setTimeout(() => {
      let data = {
        domain: this.saveConfiguration.url
          .replace(/^(https?:\/\/)?(www\.)?/, '')
          .split('/')[0],
        method:
          this.keywordMethodSelection == 'Automatic' ? 'automatic' : 'manual',
      };

      this.seoService.chooseMethodOfKeywords(data).subscribe({
        next: (res: any) => {
          if (this.seoSubscription == 'Premium') {
            if (this.skippedCompetitor) {
              if (this.keywordMethodSelection === 'Automatic') {
                if (this.showKeywords) {
                  this.selectedOptionPos = 'Show All';
                  this.selectedOptionDiff = 'Top Traffic';
                  this.selectedOptionVol = 'Show All';
                  this.carouselFunction(5);
                  this.getOrganicKeywords();
                } else {
                  let tmpObj = {
                    url: this.saveConfiguration.url,
                    country: this.saveConfiguration.country,
                    language: suggestedLanguages.filter(
                      (language: any) =>
                        language.name === this.saveConfiguration.language
                    )[0].value,
                  };

                  this.gatheringData = true;

                  this.onboardNewTargetSub = this.seoService
                    .onboardNewTarget(tmpObj)
                    .subscribe({
                      next: (res: any) => {
                        sessionStorage.setItem(
                          'pages_seo_url',
                          this.saveConfiguration.url
                        );
                        localStorage.removeItem('fromProjects');
                        sessionStorage.setItem('fromOnboarding', 'true');
                        this.router.navigate(['/seo/getting-started']);
                      },
                      error: (err: any) => {
                        console.log(err);
                      },
                    });
                }
              } else {
                this.carouselFunction(5);
              }
            } else {
              this.seoService.selectedTargetCompetitor(tmpObj).subscribe({
                next: (res: any) => {
                  if (this.keywordMethodSelection === 'Automatic') {
                    if (this.showKeywords) {
                      this.selectedOptionPos = 'Show All';
                      this.selectedOptionDiff = 'Top Traffic';
                      this.selectedOptionVol = 'Show All';
                      this.carouselFunction(5);
                      this.getOrganicKeywords();
                    } else {
                      let tmpObj = {
                        url: this.saveConfiguration.url,
                        country: this.saveConfiguration.country,
                        language: suggestedLanguages.filter(
                          (language: any) =>
                            language.name === this.saveConfiguration.language
                        )[0].value,
                      };

                      this.gatheringData = true;

                      this.onboardNewTargetSub = this.seoService
                        .onboardNewTarget(tmpObj)
                        .subscribe({
                          next: (res: any) => {
                            sessionStorage.setItem(
                              'pages_seo_url',
                              this.saveConfiguration.url
                            );
                            localStorage.removeItem('fromProjects');
                            sessionStorage.setItem('fromOnboarding', 'true');
                            this.router.navigate(['/seo/getting-started']);
                          },
                          error: (err: any) => {
                            console.log(err);
                          },
                        });
                    }
                  } else {
                    this.carouselFunction(5);
                  }
                },
                error: (err: any) => {
                  console.log(err);
                },
              });
            }
          } else {
            if (this.keywordMethodSelection === 'Automatic') {
              if (this.showKeywords) {
                this.selectedOptionPos = 'Show All';
                this.selectedOptionDiff = 'Top Traffic';
                this.selectedOptionVol = 'Show All';
                this.carouselFunction(5);
                this.getOrganicKeywords();
              } else {
                let tmpObj = {
                  url: this.saveConfiguration.url,
                  country: this.saveConfiguration.country,
                  language: suggestedLanguages.filter(
                    (language: any) =>
                      language.name === this.saveConfiguration.language
                  )[0].value,
                };

                this.gatheringData = true;

                this.onboardNewTargetSub = this.seoService
                  .onboardNewTarget(tmpObj)
                  .subscribe({
                    next: (res: any) => {
                      sessionStorage.setItem(
                        'pages_seo_url',
                        this.saveConfiguration.url
                      );
                      this.loading_redirect_from_web = false;
                      localStorage.removeItem('fromProjects');
                      sessionStorage.setItem('fromOnboarding', 'true');
                      this.router.navigate(['/seo/getting-started']);
                    },
                    error: (err: any) => {
                      console.log(err);
                    },
                  });
              }
            } else {
              this.carouselFunction(5);
            }
          }
        },
        error: (err: any) => {
          console.log(err);
        },
      });
    }, 100);
  }

  goToKeywordsCsv() {
    // if (this.fileUploaded) {
    //   console.log('User has uploaded the file already...');
    //   console.log(this.fileInput);
    // }
  }

  selectedOptionPos: string | null = null;
  customRangePos = { from: null, to: null };

  updateSelection(option: string): void {
    this.selectedOptionPos = option;
    this.getOrganicKeywords();
  }

  applyCustomRange(menu: MatMenu): void {
    if (this.customRangePos.from !== null && this.customRangePos.to !== null) {
      this.selectedOptionPos = `From ${this.customRangePos.from} to ${this.customRangePos.to}`;
    }
    menu.closed.emit();

    this.getOrganicKeywords();
  }

  selectedOptionVol: string | null = null;
  customRangeVol = { from: null, to: null };

  updateSelectionVol(option: string): void {
    this.selectedOptionVol = option;
    this.getOrganicKeywords();
  }

  applyCustomRangeVol(menu: MatMenu): void {
    if (this.customRangeVol.from !== null && this.customRangeVol.to !== null) {
      this.selectedOptionVol = `From ${this.customRangeVol.from} to ${this.customRangeVol.to}`;
    }
    menu.closed.emit();

    this.getOrganicKeywords();
  }

  formatNumberWithPercentge(val: number) {
    return val?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.keywordFileName = file.name;
      this.fileUploaded = true;

      if (file.type === 'text/csv') {
        const formData = new FormData();
        formData.append('file', file);

        const domain = this.saveConfiguration.url
          .replace(/^(https?:\/\/)?(www\.)?/, '')
          .split('/')[0];

        this.seoService.uploadKeywordsCsv(formData, domain).subscribe(
          (res: any) => {
            this.uploadErrorMessage = '';
            this.uploadedFileCorrect = true;
          },
          (error) => {
            this.uploadErrorMessage =
              'The uploaded .csv file does not contain the required columns';
            this.uploadedFileCorrect = false;
          }
        );
      } else {
        alert("Please upload a valid '.csv' extension file.");
        this.uploadedFileCorrect = false;
      }
    } else {
      this.uploadedFileCorrect = false;
    }
  }

  removeFile(): void {
    this.keywordFileName = 'Upload .csv file here';
    this.fileUploaded = false;

    this.uploadErrorMessage = '';
    this.uploadedFileCorrect = false;

    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  triggerFileInput(input: HTMLInputElement): void {
    input.click();
  }

  selectedOptionDiff: string | null = null;
  customRangeDiff = { from: null, to: null };

  updateSelectionKd(option: string): void {
    this.selectedOptionDiff = option;
    this.getOrganicKeywords();
  }

  resetFilters(menuType: string): void {
    if (menuType === 'pos') {
      this.selectedOptionPos = 'Show All';
    } else if (menuType === 'vol') {
      this.selectedOptionVol = 'Show All';
    } else if (menuType === 'diff') {
      this.selectedOptionDiff = 'Show All';
    }

    this.getOrganicKeywords();
  }

  lastSelectedFilters: {};
  getOrganicKeywords() {
    this.keywordsLoading = true;

    let paramsObj: any = {
      project: this.saveConfiguration.url,
      page: 1,
      pageSize: 10,
    };
    this.lastSelectedFilters = paramsObj;

    if (
      this.selectedOptionPos !== 'Show All' &&
      this.selectedOptionPos != null
    ) {
      if (this.selectedOptionPos.includes('From')) {
        paramsObj['positionFilter'] = [
          this.customRangePos.from,
          this.customRangePos.to,
        ];
      } else {
        paramsObj['positionFilter'] = this.selectedOptionPos
          .replaceAll('#', '')
          .toLocaleLowerCase();
      }
    } else {
      delete paramsObj['positionFilter'];
    }

    if (
      this.selectedOptionVol !== 'Show All' &&
      this.selectedOptionVol != null
    ) {
      if (this.selectedOptionVol.includes('From')) {
        paramsObj['SearchVolume'] = [
          this.customRangeVol.from,
          this.customRangeVol.to,
        ];
      } else {
        paramsObj['SearchVolume'] = this.selectedOptionVol
          .replaceAll(',', '')
          .toLocaleLowerCase();
      }
    } else {
      delete paramsObj['SearchVolume'];
    }

    if (
      this.selectedOptionDiff !== 'Show All' &&
      this.selectedOptionDiff != null
    ) {
      if (this.selectedOptionDiff == 'Top Traffic') {
        paramsObj['sortKey'] = 'Traffic (%)';
        paramsObj['sortOrder'] = '-1';
      } else if (this.selectedOptionDiff == 'Top Volume') {
        paramsObj['sortKey'] = 'Search Volume';
        paramsObj['sortOrder'] = '-1';
      } else if (this.selectedOptionDiff == 'Top Position') {
        paramsObj['sortKey'] = 'Position';
        paramsObj['sortOrder'] = '-1';
      }
    } else {
      delete paramsObj['sortKey'];
      delete paramsObj['sortOrder'];
    }

    this.seoService.getOrganicKeywords(paramsObj).subscribe({
      next: (response: any) => {
        this.listOfKeywords = response.keywords;
        this.totalCount = response.total;
        setTimeout(() => {
          this.keywordsLoading = false;
        }, 350);
      },
      error: (error) => {},
    });
  }

  formatComma(val: number) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  confirmSelection() {
    let tmpObj = {
      url: this.saveConfiguration.url,
      filters: this.lastSelectedFilters,
    };
    this.seoService.getSelectedKeywords(tmpObj).subscribe((res) => {
      if (res) {
        this.onboardNewTarget();
      }
    });
    sessionStorage.setItem('pages_seo_url', this.saveConfiguration.url);
    localStorage.removeItem('fromProjects');
    sessionStorage.setItem('fromOnboarding', 'true');
    this.router.navigate(['/seo/getting-started']);
  }

  uploadConfirmation() {
    this.onboardNewTarget();
    sessionStorage.setItem('pages_seo_url', this.saveConfiguration.url);
    localStorage.removeItem('fromProjects');
    sessionStorage.setItem('fromOnboarding', 'true');
    this.router.navigate(['/seo/getting-started']);
  }

  carouselFunction(index: number) {
    if (this.gatheringData) return;

    if (index == 1 && this.stepsIndex < this.maxSteps) {
      this.stepsIndex++;
    } else if (index == -1 && this.stepsIndex > 0) {
      this.stepsIndex--;
    } else if (index == 2) {
      this.stepsIndex++;
    } else if (index == 3) {
      this.stepsIndex++;
    } else if (index == 4) {
      this.stepsIndex++;
    } else if (index == 5) {
      this.stepsIndex++;
    } else if (index == 44) {
      this.stepsIndex = this.stepsIndex + 2;
    } else if (index == -11) {
      this.stepsIndex = this.stepsIndex - 2;
    }
  }

  logout() {
    this.sessionService.logout();
  }
}
