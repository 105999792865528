import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'src/app/services/pagetitle.service';

@Component({
  selector: 'app-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.css'],
})
export class UnderMaintenanceComponent implements OnInit {
  constructor(private pageTitle: PageTitleService) {}

  ngOnInit(): void {
    this.pageTitle.setTitle('Organic');
  }
}
