<div class="main-container">
  <div class="dashboard-header-intro">
    <div class="dashboard-header-intro-inner">
      <h1>Conversion Rate</h1>
      <h2 *ngIf="!initialLoader">
        {{ barChartCr[0].diff || 0 + "%" }}
      </h2>

      <p *ngIf="!initialLoader">{{ timeRangeSelected }}</p>
      <div class="skeleton-fields" *ngIf="initialLoader">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            height: '48px',
            outline: 'none',
            'margin-right': '0px',
            'margin-bottom': '0px',
            'background-color': '#37474f1a'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div class="dashboard-header-intro-inner">
      <h1>Revenue Per Visit</h1>
      <h2 *ngIf="!initialLoader">
        {{ barChartRpv[0].diff || 0 + "%" }}
      </h2>
      <p *ngIf="!initialLoader">{{ timeRangeSelected }}</p>
      <div class="skeleton-fields" *ngIf="initialLoader">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            height: '48px',
            outline: 'none',
            'margin-right': '0px',
            'margin-bottom': '0px',
            'background-color': '#37474f1a'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div class="dashboard-header-intro-inner">
      <h1>Average Order Value</h1>
      <h2 *ngIf="!initialLoader">
        {{ barChartAov[0].diff || 0 + "%" }}
      </h2>
      <p *ngIf="!initialLoader">{{ timeRangeSelected }}</p>
      <div class="skeleton-fields" *ngIf="initialLoader">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            height: '48px',
            outline: 'none',
            'margin-right': '0px',
            'margin-bottom': '0px',
            'background-color': '#37474f1a'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div class="dashboard-header-intro-inner">
      <h1 *ngIf="!nudgeLite">Revenue Uplift</h1>
      <h1 *ngIf="nudgeLite">Revenue</h1>
      <h2 *ngIf="!initialLoader">
        {{ revUplift | currencyFormatter }}
      </h2>
      <p *ngIf="!initialLoader">{{ timeRangeSelected }}</p>
      <div class="skeleton-fields" *ngIf="initialLoader">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            height: '48px',
            outline: 'none',
            'margin-right': '0px',
            'margin-bottom': '0px',
            'background-color': '#37474f1a'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>

<div>
  <div class="dtpickerall" *ngIf="firstLoader">
    <div class="master_longitudinal_main-header master_performance_main-header">
      <mat-form-field
        class="bhm-option-container-1"
        style="--label-name: 'Device:'; --form-field-infix-width: 140px"
      >
        <mat-select
          (selectionChange)="selectionDeviceChanged($event)"
          [(ngModel)]="incentiveBarChart.selectedPlatform"
        >
          <mat-option class="bhm-option-select-option-1" value="overall">
            Overall
          </mat-option>
          <mat-option class="bhm-option-select-option-1" value="desktop">
            Desktop
          </mat-option>
          <mat-option class="bhm-option-select-option-1" value="tablet">
            Tablet
          </mat-option>
          <mat-option class="bhm-option-select-option-1" value="mobile">
            Mobile
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="bhm-option-container-1"
        style="--label-name: 'Visits:'; --form-field-infix-width: 160px"
      >
        <mat-select
          (selectionChange)="filterVisits($event)"
          [value]="incentiveBarChart.selectedFilter"
        >
          <mat-option class="bhm-option-select-option-1" value="overall">
            Overall
          </mat-option>
          <mat-option class="bhm-option-select-option-1" value="newVisit">
            New Visits
          </mat-option>
          <mat-option class="bhm-option-select-option-1" value="returningVisit">
            Returning Visits
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="bhm-option-container-1"
        style="--label-name: 'Outliers:'; --form-field-infix-width: 140px"
      >
        <mat-select
          [(ngModel)]="selectedOutlier"
          (selectionChange)="onOutlierChange($event)"
        >
          <mat-option
            class="bhm-option-select-option-1"
            *ngFor="let outlier of outliers"
            [value]="outlier"
          >
            {{ outlier }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="sidebtn-action-holder">
        <button
          mat-button
          class="btn_withoutIcon custom-button"
          [disabled]="rawDataButtonDisabled"
          (click)="generateRawData()"
        >
          <span style="margin-right: 3px; top: -1px; position: relative">
            {{ rawDataButtonTxt }}
          </span>
          <svg
            _ngcontent-qwg-c14=""
            class="button_download_icon"
            style="width: 13px; position: relative; top: 1px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title _ngcontent-qwg-c14=""></title>
            <g _ngcontent-qwg-c14="" id="Complete">
              <g _ngcontent-qwg-c14="" id="download">
                <g _ngcontent-qwg-c14="">
                  <path
                    _ngcontent-qwg-c14=""
                    d="M3,12.3v7a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-7"
                    fill="none"
                    stroke="var(--text-main-color)"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  ></path>
                  <g _ngcontent-qwg-c14="">
                    <polyline
                      _ngcontent-qwg-c14=""
                      data-name="Right"
                      fill="none"
                      id="Right-2"
                      points="7.9 12.3 12 16.3 16.1 12.3"
                      stroke="var(--text-main-color)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    ></polyline>
                    <line
                      _ngcontent-qwg-c14=""
                      fill="none"
                      stroke="var(--text-main-color)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      x1="12"
                      x2="12"
                      y1="2.7"
                      y2="14.2"
                    ></line>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </button>
        <button
          mat-button
          (click)="generateReport('report')"
          [disabled]="this.daysDifference <= 1"
          class="btn_withoutIcon custom-button"
        >
          <span style="margin-right: 3px; top: -1px; position: relative">
            Report
          </span>
          <svg
            _ngcontent-qwg-c14=""
            class="button_download_icon"
            style="width: 13px; position: relative; top: 1px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title _ngcontent-qwg-c14=""></title>
            <g _ngcontent-qwg-c14="" id="Complete">
              <g _ngcontent-qwg-c14="" id="download">
                <g _ngcontent-qwg-c14="">
                  <path
                    _ngcontent-qwg-c14=""
                    d="M3,12.3v7a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-7"
                    fill="none"
                    stroke="var(--text-main-color)"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  ></path>
                  <g _ngcontent-qwg-c14="">
                    <polyline
                      _ngcontent-qwg-c14=""
                      data-name="Right"
                      fill="none"
                      id="Right-2"
                      points="7.9 12.3 12 16.3 16.1 12.3"
                      stroke="var(--text-main-color)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    ></polyline>
                    <line
                      _ngcontent-qwg-c14=""
                      fill="none"
                      stroke="var(--text-main-color)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      x1="12"
                      x2="12"
                      y1="2.7"
                      y2="14.2"
                    ></line>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="dtpickerall skeleton-fields" *ngIf="!firstLoader">
    <ngx-skeleton-loader
      count="3"
      [theme]="{
        height: '45.5px',
        outline: 'none',
        'margin-right': '15px',
        width: '180px',
        'background-color': '#37474f1a'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div
    class="dtpickerall skeleton-fields exportation-skeleton"
    *ngIf="!firstLoader"
  >
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        height: '35px',
        outline: 'none',
        'margin-right': '0px',
        width: '110px',
        'background-color': '#37474f1a'
      }"
    ></ngx-skeleton-loader>
  </div>
</div>

<div id="overall-card" style="width: 100%; flex-direction: column">
  <div
    class="ordersamount-container"
    fxFlex.gt-sm="100"
    fxFlex.gt-xs="100"
    fxFlex="100"
  >
    <div class="performance-box-orders_content">
      <ng-container *ngIf="firstLoader">
        <div class="performance-box-orders_table">
          <div class="performance-box-orders_table-header">
            <div class="performance-box-orders_table-item width80">
              <p></p>
            </div>
            <div class="performance-box-orders_table-item width120">
              <p
                matTooltip="Visits who have seen at least one nudge"
                matTooltipPosition="above"
              >
                Nudges Overall
              </p>
            </div>
            <div
              class="performance-box-orders_table-item width70"
              *ngIf="!nudgeLite"
            >
              <p
                matTooltip="Visits who would have seen at least one nudge but were in control. Control group sees no nudges at all."
                matTooltipPosition="above"
              >
                Control
              </p>
            </div>
            <div class="performance-box-orders_table-item">
              <p *ngIf="!nudgeLite">Revenue Uplift</p>
              <p *ngIf="nudgeLite">Revenue</p>
            </div>
          </div>
          <div class="performance-box-orders_table-body">
            <div class="performance-box-orders_table-row">
              <div class="performance-box-orders_table-item width80">
                <p
                  matTooltip="A visit is a session starting when the browser enters the shop and ends when the user exits the shop, i.e. closes the shop tab on browser. The visit lasts as long as the browser is open. Multiple tabs count as new visit."
                  matTooltipPosition="above"
                >
                  Visits
                </p>
              </div>
              <div class="performance-box-orders_table-item width120">
                <p *ngIf="this.chartDataCr[0].data[0] == 0.222">0</p>
                <p *ngIf="this.chartDataCr[0].data[0] != 0.222">
                  {{ incentiveBarChart.crorderAmount.incentivesAmount.visits }}
                </p>
              </div>
              <div
                class="performance-box-orders_table-item width70"
                *ngIf="!nudgeLite"
              >
                <p *ngIf="this.chartDataCr[0].data[0] == 0.222">0</p>
                <p *ngIf="this.chartDataCr[0].data[0] != 0.222">
                  {{ incentiveBarChart.crorderAmount.controlAmount.visits }}
                </p>
              </div>
              <div class="performance-box-orders_table-item">
                <p
                  [ngClass]="{
                    'uplift-green': checkUpliftColor(this.revUplift) === true,
                    'uplift-red': checkUpliftColor(this.revUplift) === false,
                    'uplift-none': checkUpliftColor(this.revUplift) === 0
                  }"
                  style="white-space: nowrap"
                >
                  {{ revUplift | currencyFormatter }}
                </p>
              </div>
            </div>
            <div class="performance-box-orders_table-row">
              <div class="performance-box-orders_table-item width80">
                <p>Orders</p>
              </div>
              <div class="performance-box-orders_table-item width120">
                <p *ngIf="this.chartDataCr[0].data[0] == 0.222">0</p>
                <p *ngIf="this.chartDataCr[0].data[0] != 0.222">
                  {{ incentiveBarChart.crorderAmount.incentivesAmount.orders }}
                </p>
              </div>
              <div
                class="performance-box-orders_table-item width70"
                *ngIf="!nudgeLite"
              >
                <p *ngIf="this.chartDataCr[0].data[0] == 0.222">0</p>
                <p *ngIf="this.chartDataCr[0].data[0] != 0.222">
                  {{ incentiveBarChart.crorderAmount.controlAmount.orders }}
                </p>
              </div>
              <div class="performance-box-orders_table-item">
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="performance-box-orders_content-divider"
          *ngIf="!nudgeLite"
        ></div>
        <div class="performance-box-orders_headless_table">
          <div
            class="performance-box-orders_headless_table-item"
            *ngIf="!nudgeLite"
          >
            <p><b>Share of Total Revenue</b></p>
            <p class="performance-box-orders_headless_table-value">
              {{ formatPercentage(this.totalInfo.share.revenueShare) }}
            </p>
            <div class="rev-share-hidden">
              <p>
                The Share of Total Revenue is the Revenue Uplift driven by
                behamics divided by the Total Revenue:
              </p>
              <p>
                <span
                  [ngClass]="{
                    'uplift-green': checkUpliftColor(this.revUplift) === true,
                    'uplift-red': checkUpliftColor(this.revUplift) === false,
                    'uplift-none': checkUpliftColor(this.revUplift) === 0
                  }"
                  >{{ formatNumber(this.revUplift) }}</span
                >
                /
                {{
                  this.isNaN(this.totalInfo.total.totalRevenue)
                    ? 0
                    : this.totalInfo.total.totalRevenue
                }}
                =
                {{ this.getFormattedValue(this.totalInfo.share.revenueShare) }}
              </p>
            </div>
          </div>
          <div
            class="performance-box-orders_headless_table-item"
            *ngIf="!nudgeLite"
          >
            <p><b>Share of Traffic</b></p>
            <p class="performance-box-orders_headless_table-value">
              {{ formatPercentage(this.totalInfo.share.trafficShare) }}
            </p>
            <div class="rev-share-hidden">
              <p>
                The Share of Traffic is the sum of the visits in the Nudge and
                Control Group divided by the total number of visits:
              </p>
              <p>
                {{
                  this.safeSum(
                    this.totalInfo.difference.visitsIncentive,
                    this.totalInfo.difference.visitsControl
                  )
                }}
                /
                {{
                  this.safeSum(
                    this.totalInfo.difference.v,
                    this.totalInfo.difference.vgc
                  )
                }}
                =
                {{ this.getTrafficShare() }}
              </p>
            </div>
          </div>
          <div
            class="performance-box-orders_headless_table-item"
            *ngIf="!nudgeLite"
          >
            <p><b>Share of Orders</b></p>
            <p class="performance-box-orders_headless_table-value">
              {{ formatPercentage(this.totalInfo.share.ordersShare) }}
            </p>
            <div class="rev-share-hidden">
              <p>
                The Share of Orders is the sum of the orders in the Nudge and
                Control Group divided by the total number of orders:
              </p>
              <p>
                {{
                  this.safeSum(
                    this.totalInfo.difference.ordersIncentive,
                    this.totalInfo.difference.ordersControl
                  )
                }}
                /
                {{
                  this.safeSum(
                    this.totalInfo.difference.o,
                    this.totalInfo.difference.ogc
                  )
                }}
                =
                {{ this.getOrdersShare() }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="skeleton-lines" *ngIf="!firstLoader">
      <ngx-skeleton-loader
        count="3"
        [theme]="{
          height: '18px',
          outline: 'none',
          'background-color': '#37474f1a'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>

  <div class="sq-title-loader" *ngIf="!firstLoader">
    <div fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100">
      <div class="skeleton-title-squares">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            height: '20px',
            outline: 'none',
            'background-color': '#37474f1a'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="skeleton-box-square">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            height: '290px',
            outline: 'none',
            'background-color': '#37474f1a'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100">
      <div class="skeleton-title-squares">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            height: '20px',
            outline: 'none',
            'background-color': '#37474f1a'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="skeleton-box-square">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            height: '290px',
            outline: 'none',
            'background-color': '#37474f1a'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100">
      <div class="skeleton-title-squares">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            height: '20px',
            outline: 'none',
            'background-color': '#37474f1a'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="skeleton-box-square">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            height: '290px',
            outline: 'none',
            'background-color': '#37474f1a'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <div
    class="pov-bar-charts-holder"
    *ngIf="firstLoader"
    [ngClass]="{
      nudgeLite: nudgeLite
    }"
  >
    <div class="pov-pageBarChart-container">
      <div class="pov-pageBarChart-axis">
        <h5>CR</h5>
        <div
          class="pov-pageBarChart-axis-inner"
          *ngFor="let axis of barChartCrAxis"
        >
          <p>{{ axis }}%</p>
        </div>
      </div>
      <div class="pov-pageBarChart-main">
        <div
          class="pov-pageBarChart-main-charts"
          *ngFor="let bar of barChartCr; let i = index"
          [ngClass]="{
            controlBar: bar.name == 'Control'
          }"
        >
          <div class="pov-pageBarChart-main-charts-bar-container">
            <div
              class="pov-pageBarChart-main-charts-bar"
              [ngClass]="{
                defaultCol: bar.name == 'Control'
              }"
              [ngStyle]="{ height: bar.height + '%' }"
            >
              <div
                [ngClass]="{ zeroBarVal: bar.value == 0 }"
                class="pov-pageBarChart-main-charts-bar-innervalue"
              >
                <div>{{ bar.value }}%</div>
                <div *ngIf="bar.name != 'Control' && bar.diff && !nudgeLite">
                  ({{ bar.diff }})
                </div>
              </div>
              <div
                class="pov-pageBarChart-main-charts-bar-tooltip"
                *ngIf="bar.tooltip && !nudgeLite"
              >
                <b>Sig. Control:</b> {{ bar.tooltip }}
              </div>
            </div>
          </div>
          <div class="pov-pageBarChart-main-charts-name">
            <p>{{ bar.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pov-pageBarChart-container">
      <div class="pov-pageBarChart-axis">
        <h5>RPV</h5>
        <div
          class="pov-pageBarChart-axis-inner"
          *ngFor="let axis of barChartRpvAxis"
        >
          <p>{{ axis | currencyFormatter }}</p>
        </div>
      </div>
      <div class="pov-pageBarChart-main">
        <div
          class="pov-pageBarChart-main-charts"
          *ngFor="let bar of barChartRpv; let i = index"
          [ngClass]="{
            controlBar: bar.name == 'Control'
          }"
        >
          <div class="pov-pageBarChart-main-charts-bar-container">
            <div
              class="pov-pageBarChart-main-charts-bar"
              [ngClass]="{
                defaultCol: bar.name == 'Control'
              }"
              [ngStyle]="{ height: bar.height + '%' }"
            >
              <div
                [ngClass]="{ zeroBarVal: bar.value == 0 }"
                class="pov-pageBarChart-main-charts-bar-innervalue"
              >
                {{ bar.value | currencyFormatter }}
                <div *ngIf="bar.name != 'Control' && bar.diff && !nudgeLite">
                  ({{ bar.diff }})
                </div>
              </div>

              <div
                class="pov-pageBarChart-main-charts-bar-tooltip"
                *ngIf="bar.tooltip && !nudgeLite"
              >
                <b>Sig. Control:</b> {{ bar.tooltip }}
              </div>
            </div>
          </div>
          <div class="pov-pageBarChart-main-charts-name">
            <p>{{ bar.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pov-pageBarChart-container">
      <div class="pov-pageBarChart-axis">
        <h5>AOV</h5>
        <div
          class="pov-pageBarChart-axis-inner"
          *ngFor="let axis of barChartAovAxis"
        >
          <p>{{ axis | currencyFormatter }}</p>
        </div>
      </div>
      <div class="pov-pageBarChart-main">
        <div
          class="pov-pageBarChart-main-charts"
          *ngFor="let bar of barChartAov; let i = index"
          [ngClass]="{
            controlBar: bar.name == 'Control'
          }"
        >
          <div class="pov-pageBarChart-main-charts-bar-container">
            <div
              class="pov-pageBarChart-main-charts-bar"
              [ngClass]="{
                defaultCol: bar.name == 'Control'
              }"
              [ngStyle]="{ height: bar.height + '%' }"
            >
              <div
                [ngClass]="{ zeroBarVal: bar.value == 0 }"
                class="pov-pageBarChart-main-charts-bar-innervalue"
              >
                {{ bar.value | currencyFormatter }}
                <div *ngIf="bar.name != 'Control' && bar.diff && !nudgeLite">
                  ({{ bar.diff }})
                </div>
              </div>
              <div
                class="pov-pageBarChart-main-charts-bar-tooltip"
                *ngIf="bar.tooltip && !nudgeLite"
              >
                <b>Sig. Control:</b> {{ bar.tooltip }}
              </div>
            </div>
          </div>
          <div class="pov-pageBarChart-main-charts-name">
            <p>{{ bar.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pov-pageBarChart-container" *ngIf="!receivedInfoRrNull">
      <div class="pov-pageBarChart-axis">
        <h5>RR</h5>
        <div
          class="pov-pageBarChart-axis-inner"
          *ngFor="let axis of barChartRRAxis"
        >
          <p>{{ axis }}%</p>
        </div>
      </div>
      <div class="pov-pageBarChart-main">
        <div
          class="pov-pageBarChart-main-charts"
          *ngFor="let bar of barChartRR; let i = index"
        >
          <div class="pov-pageBarChart-main-charts-bar-container">
            <div
              class="pov-pageBarChart-main-charts-bar"
              [ngClass]="{
                defaultCol: bar.name == 'Control'
              }"
              [ngStyle]="{ height: bar.height + '%' }"
            >
              <div
                [ngClass]="{ zeroBarVal: bar.value == 0 }"
                class="pov-pageBarChart-main-charts-bar-innervalue"
              >
                {{ bar.value }}%
                <div *ngIf="bar.name != 'Control'">({{ bar.diff }})</div>
              </div>
              <div
                class="pov-pageBarChart-main-charts-bar-tooltip"
                *ngIf="bar.tooltip"
              >
                <b>Sig. Control:</b> {{ bar.tooltip }}
              </div>
            </div>
          </div>
          <div class="pov-pageBarChart-main-charts-name">
            <p>{{ bar.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pov-pageBarChart-container" *ngIf="!receivedInfoNrNull">
      <div class="pov-pageBarChart-axis">
        <h5>NR</h5>
        <div
          class="pov-pageBarChart-axis-inner"
          *ngFor="let axis of barChartNRAxis"
        >
          <p>{{ axis | currencyFormatter }}</p>
        </div>
      </div>
      <div class="pov-pageBarChart-main">
        <div
          class="pov-pageBarChart-main-charts"
          *ngFor="let bar of barChartNR; let i = index"
        >
          <div class="pov-pageBarChart-main-charts-bar-container">
            <div
              class="pov-pageBarChart-main-charts-bar"
              [ngClass]="{
                defaultCol: bar.name == 'Control'
              }"
              [ngStyle]="{ height: bar.height + '%' }"
            >
              <div
                [ngClass]="{ zeroBarVal: bar.value == 0 }"
                class="pov-pageBarChart-main-charts-bar-innervalue"
              >
                {{ bar.value | currencyFormatter }}
                <div *ngIf="bar.name != 'Control'">({{ bar.diff }})</div>
              </div>
              <div
                class="pov-pageBarChart-main-charts-bar-tooltip"
                *ngIf="bar.tooltip"
              >
                <b>Sig. Control:</b> {{ bar.tooltip }}
              </div>
            </div>
          </div>
          <div class="pov-pageBarChart-main-charts-name">
            <p>{{ bar.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loading-data-overlay" *ngIf="!chartLoader">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<!-- Performance Overall second container-->

<!-- <app-detailed></app-detailed> -->
