<div class="recorder_modal create-recorder-heatmap confirmation_modal">
  <p class="recorder_modal_title">{{ header }}</p>
  <div class="recorder_modal_text">{{ content }}</div>
  <div class="button_container">
    <ng-container *ngIf="data.confirmOnly">
      <button
        mat-raised-button
        class="primary"
        (click)="this.dialogRef.close(1)"
        #btnFocus="matButton"
        [autofocus]="btnFocus.focus()"
        [class.secondary-style]="confirmButtonDangerStyle"
      >
        {{ confirmButtonDangerStyle ? confirm_btn : "OK" }}
      </button>
    </ng-container>
    <ng-container *ngIf="!data.confirmOnly">
      <button
        mat-raised-button
        (click)="this.dialogRef.close(2)"
        class="seo_cancel"
      >
        {{ cancel_btn }}
      </button>
      <button
        mat-raised-button
        class="primary"
        (click)="this.dialogRef.close(1)"
        #btnFocus="matButton"
        [autofocus]="btnFocus.focus()"
        [class.secondary-style]="confirmButtonDangerStyle"
      >
        {{ confirm_btn }}
      </button>
    </ng-container>
  </div>
</div>
<!-- <h1 mat-dialog-title>{{ this.data.header }}</h1>
<div mat-dialog-content>
  <p>{{ this.data.content }}</p>
</div>
<div mat-dialog-actions style="float: right">
  <button mat-button (click)="this.dialogRef.close(1)" cdkFocusInitial>
    Yes
  </button>
  <button mat-button (click)="this.dialogRef.close(2)">Cancel</button>
</div> -->
