<ng-container *ngIf="firstLoader">
  <seo-premium-banner *ngIf="showPremiumSeoBanner"></seo-premium-banner>
  <!-- Users config - start -->
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0 20px 0;
    "
  >
    <h2 class="custom_table-container-h2 subtitle-h2">
      {{ "words.users" | translate }}
    </h2>
    <div style="display: flex; gap: 10px">
      <button
        style="background-color: #635bff !important"
        class="custom-button"
        mat-raised-button
        type="button"
        (click)="addUserDialog(false, null, false)"
        [disabled]="seoSubscription != 'Premium'"
      >
        <mat-icon class="button-icon" style="color: #fff">add</mat-icon>
        <span style="color: #fff">{{ "words.invite_user" | translate }}</span>
      </button>
    </div>
  </div>
  <div class="custom_table-container">
    <table class="custom_table">
      <thead>
        <tr>
          <th>
            <div>{{ "words.name" | translate }}</div>
          </th>
          <th>
            <div>{{ "words.email_address" | translate }}</div>
          </th>
          <th>
            <div>{{ "words.role" | translate }}</div>
          </th>
          <th>
            <div>{{ "words.status" | translate }}</div>
          </th>
          <th class="text_align-right">
            <div>{{ "words.actions" | translate }}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngIf="
            receivedUserList && receivedUserList.length > 0;
            else noTableData
          "
        >
          <tr *ngFor="let user of receivedUserList; let i = index">
            <td>
              {{ user.name }}
            </td>
            <td>
              {{ user.email }}
            </td>
            <td>{{ user.role }}</td>
            <td>
              <ng-container *ngIf="user.active || user.active == undefined">
                {{ "words.active" | translate }}
              </ng-container>
              <ng-container *ngIf="!user.active && user.active != undefined">
                {{ "words.pending" | translate }}
              </ng-container>
            </td>
            <td class="text_align-right">
              <div
                class="custom_table-editButton custom_table-buttons_container"
              >
                <i
                  *ngIf="!user.active && user.active != undefined"
                  matTooltip="Resend Email"
                  class="fas fa-paper-plane"
                  (click)="resendUserEmail(user, i)"
                ></i>
                <i
                  *ngIf="seoSubscription == 'Premium'"
                  matTooltip="Edit User"
                  class="fa fa-edit"
                  (click)="addUserDialog(true, user, false)"
                ></i>
                <i
                  *ngIf="seoSubscription != 'Premium'"
                  style="opacity: 0.7; cursor: not-allowed"
                  matTooltip="Edit User"
                  class="fa fa-edit"
                ></i>
                <i
                  *ngIf="checkSelfEmail(user)"
                  matTooltip="Delete User"
                  class="fa fa-times text-danger"
                  (click)="deleteUserDialog(user)"
                ></i>
              </div>
            </td>
          </tr>
        </ng-container>
        <ng-template #noTableData>
          <tr>
            <td colspan="10">No Users Found!</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
</ng-container>
<!-- Skeleton fields -->
<ng-container *ngIf="!firstLoader">
  <div>
    <ngx-skeleton-loader
      count="6"
      [theme]="{
        height: '40px',
        outline: 'none',
        'margin-bottom': '16px',
        'background-color': '#37474f1a'
      }"
    ></ngx-skeleton-loader>
    <div style="margin-bottom: 20px"></div>
  </div>
  <div style="display: flex; gap: 20px">
    <div style="flex: 1" *ngFor="let a of [].constructor(5)">
      <ngx-skeleton-loader
        count="6"
        [theme]="{
          height: '40px',
          outline: 'none',
          'margin-bottom': '16px',
          'background-color': '#37474f1a'
        }"
      ></ngx-skeleton-loader>
      <div style="margin-bottom: 20px"></div>
    </div>
  </div>
</ng-container>
