import { Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

declare const behamics: any;

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  constructor() {}

  private minDate = new BehaviorSubject('');

  private activeTabIndex = new BehaviorSubject(0);

  private seoProject = new BehaviorSubject('');

  private sidenavComponent = new BehaviorSubject('');

  getMinDate() {
    return this.minDate.asObservable();
  }

  childData$(): Observable<any> {
    return this.sidenavComponent.asObservable();
  }

  setMinDate(date: string) {
    this.minDate.next(date);
  }

  getActiveTabIndex() {
    if (sessionStorage.getItem('activeTabIndex')) {
      this.activeTabIndex.next(
        parseInt(sessionStorage.getItem('activeTabIndex') || '')
      );
      this.removeActiveTabIndex();
    }
    return this.activeTabIndex.asObservable();
  }
  updateActiveTabIndex(index: number) {
    sessionStorage.setItem('activeTabIndex', index.toString());
    this.activeTabIndex.next(index);
  }
  removeActiveTabIndex() {
    sessionStorage.removeItem('activeTabIndex');
  }
  getSeoProject() {
    return this.seoProject.asObservable();
  }
  setSeoProject(project: string) {
    sessionStorage.setItem('selectedProject', JSON.stringify(project));
    this.seoProject.next(project);
  }
  sendDataToSidenav(project: any) {
    this.sidenavComponent.next(project);
  }

  private statusUpdate$ = new Subject<number>();
  statusUpdateObservable = this.statusUpdate$.asObservable();

  updateProjectStatus(status: number) {
    this.statusUpdate$.next(status);
  }

  triggerPageChange() {
    // document.body.classList.add('page_change');

    // setTimeout(() => {
    //   document.body.classList.remove('page_change');
    // }, 10);
    try {
      behamics.run();
    } catch (e) {
      console.log(e);
    }
  }
}
