import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { SessionService } from '../session.service';

@Injectable()
export class MasterGuardService implements CanActivate {
  constructor(private router: Router, private sessionService: SessionService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const role = localStorage.getItem('privileges')?.split(',') || [];

    if (role.findIndex((priv) => priv == route.data['privileges']) < 0) {
      let seoLinksIndex = 0;
      for (let i = 0; i < role.length; i++) {
        if (role[i].includes('seo') || role[i] == 'userPermissions') {
          seoLinksIndex += 1;
        }
      }

      if (seoLinksIndex == role.length) {
        sessionStorage.setItem('seoOnly', 'true');
      } else {
        sessionStorage.removeItem('seoOnly');
      }

      let privilegeRoute = this.sessionService.routesWithPrivileges.find(
        (route: any) => role.includes(route.privileges)
      )?.path;

      if (privilegeRoute) {
        this.router.navigate([privilegeRoute]);
      } else {
        this.router.navigate(['dashboard']);
      }

      return false;
    }

    return true;
  }
}
