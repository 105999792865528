import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TargetService } from '../sidenav/target.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { SeoService } from '../seo/seo.service';
import { navigationLinks } from '../sidenav/navigationLinks';
import { IntercomService } from '../services/intercom.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public user: any = null;

  // here we will store all the routes as objects with {path: '...', privileges: '...'} , in order to get specific path from privileges
  routesWithPrivileges: any = [];

  constructor(
    private _http: HttpClient,
    private router: Router,
    private targetList: TargetService,
    private seoService: SeoService,
    private intercomService: IntercomService
  ) {
    this.constructRoutesWithPrivileges();
  }

  constructRoutesWithPrivileges() {
    for (let i = 0; i < navigationLinks.length; i++) {
      if (
        navigationLinks[i].children &&
        navigationLinks[i].children.length > 0
      ) {
        for (let j = 0; j < navigationLinks[i].children.length; j++) {
          let routerLink = navigationLinks[i].children[
            j
          ].routerLink?.startsWith('')
            ? navigationLinks[i].children[j].routerLink.replace('/', '')
            : navigationLinks[i].children[j].routerLink;

          this.routesWithPrivileges.push({
            path: routerLink,
            privileges: navigationLinks[i].children[j].privilege,
          });
        }
      } else {
        let routerLink = navigationLinks[i].routerLink?.startsWith('')
          ? navigationLinks[i]?.routerLink?.replace('/', '')
          : navigationLinks[i]?.routerLink;

        this.routesWithPrivileges.push({
          path: routerLink,
          privileges: navigationLinks[i].privilege,
        });
      }
    }
  }

  login(data: any) {
    this.constructRoutesWithPrivileges();

    const selectedTarget = data['user'].target?.split(',')[0];

    this.user = data['user'];
    localStorage.setItem('auth_key', data['user'].token);
    localStorage.setItem('id', data['user'].id);
    localStorage.setItem('name', data['user'].name);
    localStorage.setItem('email', data['user'].email);
    localStorage.setItem('role', data['user'].role);
    // localStorage.setItem('privileges', data['user'].userPrivileges);
    localStorage.setItem('target', selectedTarget);
    localStorage.setItem('selectedTarget', selectedTarget);
    localStorage.setItem('description', data['user'].description);
    localStorage.setItem('currency', data['user'].currency);
    localStorage.setItem('master', data['user'].master);
    localStorage.setItem('bugSubscription', data['user'].bugSubscription);
    localStorage.setItem(
      'seoSubscriptionObj',
      JSON.stringify(data['user'].seoSubscription)
    );
    localStorage.setItem('seo_roles', JSON.stringify(data['user'].seo_roles));

    if (data['user'].customerId) {
      localStorage.setItem('customerId', data['user'].customerId);
    }

    // localStorage.setItem('selectedTarget', data['user'].target?.split(',')[0]);

    let allUserTargetPrivileges: any;
    if (data['user'].userPrivilegesTest) {
      allUserTargetPrivileges = data['user'].userPrivilegesTest;
    } else {
      allUserTargetPrivileges = data['user'].userPrivileges;
    }

    localStorage.setItem(
      'allUserTargetPrivileges',
      JSON.stringify(allUserTargetPrivileges)
    );

    let privileges = allUserTargetPrivileges.find((user: any) => {
      return user.shopID === selectedTarget;
    })?.priv;

    if (!allUserTargetPrivileges || allUserTargetPrivileges?.length == 0) {
      privileges = data['user'].userPrivileges;
    } else if (!privileges || privileges?.length == 0) {
      privileges = allUserTargetPrivileges?.[0]?.priv;
    }

    localStorage.setItem('privileges', privileges);

    // Nudge Lite Detector
    if (data['user'].ratio == 0.01) {
      localStorage.setItem('nudgeLite', 'true');
    }

    // Region Check
    if (data['user'].region == 'west-eu') {
      localStorage.setItem('region', 'west-eu');
    } else if (data['user'].region == 'central-us') {
      localStorage.setItem('region', 'central-us');
    } else {
      localStorage.setItem('region', 'west-eu');
    }

    this.intercomService.initIntercom();
    this.isloggedIn = true;
  }

  logout() {
    localStorage.removeItem('auth_key');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('id');
    localStorage.removeItem('role');
    localStorage.removeItem('privileges');
    localStorage.removeItem('target');
    localStorage.removeItem('targetName');
    localStorage.removeItem('selectedTarget');
    localStorage.removeItem('description');
    localStorage.removeItem('rangeValue');
    localStorage.removeItem('currency');
    localStorage.removeItem('kpiSymbol');
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    localStorage.removeItem('bugSubscription');
    localStorage.removeItem('master');
    localStorage.removeItem('selectedProject');
    localStorage.removeItem('socket_object');
    localStorage.removeItem('region');
    localStorage.removeItem('nudgeLite');
    localStorage.removeItem('seo_roles');
    localStorage.removeItem('seoSubscriptionObj');
    localStorage.removeItem('seoSubscription');
    localStorage.removeItem('activeTab');
    localStorage.removeItem('activeTabAiAnalysis');
    localStorage.removeItem('organic_onboarding_redirect');
    localStorage.removeItem('customerId');
    sessionStorage.removeItem('targetName');
    sessionStorage.removeItem('selectedTarget');
    sessionStorage.removeItem('currency');
    sessionStorage.clear();

    // Intercom
    this.intercomService.shutdown();

    this.isloggedIn = false;
    this.user = null;
    this.router.navigateByUrl('/login');
  }

  authenticated(email: string, password: string, twoFARequired?: string) {
    const payload: any = {
      email: email,
      password: password,
    };

    if (twoFARequired) {
      payload.twoFARequired = twoFARequired;
    }
    return this._http.post('/authenticate', payload);
  }

  resetRequest(email: string) {
    return this._http.post('/resetPasswordRequest', { email: email });
  }

  resetPassword(password: string, token: string, userID: string) {
    var params = '?token=' + token + '&userId=' + userID;
    return this._http.post('/resetPassword' + params, { password: password });
  }

  private redirectUrl: string = '/';
  private loginUrl: string = '/login';
  private isloggedIn: boolean = false;

  isUserLoggedIn(): boolean {
    this.isKey();
    return this.isloggedIn;
  }
  isKey() {
    var key = localStorage.getItem('auth_key');

    if (key != null) {
      this.isloggedIn = true;
    } else {
      this.isloggedIn = false;
    }
  }

  private loginMoreInfo = new BehaviorSubject('');

  getLoginMoreInfo() {
    return this.loginMoreInfo.asObservable();
  }
  setLoginMoreInfo(info: string) {
    this.loginMoreInfo.next(info);
  }

  navigate(user?: any) {
    // const getRouteFromPrivilege = (navigation: any[], privileges: string[]) => {
    //   try {
    //     for (let i = 0; i < navigation?.length; i++) {
    //       for (let j = 0; j < privileges.length; j++) {
    //         if (navigation[i].privilege == privileges[j]) {
    //           // console.log('navigation[i]', navigation[i], privileges[j]);
    //           console.log(i, j);
    //           return navigation[i].routerLink;
    //         } else if (navigation[i].children?.length > 0) {
    //           console.log('children', i, j);
    //           const value: any = getRouteFromPrivilege(
    //             navigation[i].children,
    //             privileges
    //           );
    //           if (value) {
    //             return value;
    //           }
    //         }
    //       }
    //     }
    //     return 'dashboard';
    //   } catch (e) {
    //     console.log(e);
    //     return 'dashboard';
    //   }
    // };

    this.targetList
      .getCurrentUserRoles(localStorage.getItem('email'))
      .subscribe({
        next: (data) => {
          // const privileges = data['privileges'] || [];
          let allUserTargetPrivileges = data['privileges'] || [];
          if (data.userPrivilegesTest) {
            allUserTargetPrivileges = data['userPrivilegesTest'];
          } else {
            allUserTargetPrivileges = data['privileges'];
          }

          const selectedTarget = localStorage.getItem('selectedTarget');

          let privileges =
            allUserTargetPrivileges.find((user: any) => {
              return user.shopID === selectedTarget;
            })?.priv || [];

          if (
            !allUserTargetPrivileges ||
            allUserTargetPrivileges?.length == 0
          ) {
            privileges = data['privileges'];
          } else if (!privileges || privileges?.length == 0) {
            privileges = allUserTargetPrivileges?.[0]?.priv;
          }

          let seoLinksIndex = 0;
          if (privileges.length > 0) {
            for (let i = 0; i < privileges.length; i++) {
              if (privileges[i].includes('seo')) {
                seoLinksIndex += 1;
              }
            }
            if (seoLinksIndex == privileges.length) {
              let tmpUser = user?.user;
              if (tmpUser && tmpUser.totalAnalysis) {
                this.seoService.getProjects().subscribe({
                  next: (res) => {
                    if (!res || res?.length == 0) {
                      this.router.navigate(['organic_onboarding']);
                    } else {
                      this.router.navigate(['seo/getting-started']);
                    }
                  },
                  error: (err) => {},
                });
              } else {
                this.router.navigate(['seo/getting-started']);
              }
            } else {
              this.router.navigate(['dashboard']);
            }
            this.setLoginMoreInfo('');

            // console.log(
            //   'getRouteFromPrivilege::: ',
            //   getRouteFromPrivilege(navigationLinks, privileges)
            // );
          } else {
            this.setLoginMoreInfo(
              'Login successful, but this account has no privileges. Please contact admin.'
            );
            this.router.navigate(['login']);
          }
        },
        error: (e) => {
          this.setLoginMoreInfo(
            'Login successful, but this account has no privileges. Please contact admin.'
          );
          this.router.navigate(['login']);
        },
      });
  }

  public getToken() {
    return localStorage.getItem('auth_key');
  }
  public getTarget() {
    return localStorage.getItem('target');
  }
  public getId() {
    return localStorage.getItem('id');
  }

  getRedirectUrl(): string {
    return this.redirectUrl;
  }
  setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }
  getLoginUrl(): string {
    return this.loginUrl;
  }
  logoutUser(): void {
    this.isloggedIn = false;
  }
  getCurrentUserRoles(email: any): Observable<any> {
    return this._http.get('/privileges/userPrivileges/user?email=' + email);
  }
  getMicrosoftLoginUrl(): Observable<any> {
    return this._http.get('/authenticate/azure/login');
  }
  signup(data: any): Observable<any> {
    return this._http.post('/signup', data);
  }
}

declare global {
  interface Window {
    Intercom: any;
  }
}
