import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { Interceptor } from './utils/interceptor.interceptor';
import { AppComponent } from './app.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
// Guards
import { AuthcheckerGuard } from './authentication/guard/authchecker.guard';
import { MasterGuardService } from './authentication/guard/masterchecker.guard';

// Components
import { OrganicLoginComponent } from './session/organic/login/login.component';
import { SignupOrganicComponent } from './session/organic/signup/signup.component';
import { LoginComponent } from './session/login/login.component';
import { ResetpasswordComponent } from './session/resetpassword/resetpassword.component';
import { SetpasswordComponent } from './session/setpassword/setpassword.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { BackendMetricsComponent } from './metrics/backend-metrics/backend-metrics.component';
import { DownloadServiceWorkerComponent } from './seo/download-service-worker/download-service-worker.component';
import { UserPermissionsComponent } from './user-permissions/user-permissions.component';
import { UserPermissionsActionComponent } from './user-permissions/modals/user-action/user-action.component';
import { SetupComponent } from './ads/setup/setup.component';
import { SharedModule } from './shared-module/shared-module.module';
import { UnderMaintenanceComponent } from './banners/under-maintenance/under-maintenance.component';
import { PaymentConfirmedComponent } from './invoices/payment-confirmed/payment-confirmed.component';

// Translate module
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';
import { HttpLoaderFactory } from './core/translate/app.translate-loader';

export class CustomMissingTranslationHandler
  implements MissingTranslationHandler
{
  handle(params: MissingTranslationHandlerParams) {
    // console.warn(`Missing translation for: ${params.key}`);
    return params.key;
  }
}

const config: SocketIoConfig = {
  url: 'https://automation.behamics.com',
  options: {
    extraHeaders: {
      'X-SOCKETIO-AUTH': 'behamics-socketio',
    },
    path: '/socket.io',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupOrganicComponent,
    OrganicLoginComponent,
    ResetpasswordComponent,
    SetpasswordComponent,
    SidenavComponent,
    BackendMetricsComponent,
    DownloadServiceWorkerComponent,
    UserPermissionsComponent,
    UserPermissionsActionComponent,
    UnderMaintenanceComponent,
    SetupComponent,
    PaymentConfirmedComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SocketIoModule.forRoot(config),
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
    }),
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    AuthcheckerGuard,
    MasterGuardService,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
