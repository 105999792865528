import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExperimentsService {
  constructor(private http: HttpClient) {}

  getOptimizationStatus(data: any) {
    return this.http.post<any>('/optimizationStatus', {
      nudge: data.nudgeID,
      platform: data.platform,
    });
  }
  createExperiment(data: any) {
    return this.http.post<any>('/create-experiment', {
      ...data,
    });
  }
  getExperiments(
    target: string,
    search: string,
    page: number,
    sortType: string,
    online: boolean
  ) {
    let params = '';

    if (search == 'Show All') {
      search = '';
    }

    if (target != '') {
      params =
        '?target=' +
        target +
        '&search=' +
        search +
        '&page=' +
        page +
        '&sort=' +
        sortType;
    } else {
      params = '?search=' + search + '&page=' + page + '&sort=' + sortType;
    }

    if (online) {
      params += '&online=true';
    }

    return this.http.get<any>('/experiments' + params);
  }
  getExperimentPerformance(data: any) {
    let params =
      '?experimentName=' +
      data.experimentName +
      '&startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate;
    return this.http.get<any>('/experiment-performance' + params);
  }
  getRollingRpv(data: any) {
    let params =
      '?experimentName=' +
      data.experimentName +
      '&startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate;
    return this.http.get<any>('/getRollingRpv' + params);
  }
  getRollingRevenue(data: any) {
    let params =
      '?target=' +
      data.target +
      '&startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&defaultNudge=' +
      data.defaultNudgeId +
      '&AINudge=' +
      data.aiNudge +
      '&mobile=' +
      data.mobile +
      '&desktop=' +
      data.desktop +
      '&rate=' +
      data.rate;

    return this.http.get<any>('/rolling-revenue' + params);
  }
  deviceincentiveofflinetoggle(data: any) {
    return this.http.post<any>('/deviceincentiveofflinetoggle', {
      ...data,
    });
  }
  deviceincentiveonlinetoggle(data: any) {
    return this.http.post<any>('/deviceincentiveonlinetoggle', {
      ...data,
    });
  }
  createExperimentCampaign(data: any) {
    return this.http.post<any>('/create-experimentCampaign', {
      ...data,
    });
  }
  getEventTypes() {
    return this.http.get<any>('/client-side/builder/eventTypes');
  }
  getRunningMethod() {
    return this.http.get<any>('/client-side/builder/runningMethods');
  }
  getClientSideExperiments(
    target: string,
    search: string,
    page: number,
    sortType: string
  ) {
    let params = '';
    if (target != '') {
      params = '?target=' + target;
    }
    if (search != '') {
      params = params.includes('?')
        ? params + '&search=' + search
        : params + '?search=' + search;
    }
    if (page != 0) {
      params = params.includes('?')
        ? params + '&page=' + page
        : params + '?page=' + page;
    }
    if (sortType != '') {
      params = params.includes('?')
        ? params + '&sort=' + sortType
        : params + '?sort=' + sortType;
    }
    return this.http.get<any>('/client-side/experiments/all' + params);
  }
  createClientSideExperiment(data: any) {
    return this.http.post<any>('/client-side/experiments/create', {
      ...data,
    });
  }
  createClientVariant(data: any) {
    return this.http.post<any>(
      '/client-side/experiments/builder/createVariant',
      {
        ...data,
      }
    );
  }
  getClientVariants(data: any) {
    return this.http.get<any>(
      `/client-side/experiment/variants?target=${data.target}&siteID=${data.siteID}&boxID=${data.boxID}&ruleID=${data.ruleID}`
    );
  }
  getClientAttributes(target: string, page: string) {
    return this.http.get<any>(
      `/client-side/builder/attributes?target=${target}&page=${page}`
    );
  }
  getClientOperators() {
    return this.http.get<any>(`/client-side/experiments/builder/operators`);
  }
  deleteIncentive(data: any) {
    return this.http.post('/deleteincentive', {
      ...data,
    });
  }
  enableIncentive(data: any) {
    return this.http.post('/setincentiveonline', {
      ...data,
    });
  }
  disableIncentive(data: any) {
    return this.http.post('/setincentiveoffline', {
      ...data,
    });
  }
  editClientVariant(data: any) {
    return this.http.put<any>('/client-side/experiments/builder/editVariant', {
      ...data,
    });
  }
  updateSplitRatio(data: any) {
    return this.http.post<any>(`/update-ratio`, data);
  }
  trafficAllocation(data: any, isAutomatic: boolean) {
    if (isAutomatic) {
      return this.http.post<any>(`/switchAutomatic`, data);
    } else {
      return this.http.post<any>(`/switchManual`, data);
    }
  }
  trafficGraph(data: any) {
    return this.http.post<any>(`/trafficGraph`, data);
  }

  getTargetList(): Observable<any> {
    return this.http.get<any>('/targets');
  }

  getCurrentUserRoles(email: any): Observable<any> {
    return this.http.get('/privileges/userPrivileges/user?email=' + email);
  }
}
