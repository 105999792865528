import { Component, OnInit } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
import { Subscription } from 'rxjs';
import { SelectionService } from 'src/app/services/selection.service';
import { ExperimentsService } from '../experiments.service';
import { Socket } from 'ngx-socket-io';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/modals/confirmation/confirmation.component';
import { EditSplitratioComponent } from '../edit-splitratio/edit-splitratio.component';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageTitleService } from 'src/app/services/pagetitle.service';

@Component({
  selector: 'app-experiment-view',
  templateUrl: './experiment-view.component.html',
  styleUrls: ['./experiment-view.component.css'],
})
export class ExperimentViewComponent {
  constructor(
    private socket: Socket,
    private selectorService: SelectionService,
    private service: ExperimentsService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private router: Router,
    private pageTitle: PageTitleService
  ) {
    if (localStorage.getItem('darkMode') == 'true') {
      this.darkMode = true;
    } else {
      this.darkMode = false;
    }
  }

  // Loaders
  isLoading: boolean = false;
  firstLoader: boolean = false;
  loadNrIndicator: number = 0;

  confirmDialogRef: MatDialogRef<ConfirmationComponent>;
  editSplitRatioDialogRef: MatDialogRef<EditSplitratioComponent>;

  createdExperiment: any;
  experimentObj: any;
  experimentLineChartData: any;
  showExperimentLineChart: boolean = false;

  startDate: any;
  endDate: any;
  selectedRangeValue: any = 'today';
  rate: string = 'hourly';
  sub: Subscription;
  selectedStore: any = '';

  // data for experiments table
  experimentPlatforms: any = [];
  experimentGeneralData: any;
  experimentTableData: any = {
    desktop: {
      ids: ['-', '-'],
      rpv: ['0', '0'],
      cr: ['0', '0'],
      aov: ['0', '0'],
      orders: ['0', '0'],
      visits: ['0', '0'],
      revenueOverall: ['0', '0'],
    },
    mobile: {
      ids: ['-', '-'],
      rpv: ['0', '0'],
      cr: ['0', '0'],
      aov: ['0', '0'],
      orders: ['0', '0'],
      visits: ['0', '0'],
      revenueOverall: ['0', '0'],
    },
  };
  noTableData: boolean = false;
  showDesktopLineChart: boolean = false;
  showMobileLineChart: boolean = false;

  experimentStatus: string = '';
  experimentLine: string = '';

  isDayDisabled: boolean = false;
  isMonthDisabled: boolean = true;

  minDate: any;

  experimentTrafficGraphData: any = {};
  showDesktopRatioChart: boolean = false;
  showMobileRatioChart: boolean = false;

  darkMode: boolean = false;

  ngOnInit() {
    this.pageTitle.setTitle('Experiment View');

    // disconnect socket from automation.behamics.com
    this.socket.disconnect();
    this.socket = new Socket({
      url: 'https://modelautomation.behamics.com',
      options: {
        extraHeaders: {
          'X-SOCKETIO-AUTH': 'behamics-socketio',
        },
        path: '/ws/socket.io',
      },
    });
    this.socket.connect();
    this.socket.emit('connection', 'connected');
    this.socket.fromEvent('message').subscribe((data) => {
      console.log('connected!', data);
    });

    if (localStorage.getItem('viewExperiment')) {
      this.experimentObj = JSON.parse(
        localStorage.getItem('viewExperiment') || ''
      );
      if (
        this.experimentObj.creationDate &&
        this.experimentObj.creationDate != 'NA'
      ) {
        this.minDate = new Date(this.experimentObj.creationDate)
          .toISOString()
          .split('T')[0];
        // this.sidenavService.setMinDate(this.minDate);
      }
    }

    if (localStorage.getItem('createdExperiment')) {
      this.createdExperiment = JSON.parse(
        localStorage.getItem('createdExperiment') || ''
      );
      localStorage.removeItem('createdExperiment');
    }

    this.sub = this.selectorService.currentMessage.subscribe((message: any) => {
      if (localStorage.getItem('darkMode') == 'true') {
        this.darkMode = true;
      } else {
        this.darkMode = false;
      }

      this.selectedStore = message;
      this.startDate = window.localStorage.getItem('startDate');
      this.endDate = window.localStorage.getItem('endDate');

      this.fetchData();

      // If component has KPI with rate filter
      this.selectedRangeValue = window.localStorage.getItem('rangeValue');
      if (this.selectedRangeValue == 'thisyear') {
        this.rate = 'day';
        this.isDayDisabled = false;
      } else if (this.selectedRangeValue == 'thisweek') {
        this.rate = 'day';
        this.isDayDisabled = false;
      } else if (this.selectedRangeValue == 'last7days') {
        this.rate = 'day';
        this.isDayDisabled = false;
      } else if (this.selectedRangeValue == 'last30days') {
        this.rate = 'day';
        this.isDayDisabled = false;
      } else if (this.selectedRangeValue == 'thismonth') {
        this.rate = 'day';
        this.isDayDisabled = false;
      } else if (this.selectedRangeValue == 'custom') {
        this.isDayDisabled = false;
        this.rate = 'day';
      } else if (this.selectedRangeValue == 'today') {
        this.isDayDisabled = true;
        this.rate = 'hourly';
      } else if (this.selectedRangeValue == 'yestarday') {
        this.isDayDisabled = true;
        this.rate = 'hourly';
      }
    });
    localStorage.setItem('save_last_tab', 'true');
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.socket.removeAllListeners('ai_optimization');
    this.socket.removeAllListeners('message');
    this.socket.removeAllListeners('message_ping');
    // this.socket.disconnect();
  }

  isCurrencyRightAligned: boolean;
  displayCurrency() {
    var currency = window.localStorage.getItem('currency');

    switch (currency) {
      case 'EUR':
        this.isCurrencyRightAligned = true;
        return '€';
      case 'USD':
        this.isCurrencyRightAligned = false;
        return '$';
      case 'CHF':
        this.isCurrencyRightAligned = false;
        return 'CHF ';
      case 'GBP':
        this.isCurrencyRightAligned = false;
        return '£';
      default:
        this.isCurrencyRightAligned = false;
        return '$';
    }
  }
  clientCurrency: string = this.displayCurrency();
  combinedLoader(num: any) {
    this.loadNrIndicator = this.loadNrIndicator + num;
    if (this.loadNrIndicator == 2) {
      setTimeout(() => {
        this.firstLoader = true;
      }, 800);
    }
  }
  public desktopExperimentLineChart: GoogleChartInterface = {
    chartType: 'LineChart',
    dataTable: [],
    options: {
      displayAnnotations: true,
      legend: {
        position: 'top',
        maxLines: 2,
        alignment: 'center',
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
      },
      pointSize: 0,
      series: {},
      crosshair: {
        trigger: 'focus',
        orientation: 'vertical',
        color: '#eeeeee',
      },
      //forceIFrame: true,
      focusTarget: 'category',
      colors: ['#A3ACB9', '#5469D4'],
      height: 270,
      vAxis: {
        // minValue: 0,
        // baseline: 0,
        baselineColor: this.darkMode ? '#3b3a3a' : '#c0c0c0',
        gridlineColor: this.darkMode ? '#3b3a3a' : '#ededed',
        titleTextStyle: {
          fontSize: 12,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        format: "#'%'",
        // viewWindow: {
        //   min: 0,
        // },
      },
      vAxes: {
        0: {
          // baseline: 0,
          format: "#'%'",
          // viewWindow: {
          // min: 0,
          // },
        },
        1: {
          format: "#'%'",
          // viewWindow: {
          //   min: 0,
          // },
          textStyle: {
            color: 'transparent',
          },
        },
        2: {
          format: "#'%'",
          // viewWindow: {
          //   min: 0,
          // },
          textStyle: {
            color: 'transparent',
          },
        },
        3: {
          format: "#'%'",
          // viewWindow: {
          //   min: 0,
          // },
          textStyle: {
            color: 'transparent',
          },
        },
        4: {
          format: "#'%'",
          // viewWindow: {
          //   min: 0,
          // },
          textStyle: {
            color: 'transparent',
          },
        },
        5: {
          format: "#'%'",
          viewWindow: {
            min: 0,
          },
          textStyle: {
            color: 'transparent',
          },
        },
      },
      tooltip: {
        textStyle: {
          fontSize: 11,
        },
        isHtml: true,
      },
      hAxis: {
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        titleTextStyle: {
          fontSize: 12,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        viewWindow: {
          min: 0.5,
        },
      },
      chartArea: {
        height: '90%',
        top: 20,
        left: 40,
        right: 20,
        bottom: 55,
        width: '90%',
      },
    },
  };
  public mobileExperimentLineChart: GoogleChartInterface = {
    chartType: 'LineChart',
    dataTable: [],
    options: {
      displayAnnotations: true,
      legend: {
        position: 'top',
        maxLines: 2,
        alignment: 'center',
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
      },
      pointSize: 0,
      series: {},
      crosshair: {
        trigger: 'focus',
        orientation: 'vertical',
        color: '#eeeeee',
      },
      //forceIFrame: true,
      focusTarget: 'category',
      colors: ['#A3ACB9', '#5469D4'],
      height: 270,
      vAxis: {
        // minValue: 0,
        // baseline: 0,
        baselineColor: this.darkMode ? '#3b3a3a' : '#c0c0c0',
        gridlineColor: this.darkMode ? '#3b3a3a' : '#ededed',
        titleTextStyle: {
          fontSize: 12,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        format: "#'%'",
        // viewWindow: {
        //   min: 0,
        // },
      },
      vAxes: {
        0: {
          // baseline: 0,
          format: "#'%'",
          // viewWindow: {
          // min: 0,
          // },
        },
        1: {
          format: "#'%'",
          // viewWindow: {
          //   min: 0,
          // },
          textStyle: {
            color: 'transparent',
          },
        },
        2: {
          format: "#'%'",
          // viewWindow: {
          //   min: 0,
          // },
          textStyle: {
            color: 'transparent',
          },
        },
        3: {
          format: "#'%'",
          // viewWindow: {
          //   min: 0,
          // },
          textStyle: {
            color: 'transparent',
          },
        },
        4: {
          format: "#'%'",
          // viewWindow: {
          //   min: 0,
          // },
          textStyle: {
            color: 'transparent',
          },
        },
        5: {
          format: "#'%'",
          viewWindow: {
            min: 0,
          },
          textStyle: {
            color: 'transparent',
          },
        },
      },
      tooltip: {
        textStyle: {
          fontSize: 11,
        },
        isHtml: true,
      },
      hAxis: {
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        titleTextStyle: {
          fontSize: 12,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        viewWindow: {
          min: 0.5,
        },
      },
      chartArea: {
        height: '90%',
        top: 20,
        left: 40,
        right: 20,
        bottom: 55,
        width: '90%',
      },
    },
  };
  public desktopRatioExperimentLineChart: GoogleChartInterface = {
    chartType: 'LineChart',
    dataTable: [],
    options: {
      displayAnnotations: true,
      legend: {
        position: 'top',
        maxLines: 2,
        alignment: 'center',
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
      },
      pointSize: 0,
      series: {},
      crosshair: {
        trigger: 'focus',
        orientation: 'vertical',
        color: '#eeeeee',
      },
      //forceIFrame: true,
      focusTarget: 'category',
      colors: ['#A3ACB9', '#5469D4'],
      height: 270,
      vAxis: {
        // minValue: 0,
        // baseline: 0,
        baselineColor: this.darkMode ? '#3b3a3a' : '#c0c0c0',
        gridlineColor: this.darkMode ? '#3b3a3a' : '#ededed',
        titleTextStyle: {
          fontSize: 12,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        format: "#'%'",
        // viewWindow: {
        //   min: 0,
        // },
      },
      vAxes: {
        0: {
          // baseline: 0,
          format: "#'%'",
          viewWindow: {
            min: 0,
            max: 100,
          },
        },
        1: {
          format: "#'%'",
          // viewWindow: {
          //   min: 0,
          // },
          textStyle: {
            color: 'transparent',
          },
        },
        2: {
          format: "#'%'",
          // viewWindow: {
          //   min: 0,
          // },
          textStyle: {
            color: 'transparent',
          },
        },
        3: {
          format: "#'%'",
          // viewWindow: {
          //   min: 0,
          // },
          textStyle: {
            color: 'transparent',
          },
        },
        4: {
          format: "#'%'",
          // viewWindow: {
          //   min: 0,
          // },
          textStyle: {
            color: 'transparent',
          },
        },
        5: {
          format: "#'%'",
          viewWindow: {
            min: 0,
          },
          textStyle: {
            color: 'transparent',
          },
        },
      },
      tooltip: {
        textStyle: {
          fontSize: 11,
        },
        isHtml: true,
      },
      hAxis: {
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        titleTextStyle: {
          fontSize: 12,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        viewWindow: {
          min: 0.5,
        },
      },
      chartArea: {
        height: '90%',
        top: 20,
        left: 40,
        right: 20,
        bottom: 55,
        width: '90%',
      },
    },
  };
  public mobileRatioExperimentLineChart: GoogleChartInterface = {
    chartType: 'LineChart',
    dataTable: [],
    options: {
      displayAnnotations: true,
      legend: {
        position: 'top',
        maxLines: 2,
        alignment: 'center',
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
      },
      pointSize: 0,
      series: {},
      crosshair: {
        trigger: 'focus',
        orientation: 'vertical',
        color: '#eeeeee',
      },
      //forceIFrame: true,
      focusTarget: 'category',
      colors: ['#A3ACB9', '#5469D4'],
      height: 270,
      vAxis: {
        // minValue: 0,
        // baseline: 0,
        baselineColor: this.darkMode ? '#3b3a3a' : '#c0c0c0',
        gridlineColor: this.darkMode ? '#3b3a3a' : '#ededed',
        titleTextStyle: {
          fontSize: 12,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        format: "#'%'",
        // viewWindow: {
        //   min: 0,
        // },
      },
      vAxes: {
        0: {
          // baseline: 0,
          format: "#'%'",
          viewWindow: {
            min: 0,
            max: 100,
          },
        },
        1: {
          format: "#'%'",
          textStyle: {
            color: 'transparent',
          },
        },
        2: {
          format: "#'%'",
          // viewWindow: {
          //   min: 0,
          // },
          textStyle: {
            color: 'transparent',
          },
        },
        3: {
          format: "#'%'",
          // viewWindow: {
          //   min: 0,
          // },
          textStyle: {
            color: 'transparent',
          },
        },
        4: {
          format: "#'%'",
          // viewWindow: {
          //   min: 0,
          // },
          textStyle: {
            color: 'transparent',
          },
        },
        5: {
          format: "#'%'",
          viewWindow: {
            min: 0,
          },
          textStyle: {
            color: 'transparent',
          },
        },
      },
      tooltip: {
        textStyle: {
          fontSize: 11,
        },
        isHtml: true,
      },
      hAxis: {
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        titleTextStyle: {
          fontSize: 12,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        viewWindow: {
          min: 0.5,
        },
      },
      chartArea: {
        height: '90%',
        top: 20,
        left: 40,
        right: 20,
        bottom: 55,
        width: '90%',
      },
    },
  };
  onRateChange(value: any) {
    this.selectedRangeValue = window.localStorage.getItem('rangeValue');

    window.sessionStorage.setItem('selectedRate', value.value);

    if (value.value == 'hourly') {
      this.rate = 'hourly';
      if (this.selectedRangeValue == 'today') {
        var startDate = new Date();
        startDate.setHours(0, 0, 0);
        this.startDate =
          startDate.toISOString() +
          '@' +
          Intl.DateTimeFormat().resolvedOptions().timeZone;
      } else if (this.selectedRangeValue == 'yestarday') {
        var startDate = new Date();
        startDate.setHours(0, 0, 0);
        startDate.setDate(startDate.getDate() - 1);
        this.startDate =
          startDate.toISOString() +
          '@' +
          Intl.DateTimeFormat().resolvedOptions().timeZone;

        var endDate = new Date();
        endDate.setHours(23, 59, 59);
        endDate.setDate(endDate.getDate() - 1);
        this.endDate =
          endDate.toISOString() +
          '@' +
          Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    } else if (value.value == 'day') {
      this.rate = 'day';
      if (this.selectedRangeValue == 'today') {
        var startDate = new Date();
        startDate.setHours(0, 0, 0);
        startDate.setDate(startDate.getDate() - 1);
        this.startDate =
          startDate.toISOString() +
          '@' +
          Intl.DateTimeFormat().resolvedOptions().timeZone;

        var endDate = new Date();
        this.endDate =
          endDate.toISOString() +
          '@' +
          Intl.DateTimeFormat().resolvedOptions().timeZone;
      } else if (this.selectedRangeValue == 'yestarday') {
        var startDate = new Date();
        startDate.setHours(0, 0, 0);
        startDate.setDate(startDate.getDate() - 2);
        this.startDate =
          startDate.toISOString() +
          '@' +
          Intl.DateTimeFormat().resolvedOptions().timeZone;

        var endDate = new Date();
        endDate.setHours(23, 59, 59);
        endDate.setDate(endDate.getDate() - 1);
        this.endDate =
          endDate.toISOString() +
          '@' +
          Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    }
    this.rate = value.value;
    this.getRollingData();
  }
  formatDateFull(date: any) {
    if (date.length > 11) {
      var x = date.slice(0, -3) + 'T' + date.slice(-2) + ':00:00';
      var MM = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      var xx = x.replace(
        /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}/,
        function ($0, $1, $2, $3, $4, $5) {
          var ampm12 = $4 >= 12 ? 'PM' : 'AM';
          $4 = $4 % 12;
          $4 = $4 ? $4 : 12;
          return MM[$2 - 1] + ' ' + $3 + ', ' + $1 + ' - ' + $4 + ampm12;
        }
      );
      return xx;
    } else if (date.length > 8) {
      var xd = date;
      var MMd = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      var xxd = xd.replace(
        /(\d{4})-(\d{2})-(\d{2})/,
        function ($0: any, $1: any, $2: any, $3: any) {
          return MMd[$2 - 1] + ' ' + $3 + ', ' + $1;
        }
      );
      return xxd;
    } else {
      var xm = date;
      var MMm = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      var xxm = xm.replace(
        /(\d{4})-(\d{2})/,
        function ($0: any, $1: any, $2: any, $3: any) {
          return MMm[$2 - 1] + ', ' + $1;
        }
      );
      return xxm;
    }
  }
  populateDesktopExperimentLineChart() {
    var triggerOne = false;
    function formatAMPM(date: any) {
      if (date.length > 11) {
        var x = date.slice(0, -3) + 'T' + date.slice(-2) + ':00:00';
        var MM = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        var xx = x.replace(
          /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}/,
          function ($0, $1, $2, $3, $4, $5) {
            var ampm12 = $4 >= 12 ? 'PM' : 'AM';
            $4 = $4 % 12;
            $4 = $4 ? $4 : 12;
            return $4 + ampm12;
          }
        );
        if (!triggerOne) {
          triggerOne = !triggerOne;
          return xx;
        }
        {
          triggerOne = !triggerOne;
          return '';
        }
      } else if (date.length > 8) {
        var xd = date;
        var MMd = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        var xxd = xd.replace(
          /(\d{4})-(\d{2})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMd[$2 - 1] + ' ' + $3;
          }
        );
        return xxd;
      } else {
        var xm = date;
        var MMm = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        var xxm = xm.replace(
          /(\d{4})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMm[$2 - 1] + ', ' + $1;
          }
        );
        return xxm;
      }
    }

    let incLineData = [
      [
        'Time',
        'Default Variant',
        'AI Variant',
        { role: 'tooltip', p: { html: true } },
      ],
    ];

    if (this.experimentLineChartData?.defaultIncentive?.desktop) {
      for (
        let j = 0;
        j < this.experimentLineChartData.defaultIncentive.desktop.length;
        j++
      ) {
        incLineData.push([
          {
            v: this.experimentLineChartData?.defaultIncentive?.desktop[j].date,
            f: formatAMPM(
              this.experimentLineChartData?.defaultIncentive?.desktop[j].date
            ),
          },

          this.experimentLineChartData?.defaultIncentive?.desktop[j].pct_diff,
          this.experimentLineChartData?.AIVariantIncentive?.desktop[j].pct_diff,
          "<p class='tooltip-text'><b>" +
            this.formatDateFull(
              this.experimentLineChartData?.defaultIncentive?.desktop[j].date
            ) +
            '</b></p>' +
            "<p class='tooltip-text'><b>Default: </b>" +
            this.experimentLineChartData?.defaultIncentive?.desktop[j]
              .pct_diff +
            '%</p>' +
            "<p class='tooltip-text'><b>AI Variant: </b>" +
            this.experimentLineChartData?.AIVariantIncentive?.desktop[j]
              .pct_diff +
            '%</p>',
        ]);
      }

      this.desktopExperimentLineChart.options['hAxis'].viewWindow.max =
        incLineData.length - 1.5;

      // if (this.experimentLineChartData.defaultIncentive.mobile.length >= 45) {
      //   this.desktopExperimentLineChart.options['pointSize'] = 0;
      // } else {
      //   this.desktopExperimentLineChart.options['pointSize'] = 4;
      // }
    }

    this.desktopExperimentLineChart.dataTable = incLineData;

    this.desktopExperimentLineChart.component?.draw(
      this.desktopExperimentLineChart
    );

    this.desktopExperimentLineChart.options['tooltip'].trigger = 'hover';

    if (this.experimentLineChartData?.length == 0) {
      this.desktopExperimentLineChart.dataTable = [
        ['Time', 'Total Cases'],
        ['', 0],
        ['', 0],
      ];
      // this.desktopExperimentLineChart.options['pointSize'] = 0;
      this.desktopExperimentLineChart.options['vAxis'].title = '';
      this.desktopExperimentLineChart.options['hAxis'].title = '';
      this.desktopExperimentLineChart.options['tooltip'].trigger = 'none';
    }

    this.showExperimentLineChart = true;
    this.combinedLoader(1);
  }
  populateMobileExperimentLineChart() {
    function formatNumber(num: any) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    function removePercentage(num: any) {
      if (typeof num === 'string') {
        return parseFloat(num.replace(/,/g, '').replace('%', ''));
      }
      return num;
    }
    var triggerOne = false;

    function formatAMPM(date: any) {
      if (date.length > 11) {
        var x = date.slice(0, -3) + 'T' + date.slice(-2) + ':00:00';
        var MM = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        var xx = x.replace(
          /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}/,
          function ($0, $1, $2, $3, $4, $5) {
            var ampm12 = $4 >= 12 ? 'PM' : 'AM';
            $4 = $4 % 12;
            $4 = $4 ? $4 : 12;
            return $4 + ampm12;
          }
        );
        if (!triggerOne) {
          triggerOne = !triggerOne;
          return xx;
        }
        {
          triggerOne = !triggerOne;
          return '';
        }
      } else if (date.length > 8) {
        var xd = date;
        var MMd = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        var xxd = xd.replace(
          /(\d{4})-(\d{2})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMd[$2 - 1] + ' ' + $3;
          }
        );
        return xxd;
      } else {
        var xm = date;
        var MMm = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        var xxm = xm.replace(
          /(\d{4})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMm[$2 - 1] + ', ' + $1;
          }
        );
        return xxm;
      }
    }

    let incLineData = [
      [
        'Time',
        'Default Variant',
        'AI Variant',
        { role: 'tooltip', p: { html: true } },
      ],
    ];

    if (this.experimentLineChartData?.defaultIncentive?.mobile) {
      for (
        let j = 0;
        j < this.experimentLineChartData.defaultIncentive.mobile.length;
        j++
      ) {
        incLineData.push([
          {
            v: this.experimentLineChartData?.defaultIncentive?.mobile[j].date,
            f: formatAMPM(
              this.experimentLineChartData?.defaultIncentive?.mobile[j].date
            ),
          },
          removePercentage(
            this.experimentLineChartData?.defaultIncentive?.mobile[j].pct_diff
          ),
          removePercentage(
            this.experimentLineChartData?.AIVariantIncentive?.mobile[j].pct_diff
          ),
          "<p class='tooltip-text'><b>" +
            this.formatDateFull(
              this.experimentLineChartData?.defaultIncentive?.mobile[j].date
            ) +
            '</b></p>' +
            "<p class='tooltip-text'><b>Default: </b>" +
            this.experimentLineChartData?.defaultIncentive?.mobile[j].pct_diff +
            '%</p>' +
            "<p class='tooltip-text'><b>AI Variant: </b>" +
            this.experimentLineChartData?.AIVariantIncentive?.mobile[j]
              .pct_diff +
            '%</p>',
        ]);
      }

      this.mobileExperimentLineChart.options['hAxis'].viewWindow.max =
        incLineData.length - 1.5;

      // if (this.experimentLineChartData.defaultIncentive.mobile.length >= 45) {
      //   this.mobileExperimentLineChart.options['pointSize'] = 0;
      // } else {
      //   this.mobileExperimentLineChart.options['pointSize'] = 4;
      // }
    }

    this.mobileExperimentLineChart.dataTable = incLineData;

    this.mobileExperimentLineChart.component?.draw(
      this.mobileExperimentLineChart
    );

    this.mobileExperimentLineChart.options['tooltip'].trigger = 'hover';

    if (this.experimentLineChartData?.length == 0) {
      this.mobileExperimentLineChart.dataTable = [
        ['Time', 'Total Cases'],
        ['', 0],
        ['', 0],
      ];
      // this.mobileExperimentLineChart.options['pointSize'] = 0;
      this.mobileExperimentLineChart.options['vAxis'].title = '';
      this.mobileExperimentLineChart.options['hAxis'].title = '';
      this.mobileExperimentLineChart.options['tooltip'].trigger = 'none';
    }

    this.showExperimentLineChart = true;
    this.combinedLoader(1);
  }
  populateDesktopRatioLineChart(data: any) {
    var triggerOne = false;

    function formatAMPM(date: any) {
      if (date.length > 11) {
        var x = date.slice(0, -3) + 'T' + date.slice(-2) + ':00:00';
        var MM = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        var xx = x.replace(
          /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}/,
          function ($0, $1, $2, $3, $4, $5) {
            var ampm12 = $4 >= 12 ? 'PM' : 'AM';
            $4 = $4 % 12;
            $4 = $4 ? $4 : 12;
            return $4 + ampm12;
          }
        );
        if (!triggerOne) {
          triggerOne = !triggerOne;
          return xx;
        }
        {
          triggerOne = !triggerOne;
          return '';
        }
      } else if (date.length > 8) {
        var xd = date;
        var MMd = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        var xxd = xd.replace(
          /(\d{4})-(\d{2})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMd[$2 - 1] + ' ' + $3;
          }
        );
        return xxd;
      } else {
        var xm = date;
        var MMm = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        var xxm = xm.replace(
          /(\d{4})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMm[$2 - 1] + ', ' + $1;
          }
        );
        return xxm;
      }
    }

    let incLineData = [
      [
        'Time',
        'AI Variant',
        // 'Default Variant',
        { role: 'tooltip', p: { html: true } },
      ],
    ];

    this.desktopRatioExperimentLineChart.options['colors'] = [
      '#5469D4',
      '#A3ACB9',
    ];

    let tmpM = 1;
    if (data.length > 20) {
      tmpM = 4;
    } else if (data.length > 9) {
      tmpM = 2;
    }

    let tmpP: any;
    let tmpD: any;
    if (data && data.length > 0) {
      for (let j = 0; j < data.length; j++) {
        tmpP = data[j].ai * 100;

        incLineData.push([
          {
            v: data[j].date,
            f: formatAMPM(data[j].date),
          },
          tmpP,
          "<p class='tooltip-text'><b>" +
            this.formatDateFull(data[j].date) +
            '</b></p>' +
            "<p class='tooltip-text'><b>Traffic Allocation: </b>" +
            data[j].trafficType +
            '</p>' +
            "<p class='tooltip-text'><b>AI Variant: </b>" +
            Math.round(data[j].ai * 100) +
            '%</p>' +
            "<p class='tooltip-text'><b>Default: </b>" +
            Math.round(data[j].default * 100) +
            '%</p>',
        ]);
      }

      this.desktopRatioExperimentLineChart.options['hAxis'].viewWindow.max =
        incLineData.length - 1.5;

      // if (data.length >= 45) {
      //   this.desktopRatioExperimentLineChart.options['pointSize'] = 0;
      // } else {
      //   this.desktopRatioExperimentLineChart.options['pointSize'] = 4;
      // }
    }

    this.desktopRatioExperimentLineChart.dataTable = incLineData;
    this.desktopRatioExperimentLineChart.options['tooltip'].trigger = 'hover';
    if (!data || data?.message || data?.length == 0) {
      this.showDesktopRatioChart = false;
    } else {
      this.showDesktopRatioChart = true;
    }
    this.desktopRatioExperimentLineChart.component?.draw(
      this.desktopRatioExperimentLineChart
    );
    this.combinedLoader(1);
  }
  populateMobileRatioLineChart(data: any) {
    var triggerOne = false;

    function formatAMPM(date: any) {
      if (date.length > 11) {
        var x = date.slice(0, -3) + 'T' + date.slice(-2) + ':00:00';
        var MM = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        var xx = x.replace(
          /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}/,
          function ($0, $1, $2, $3, $4, $5) {
            var ampm12 = $4 >= 12 ? 'PM' : 'AM';
            $4 = $4 % 12;
            $4 = $4 ? $4 : 12;
            return $4 + ampm12;
          }
        );
        if (!triggerOne) {
          triggerOne = !triggerOne;
          return xx;
        }
        {
          triggerOne = !triggerOne;
          return '';
        }
      } else if (date.length > 8) {
        var xd = date;
        var MMd = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        var xxd = xd.replace(
          /(\d{4})-(\d{2})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMd[$2 - 1] + ' ' + $3;
          }
        );
        return xxd;
      } else {
        var xm = date;
        var MMm = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        var xxm = xm.replace(
          /(\d{4})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMm[$2 - 1] + ', ' + $1;
          }
        );
        return xxm;
      }
    }

    let incLineData = [
      [
        'Time',
        'AI Variant',
        // "Default Variant",
        { role: 'tooltip', p: { html: true } },
      ],
    ];

    this.mobileRatioExperimentLineChart.options['colors'] = ['#5469D4'];

    let tmpM = 1;
    if (data.length > 20) {
      tmpM = 4;
    } else if (data.length > 9) {
      tmpM = 2;
    }

    let tmpP: any;
    if (data) {
      for (let j = 0; j < data.length; j++) {
        tmpP = data[j].ai * 100;
        incLineData.push([
          {
            v: data[j].date,
            f: formatAMPM(data[j].date),
          },
          tmpP,
          "<p class='tooltip-text'><b>" +
            this.formatDateFull(data[j].date) +
            '</b></p>' +
            "<p class='tooltip-text'><b>Traffic Allocation: </b>" +
            data[j].trafficType +
            '</p>' +
            "<p class='tooltip-text'><b>AI Variant: </b>" +
            Math.round(data[j].ai * 100) +
            '%</p>' +
            "<p class='tooltip-text'><b>Default: </b>" +
            Math.round(data[j].default * 100) +
            '%</p>',
        ]);
      }

      this.mobileRatioExperimentLineChart.options['hAxis'].viewWindow.max =
        incLineData.length - 1.5;

      // if (data.length >= 45) {
      //   this.mobileRatioExperimentLineChart.options['pointSize'] = 0;
      // } else {
      //   this.mobileRatioExperimentLineChart.options['pointSize'] = 4;
      // }
    }

    this.mobileRatioExperimentLineChart.dataTable = incLineData;

    this.mobileRatioExperimentLineChart.options['tooltip'].trigger = 'hover';

    if (!data || data?.message || data?.length == 0) {
      this.showMobileRatioChart = false;
    } else {
      this.showMobileRatioChart = true;
    }
    this.mobileRatioExperimentLineChart.component?.draw(
      this.mobileRatioExperimentLineChart
    );

    this.combinedLoader(1);
  }
  constructTableData() {
    function formatNumber(num: any) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    let platform = '';
    for (let i = 0; i < this.experimentPlatforms.length; i++) {
      platform = this.experimentPlatforms[i];
      if (this.experimentGeneralData[platform]?.incentive.length == 0) {
        this.noTableData = true;
        this.experimentTableData[platform].ids = [
          { incentive: '-', devices: [] },
          { incentive: '-', devices: [] },
        ];
      } else {
        this.noTableData = false;
        this.experimentTableData[platform].ids =
          this.experimentGeneralData[platform]?.incentive;
      }
      if (this.experimentGeneralData[platform]?.rpv?.uplift.length == 0) {
        this.experimentTableData[platform].rpv = ['0', '0'];
      } else {
        this.experimentTableData[platform].rpv =
          this.experimentGeneralData[platform]?.rpv?.uplift;
      }
      if (this.experimentGeneralData[platform]?.cr?.uplift.length == 0) {
        this.experimentTableData[platform].cr = ['0', '0'];
      } else {
        this.experimentTableData[platform].cr =
          this.experimentGeneralData[platform]?.cr?.uplift;
      }
      if (this.experimentGeneralData[platform]?.aov?.uplift.length == 0) {
        this.experimentTableData[platform].aov = ['0', '0'];
      } else {
        this.experimentTableData[platform].aov =
          this.experimentGeneralData[platform]?.aov?.uplift;
      }
      if (this.experimentGeneralData[platform]?.orders?.incentive.length == 0) {
        this.experimentTableData[platform].orders = ['0', '0'];
      } else {
        this.experimentTableData[platform].orders =
          this.experimentGeneralData[platform]?.orders?.incentive;
      }
      if (this.experimentGeneralData[platform]?.visits?.incentive.length == 0) {
        this.experimentTableData[platform].visits = ['0', '0'];
      } else {
        this.experimentTableData[platform].visits =
          this.experimentGeneralData[platform]?.visits?.incentive;
      }
      if (
        this.experimentGeneralData[platform]?.rpv?.revenueOverall.length == 0
      ) {
        this.experimentTableData[platform].revenueOverall = ['0', '0'];
      } else {
        for (
          let i = 0;
          i < this.experimentGeneralData[platform]?.rpv?.revenueOverall.length;
          i++
        ) {
          try {
            if (
              typeof this.experimentGeneralData[platform].rpv.revenueOverall[
                i
              ] == 'string' &&
              this.experimentGeneralData[platform].rpv.revenueOverall[i]?.split(
                ' '
              )?.length > 1 &&
              parseFloat(
                this.experimentGeneralData[platform].rpv.revenueOverall[
                  i
                ]?.split(' ')[1]
              ) > 9999
            ) {
              this.experimentGeneralData[platform].rpv.revenueOverall[i] =
                this.experimentGeneralData[platform].rpv.revenueOverall[
                  i
                ].slice(0, -1);
            }
          } catch (e) {
            console.log(e);
          }

          try {
            this.experimentGeneralData[platform].rpv.revenueOverall[i] =
              this.experimentGeneralData[platform]?.rpv?.revenueOverall[
                i
              ].split(' ')[0] +
              this.clientCurrency +
              ' ' +
              formatNumber(
                this.experimentGeneralData[platform]?.rpv?.revenueOverall[
                  i
                ].split(' ')[1]
              );
          } catch (e) {
            this.experimentGeneralData[platform].rpv.revenueOverall[i] =
              this.clientCurrency +
              ' ' +
              this.experimentGeneralData[platform]?.rpv?.revenueOverall[i];
          }
        }
        this.experimentTableData[platform].revenueOverall =
          this.experimentGeneralData[platform]?.rpv?.revenueOverall;
      }
      if (this.experimentGeneralData[platform]?.splitRatio) {
        let tmpToFixed = 1;
        if (
          String(this.experimentGeneralData[platform]?.splitRatio).split('.')[1]
            ?.length > 1
        ) {
          tmpToFixed = 3;
        }

        let tmpNr = (
          1 - this.experimentGeneralData[platform]?.splitRatio
        ).toFixed(tmpToFixed);

        if (tmpToFixed == 1) {
          this.experimentTableData[platform].splitRatioAI =
            (this.experimentGeneralData[platform]?.splitRatio * 100).toFixed(
              0
            ) + '%';

          this.experimentTableData[platform].splitRatioDefault =
            parseFloat(tmpNr) * 100 + '%';
        } else {
          this.experimentTableData[platform].splitRatioAI =
            (this.experimentGeneralData[platform]?.splitRatio * 100).toFixed(
              1
            ) + '%';
          this.experimentTableData[platform].splitRatioDefault =
            (parseFloat(tmpNr) * 100).toFixed(1) + '%';
        }
      } else {
        this.experimentTableData[platform].splitRatio = 0;
      }
      if (this.experimentGeneralData[platform]?.currentTrafficType) {
        this.experimentTableData[platform].currentTrafficType =
          this.experimentGeneralData[platform]?.currentTrafficType == 'manual'
            ? false
            : true;
      } else {
        this.experimentTableData[platform].currentTrafficType = false;
      }
      if (this.experimentTableData[platform]?.ids?.length > 1) {
        this.experimentTableData[platform].twoRows = true;
      } else {
        this.experimentTableData[platform].twoRows = false;
      }
    }
    this.combinedLoader(1);
  }
  openSnackBar(a: string, b: string) {
    this.snackBar.open(a, 'x', {
      duration: 3000,
      panelClass: ['snackbar-class-success'],
    });
  }
  activeStatusChanged(event: any, nudge: any, platform: string) {
    let incentiveObj = {
      siteID: nudge['incentive'].split('.')[0],
      boxID: nudge['incentive'].split('.')[1],
      ruleID: nudge['incentive'].split('.')[2],
      incentiveID: nudge['incentive'].split('.')[3],
      device: platform,
      target: this.selectedTargetApi,
      groupVariant: nudge['incentive'].split('.')[3].includes('000')
        ? 'AIVARIANT'
        : 'DEFAULT',
    };

    if (event.checked) {
      this.confirmDialogRef = this.dialog.open(ConfirmationComponent, {
        width: '430px',
        data: {
          header: 'Enable Variant',
          content: 'Are you sure you want to enable this Variant?',
        },
      });
      this.confirmDialogRef.afterClosed().subscribe((result) => {
        if (result == 1) {
          event.source.checked = true;
          this.service.deviceincentiveonlinetoggle(incentiveObj).subscribe(
            (res) => {
              this.openSnackBar('Variant Enabled', 'OK');
            },
            (err) => {
              console.log(err);
            }
          );
        } else {
          event.source.checked = false;
        }
      });
    } else {
      this.confirmDialogRef = this.dialog.open(ConfirmationComponent, {
        width: '430px',
        data: {
          header: 'Disable Variant',
          content: 'Are you sure you want to disable this Variant?',
        },
      });
      this.confirmDialogRef.afterClosed().subscribe((result) => {
        if (result == 1) {
          this.service.deviceincentiveofflinetoggle(incentiveObj).subscribe(
            (res) => {
              this.openSnackBar('Variant Disabled', 'OK');
            },
            (err) => {
              console.log(err);
            }
          );
        } else {
          event.source.checked = true;
        }
      });
    }
  }
  experimentStartBtn: boolean = false;
  eperimentProgress: number = 0;
  experimentStatusFunc(action: string) {
    if (action == 'start') {
      let tmpInc = '';
      let tmpPlatform = [];
      if (this.createdExperiment) {
        tmpInc = this.createdExperiment['incID'];
        if (this.createdExperiment['device'] == 'overall') {
          tmpPlatform = ['desktop', 'mobile'];
        } else {
          tmpPlatform.push(this.createdExperiment['device']);
        }
      } else {
        tmpInc = this.experimentObj['incentive'];
        tmpPlatform = this.experimentPlatforms;
      }

      // let tmpPlatformsLength = 0;
      // function checkDevices() {
      //   tmpPlatformsLength++;
      //   if (tmpPlatformsLength == tmpPlatform.length) {
      //     return true;
      //   }
      //   return false;
      // }

      this.experimentStartBtn = true;
      let data = [];
      for (let i = 0; i < tmpPlatform.length; i++) {
        data.push({
          experimentName: this.experimentObj['name'],
          target: this.selectedTargetApi
            ? this.selectedTargetApi
            : this.experimentObj['target'],
          device: tmpPlatform[i],
          incID: tmpInc,
          creationDate: new Date(),
          online: true,
          AIVariantOnline: true,
        });
      }
      for (let i = 0; i < data.length; i++) {
        this.socket.emit('ai_optimization', data[i]);
      }

      this.socket.fromEvent('message').subscribe(
        (data: any) => {
          Object.keys(data).forEach((key) => {
            this.experimentStatus = data[key].status;
            this.experimentLine = data[key].line;

            if (this.experimentStatus == 'Finished') {
              this.socket.removeAllListeners('ai_optimization');
              this.socket.removeAllListeners('message');

              // if (checkDevices()) {
              //   this.createExperimentCampaign({
              //     target: this.selectedTargetApi,
              //     ruleID:
              //       tmpInc.split(".")[0] +
              //       "." +
              //       tmpInc.split(".")[1] +
              //       "." +
              //       tmpInc.split(".")[2],
              //     variantID: parseInt(tmpInc.split(".")[3]),
              //     devices: tmpPlatform,
              //   });
              // }
            }
          });
        },
        (err) => {
          console.log(err);
        }
      );
      this.socket.fromEvent('message_ping').subscribe(
        (data: any) => {
          this.eperimentProgress = Math.floor(data * 100);
          if (this.eperimentProgress == 100) {
            this.socket.removeAllListeners('message_ping');
            this.eperimentProgress = 0;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else if (action == 'stop') {
      this.experimentStatus = 'Stopped';
      this.socket.removeAllListeners('ai_optimization');
      this.socket.removeAllListeners('message');
      this.socket.removeAllListeners('message_ping');
    }
  }
  openSplitRatioDialog(platform: string, type: string) {
    let tmpPrc = this.experimentTableData[platform]?.splitRatioAI;
    if (typeof tmpPrc == 'string') {
      tmpPrc = parseFloat(tmpPrc.replace('%', '')) / 100;
    }
    if (!this.experimentTableData[platform]?.currentTrafficType) {
      this.editSplitRatioDialogRef = this.dialog.open(EditSplitratioComponent, {
        width: '430px',
        data: {
          experiment: this.experimentObj,
          isAI: type == 'ai' ? true : false,
          aiVariantRatio: type == 'ai' ? tmpPrc : 1 - tmpPrc,
          platform: platform,
          creationDate: this.experimentGeneralData[platform]?.creationDate,
          variants: this.experimentTableData[platform]?.ids,
        },
      });
      this.editSplitRatioDialogRef.afterClosed().subscribe((result) => {
        if (result == 1) {
          this.openSnackBar('Split Ratio updated!', 'OK');
          this.fetchData();
        } else if (result == 2) {
          this.openSnackBar('Something went wrong!', 'OK');
        }
      });
    }
  }
  changeTrafficAllocation(event: any, platform: string) {
    let variants: any = [];
    for (let i = 0; i < this.experimentTableData[platform].ids.length; i++) {
      variants.push(
        this.experimentTableData[platform].ids[i].incentive.replaceAll('.', '-')
      );
    }
    if (event.checked) {
      this.confirmDialogRef = this.dialog.open(ConfirmationComponent, {
        width: '430px',
        data: {
          header: 'AI Traffic Allocation',
          content: 'Are you sure you want to enable AI Traffic Allocation?',
        },
      });
      this.confirmDialogRef.afterClosed().subscribe((result) => {
        if (result == 1) {
          this.service
            .trafficAllocation(
              {
                experimentID: this.experimentObj.experimentID,
                target: this.experimentObj.target,
                device: platform,
                variants: variants,
                split_ratio:
                  parseFloat(
                    this.experimentTableData[platform].splitRatioAI.replace(
                      '%',
                      ''
                    )
                  ) / 100,
                creationDate: this.experimentGeneralData[platform].creationDate,
              },
              true
            )
            .subscribe(
              (res: any) => {
                if (res?.message == 'success') {
                  this.openSnackBar('AI Traffic Allocation Enabled', 'OK');
                  this.experimentTableData[platform].currentTrafficType = true;
                  event.source.checked = true;
                } else {
                  this.openSnackBar('Something went wrong!', 'OK');
                  event.source.checked = false;
                }
              },
              (err) => {
                this.openSnackBar('Something went wrong!', 'OK');
                event.source.checked = false;
              }
            );
        } else {
          this.experimentTableData[platform].currentTrafficType = false;
          event.source.checked = false;
        }
      });
    } else {
      this.confirmDialogRef = this.dialog.open(ConfirmationComponent, {
        width: '430px',
        data: {
          header: 'AI Traffic Allocation',
          content: 'Are you sure you want to disable AI Traffic Allocation?',
        },
      });
      this.confirmDialogRef.afterClosed().subscribe((result) => {
        if (result == 1) {
          this.service
            .trafficAllocation(
              {
                experimentID: this.experimentObj.experimentID,
                target: this.experimentObj.target,
                device: platform,
              },
              false
            )
            .subscribe(
              (res: any) => {
                if (res?.message && res?.message == 'success') {
                  this.openSnackBar('AI Traffic Allocation Disabled', 'OK');
                  this.experimentTableData[platform].currentTrafficType = false;
                  event.source.checked = false;
                } else {
                  this.openSnackBar('Something went wrong!', 'OK');
                  event.source.checked = true;
                }
              },
              (err) => {
                this.openSnackBar('Something went wrong!', 'OK');
                event.source.checked = true;
              }
            );
        } else {
          this.experimentTableData[platform].currentTrafficType = true;
          event.source.checked = true;
        }
      });
    }
  }
  selectedTargetApi: string = '';
  rollingObjApi: any = {};
  fetchData() {
    let data = {
      startDate: this.startDate,
      endDate: this.endDate,
      experimentName: this.experimentObj['name'],
    };
    this.isLoading = true;
    this.service.getExperimentPerformance(data).subscribe(
      (res) => {
        this.selectedTargetApi = res.message?.target;
        this.experimentStatus = res.message?.status;

        this.experimentPlatforms = [];
        if (
          Object.keys(res.message).includes('desktop') &&
          res.message?.desktop?.incentive?.length > 0
        ) {
          this.experimentPlatforms.push('desktop');
        }
        if (
          Object.keys(res.message).includes('mobile') &&
          res.message?.mobile?.incentive?.length > 0
        ) {
          this.experimentPlatforms.push('mobile');
        }

        if (this.experimentPlatforms.length == 0) {
          // this.experimentPlatforms = ['desktop', 'mobile'];
          this.noTableData = true;
        }

        this.experimentGeneralData = res.message;
        this.constructTableData();
        // }

        if (res?.message?.mobile) {
          var hasMobile = true;
        } else {
          var hasMobile = false;
        }

        if (res?.message?.desktop) {
          var hasDesktop = true;
        } else {
          var hasDesktop = false;
        }

        let tmpInc = '';
        if (this.createdExperiment) {
          tmpInc = this.createdExperiment['incID'];
        } else {
          tmpInc = this.experimentObj['incID'];
        }
        this.rollingObjApi = {
          target: this.selectedTargetApi,
          startDate: this.startDate,
          endDate: this.endDate,
          defaultNudgeId: tmpInc,
          aiNudge: tmpInc + '000',
          mobile: hasMobile,
          desktop: hasDesktop,
          rate: this.rate,
        };

        this.getRollingData();
        this.getTrafficGraph();
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }
  getRollingData() {
    this.rollingObjApi.rate = this.rate;
    this.rollingObjApi.startDate = this.startDate;
    this.rollingObjApi.endDate = this.endDate;
    this.isLoading = true;
    this.service.getRollingRevenue(this.rollingObjApi).subscribe((res) => {
      this.isLoading = false;
      this.experimentLineChartData = res;

      if (this.rollingObjApi.desktop) {
        this.showDesktopLineChart = true;
        this.populateDesktopExperimentLineChart();
      }
      if (this.rollingObjApi.mobile) {
        this.showMobileLineChart = true;
        this.populateMobileExperimentLineChart();
      }
    });
  }
  getTrafficGraph() {
    for (let i = 0; i < this.experimentPlatforms.length; i++) {
      this.service
        .trafficGraph({
          experimentID: this.experimentObj.experimentID,
          target: this.experimentObj.target,
          device: this.experimentPlatforms[i],
        })
        .subscribe(
          (res) => {
            this.experimentTrafficGraphData[this.experimentPlatforms[i]] = res;
            if (this.experimentPlatforms[i] == 'desktop') {
              this.populateDesktopRatioLineChart(res);
            } else if (this.experimentPlatforms[i] == 'mobile') {
              this.populateMobileRatioLineChart(res);
            }
          },
          (err) => {
            if (this.experimentPlatforms[i] == 'desktop') {
              this.populateDesktopRatioLineChart([]);
              this.showDesktopRatioChart = false;
            } else if (this.experimentPlatforms[i] == 'mobile') {
              this.populateMobileRatioLineChart([]);
              this.showMobileRatioChart = false;
            }
          }
        );
    }
  }
  createExperimentCampaign(data: any) {
    // console.log(data, " -------data");
    // this.service.createExperimentCampaign(data).subscribe((res) => {
    //   console.log(res, " ---------res");
    // });
  }
  redirectToExperimentList() {
    // go back one step in the router history
    let tmpParams: any = {};
    if (sessionStorage.getItem('paramsNudgeOptimization')) {
      tmpParams = JSON.parse(
        sessionStorage.getItem('paramsNudgeOptimization') || ''
      );
      sessionStorage.removeItem('paramsNudgeOptimization');
    }

    this.router.navigate(['master-preview'], {
      queryParams: {
        page: tmpParams['page'],
        sort: tmpParams['sort'],
        search: tmpParams['search'],
        online: tmpParams['online'],
      },
      queryParamsHandling: 'merge',
    });
  }

  onResize(event: any) {
    // Rebuild charts so they are responsive
    this.desktopExperimentLineChart.component?.draw(
      this.desktopExperimentLineChart
    );
    this.mobileExperimentLineChart.component?.draw(
      this.mobileExperimentLineChart
    );
    this.desktopRatioExperimentLineChart.component?.draw(
      this.desktopRatioExperimentLineChart
    );
    this.mobileRatioExperimentLineChart.component?.draw(
      this.mobileRatioExperimentLineChart
    );
  }

  removeSigns(value: string): string {
    return value.replace(/[+-]/g, '');
  }
}
