import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'seo-premium-banner',
  templateUrl: './seo-premium-banner.component.html',
  styleUrls: ['./seo-premium-banner.component.css'],
})
export class SeoPremiumBannerComponent implements OnInit {
  constructor(private router: Router) {}
  ngOnInit(): void {}
  redirectToBilling() {
    this.router.navigate(['/seo/billing']);
  }
}
