import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SessionService } from '../authentication/session.service';
import { TargetService } from './target.service';
import { SelectionService } from '../services/selection.service';
import { DateService } from '../services/date.service';
import { DarkmodeService } from '../services/darkmode.service';
import { SidenavService } from './sidenav.service';
import { PageTitleService } from '../services/pagetitle.service';
import { SeoService } from '../seo/seo.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { navigationLinks } from './navigationLinks';
import { ConfirmationComponent } from '../modals/confirmation/confirmation.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map, Observable, of, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IntercomService } from '../services/intercom.service';
import { Title } from '@angular/platform-browser';

// declare const Intercom: any;

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
  providers: [SessionService, PageTitleService],
})
export class SidenavComponent implements OnInit, OnDestroy {
  clientList: any = [];
  clientListFiltered: any = [];
  liveClientList: any;
  notLiveClientList: any;
  darkMode = false;
  selectedStore = 'Douglas DE';
  sidenavOpen: boolean = false;
  url: string = '';
  loadNr = 0;
  header: any = '';
  headerBetaTxt: string = '';
  window: any;
  activeTarget = window.localStorage.getItem('target');
  compareActive: boolean = false;
  startDateMin = '';
  isOpened = false; // Default state of the sidenav
  mode = 'side';
  mobileQuery: MediaQueryList;
  seoOnly: any;

  allUserTargetPrivileges: any[];

  constructor(
    private authService: SessionService,
    public router: Router,
    public selectionService: SelectionService,
    public dateService: DateService,
    private targetList: TargetService,
    private sidenavService: SidenavService,
    private pageTitleService: PageTitleService,
    private seoService: SeoService,
    private changeDetectorRef: ChangeDetectorRef,
    private darkModeService: DarkmodeService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    media: MediaMatcher,
    private translate: TranslateService,
    private intercomService: IntercomService,
    private titleService: Title
  ) {
    this.titleService.setTitle('Behamics Dashboard');
    this.mobileQuery = media.matchMedia('(max-width: 1200px)');
    this.updateLocalStorageDates();

    // const params = new URLSearchParams(window.location.search);
    // params.forEach((value, key) => {
    //   if (key === 'auth_key') {
    //     localStorage.setItem('auth_key', value);
    //   }
    // });
  }

  subs: Subscription = new Subscription();

  navigationLinks: any = [];

  subTriggerInvoices: boolean = false;
  subTriggerPerformance: boolean = false;
  subTriggerModelAutomation: boolean = false;
  subTriggerClientResults: boolean = false;
  subTriggerAutomationScript: boolean = false;
  subTriggerSEO: boolean = false;
  currentUserRole: any;
  subMasterTargetList: any;
  seoSubscription: any;
  seoSubscriptionList: any;

  confirmDialogRef: MatDialogRef<ConfirmationComponent>;
  gaStatus: boolean = false;
  gaStatusLoaded: boolean = false;

  keyWordData: any;
  selectedKeyWordData: any;

  // Filters
  selectedRangeValue = 'today';
  ranges = [
    {
      value: 'custom',
      viewValue: 'Custom',
    },
    {
      value: 'today',
      viewValue: 'Today',
    },
    {
      value: 'yestarday',
      viewValue: 'Yesterday',
    },
    {
      value: 'thisweek',
      viewValue: 'This Week',
    },
    { value: 'last7days', viewValue: 'Last 7 days' },
    { value: 'last30days', viewValue: 'Last 30 days' },
    {
      value: 'thismonth',
      viewValue: 'This Month',
    },
    {
      value: 'thisyear',
      viewValue: 'This Year',
    },
  ];

  startDate = new Date();
  endDate = new Date();
  maxDate = new Date();
  endMaxDate = new Date();
  minDate = new Date();
  rate = 'hourly';
  compareStartDate = new Date();
  compareEndDate = new Date();
  isLimitedView = false;
  demoAccount = false;
  selectedTab = 0;
  showNavWithTabs = false;
  hasOwnTabs = false;
  pagesWithTabs = [
    { url: '/recording', tabs: true, hasOwnTabs: true },
    { url: '/diagnostic', tabs: true, hasOwnTabs: true },
    { url: '/automations', tabs: true, hasOwnTabs: true },
    { url: '/seo/pages', tabs: false },
    { url: '/seo/projects', tabs: false },
    { url: '/seo/backlinks', tabs: true, hasOwnTabs: true },
    { url: '/seo/keywords', tabs: true, hasOwnTabs: true },
    { url: '/seo/automations', tabs: true, hasOwnTabs: true },
    { url: '/seo/ai-analysis', tabs: false },
    { url: '/seo/backlink-prediction', tabs: true, hasOwnTabs: true },
    { url: '/seo/positions', tabs: false },
    { url: '/seo/technicals', tabs: false },
    { url: '/seo/on-page', tabs: false },
    { url: '/seo/social', tabs: false },
    { url: '/detailed/trace-error', tabs: false },
    { url: '/merchandising', tabs: true, hasOwnTabs: true },
    { url: '/master-ai-experiments', tabs: true },
    { url: '/client-results/overall', tabs: true },
    { url: '/merchandising/product-details', tabs: false },
    { url: '/master-preview', tabs: true, hasOwnTabs: true },
    { url: '/master-performance-detailed', tabs: true },
    { url: '/statistics-configurations', tabs: false },
    { url: '/merchandising/category-details', tabs: false },
  ];

  seoProjects: any = [];
  seoProjectsFiltered: any = [];
  selectedSeoProject: any;
  selectedSeoProjectURL: string;
  seoStillLoadingBlock: boolean = true;
  noProjectSelected: boolean = true;

  seoOnlyLinks: boolean = false;

  navigationLinksFiltered: any = [];

  allKeywords: any;

  isLoading: boolean = true;

  disableSeoLinks: boolean = false;

  seoSubscriptionsList = [];

  savedLang: string = '';

  is_organic_under_maintenance: boolean = false;

  ngOnInit() {
    this.savedLang = localStorage.getItem('language') || 'en';
    this.translate.use(this.savedLang);

    // Deepclone array to not update the one from navigationLinks file
    this.navigationLinks = JSON.parse(JSON.stringify(navigationLinks));

    this.updateSidenavLinks();

    // this.toggleSidenavSize();
    // Theme Mode Checker
    if (localStorage.getItem('darkMode') === 'true') {
      this.darkMode = true;
      document.body.classList.add('dark-theme');
    } else if (localStorage.getItem('darkMode') === 'false') {
      this.darkMode = false;
      document.body.classList.remove('dark-theme');
    } else {
      const deviceMode = window.matchMedia('(prefers-color-scheme: dark)');
      if (deviceMode.matches) {
        this.darkMode = true;
        document.body.classList.add('dark-theme');
        window.localStorage.setItem('darkMode', 'true');
      }
    }

    if (!window.localStorage.getItem('kpiSymbol')) {
      window.localStorage.setItem('kpiSymbol', "'€'#");
    }

    this.subs.add(
      this.selectionService.currentMessage.subscribe((message) => {
        if (typeof message === 'string') {
          this.selectedStore = message;
        }
      })
    );

    this.sidenavService.statusUpdateObservable.subscribe((status) => {
      if (status >= 100) {
        this.seoStillLoadingBlock = false;
        this.openProductTour();
      }
    });

    this.seoService.value$.subscribe((newValue) => {
      this.keyWordData = newValue;

      // Set the first value of keyWordData if available
      if (this.keyWordData && this.keyWordData.length > 0) {
        this.selectedKeyWordData = this.keyWordData[0];
        this.seoService.updateSelectedObject(this.keyWordData[0]);
      } else {
        this.seoService.updateSelectedObject(undefined);
      }
    });

    this.seoService.allValue$.subscribe((newValue) => {
      if (newValue) {
        this.allKeywords = newValue;
      }
    });

    this.subs.add(
      this.pageTitleService.title.subscribe((val: string) => {
        if (val) {
          if (
            localStorage.getItem('save_last_tab') == null &&
            sessionStorage.getItem('save_temporary_title') !== val
          ) {
            this.sidenavService.updateActiveTabIndex(0);
            this.selectedTab = 0;
          }
          localStorage.removeItem('save_last_tab');

          sessionStorage.setItem('save_temporary_title', val);
        }

        if (val?.includes('beta version')) {
          this.headerBetaTxt = '(Beta Version)';
          this.header = val.split('beta version')[0].trim();
        } else {
          this.headerBetaTxt = '';

          this.header =
            this.getTranslatedText(val, 'sidenav_links').pipe(
              catchError(() => of(val))
            ) || '';
        }

        let falseIndex = 0;
        for (let i = 0; i < this.pagesWithTabs.length; i++) {
          let url = this.router.url.split('?')[0];
          if (url == this.pagesWithTabs[i].url) {
            this.showNavWithTabs = this.pagesWithTabs[i].tabs;
            this.hasOwnTabs = this.pagesWithTabs[i].hasOwnTabs ? true : false;
            break;
          } else {
            falseIndex++;
          }
        }
        if (falseIndex == this.pagesWithTabs.length) {
          this.showNavWithTabs = false;
          this.hasOwnTabs = false;
        }

        if (
          this.router.url.includes('/seo/') &&
          val &&
          !this.router.url.includes('/seo/billing')
        )
          this.getSeoProjectsFunc();
      })
    );
    this.subs.add(
      this.sidenavService.getActiveTabIndex().subscribe((index) => {
        this.selectedTab = index;
      })
    );

    window.localStorage.setItem('kpiSymbol', "#'€'");

    var etoday = new Date();
    etoday.setHours(etoday.getHours(), 59, 59);
    this.endDate = etoday;
    var today = new Date();
    today.setHours(0, 0, 0);
    this.startDate = today;

    this.updateLocalStorageDates();

    window.localStorage.setItem('compareActive', this.compareActive.toString());

    window.localStorage.setItem('rangeValue', this.selectedRangeValue);

    if (
      this.router.url == '/invoices/list' ||
      this.router.url == '/invoices/configuration'
    ) {
      this.subTriggerInvoices = true;
    }
    if (this.router.url.includes('/model-automation')) {
      this.subTriggerModelAutomation = true;
    }
    if (
      this.router.url.includes('/seo/') &&
      !this.router.url.includes('/seo/billing')
    ) {
      this.subTriggerSEO = true;
      for (let i = 0; i < this.navigationLinks.length; i++) {
        if (this.navigationLinks[i].title === 'Organic') {
          this.navigationLinks[i].active = true;
        }
      }
    }
    if (this.router.url != '/seo/content') {
      sessionStorage.removeItem('draftActive');
      sessionStorage.removeItem('task_Active');
      sessionStorage.removeItem('keyword_opened_editor_Active');
    }
    if (this.router.url == '/client-results/overall') {
      this.subTriggerClientResults = true;
    }
    if (
      this.router.url == '/script-automation/configuration' ||
      this.router.url == '/script-automation/debug'
    ) {
      this.subTriggerAutomationScript = true;
    }

    this.currentUserRole = window.localStorage.getItem('role');

    window.sessionStorage.removeItem('compareActive');
    window.sessionStorage.removeItem('compareStartDate');
    window.sessionStorage.removeItem('compareEndDate');

    if (
      this.router.url.includes('/seo/') &&
      this.is_organic_under_maintenance
    ) {
      this.showNavWithTabs = false;
      this.hasOwnTabs = false;
      this.router.navigate(['organic-maintenance']);
      return;
    }
    if (
      this.router.url.includes('/seo/') &&
      !this.router.url.includes('/seo/billing')
    ) {
      this.openProductTour();
    }

    this.subs.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.compareActive = false;
          window.sessionStorage.removeItem('compareActive');
          window.sessionStorage.removeItem('compareStartDate');
          window.sessionStorage.removeItem('compareEndDate');

          const contentContainer =
            document.querySelector('.mat-sidenav-content') || this.window;
          contentContainer.scrollTo(0, 0);

          this.updateLocalStorageDates();

          this.updateIntercom();

          if (
            this.router.url.includes('/seo/') &&
            this.is_organic_under_maintenance
          ) {
            this.showNavWithTabs = false;
            this.hasOwnTabs = false;
            this.router.navigate(['organic-maintenance']);
            return;
          }
          if (this.router.url.includes('/seo/')) {
            this.openProductTour();
          }
        }
      })
    );

    if (this.currentUserRole == 'master') {
      if (sessionStorage.getItem('targetName')) {
        this.selectedStore = sessionStorage.getItem('targetName') || '';
        window.localStorage.setItem(
          'targetName',
          sessionStorage.getItem('targetName') || ''
        );
        window.localStorage.setItem(
          'selectedTarget',
          sessionStorage.getItem('selectedTarget') || ''
        );
      } else {
        this.selectedStore = 'Douglas DE';
        localStorage.setItem('targetName', 'Douglas DE');
        localStorage.setItem('selectedTarget', 'douglas');
        localStorage.setItem('region', 'west-eu');
      }

      this.subs.add(
        this.targetList.getTargetList().subscribe((list) => {
          this.clientList = list;
          this.clientListFiltered = list;

          localStorage.setItem('shopId_list', JSON.stringify(list));

          this.liveClientList = this.clientList.filter(
            (o: any) => o.live == true
          );
          this.liveClientList = this.liveClientList.sort((a: any, b: any) =>
            a.description
              .toLowerCase()
              .localeCompare(b.description.toLowerCase())
          );

          this.notLiveClientList = this.clientList.filter(
            (o: any) => o.live == false
          );
          this.notLiveClientList = this.notLiveClientList.sort(
            (a: any, b: any) =>
              a.description
                .toLowerCase()
                .localeCompare(b.description.toLowerCase())
          );

          let filteredList: any[];
          filteredList = this.clientList.filter(
            (o: any) => o.description == this.selectedStore
          );

          this.gaStatus = filteredList[0].ga4;
          this.gaStatusLoaded = true;

          if (
            window.location.href.includes('?target') &&
            !window.location.href.includes('?mailredirect')
          ) {
            var url_string = window.location.href;
            var url = new URL(url_string);

            this.selectedStore = url.searchParams.get('target') || '';
            this.startDate = new Date(url.searchParams.get('startDate') || '');
            this.endDate = new Date(url.searchParams.get('endDate') || '');

            window.localStorage.setItem('selectedTarget', this.selectedStore);
            this.updateLocalStorageDates();
          }

          if (window.location.href.includes('?mailredirect')) {
            var url_string = window.location.href;
            var url = new URL(url_string);

            this.selectedStore = this.liveClientList.filter(
              (o: any) => o.target == url.searchParams.get('target')
            )[0].description;

            this.startDate = new Date(url.searchParams.get('startDate') || '');
            this.endDate = new Date(url.searchParams.get('endDate') || '');

            window.localStorage.setItem(
              'selectedTarget',
              url.searchParams.get('target') || ''
            );
            this.updateLocalStorageDates();

            localStorage.removeItem('mailredirect');

            this.subTriggerPerformance = true;
            // this.router.navigateByUrl('/master-performance-overall');
          } else if (
            localStorage.getItem('mailredirect') &&
            localStorage.getItem('mailredirect') != '' &&
            JSON.parse(window.localStorage.getItem('mailredirect') || '')
              ?.mailredirect == 'true'
          ) {
            var data = JSON.parse(
              window.localStorage.getItem('mailredirect') || ''
            );

            this.startDate = new Date(data.startDate);
            this.endDate = new Date(data.endDate);

            this.selectedStore = this.liveClientList.filter(
              (o: any) => o.target == data.target
            )[0].description;

            window.localStorage.setItem('selectedTarget', data.target);
            this.updateLocalStorageDates();

            localStorage.removeItem('mailredirect');

            this.subTriggerPerformance = true;
            // this.router.navigateByUrl('/master-performance-overall');
          }

          window.localStorage.setItem('currency', filteredList[0].currency);
        })
      );
    }

    this.activeTarget = window.localStorage.getItem('target');
    setTimeout(() => {
      this.updateCurrencySymbol();
    }, 400);

    if (localStorage.getItem('email') == 'master@behamics.com') {
      localStorage.setItem('seoSubscription', 'Premium');
    } else {
      if (
        localStorage.getItem('seoSubscriptionObj') &&
        localStorage.getItem('seoSubscriptionObj') !== 'undefined'
      ) {
        if (
          localStorage.getItem('seoSubscriptionObj') == '"Premium"' ||
          localStorage.getItem('seoSubscriptionObj') == '"Freemium"'
        ) {
          if (localStorage.getItem('seoSubscriptionObj') == '"Premium"') {
            localStorage.setItem('seoSubscription', 'Premium');
          }
          if (localStorage.getItem('seoSubscriptionObj') == '"Freemium"') {
            localStorage.setItem('seoSubscription', 'Freemium');
          }
        } else {
          const seoObj: any = JSON.parse(
            localStorage.getItem('seoSubscriptionObj') || '[]'
          );
          const filtered = seoObj?.filter(
            (item: any) => item.shopID == this.activeTarget
          );
          if (filtered.length > 0) {
            localStorage.setItem(
              'seoSubscription',
              filtered[0].seoSubscription ?? 'Freemium'
            );
          } else {
            localStorage.setItem('seoSubscription', 'Freemium');
          }
        }
        this.seoSubscription = localStorage.getItem('seoSubscription');
      }
    }

    if (
      this.router.url === '/statistics-configurations' ||
      this.router.url === '/predictions/returnrate' ||
      this.router.url === '/atc-alert-system' ||
      this.router.url === '/model-automation'
    ) {
      this.updateSidenavLinks();
    } else {
      this.updateUserRoles();
    }
    try {
      this.sidenavService.getMinDate().subscribe((date: string) => {
        this.startDateMin = date;
      });
    } catch (e) {
      console.log(e);
    }
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getTranslatedText(text: string, type: string = 'words'): Observable<string> {
    return this.translate.get(`${type}.${text}`).pipe(
      map((translated: string) =>
        translated === `${type}.${text}` ? text : translated
      ),
      catchError(() => of(text))
    );
  }

  onSelectKeywordData(selectedValue: any) {
    this.selectedKeyWordData = selectedValue;

    if (selectedValue) {
      this.seoService.updateSelectedObject(selectedValue);
    }
  }

  updateIntercom(): void {
    if (window.Intercom) {
      window.Intercom('update');
    }
  }

  changeGa4Status() {
    let header;
    let content;

    if (this.gaStatus) {
      header = 'Disconnect Google Analytics';
      content =
        'Are you sure you want to disable event transfer to Google Analytics?';
    } else {
      header = 'Connect Google Analytics';
      content =
        'Are you sure you want to connect and transfer events to Google Analytics?';
    }

    this.confirmDialogRef = this.dialog.open(ConfirmationComponent, {
      width: '430px',
      data: {
        header: header,
        content: content,
      },
    });

    this.confirmDialogRef.afterClosed().subscribe((result) => {
      if (result == 1) {
        this.targetList
          .updateGa4Status(
            localStorage.getItem('selectedTarget'),
            !this.gaStatus
          )
          .subscribe((res: any) => {
            this.gaStatus = res.ga4;
            if (res.ga4) {
              this.snackBar.open('Google Analytics has been connected!', 'x', {
                duration: 2000,
              });
            } else {
              this.snackBar.open(
                'Google Analytics has been disconnected!',
                'x',
                {
                  duration: 2000,
                }
              );
            }
          });
      }
    });
  }
  updateSidenavLinks() {
    this.isLoading = true;
    let role = localStorage.getItem('privileges')?.split(',') || [];

    if (role.length === 0) {
      this.router.navigate(['login']);
    }

    let seoLinksIndex = 0;
    this.navigationLinksFiltered = [];

    for (let i = 0; i < role.length; i++) {
      if (role[i].includes('seo') || role[i] == 'userPermissions') {
        seoLinksIndex += 1;
      }
    }

    if (seoLinksIndex == role.length) {
      this.seoOnly = true;
    } else {
      this.seoOnly = false;
    }

    for (let i = 0; i < this.navigationLinks.length; i++) {
      if (
        role.includes(this.navigationLinks[i].privilege) ||
        (this.navigationLinks[i].title == 'Organic' && seoLinksIndex > 0)
      ) {
        let link = { ...this.navigationLinks[i] };
        // check for children
        if (this.navigationLinks[i]?.children?.length > 0) {
          let children = [];
          for (let j = 0; j < this.navigationLinks[i].children.length; j++) {
            if (
              role.includes(this.navigationLinks[i].children[j].privilege) &&
              !this.navigationLinks[i].children[j].hideFromView
            ) {
              children.push(this.navigationLinks[i].children[j]);
            }
          }

          if (!this.navigationLinks[i]?.hideFromView) {
            link.children = [...children];
          }
        }

        // add to filtered list
        if (!this.navigationLinks[i]?.hideFromView) {
          this.navigationLinksFiltered.push(link);
        }
      } else if (
        !this.navigationLinks[i]?.hideFromView &&
        !this.navigationLinks[i]?.hideIfNoPrivileges
      ) {
        this.navigationLinksFiltered.push({
          title: this.navigationLinks[i]?.title,
          icon: {
            src: this.navigationLinks[i]?.icon?.src,
            inlineStyle: this.navigationLinks[i]?.icon.inlineStyle,
          },
          active: false,
          children: [],
          callback: () => {
            this.closeParentTrigger();
          },
          disabled: true,
        });
      }
    }

    if (seoLinksIndex === role.length) {
      let seoLinks = this.navigationLinksFiltered.filter(
        (link: any) => link.title === 'Organic'
      )?.[0];
      this.navigationLinksFiltered = [...seoLinks?.children];
      this.seoOnlyLinks = true;
    } else {
      this.navigationLinksFiltered.sort((a: any, b: any) => {
        if (!a?.disabled && b?.disabled) {
          return -1;
        }
        return 0;
      });
      this.seoOnlyLinks = false;
    }

    this.isLoading = false;

    this.adaptNavigationListHeight();
  }

  navigationLinksCallback(title: string) {
    if (title == 'Organic' && this.is_organic_under_maintenance) {
      this.showNavWithTabs = false;
      this.hasOwnTabs = false;
      this.router.navigate(['organic-maintenance']);
      return;
    }

    if (title == 'Organic') {
      this.parentSEOClick();
    } else {
      this.closeParentTrigger();
    }
    if (this.mobileQuery.matches) {
      let selectedNavigationLink = this.navigationLinksFiltered.find(
        (obj: any) => {
          return obj.title === title;
        }
      );
      if (
        selectedNavigationLink &&
        selectedNavigationLink?.children?.length > 0
      ) {
        this.isOpened = true;
      }
    }
  }

  ngAfterContentChecked() {
    this.changeDetectorRef?.detectChanges();
  }
  seoProjectsFired = false;
  getSeoProjectsFunc(byPassFirstLoad = false) {
    if (this.seoProjectsFired && !byPassFirstLoad) return;

    if (
      !byPassFirstLoad &&
      !localStorage.getItem('organic_onboarding_redirect') &&
      this.seoOnly
    ) {
      document.body.classList.add('organic_loading');
    }

    this.seoProjectsFired = true;
    this.subs.add(
      this.seoService.getProjects().subscribe({
        next: (response) => {
          document.body.classList.remove('organic_loading');

          if (this.is_organic_under_maintenance) return;

          if (response && response.length > 0) {
            this.disableSeoLinks = false;
          } else {
            this.disableSeoLinks = true;
          }
          if (response) {
            if (response.length === 0) {
              if (localStorage.getItem('organic_onboarding_redirect')) {
                this.router.navigate(['/seo/projects']);
              } else {
                localStorage.setItem('organic_onboarding_redirect', 'true');
                this.router.navigate(['/organic_onboarding']);
              }
            }
            this.seoProjects = response;

            this.seoProjects = this.seoProjects.sort((a: any, b: any) => {
              if (a?.domain < b?.domain) {
                return -1;
              }
              if (a?.domain > b?.domain) {
                return 1;
              }
              return 0;
            });

            this.seoProjectsFiltered = [...this.seoProjects];

            let parsedSessionProject: any;
            try {
              if (
                sessionStorage.getItem('selectedProject') &&
                sessionStorage.getItem('selectedProject')?.trim() !==
                  'undefined'
              ) {
                parsedSessionProject = JSON.parse(
                  sessionStorage.getItem('selectedProject') || '{}'
                );
              } else {
                parsedSessionProject = {};
              }
            } catch (e) {
              console.log(e);
            }

            const pagesSeoUrl = sessionStorage.getItem('pages_seo_url');

            if (pagesSeoUrl) {
              this.selectedSeoProject = this.seoProjects.find((p: any) =>
                pagesSeoUrl.includes(p['domain'])
              );

              sessionStorage.removeItem('pages_seo_url');
            } else if (Object.keys(parsedSessionProject).length > 0) {
              this.selectedSeoProject = this.seoProjects.find((p: any) =>
                parsedSessionProject['domain']?.includes(p['domain'])
              );

              if (!this.selectedSeoProject) {
                this.selectedSeoProject = this.seoProjects[0];
              }
            } else {
              this.selectedSeoProject = this.seoProjects[0];
            }

            this.selectedSeoProjectURL = this.selectedSeoProject?.url;

            if (this.selectedSeoProject) {
              this.sidenavService.setSeoProject(this.selectedSeoProject);
            } else {
              this.sidenavService.setSeoProject('');
            }

            this.subs.add(
              this.sidenavService.childData$().subscribe((data: any) => {
                if (data.url) {
                  this.selectedSeoProjectURL = data.url;
                } else if (this.selectedSeoProject) {
                  this.selectedSeoProjectURL = this.selectedSeoProject.url;
                }
                this.seoProjectChanged();
              })
            );
          } else {
            this.seoProjects = [];
            this.sidenavService.setSeoProject('');
          }
        },
        error: (error) => {
          document.body.classList.remove('organic_loading');
          console.error(error);
          this.seoProjects = [];
          this.seoProjectsFiltered = [];
          this.sidenavService.setSeoProject('');
        },
      })
    );
  }

  searchProjects(event: any) {
    const value = event?.target?.value;

    try {
      this.seoProjectsFiltered = this.seoProjects.filter((project: any) => {
        return (
          project.domain.includes(value) ||
          project.url.includes(this.selectedSeoProjectURL)
        );
      });
    } catch (e) {
      console.log(e);
    }
  }
  searchTargets(event: any) {
    const value = event?.target?.value;

    try {
      this.clientListFiltered = this.clientList.filter((target: any) => {
        return (
          target['description']?.includes(value) ||
          target['target']?.includes(value)
        );
      });

      this.liveClientList = this.clientListFiltered.filter(
        (o: any) => o.live == true
      );
      this.liveClientList = this.liveClientList.sort((a: any, b: any) =>
        a.description.toLowerCase().localeCompare(b.description.toLowerCase())
      );

      this.notLiveClientList = this.clientListFiltered.filter(
        (o: any) => o.live == false
      );
      this.notLiveClientList = this.notLiveClientList.sort((a: any, b: any) =>
        a.description.toLowerCase().localeCompare(b.description.toLowerCase())
      );
    } catch (e) {
      console.log(e);
    }
  }

  seoLinkLoadingChecker(parent: any, child: any) {
    if (parent?.title === 'Organic' || parent['disable_on_zero_projects']) {
      if (
        child?.title === 'Dashboard' ||
        child?.title === 'Content' ||
        child?.title === 'Positions' ||
        child?.title === 'Keywords' ||
        child?.title === 'AI Analysis' ||
        child?.title === 'Backlink Prediction' ||
        child?.title === 'Competitors' ||
        parent?.['disable_on_zero_projects']
      ) {
        return this.seoStillLoadingBlock;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  redirectToScripts() {
    this.router.navigate(['/seo/scripts']);
  }
  seoProjectChanged() {
    this.selectedSeoProject = this.seoProjects.filter(
      (p: any) => this.selectedSeoProjectURL === p['url']
    )[0];

    // If there is no project selected return
    if (!this.selectedSeoProject) {
      this.noProjectSelected = true;
      return;
    }
    this.noProjectSelected = false;

    this.subs.add(
      this.seoService
        .getStatusOfProject({ domain: this.selectedSeoProject?.domain?.trim() })
        .subscribe({
          next: (res) => {
            if (res.averageStatusBar >= 100) {
              this.seoStillLoadingBlock = false;
            } else {
              this.seoStillLoadingBlock = true;
              if (!this.router.url.includes('/seo/projects')) {
                this.router.navigate(['/seo/getting-started']);
              }
            }
            this.sidenavService.setSeoProject(this.selectedSeoProject);
          },
          error: (err) => {
            console.log(err);
          },
        })
    );
  }

  checkRoleGuard(page: any) {
    if (localStorage.getItem('privileges')) {
      let role = localStorage.getItem('privileges')?.split(',') || [];

      if (role.findIndex((priv) => priv == page) < 0) {
        return false;
      } else {
        return true;
      }
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }

  switchThemeMode(event: any) {
    if (event.checked) {
      this.darkMode = true;
      document.body.classList.add('dark-theme');
      window.localStorage.setItem('darkMode', 'true');
    } else {
      this.darkMode = false;
      document.body.classList.remove('dark-theme');
      window.localStorage.setItem('darkMode', 'false');
    }

    // this.selectionService.selectedStore({ themeMode: 'changed' });
    this.darkModeService.updateDarkMode(event.checked);
  }

  updateUserRoles() {
    this.subs.add(
      this.targetList
        .getCurrentUserRoles(window.localStorage.getItem('email'))
        .subscribe({
          next: (res) => {
            localStorage.setItem('customerId', res['customerId']);

            this.subMasterTargetList = res.shopID;

            this.seoSubscriptionList = res?.seoSubscription || [];
            this.seoSubscription = '';

            let role = localStorage.getItem('role');
            if (role == 'master') {
              this.seoSubscription = 'Premium';
            } else {
              const target = localStorage.getItem('selectedTarget');
              const seoSub =
                this.seoSubscriptionList.find((sub: any) => {
                  return sub.shopID == target;
                })?.seoSubscription || 'Freemium';

              this.seoSubscription = seoSub || 'Freemium';
            }
            localStorage.setItem('seoSubscription', this.seoSubscription);

            if (this.currentUserRole == 'sub-master') {
              const tmpTarget = localStorage.getItem('target');
              if (
                tmpTarget &&
                this.subMasterTargetList.find(
                  (list: any) => list.target == tmpTarget
                )
              ) {
                const storageTarget = this.subMasterTargetList.find(
                  (list: any) => list.target == tmpTarget
                );

                this.selectedStore = storageTarget.description;

                window.localStorage.setItem(
                  'selectedTarget',
                  storageTarget.target
                );
                window.localStorage.setItem(
                  'targetName',
                  storageTarget.description
                );
              } else {
                this.selectedStore = this.subMasterTargetList[0].description;
                window.localStorage.setItem(
                  'selectedTarget',
                  this.subMasterTargetList[0].target
                );
                window.localStorage.setItem(
                  'targetName',
                  this.subMasterTargetList[0].description
                );
              }

              this.clientList = this.subMasterTargetList;

              localStorage.setItem(
                'shopId_list',
                JSON.stringify(this.clientList)
              );

              if (window.location.href.includes('?mailredirect')) {
                var url_string = window.location.href;
                var url = new URL(url_string);

                this.startDate = new Date(
                  url.searchParams.get('startDate') || ''
                );
                this.endDate = new Date(url.searchParams.get('endDate') || '');
                var target = this.subMasterTargetList.filter(
                  (o: any) => o.target == url.searchParams.get('target')
                );

                this.selectedStore = target[0].description;

                window.localStorage.setItem(
                  'selectedTarget',
                  url.searchParams.get('target') || ''
                );
                this.updateLocalStorageDates();

                localStorage.removeItem('mailredirect');

                this.subTriggerPerformance = true;
                // this.router.navigateByUrl('/master-performance-overall');
              } else if (
                window.localStorage.getItem('mailredirect') &&
                window.localStorage.getItem('mailredirect') != '' &&
                JSON.parse(window.localStorage.getItem('mailredirect') || '')
                  ?.mailredirect == 'true'
              ) {
                var data = JSON.parse(
                  window.localStorage.getItem('mailredirect') || ''
                );

                this.startDate = new Date(data.startDate);
                this.endDate = new Date(data.endDate);
                var target = this.subMasterTargetList.filter(
                  (o: any) => o.target == data.target
                );

                this.selectedStore = target[0].description;

                window.localStorage.setItem('selectedTarget', data.target);
                this.updateLocalStorageDates();

                localStorage.removeItem('mailredirect');

                this.subTriggerPerformance = true;
                // this.router.navigateByUrl('/master-performance-overall');
              }
            }

            if (res.userPrivilegesTest) {
              this.allUserTargetPrivileges = res.userPrivilegesTest;
            } else {
              this.allUserTargetPrivileges = res.privileges;
            }

            const selectedTarget = localStorage.getItem('selectedTarget');

            localStorage.setItem(
              'allUserTargetPrivileges',
              JSON.stringify(this.allUserTargetPrivileges)
            );

            let privileges = this.allUserTargetPrivileges.find((user: any) => {
              return user.shopID === selectedTarget;
            })?.priv;

            if (
              !this.allUserTargetPrivileges ||
              this.allUserTargetPrivileges?.length == 0
            ) {
              privileges = res['privileges'];
            } else if (!privileges || privileges?.length == 0) {
              privileges = this.allUserTargetPrivileges?.[0]?.priv;
            }

            localStorage.setItem('privileges', privileges);

            // localStorage.setItem('privileges', res.privileges);

            this.updateSidenavLinks();
          },
          error: (e) => {
            const darkMode = localStorage.getItem('darkMode') || '';
            const seenTours = JSON.parse(
              localStorage.getItem('seenTours') || '{}'
            );

            localStorage.clear();

            // Set darkmode and seenTours
            localStorage.setItem('darkMode', darkMode);
            localStorage.setItem('seenTours', JSON.stringify(seenTours));
          },
        })
    );
  }

  updateCurrencySymbol() {
    var currency = window.localStorage.getItem('currency');

    switch (currency) {
      case 'EUR':
        return localStorage.setItem('kpiSymbol', "#,###'€'");
      case 'USD':
        return localStorage.setItem('kpiSymbol', "'$'#,###");
      case 'CHF':
        return localStorage.setItem('kpiSymbol', "#,###'CHF'");
      case 'GBP':
        return localStorage.setItem('kpiSymbol', "'£'#,###");
      case 'CZK':
        return localStorage.setItem('kpiSymbol', "'kc'#,###");
      case 'PLN':
        return localStorage.setItem('kpiSymbol', "'zl'#,###");
      case 'SEK':
        return localStorage.setItem('kpiSymbol', "#,###'kr'");
      case 'JPY':
        return localStorage.setItem('kpiSymbol', "'¥'#,###");
    }
  }

  refreshTargetList() {
    this.clientList = [];
    this.liveClientList = [];
    this.subs.add(
      this.targetList.getTargetList().subscribe((list) => {
        this.clientList = list;
        this.liveClientList = this.clientList.filter(
          (o: any) => o.live == true
        );
        this.liveClientList = this.liveClientList.sort((a: any, b: any) =>
          a.description.toLowerCase().localeCompare(b.description.toLowerCase())
        );
        this.notLiveClientList = this.clientList.filter(
          (o: any) => o.live == false
        );
        this.notLiveClientList = this.notLiveClientList.sort((a: any, b: any) =>
          a.description.toLowerCase().localeCompare(b.description.toLowerCase())
        );
        let filteredList: any[];
        filteredList = this.clientList.filter(
          (o: any) => o.description == this.selectedStore
        );

        window.localStorage.setItem('currency', filteredList[0].currency);
      })
    );
  }

  getStartDate(event: any) {
    this.selectedRangeValue = 'custom';
    this.startDate = new Date(event.value);
    this.minDate = this.startDate;

    // Send Date Values
    this.updateLocalStorageDates();

    window.localStorage.setItem('compareActive', this.compareActive.toString());
    window.localStorage.setItem('rangeValue', this.selectedRangeValue);
    if (
      this.currentUserRole != 'master' &&
      this.currentUserRole != 'hirmer' &&
      this.currentUserRole != 'ochsnersport' &&
      this.currentUserRole != 'calidaschuster' &&
      this.currentUserRole != 'esprit' &&
      this.currentUserRole != 'manor' &&
      this.currentUserRole != 'gerryweber' &&
      this.currentUserRole != 'christ' &&
      this.currentUserRole != 'sub-master'
    ) {
      this.dateService.selectedDate(this.startDate);
    } else {
      let filteredList: any[];
      filteredList = this.clientList.filter(
        (o: any) => o.description == this.selectedStore
      );
      this.selectionService.selectedStore(filteredList[0]?.description);
    }
  }

  getCompareStartDate(event: any) {
    this.compareStartDate = new Date(event.value);

    window.sessionStorage.setItem(
      'compareStartDate',
      this.compareStartDate.toISOString() +
        '@' +
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    window.sessionStorage.setItem(
      'compareEndDate',
      this.endDate.toISOString() +
        '@' +
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    window.sessionStorage.setItem(
      'compareActive',
      this.compareActive.toString()
    );
    if (
      this.currentUserRole != 'master' &&
      this.currentUserRole != 'hirmer' &&
      this.currentUserRole != 'ochsnersport' &&
      this.currentUserRole != 'calidaschuster' &&
      this.currentUserRole != 'esprit' &&
      this.currentUserRole != 'manor' &&
      this.currentUserRole != 'gerryweber' &&
      this.currentUserRole != 'christ'
    ) {
      this.dateService.selectedDate(this.startDate);
    } else {
      let filteredList: any[];
      filteredList = this.clientList.filter(
        (o: any) => o.description == this.selectedStore
      );
      this.selectionService.selectedStore(filteredList[0]?.description);
    }
  }

  getEndDate(event: any) {
    this.selectedRangeValue = 'custom';

    var nowDate = new Date();
    var date = new Date(event.value);
    this.maxDate = date;

    if (
      date.getFullYear() == nowDate.getFullYear() &&
      date.getMonth() == nowDate.getMonth() &&
      date.getDate() == nowDate.getDate()
    ) {
      date.setHours(nowDate.getHours(), 59, 59);
    } else {
      date.setHours(23, 59, 59);
    }
    this.endDate = new Date(date);

    // Send Date Values
    this.updateLocalStorageDates();

    window.localStorage.setItem('rangeValue', this.selectedRangeValue);

    window.localStorage.setItem('compareActive', this.compareActive.toString());
    if (
      this.currentUserRole != 'master' &&
      this.currentUserRole != 'hirmer' &&
      this.currentUserRole != 'ochsnersport' &&
      this.currentUserRole != 'calidaschuster' &&
      this.currentUserRole != 'esprit' &&
      this.currentUserRole != 'manor' &&
      this.currentUserRole != 'gerryweber' &&
      this.currentUserRole != 'christ' &&
      this.currentUserRole != 'sub-master'
    ) {
      this.dateService.selectedDate(this.startDate);
    } else {
      let filteredList: any[];
      filteredList = this.clientList.filter(
        (o: any) => o.description == this.selectedStore
      );
      this.selectionService.selectedStore(filteredList[0]?.description);
    }
  }

  getEndDateCompared(event: any) {
    var nowDate = new Date();
    var date = new Date(event.value);

    if (
      date.getFullYear() == nowDate.getFullYear() &&
      date.getMonth() == nowDate.getMonth() &&
      date.getDate() == nowDate.getDate()
    ) {
      date.setHours(nowDate.getHours(), 59, 59);
    } else {
      date.setHours(23, 59, 59);
    }
    this.compareEndDate = new Date(date);

    // Send Date Values
    window.sessionStorage.setItem(
      'compareStartDate',
      this.compareStartDate.toISOString() +
        '@' +
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    window.sessionStorage.setItem(
      'compareEndDate',
      this.compareEndDate.toISOString() +
        '@' +
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    window.sessionStorage.setItem(
      'compareActive',
      this.compareActive.toString()
    );
    if (
      this.currentUserRole != 'master' &&
      this.currentUserRole != 'hirmer' &&
      this.currentUserRole != 'ochsnersport' &&
      this.currentUserRole != 'calidaschuster' &&
      this.currentUserRole != 'esprit' &&
      this.currentUserRole != 'manor' &&
      this.currentUserRole != 'gerryweber' &&
      this.currentUserRole != 'christ'
    ) {
      this.dateService.selectedDate(this.startDate);
    } else {
      let filteredList: any[];
      filteredList = this.clientList.filter(
        (o: any) => o.description == this.selectedStore
      );
      this.selectionService.selectedStore(filteredList[0]?.description);
    }
  }

  rangeChanged(event: any) {
    var stoday = new Date();
    var etoday = new Date();

    if (this.selectedRangeValue == 'today') {
      stoday.setHours(0, 0, 0);
      this.startDate = stoday;
      this.minDate = stoday;

      etoday.setHours(etoday.getHours(), 59, 59);
      this.endDate = etoday;
      this.maxDate = etoday;
    } else if (this.selectedRangeValue == 'yestarday') {
      stoday.setDate(stoday.getDate() - 1);
      stoday.setHours(0, 0, 0);
      this.startDate = stoday;

      etoday.setDate(etoday.getDate() - 1);
      etoday.setHours(23, 59, 59);
      this.endDate = etoday;
      this.maxDate = etoday;
    } else if (this.selectedRangeValue == 'thisweek') {
      var d = new Date();
      var day = d.getDay();
      var diff = d.getDate() - day + (day == 0 ? -6 : 1);
      stoday = new Date(d.setDate(diff));

      stoday.setHours(0, 0, 0);
      this.startDate = stoday;
      this.minDate = stoday;

      etoday.setHours(etoday.getHours(), 59, 59);
      this.endDate = etoday;
      this.maxDate = etoday;
    } else if (this.selectedRangeValue == 'last7days') {
      stoday.setDate(stoday.getDate() - 7);
      stoday.setHours(0, 0, 0);
      this.startDate = stoday;
      this.minDate = stoday;

      etoday.setHours(etoday.getHours(), 59, 59);
      this.endDate = etoday;
      this.maxDate = etoday;
    } else if (this.selectedRangeValue == 'last30days') {
      stoday.setDate(stoday.getDate() - 30);
      stoday.setHours(0, 0, 0);
      this.startDate = stoday;
      this.minDate = stoday;

      etoday.setHours(etoday.getHours(), 59, 59);
      this.endDate = etoday;
      this.maxDate = etoday;
    } else if (this.selectedRangeValue == 'thismonth') {
      var tmpToday = new Date();
      var monthStartDate = new Date(
        tmpToday.getFullYear(),
        tmpToday.getMonth(),
        1
      );

      this.startDate = monthStartDate;
      this.minDate = monthStartDate;

      etoday.setHours(etoday.getHours(), 59, 59);
      this.endDate = etoday;
      this.maxDate = etoday;
    } else if (this.selectedRangeValue == 'thisyear') {
      var thisyear = new Date(new Date().getFullYear(), 0, 1);

      this.startDate = thisyear;
      this.minDate = thisyear;

      etoday.setHours(etoday.getHours(), 59, 59);
      this.endDate = etoday;
      this.maxDate = etoday;
    }

    if (
      this.selectedRangeValue == 'today' ||
      this.selectedRangeValue == 'yesterday'
    ) {
      this.rate = 'hourly';
    }

    // Send Date Values
    this.updateLocalStorageDates();

    window.localStorage.setItem('rangeValue', this.selectedRangeValue);

    window.localStorage.setItem('compareActive', this.compareActive.toString());

    if (
      this.currentUserRole != 'master' &&
      this.currentUserRole != 'hirmer' &&
      this.currentUserRole != 'ochsnersport' &&
      this.currentUserRole != 'calidaschuster' &&
      this.currentUserRole != 'esprit' &&
      this.currentUserRole != 'manor' &&
      this.currentUserRole != 'gerryweber' &&
      this.currentUserRole != 'christ' &&
      this.currentUserRole != 'sub-master'
    ) {
      this.dateService.selectedDate(this.startDate);
    } else {
      let filteredList: any[];
      filteredList = this.clientList.filter(
        (o: any) => o.description == this.selectedStore
      );
      this.selectionService.selectedStore(filteredList[0]?.description);
    }
  }

  updateLocalStorageDates() {
    window.localStorage.setItem(
      'startDate',
      this.startDate.toISOString() +
        '@' +
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    window.localStorage.setItem(
      'endDate',
      this.endDate.toISOString() +
        '@' +
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
  }

  parentClientClick() {
    this.subTriggerModelAutomation = false;
    this.subTriggerClientResults = false;
    this.subTriggerAutomationScript = false;
    this.subTriggerPerformance = false;
    this.subTriggerSEO = false;

    this.subTriggerInvoices = !this.subTriggerInvoices;
    this.router.navigateByUrl('/invoices/list');
  }

  parentAutomationScriptClick() {
    this.subTriggerInvoices = false;
    this.subTriggerClientResults = false;
    this.subTriggerPerformance = false;
    this.subTriggerSEO = false;
    this.subTriggerAutomationScript = !this.subTriggerAutomationScript;
  }

  parentSEOClick() {
    this.subTriggerModelAutomation = false;

    if (
      (!this.isOpened && this.mobileQuery.matches) ||
      (this.router.url.includes('/seo/') &&
        !this.router.url.includes('/seo/billing'))
    ) {
      const link = this.navigationLinksFiltered.find(
        (link: any) => link.title == 'Organic'
      );
      link.active = !link.active;

      return;
    }

    for (let i = 0; i < this.navigationLinksFiltered.length; i++) {
      if (this.navigationLinksFiltered[i].title == 'Organic') {
        this.navigationLinksFiltered[i].active =
          !this.navigationLinksFiltered[i].active;
      } else {
        this.navigationLinksFiltered[i].active = false;
      }
    }

    this.router.navigateByUrl('/seo/getting-started');
  }
  closeParentTrigger() {
    this.subTriggerModelAutomation = false;
    for (let i = 0; i < this.navigationLinksFiltered.length; i++) {
      this.navigationLinksFiltered[i].active = false;
    }
  }
  parentModelsClick() {
    this.subTriggerModelAutomation = !this.subTriggerModelAutomation;
  }

  showNonLive = false;

  toggleNonLive() {
    this.showNonLive = !this.showNonLive; // Toggle the visibility of non-live items
  }

  formatComma(value: any) {
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // Handle actual selection changes
  onSelectionChange(event: any) {
    // Only execute targetChange if a valid option is selected
    if (event.value && event.value !== 'nonLiveGroup') {
      this.selectedStore = event.value; // Update the selected store
      this.targetChange(); // Call your targetChange function
    }
  }

  targetChange() {
    let filteredList: any[];
    filteredList = this.clientList.filter(
      (o: any) => o.description == this.selectedStore
    );

    try {
      let role = localStorage.getItem('role');
      if (role == 'master') {
        this.seoSubscription = 'Premium';
      } else {
        const seoSub =
          this.seoSubscriptionList?.find((sub: any) => {
            return sub.shopID == filteredList[0].target;
          })?.seoSubscription || 'Freemium';

        this.seoSubscription = seoSub || 'Freemium';
      }

      localStorage.setItem('seoSubscription', this.seoSubscription);
    } catch (e) {
      console.log(e);
    }

    this.gaStatus = filteredList[0].ga4;

    if (filteredList[0].region == 'west-eu') {
      localStorage.setItem('region', 'west-eu');
    } else if (filteredList[0].region == 'central-us') {
      localStorage.setItem('region', 'central-us');
    } else {
      localStorage.setItem('region', 'west-eu');
    }

    sessionStorage.setItem('selectedTarget', filteredList[0].target);
    sessionStorage.setItem('targetName', this.selectedStore);
    sessionStorage.setItem('target', filteredList[0].target);
    sessionStorage.setItem('currency', filteredList[0].currency);
    window.localStorage.setItem('currency', filteredList[0].currency);
    window.localStorage.setItem('selectedTarget', filteredList[0].target);
    window.localStorage.setItem('targetName', this.selectedStore);
    window.localStorage.setItem('target', filteredList[0].target);

    let privileges = this.allUserTargetPrivileges.find((user: any) => {
      return user.shopID === filteredList[0].target;
    })?.priv;

    if (!privileges || privileges.length == 0) {
      privileges = this.allUserTargetPrivileges?.[0]?.priv;
    }

    localStorage.setItem('privileges', privileges);

    this.selectionService.selectedStore(filteredList[0]?.description);

    // Nudge Lite Detector
    if (filteredList[0].value == 0.01) {
      localStorage.setItem('nudgeLite', 'true');
    } else {
      localStorage.removeItem('nudgeLite');
    }

    // Get path depending on privilege
    const privilegeRoute = this.authService.routesWithPrivileges.find(
      (route: any) => privileges.includes(route.privileges)
    )?.path;

    // Check if you have privileges for current path
    const currentPathPrivilege = this.authService.routesWithPrivileges.find(
      (route: any) => window.location.pathname.includes(route.path)
    )?.privileges;

    // Check if user has privilege for current path
    if (
      !privileges.includes(currentPathPrivilege) &&
      (privilegeRoute || privilegeRoute != '')
    ) {
      // if user doesn't have privilege then get the first path that
      // match with user privileges and redirect to it
      this.router.navigate([privilegeRoute]);
    }

    this.updateLocalStorageDates();

    if (
      this.router.url.includes('/seo/') &&
      !this.router.url.includes('/seo/billing')
    ) {
      this.getSeoProjectsFunc(true);
    }

    this.updateCurrencySymbol();
    this.updateSidenavLinks();
  }

  getTarget() {
    this.activeTarget = this.authService.getTarget();
    return this.authService.getTarget();
  }

  getName() {
    return window.localStorage.getItem('name');
  }

  private _animationRunning = false;
  get animationRunning() {
    return this._animationRunning;
  }
  indexChange() {
    this._animationRunning = true;
  }
  onAnimationDone() {
    this.sidenavService.updateActiveTabIndex(this.selectedTab);
    this._animationRunning = false;
    this.updateIntercom;
  }

  updateProjectStatus() {
    this.subs.add(
      this.seoService.getProjects().subscribe({
        next: (response) => {
          if (response) {
            this.seoProjects = response;

            this.seoProjects = this.seoProjects.sort((a: any, b: any) => {
              if (a?.domain < b?.domain) {
                return -1;
              }
              if (a?.domain > b?.domain) {
                return 1;
              }
              return 0;
            });

            this.seoProjectsFiltered = [...this.seoProjects];

            let parsedSessionProject: any;
            try {
              if (
                sessionStorage.getItem('selectedProject') &&
                sessionStorage.getItem('selectedProject')?.trim() !==
                  'undefined'
              ) {
                parsedSessionProject = JSON.parse(
                  sessionStorage.getItem('selectedProject') || '{}'
                );
              } else {
                parsedSessionProject = {};
              }
            } catch (e) {
              console.log(e);
            }

            const pagesSeoUrl = sessionStorage.getItem('pages_seo_url');

            if (pagesSeoUrl) {
              this.selectedSeoProject = this.seoProjects.find((p: any) =>
                pagesSeoUrl.includes(p['domain'])
              );

              sessionStorage.removeItem('pages_seo_url');
            } else if (Object.keys(parsedSessionProject).length > 0) {
              this.selectedSeoProject = this.seoProjects.find((p: any) =>
                parsedSessionProject['domain']?.includes(p['domain'])
              );

              if (!this.selectedSeoProject) {
                this.selectedSeoProject = this.seoProjects[0];
              }
            } else {
              this.selectedSeoProject = this.seoProjects[0];
            }

            this.selectedSeoProjectURL = this.selectedSeoProject?.url;

            if (this.selectedSeoProject) {
              this.sidenavService.setSeoProject(this.selectedSeoProject);
            } else {
              this.sidenavService.setSeoProject('');
            }

            this.subs.add(
              this.sidenavService.childData$().subscribe((data: any) => {
                if (data.url) {
                  this.selectedSeoProjectURL = data.url;
                } else if (this.selectedSeoProject) {
                  this.selectedSeoProjectURL = this.selectedSeoProject.url;
                }
                this.seoProjectChanged();
              })
            );
          }
        },
        error: (error) => {
          console.error(error);
        },
      })
    );
  }

  logout() {
    this.authService.logout();
  }

  toggleSidenavSize() {
    this.isOpened = !this.isOpened;
  }

  productTourEnabled: boolean = false;
  toggleProductTour() {
    this.productTourEnabled = !this.productTourEnabled;
  }

  productTourLoading: boolean = false;
  triggerProductTour() {
    let tourId: number = 0;

    const domElement = document.querySelector('[behamicsProductTourID]');
    if (domElement) {
      tourId = parseInt(
        domElement.getAttribute('behamicsProductTourID') || '0'
      );
    }

    this.productTourLoading = true;
    if (tourId != 0) {
      this.intercomService.startTour(tourId);
      // Intercom('startTour', tourId);

      const getSeenTour = JSON.parse(localStorage.getItem('seenTours') || '{}');
      localStorage.setItem(
        'seenTours',
        JSON.stringify({ ...getSeenTour, [tourId]: true })
      );

      setTimeout(() => {
        this.productTourLoading = false;
      }, 600);
    } else {
      this.productTourLoading = false;
    }
  }
  openProductTour() {
    if (this.seoStillLoadingBlock) return;

    requestAnimationFrame(() => {
      const tourID =
        document
          .querySelector('[behamicsProductTourID]')
          ?.getAttribute('behamicsProductTourID') || '0';

      const getSeenTour = JSON.parse(localStorage.getItem('seenTours') || '{}');

      if (!getSeenTour[tourID] || Object.keys(getSeenTour).length == 0) {
        this.triggerProductTour();
      }
    });
  }
  changeLanguage(lang: string) {
    this.savedLang = lang;
    this.translate.use(lang);
    localStorage.setItem('language', lang);

    location.reload();
  }

  adaptNavigationListHeight(): void {
    requestAnimationFrame(() => {
      try {
        const listContainer = document.querySelector(
          '.master-admin-nav'
        ) as HTMLElement;
        const firstList = document.querySelectorAll(
          '.master-admin-nav > mat-nav-list'
        )?.[0] as HTMLElement;
        const secondList = document.querySelectorAll(
          '.master-admin-nav > mat-nav-list'
        )?.[1] as HTMLElement;

        if (!listContainer || !firstList || !secondList) return;

        const listContainerHeight = listContainer.clientHeight - 30;
        const secondListHeight = secondList.offsetHeight + 30;
        const availableHeight = Math.max(
          300,
          listContainerHeight - secondListHeight
        );

        firstList.style.maxHeight = `${availableHeight}px`;
      } catch (e) {
        console.error('Error adapting navigation list height:', e);
      }
    });
  }
}
