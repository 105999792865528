import { Component } from '@angular/core';
import { SessionService } from '../../../authentication/session.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: [
    './signup.component.css',
    '../login/login.component.css',
    '../../login/login.component.css',
  ],
})
export class SignupOrganicComponent {
  constructor(
    private sessionService: SessionService,
    private fb: FormBuilder,
    private route: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle('Signup - Organic');
  }

  loginLoading: boolean = false;

  form = this.fb.group({
    fullname: ['', Validators.required],
    email: ['', Validators.required],
    password: ['', Validators.required],
  });

  moreInfo: string = '';

  isRedirectedFromWeb: boolean = false;

  ngOnInit() {
    document.body.classList.remove('dark-theme');

    const params = new URLSearchParams(window.location.search);
    params.forEach((value, key) => {
      if (key === 'seo_url') {
        localStorage.setItem('seo_url_from_web', value);
        this.isRedirectedFromWeb = true;
      }
    });
  }
  showPassword: boolean = false;
  toogleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  signup() {
    this.loginLoading = true;
    if (this.form.valid) {
      this.sessionService.signup(this.form.value).subscribe({
        next: (res) => {
          if (res?.message.toLocaleLowerCase() == 'success') {
            this.sessionService
              .authenticated(
                this.form.value?.email?.toLowerCase() || '',
                this.form.value?.password || ''
              )
              .subscribe({
                next: (data: any) => {
                  if (data['status'] == 3) {
                    this.loginLoading = false;
                    localStorage.setItem('organicLogin', 'true');
                    localStorage.setItem('organic_signup', 'true');
                    this.sessionService.login(data);

                    this.route.navigate(['organic_onboarding']);
                  } else {
                    this.moreInfo = 'Something went wrong!';
                  }
                },
              });
          } else {
            if (res?.message) {
              this.moreInfo = res?.message;
            } else {
              this.moreInfo = 'Something went wrong!';
            }
          }
        },
        error: (error) => {
          this.loginLoading = false;
          this.moreInfo = 'Something went wrong!';
        },
      });
    }
  }
  loginWithGoogle(event: any) {
    event.preventDefault();
    const popup = window.open(
      'https://api.dashboard.behamics.com/api/auth/google?origin=' +
        location.origin,
      'googleAuth',
      'popup=1,width=600,height=700'
    );
    window.addEventListener(
      'message',
      (event) => {
        if (event.data.type === 'GoogleLogin') {
          const { result } = event.data;
          if (result) {
            popup?.close();
            if (result.status === 3) {
              localStorage.setItem('organicLogin', 'true');
              localStorage.setItem('organic_signup', 'true');
              this.sessionService.login(result);

              if (this.isRedirectedFromWeb) {
                this.route.navigate(['organic_onboarding']);
              } else {
                this.sessionService.navigate(result);
              }
              this.moreInfo = '';
              this.loginLoading = false;
            } else {
              this.moreInfo = result.message;
              this.loginLoading = false;
            }
          }
        }
      },
      { once: true }
    );
  }
  redirectToLogin() {
    this.route.navigate(['login_organic']);
  }

  showInputs: boolean = false;
  loginWithEmail(event: any) {
    if (!this.showInputs) {
      event.preventDefault();
      this.showInputs = true;
      return;
    }
  }
  privatcyPolicyClick() {
    window.open('https://behamics.com/legal/privacy-policy-en.pdf', '_blank');
  }
}
