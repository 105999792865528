import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NudgeService {
  constructor(private _http: HttpClient) {}

  reFetch(obj: any) {
    this.fetch(obj);
  }

  private fetch(obj: any) {
    var params =
      '?startDate=' +
      obj.startDate +
      '&endDate=' +
      obj.endDate +
      '&rate=' +
      obj.rate +
      '&selectedRangeValue=' +
      obj.selectedRangeValue +
      '&target=' +
      obj.target;
    //this.incentivesStats = this.dataSubject.asObservable();
    return this._http.get('/getincentivesstats' + params);
  }
  private getRpvDataInc(obj: any) {
    var params =
      '?startDate=' +
      obj.startDate +
      '&endDate=' +
      obj.endDate +
      '&rate=' +
      obj.rate +
      '&selectedRangeValue=' +
      obj.selectedRangeValue +
      '&target=' +
      obj.target;
    return this._http.get('/rpvline' + params);
  }

  public getNumberInfo(data: any) {
    var params = '?startDate=' + data.sDataRange + '&target=' + data.target;
    return this._http.get('/statisticsnumbers' + params);
  }

  private getFreqData(obj: any) {
    var params =
      '?startDate=' +
      obj.startDate +
      '&endDate=' +
      obj.endDate +
      '&device=' +
      obj.device +
      '&incentiveSector=' +
      obj.incentiveSector +
      '&siteID=' +
      obj.siteID +
      '&incentiveTypes=' +
      obj.incentiveTypes +
      '&ruleIDs=' +
      obj.ruleIDs +
      '&target=' +
      obj.target;
    return this._http.get('/incentivesfrequency' + params);
  }

  getCustomerRulesTarget(target: any) {
    if (typeof target == 'undefined') {
      var params = '';
    } else {
      var params = '?target=' + target;
    }
    return this._http.get<any[]>('/customerrules' + params);
  }

  getAllIncentives(target: any): Observable<any[]> {
    return this._http.get<any[]>('/incentives?target=' + target);
  }

  getAllIncentivesTarget(target: any): Observable<any[]> {
    if (typeof target == 'undefined') {
      var params = '';
    } else {
      var params = '?target=' + target;
    }
    return this._http.get<any[]>('/incentives' + params);
  }

  getIncentivesStats(obj: any) {
    return this.fetch(obj);
  }

  getRpvData(obj: any) {
    return this.getRpvDataInc(obj);
  }

  getFreqBarChart(obj: any) {
    return this.getFreqData(obj);
  }

  getVariants(data: any) {
    var params = '?target=' + data;
    return this._http.get('/variants' + params);
  }
}
