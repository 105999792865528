<div
  fxLayout="row"
  fxLayoutAlign="center start"
  class="height-full pad-t-xl unsub"
>
  <div
    fxLayout="column"
    fxFlex.xl="35"
    fxFlex.lg="35"
    fxFlex.md="60"
    fxFlex.sm="70"
    fxFlex.xs="100"
  >
    <mat-card class="gene-login-wrapper">
      <div>
        <div fxLayout="column" fxLayoutAlign="center center mrgn-b-md">
          <div fxFlex="100" class="login-logo justify-content">
            <img
              src="../../../assets/img/behamics_logo_black.svg"
              style="width: 135px; margin: 0; margin-top: 10px"
            />
          </div>
        </div>
        <h1
          style="
            font-size: 22px;
            font-weight: 500;
            margin: 0;
            margin-bottom: 25px;
          "
        >
          Thank you!
        </h1>
        <h1
          style="
            margin: 0;
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 10px;
          "
        >
          Your transaction has been successfully processed. <br />
          <br />We appreciate your promptness and look forward to continuing to
          serve you. Should you have any questions or require further
          assistance, please don't hesitate to reach out to us.
        </h1>

        <button
          class="mat-blue margin-top"
          mat-raised-button
          routerLink="/"
          style="width: 100%"
        >
          Back to Dashboard
        </button>
      </div>
    </mat-card>
  </div>
</div>
