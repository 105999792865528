import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getDailyIntro(data: any): Observable<any> {
    var params =
      '?target=' +
      data.target +
      '&startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate;
    return this.http.get<any>('/dailyintroreporting' + params);
  }

  getBarChartCr(data: any): Observable<any> {
    var params =
      '?startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&target=' +
      data.target +
      '&device=' +
      data.device +
      '&visitType=' +
      data.visitType;
    return this.http.get<any>('/cr' + params);
  }

  getBarChartRpv(data: any): Observable<any> {
    if (data.noOutliers) {
      var params =
        '?startDate=' +
        data.startDate +
        '&endDate=' +
        data.endDate +
        '&target=' +
        data.target +
        '&device=' +
        data.device +
        '&visitType=' +
        data.visitType +
        '&noOutliers=' +
        data.noOutliers;
    } else {
      var params =
        '?startDate=' +
        data.startDate +
        '&endDate=' +
        data.endDate +
        '&target=' +
        data.target +
        '&device=' +
        data.device +
        '&visitType=' +
        data.visitType;
    }
    return this.http.get<any>('/rpv' + params);
  }

  getBarChartRr(data: any): Observable<any> {
    var params =
      '?startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&target=' +
      data.target +
      '&device=' +
      data.device +
      '&visitType=' +
      data.visitType;
    return this.http.get<any>('/rr' + params);
  }

  getBarChartAov(data: any): Observable<any> {
    if (data.noOutliers) {
      var params =
        '?startDate=' +
        data.startDate +
        '&endDate=' +
        data.endDate +
        '&target=' +
        data.target +
        '&device=' +
        data.device +
        '&visitType=' +
        data.visitType +
        '&noOutliers=' +
        data.noOutliers;
    } else {
      var params =
        '?startDate=' +
        data.startDate +
        '&endDate=' +
        data.endDate +
        '&target=' +
        data.target +
        '&device=' +
        data.device +
        '&visitType=' +
        data.visitType;
    }
    return this.http.get<any>('/aov' + params);
  }

  getBarChartNsv(data: any): Observable<any> {
    var params =
      '?startDate=' +
      data.startDate +
      '&endDate=' +
      data.endDate +
      '&target=' +
      data.target +
      '&device=' +
      data.device +
      '&visitType=' +
      data.visitType;
    return this.http.get<any>('/nr' + params);
  }

  getMerchandiseData(data: any): Observable<any> {
    let params = '';
    Object.keys(data).forEach((key, index) => {
      params += key + '=' + data[key];
      if (index + 1 != Object.keys(data).length) {
        params += '&';
      }
    });

    return this.http.get<any>('/products/metrics/overall/dashboard?' + params);
  }

  getAutomationData(data: any): Observable<any> {
    let params = '';
    Object.keys(data).forEach((key, index) => {
      params += key + '=' + data[key];
      if (index + 1 != Object.keys(data).length) {
        params += '&';
      }
    });
    return this.http.get<any>('/automations/dashboard/revenue?' + params);
  }

  getOrganicData(): Observable<any> {
    return this.http.post<any>('/seo/analyses/get-shopID-main-view', {
      shopID: localStorage.getItem('selectedTarget'),
      language: localStorage.getItem('language') || 'en',
    });
  }
}
