import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css'],
})
export class ConfirmationComponent {
  header: string;
  content: string;

  cancel_btn: string;
  confirm_btn: string;

  confirmButtonDangerStyle: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {
    this.confirmButtonDangerStyle = data['confirmButtonDangerStyle']
      ? true
      : false;

    this.translate
      .get('words.' + data.header)
      .pipe(
        map((translatedHeader: string) =>
          translatedHeader === 'words.' + data.header
            ? data.header
            : translatedHeader
        )
      )
      .subscribe((translatedHeader: string) => {
        this.header = translatedHeader;
      });

    this.translate
      .get('phrases.' + data.content)
      .pipe(
        map((translatedContent: string) =>
          translatedContent === 'phrases.' + data.content
            ? data.content
            : translatedContent
        )
      )
      .subscribe((translatedContent: string) => {
        this.content = translatedContent;
      });

    this.translate
      .get('words.' + (data.confirm_btn ?? 'yes'))
      .pipe(
        map((translatedWord: string) =>
          translatedWord === 'words.' + data.confirm_btn
            ? data.confirm_btn
            : translatedWord
        )
      )
      .subscribe((translatedWord: string) => {
        this.confirm_btn = translatedWord;
      });
    this.translate
      .get('words.' + (data.cancel_btn ?? 'cancel'))
      .pipe(
        map((translatedWord: string) =>
          translatedWord === 'words.' + data.cancel_btn
            ? data.cancel_btn
            : translatedWord
        )
      )
      .subscribe((translatedWord: string) => {
        this.cancel_btn = translatedWord;
      });
  }
}
