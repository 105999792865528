import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'src/app/services/pagetitle.service';
import { UserPermissionsService } from './user-permissions.service';
import { SelectionService } from 'src/app/services/selection.service';
import { catchError, map, Observable, of, Subscription } from 'rxjs';
import { UserPermissionsActionComponent } from './modals/user-action/user-action.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SidenavComponent } from 'src/app/sidenav/sidenav.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationComponent } from 'src/app/modals/confirmation/confirmation.component';
import { TranslateService } from '@ngx-translate/core';
import { SeoService } from '../seo/seo.service';

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.css'],
})
export class UserPermissionsComponent {
  constructor(
    private pageTitle: PageTitleService,
    private userService: UserPermissionsService,
    private selectorService: SelectionService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private fb: FormBuilder,
    public sidenav: SidenavComponent,
    private translate: TranslateService,
    private seoService: SeoService
  ) {
    this.userManagementSub = new Subscription();
  }

  userManagementSub: Subscription;

  confirmDialogRef: MatDialogRef<ConfirmationComponent>;
  userActionsDialog: MatDialogRef<UserPermissionsActionComponent>;

  selectedStore: any;
  firstLoader = false;
  refreshLoader = true;
  refreshLoaderTwo = true;
  chartLoader = true;
  loadNrIndicator = 0;

  oldData: any;
  oldDataTwo: any;
  formValueChanged = false;
  formValueChangedTwo = false;

  productRetrival: any = FormGroup;
  returnDataRetrieval: any = FormGroup;
  targetConfig: any = FormGroup;

  globalControlData: any;
  isSettingsValid = false;

  receivedUserList: any = [];
  recievedProductRetrivalData: any;
  isEmpty = false;
  receivedReturnProductData: any;
  currentUserEmail: any;

  goLiveObject: any = {
    target: '',
    target_db: '',
    selected_db: false,
    all_dbs: [],
    redis: 'redis-vm2',
    updating: false,
  };

  seoSubscription: any;

  showPremiumSeoBanner: boolean = false;

  chargesPerUser: any;

  ngOnInit() {
    this.pageTitle.setTitle('User_Management');

    // On Target Change
    const selectorService = this.selectorService.currentMessage.subscribe(
      (message: any) => {
        this.seoSubscription = localStorage.getItem('seoSubscription');

        this.selectedStore = localStorage.getItem('selectedTarget');
        this.goLiveObject.target = localStorage.getItem('selectedTarget');
        this.loadNrIndicator = 0;

        this.showPremiumSeoBanner =
          localStorage.getItem('seoSubscription') == 'Premium' ? false : true;
        this.getUserList();
        // this.getConfigData();
        // this.getTargetSettings();

        // this.getTargetConnections();

        if (
          localStorage.getItem('customerId') &&
          localStorage.getItem('customerId') != 'null'
        ) {
          this.getStripeCustomer();
        }
      }
    );
    this.userManagementSub.add(selectorService);
    this.selectedStore = localStorage.getItem('selectedTarget');
  }

  ngOnDestroy() {
    this.userManagementSub?.unsubscribe();
  }

  getStripeCustomer() {
    this.userManagementSub.add(
      this.seoService
        .getStripeCustomer({
          customerId: localStorage.getItem('customerId'),
          target: this.selectedStore,
        })
        .subscribe({
          next: (res: any) => {
            this.chargesPerUser = this.formatCurrency(
              res?.subscription?.unitAmount,
              res?.subscription?.currency
            );
          },
          error: (err: any) => {
            console.log(err);
          },
        })
    );
  }

  getTargetConnections() {
    try {
      const goLiveAPI = this.userService
        .getGoLiveObject({
          target: this.goLiveObject.target,
        })
        .subscribe({
          next: (res) => {
            this.goLiveObject.target_db = res.target_db;
            this.goLiveObject.selected_db =
              res.target_db == 'No Database' ? false : true;
            this.goLiveObject.all_dbs = res.all_dbs || [];
          },
        });
      this.userManagementSub.add(goLiveAPI);
    } catch (e) {
      console.log(e);
    }
  }

  checkSelfEmail(user: any) {
    let currentEmail = localStorage.getItem('email');

    if (currentEmail == user.email) {
      return false;
    } else {
      return true;
    }
  }

  setGoLiveProcess() {
    if (
      this.goLiveObject.target_db == 'No Database' ||
      this.goLiveObject.selected_db
    ) {
      return;
    }
    let obj = {
      target: this.goLiveObject.target,
      redis: this.goLiveObject.redis,
      db: this.goLiveObject.target_db,
    };

    this.goLiveObject.updating = true;
    try {
      const goLiveProcess = this.userService.setGoLiveProcess(obj).subscribe({
        next: (res) => {
          this.goLiveObject.updating = false;

          this.getTranslatedText(
            'go_live_process_finished',
            'phrases'
          ).subscribe((translatedText) => {
            this.openSnackBar(translatedText, '');
          });
          this.getTargetConnections();
        },
        error: (e) => {
          this.goLiveObject.updating = false;
          this.getTranslatedText('something_went_wrong', 'phrases').subscribe(
            (translatedText) => {
              this.openSnackBar(translatedText, '');
            }
          );
          console.log(e);
        },
      });
      this.userManagementSub.add(goLiveProcess);
    } catch (e) {
      console.log(e);
    }
  }

  getUserList() {
    // Get User Data
    const getUserList = this.userService
      .getUsers(this.selectedStore)
      .subscribe((info: any) => {
        this.receivedUserList = info;
        this.combinedLoader(1);
      });
    this.userManagementSub.add(getUserList);
  }

  getConfigData() {
    const getProductRetrivalConfig = this.userService
      .getProductRetrivalConfig()
      .subscribe((info: any) => {
        this.recievedProductRetrivalData = info.filter(
          (o: any) => o.target == this.selectedStore
        );
        this.updateRetrievalData();
      });
    this.userManagementSub.add(getProductRetrivalConfig);

    const returnDataRetrivalConfig = this.userService
      .returnDataRetrivalConfig()
      .subscribe((info: any) => {
        this.receivedReturnProductData = info.filter(
          (o: any) => o.target == this.selectedStore
        );
        this.updateReturnRetrievalData();
      });
    this.userManagementSub.add(returnDataRetrivalConfig);
  }

  getTargetSettings() {
    const getSettings = this.userService
      .getSettings(this.selectedStore)
      .subscribe((info: any) => {
        this.globalControlData = info[0];
        this.updateTargetSettings();
      });
    this.userManagementSub.add(getSettings);
  }

  updateTargetSettings() {
    this.targetConfig = this.fb.group({
      globalControl: ['', Validators.required],
      isLive: ['', Validators.required],
      currency: ['', Validators.required],
    });
    this.targetConfig.patchValue({
      globalControl: this.globalControlData.value,
      isLive: this.globalControlData.live,
      currency: this.globalControlData.currency,
    });

    var oldData = this.globalControlData;

    const valueChanges = this.targetConfig.valueChanges.subscribe(
      (data: any) => {
        if (
          JSON.stringify(oldData) === JSON.stringify(this.targetConfig.value)
        ) {
          this.isSettingsValid = false;
        } else {
          this.isSettingsValid = true;
        }
      }
    );
    this.userManagementSub.add(valueChanges);
  }

  editSettings() {
    var oldData = this.globalControlData;
    var newData = {
      description: this.globalControlData.description,
      live: this.targetConfig.value.isLive,
      name: this.globalControlData.name,
      value: this.targetConfig.value.globalControl,
      currency: this.targetConfig.value.currency,
    };
    const updateSettings = this.userService
      .updateSettings(newData, oldData, this.selectedStore)
      .subscribe((info) => {
        this.getTranslatedText('settings_updated', 'words').subscribe(
          (translatedText) => {
            this.openSnackBar(translatedText, '');
          }
        );
      });
    this.userManagementSub.add(updateSettings);
  }

  updateRetrievalData() {
    try {
      this.productRetrival = this.fb.group({
        execAt: ['', Validators.required],
        executeEveryHour: ['', Validators.required],
        target: ['', Validators.required],
        type: ['', Validators.required],
        url: ['', Validators.required],
        dataType: ['', Validators.required],
        authType: [''],
        username: [''],
        password: [''],
        online: ['', Validators.required],
      });

      if (this.recievedProductRetrivalData.length != 0) {
        this.isEmpty = false;
        this.productRetrival.patchValue({
          execAt: this.recievedProductRetrivalData[0].execAt,
          executeEveryHour:
            this.recievedProductRetrivalData[0].executeEveryHour,
          target: this.recievedProductRetrivalData[0].target,
          type: this.recievedProductRetrivalData[0].type,
          url: this.recievedProductRetrivalData[0].url,
          dataType: this.recievedProductRetrivalData[0].dataType,
          authType: this.recievedProductRetrivalData[0].authType,
          username: this.recievedProductRetrivalData[0].username,
          password: this.recievedProductRetrivalData[0].password,
          online: this.recievedProductRetrivalData[0].online,
        });
        this.oldData = this.productRetrival.value;

        const productRetrivalValue =
          this.productRetrival.valueChanges.subscribe((data: any) => {
            if (
              JSON.stringify(this.oldData) ===
              JSON.stringify(this.productRetrival.value)
            ) {
              this.formValueChanged = false;
            } else {
              this.formValueChanged = true;
            }
          });
        this.userManagementSub.add(productRetrivalValue);
      } else {
        this.isEmpty = true;
      }
      this.combinedLoader(1);
    } catch (e) {
      console.log('updateRetrievalData', e);
    }
  }

  updateReturnRetrievalData() {
    try {
      this.returnDataRetrieval = this.fb.group({
        target: [{ value: '', disabled: true }, Validators.required],
        online: ['', Validators.required],
        type: ['', Validators.required],
        timeToCallStart: ['', Validators.required],
        timeToCallEnd: ['', Validators.required],

        accountName: ['', Validators.required],
        accountKey: ['', Validators.required],
        container: ['', Validators.required],

        orderFileFormat: ['', Validators.required],
        returnFileFormat: ['', Validators.required],
        dateFormat: ['', Validators.required],
        encoding: ['', Validators.required],
        seperator: ['', Validators.required],
      });
      if (this.receivedReturnProductData[0]) {
        this.returnDataRetrieval.patchValue({
          target: this.receivedReturnProductData[0].target,
          online: this.receivedReturnProductData[0].online,
          type: this.receivedReturnProductData[0].type,
          timeToCallStart: this.receivedReturnProductData[0].timeToCall[0],
          timeToCallEnd: this.receivedReturnProductData[0].timeToCall[1],

          accountName:
            this.receivedReturnProductData[0].fileConfig.azure.accountName,
          accountKey:
            this.receivedReturnProductData[0].fileConfig.azure.accountKey,
          container:
            this.receivedReturnProductData[0].fileConfig.azure.container,

          orderFileFormat:
            this.receivedReturnProductData[0].fileConfig.orderFileFormat,
          returnFileFormat:
            this.receivedReturnProductData[0].fileConfig.returnFileFormat,
          dateFormat: this.receivedReturnProductData[0].fileConfig.dateFormat,
          encoding: this.receivedReturnProductData[0].fileConfig.encoding,
          seperator: this.receivedReturnProductData[0].fileConfig.seperator,
        });
      }
      this.oldDataTwo = this.returnDataRetrieval.value;
      const returnDataRetrievalValue =
        this.returnDataRetrieval.valueChanges.subscribe((data: any) => {
          if (
            JSON.stringify(this.oldDataTwo) ===
            JSON.stringify(this.returnDataRetrieval.value)
          ) {
            this.formValueChangedTwo = false;
          } else {
            this.formValueChangedTwo = true;
          }
        });
      this.userManagementSub.add(returnDataRetrievalValue);
      this.combinedLoader(1);
    } catch (e) {
      console.log('updateReturnRetrievalData Error: ', e);
    }
  }

  editRetrievalConfig() {
    const editProductRetrivalConfig = this.userService
      .editProductRetrivalConfig({
        execAt: this.productRetrival.value.execAt,
        executeEveryHour: this.productRetrival.value.executeEveryHour,
        target: this.productRetrival.value.target,
        type: this.productRetrival.value.type,
        url: this.productRetrival.value.url,
        dataType: this.productRetrival.value.dataType,
        authType: this.productRetrival.value.authType,
        username: this.productRetrival.value.username,
        password: this.productRetrival.value.password,
        online: this.productRetrival.value.online,
      })
      .subscribe((info) => {
        this.loadNrIndicator = 1;
        this.refreshLoaderTwo = false;
        this.getConfigData();

        this.getTranslatedText(
          'product_Retrieval_Config_Updated',
          'phrases'
        ).subscribe((translatedText) => {
          this.openSnackBar(translatedText, '');
        });
      });
    this.userManagementSub.add(editProductRetrivalConfig);
  }

  editReturnDataConfig() {
    const editReturnRetrivalConfig = this.userService
      .editReturnRetrivalConfig({
        target: this.returnDataRetrieval.value.target,
        online: this.returnDataRetrieval.value.online,
        type: this.returnDataRetrieval.value.type,
        timeToCall: [
          this.returnDataRetrieval.value.timeToCallStart,
          this.returnDataRetrieval.value.timeToCallEnd,
        ],
        fileConfig: {
          azure: {
            accountName: this.returnDataRetrieval.value.accountName,
            accountKey: this.returnDataRetrieval.value.accountKey,
            container: this.returnDataRetrieval.value.container,
          },
          orderFileFormat: this.returnDataRetrieval.value.orderFileFormat,
          returnFileFormat: this.returnDataRetrieval.value.returnFileFormat,
          dateFormat: this.returnDataRetrieval.value.dateFormat,
          encoding: this.returnDataRetrieval.value.encoding,
          seperator: this.returnDataRetrieval.value.seperator,
        },
      })
      .subscribe((info) => {
        this.loadNrIndicator = 1;
        this.refreshLoaderTwo = false;
        this.getConfigData();
        this.getTranslatedText(
          'return_Data_Retrieval_Config_Updated',
          'phrases'
        ).subscribe((translatedText) => {
          this.openSnackBar(translatedText, '');
        });
      });
    this.userManagementSub.add(editReturnRetrivalConfig);
  }

  // openRolesDialog() {
  //   this.addRolesDialog = this.dialog.open(AddRolesComponent, {
  //     width: '460px',
  //     data: {},
  //     autoFocus: false,
  //   });
  // }

  addUserDialog(bool: any, data: any, isPrivilege: any) {
    if (isPrivilege) {
      var emailData = [];
      for (let i = 0; i < this.receivedUserList.length; i++) {
        emailData.push(this.receivedUserList[i].email);
      }

      this.userActionsDialog = this.dialog.open(
        UserPermissionsActionComponent,
        {
          width: '650px',
          data: {
            isPrivilegeEdit: true,
            emails: emailData,
          },
          autoFocus: false,
        }
      );

      const userActionsDialogD = this.userActionsDialog
        .afterClosed()
        .subscribe((result) => {
          if (result == 'updated') {
            // Reload List
            this.loadNrIndicator = 1;
            this.refreshLoader = false;
            this.getUserList();
            this.getTranslatedText('privileges_updated', 'words').subscribe(
              (translatedText) => {
                this.openSnackBar(translatedText, '');
              }
            );
          }
        });
      this.userManagementSub.add(userActionsDialogD);
    } else {
      if (bool) {
        // Is Edit
        this.userActionsDialog = this.dialog.open(
          UserPermissionsActionComponent,
          {
            width: '650px',
            data: {
              name: data.name,
              email: data.email,
              role: data.role,
              target: data.shopID,
              bugSubscription: data.bugSubscription,
              authProvider: data.authProvider ? data.authProvider : 'local',
            },
            autoFocus: false,
          }
        );

        const userActionsDialogE = this.userActionsDialog
          .afterClosed()
          .subscribe((result) => {
            if (result == 'updated') {
              // Reload List
              this.loadNrIndicator = 1;
              this.refreshLoader = false;
              this.getUserList();

              this.getTranslatedText('user_updated', 'words').subscribe(
                (translatedText) => {
                  this.openSnackBar(translatedText, '');
                }
              );
            }
          });
        this.userManagementSub.add(userActionsDialogE);
      } else {
        // Is False
        this.userActionsDialog = this.dialog.open(
          UserPermissionsActionComponent,
          {
            width: '430px',
            data: {
              target: this.selectedStore,
              chargesPerUser: this.chargesPerUser,
            },
            autoFocus: false,
          }
        );

        const userActionsDialogI = this.userActionsDialog
          .afterClosed()
          .subscribe((result) => {
            if (result == 'invited') {
              // Reload List
              this.loadNrIndicator = 1;
              this.refreshLoader = false;
              this.getUserList();

              this.getTranslatedText(
                'user_has_been_invited',
                'phrases'
              ).subscribe((translatedText) => {
                this.openSnackBar(translatedText, '');
              });
            } else if (result == 'added') {
              // Reload List
              this.loadNrIndicator = 1;
              this.refreshLoader = false;
              this.getUserList();
              this.getTranslatedText(
                'user_has_been_created',
                'phrases'
              ).subscribe((translatedText) => {
                this.openSnackBar(translatedText, '');
              });
            }
          });
        this.userManagementSub.add(userActionsDialogI);
      }
    }
  }
  getTranslatedText(text: string, type: string = 'words'): Observable<string> {
    return this.translate.get(`${type}.${text}`).pipe(
      map((translated: string) =>
        translated === `${type}.${text}` ? text : translated
      ),
      catchError(() => of(text))
    );
  }
  resendUserEmail(email: any, index: any) {
    this.confirmDialogRef = this.dialog.open(ConfirmationComponent, {
      width: '430px',
      data: {
        header: 'Resend Email',
        content: 'Are you sure you want to resend the invitation?',
      },
    });

    const confirmDialogRef = this.confirmDialogRef
      .afterClosed()
      .subscribe((result) => {
        if (result == 1) {
          this.userService.resendEmail(email.email).subscribe((info) => {
            this.loadNrIndicator = 1;
            this.refreshLoader = false;
            this.getConfigData();

            this.getTranslatedText('invitation_resent', 'phrases').subscribe(
              (translatedText) => {
                this.openSnackBar(translatedText, '');
              }
            );
          });
        }
      });
    this.userManagementSub.add(confirmDialogRef);
  }

  deleteUserDialog(user: any) {
    this.confirmDialogRef = this.dialog.open(ConfirmationComponent, {
      width: '430px',
      data: {
        header: 'delete_user',
        content: 'are_you_sure_you_want_to_delete_this_user',
      },
    });

    const confirmDialogRef = this.confirmDialogRef
      .afterClosed()
      .subscribe((result) => {
        if (result == 1) {
          this.userService
            .deleteUser({
              email: user.email,
              shopID: this.selectedStore,
              ...(localStorage.getItem('customerId') &&
              localStorage.getItem('customerId') != 'null'
                ? { customerId: localStorage.getItem('customerId') }
                : {}),
            })
            .subscribe((info) => {
              this.getTranslatedText(
                'user_deleted_successfully',
                'phrases'
              ).subscribe((translatedText) => {
                this.openSnackBar(translatedText, '');
              });
              this.loadNrIndicator = 1;
              this.refreshLoader = false;
              this.getUserList();
            });
        }
      });
    this.userManagementSub.add(confirmDialogRef);
  }

  combinedLoader(num: any) {
    this.loadNrIndicator = this.loadNrIndicator + num;
    if (this.loadNrIndicator == 1) {
      setTimeout(() => {
        this.firstLoader = true;
        this.refreshLoader = true;
        this.refreshLoaderTwo = true;
      }, 400);
    }
  }

  openSnackBar(a: string, b: string) {
    this.snackBar.open(a, 'x', {
      duration: 3000,
      panelClass: ['snackbar-class-success'],
    });
  }

  formatCurrency(value: any, currency: any) {
    if (currency === 'AUD') {
      return (
        'A' +
        new Intl.NumberFormat('en-AU', {
          style: 'currency',
          currency: currency,
        })
          .format(value)
          ?.replace('.00', '')
      );
    }
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: currency,
    })
      .format(value)
      .replace('.00', '');
  }
}
