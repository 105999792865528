<div class="ads_setup">
  <div class="ads_setup-header">
    <!-- back button -->
    <button
      type="button"
      class="back-button-container"
      (click)="goToPreviousIndex()"
      *ngIf="selectedStepIndex > 0"
    >
      <svg width="16" height="12" viewBox="0 0 16 12">
        <path
          fill="#242423"
          d="M0.252714043,19.3629451 L5.22803684,14.2638382 C5.56491988,13.9120539 6.11127639,13.9120539 6.44815943,14.2638382 C6.78511148,14.6156945 6.78511148,15.1860737 6.44815943,15.53793 L2.94563875,19 L15.1372592,19 C15.6136992,19 16,19.5024768 16,20 C16,20.4974331 15.6136992,21 15.1372592,21 L2.94563875,21 L6.44802139,24.462052 C6.78497345,24.8139083 6.78497345,25.3842875 6.44802139,25.7361438 C6.27961438,25.9119278 6.05875273,26 5.8379601,26 C5.61716746,26 5.39637483,25.9119278 5.2278988,25.7361438 L0.252714043,20.6370369 C-0.0842380143,20.2851806 -0.0842380143,19.7148014 0.252714043,19.3629451 Z"
          transform="translate(0 -14)"
        ></path>
      </svg>
      <span>Back</span>
    </button>

    <div class="ads_setup-steps-header">
      <div
        class="ads_setup-step"
        *ngFor="let step of setupSteps; let i = index"
        [ngClass]="{ active: selectedStepIndex >= i }"
        (click)="updateStepIndex(i)"
      >
        <div class="ads_setup-step-label">
          {{ step.label }}
        </div>
        <div class="ads_setup-step-name">
          {{ step.name }}
        </div>
      </div>
    </div>
  </div>
  <div class="ads_setup-body-container">
    <div class="ads_setup-steps-body">
      <div
        class="ads_setup-steps-carousel"
        [style.transform]="'translateX(-' + selectedStepIndex * 100 + '%)'"
      >
        <!-- Account Setup -->
        <div class="ads_setup-steps-carousel-item">
          <div class="ads_setup-steps-carousel-header">
            <img
              class="ads_setup-steps-carousel-icon"
              src="../../../assets/img/google-ads-icon.svg"
              alt="google ads icon"
            />
            <h1>Connect Google Ads</h1>
            <p>
              Connect your Google Ads account (search, display, video and
              shopping).
            </p>
          </div>
          <div class="ads_setup-steps-carousel-body">
            <div class="ads_setup-steps-carousel-options">
              <div
                class="ads_setup-steps-carousel-options-trigger"
                *ngIf="selectedEmail != ''"
              >
                <div class="mat-select-trigger-container">
                  <div class="check-mark">
                    <span></span>
                  </div>
                  {{ selectedEmail }}
                </div>
                <!-- <div
                  class="mat-select-trigger-container"
                  *ngIf="selectedEmail == ''"
                >
                  Select Your Google Ads Account
                </div> -->
              </div>

              <div class="ads_setup-steps-carousel-options-container">
                <div
                  class="ads_setup-steps-carousel-option"
                  *ngFor="let acc of customerAdsAccount"
                  (click)="emailSelected(acc)"
                  [ngClass]="{ selected: acc.name == selectedEmail }"
                >
                  Google Ads - {{ acc.name }}
                </div>
                <div
                  class="ads_setup-steps-carousel-option ads-connect-new-account"
                  (click)="connectNewAccount()"
                >
                  <svg
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <path
                      d="M5,5 L5,7.25910423 C5,7.66808986 4.55387666,7.99997209 4.00013221,8 C3.44627444,8 3,7.66808986 3,7.25913213 L3,5 L0.740839961,5 C0.331882238,5 0,4.55368779 0,3.99986779 C0,3.44612334 0.331882238,3 0.740839961,3 L3,3 L3,0.740839961 C3,0.331882238 3.44631221,9.51359389e-17 4.00013221,6.12242443e-17 C4.55387666,2.73171757e-17 5,0.331882238 5,0.740839961 L5,3 L7.25910423,3 C7.66808986,3 7.99997209,3.44612334 8,3.99986779 C8,4.55372556 7.66808986,5 7.25913213,5 L5,5 Z"
                      id="path-10"
                    ></path>
                  </svg>
                  <span> Connect New Account </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Install Extension -->
        <div class="ads_setup-steps-carousel-item">
          <div class="ads_setup-steps-carousel-header">
            <img
              class="ads_setup-steps-carousel-icon chrome-web-store"
              src="https://fonts.gstatic.com/s/i/productlogos/chrome_store/v7/192px.svg"
              alt="chrome web store"
            />
            <h1>Ads Copilot</h1>
            <p>Please install Ads Copilot Extension</p>
          </div>
          <div class="ads_setup-steps-carousel-body">
            <div class="ads_setup-steps-carousel-options-trigger">
              <div
                class="mat-select-trigger-container"
                (click)="extensionButtonClicked()"
              >
                <div
                  class="check-mark"
                  [ngClass]="{
                    'checking-status': checkingExtensionStatus,
                    neutral: !extensionInstalled && !checkingExtensionStatus
                  }"
                >
                  <span></span>
                </div>
                {{
                  extensionInstalled
                    ? "Extension Installed"
                    : checkingExtensionStatus
                    ? "Checking Status"
                    : "Install Extension"
                }}
              </div>
            </div>
            <div
              class="ads_setup-steps-carousel-options-trigger continue-to-dashboard"
              *ngIf="extensionInstalled"
            >
              <div
                class="mat-select-trigger-container"
                (click)="redirectToDashboard()"
              >
                Redirecting to dashboard ...
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
