import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectionService {
  selectMethod$: Observable<any>;

  currentUserRole = window.localStorage.getItem('role');
  role: any = '';

  private messageSource;
  currentMessage;

  constructor() {
    this.messageSource = new BehaviorSubject(this.role);
    this.currentMessage = this.messageSource.asObservable();
  }

  selectedStore(message: any) {
    this.messageSource.next(message);
  }

  // trigger onchange for ranges
  private customDateRange = new BehaviorSubject('');
  getCustomDateRange() {
    return this.customDateRange.asObservable();
  }
  setCustomDateRange(rate: string) {
    if (rate) {
      this.customDateRange.next(rate);
    }
  }
}
