<div class="experiment-view">
  <ng-container *ngIf="firstLoader">
    <div class="experiment-header-content">
      <button
        class="custom-button btn_withoutIcon"
        mat-raised-button
        type="button"
        (click)="redirectToExperimentList()"
      >
        Go Back to AI Experiments
      </button>
      <div class="experiment-header-down">
        <h2 class="custom_table-container-h2 subtitle-h2">
          {{ experimentObj["name"] }}
        </h2>
        <div style="display: flex; gap: 20px; align-items: center">
          <p class="status">
            Status
            <span
              class="neutral"
              [ngClass]="{ active: experimentStatus == 'Running' }"
            >
              <span
                class="experiment-view-progress"
                [ngStyle]="{ width: eperimentProgress + '%' }"
              ></span>
              {{ experimentStatus }}
            </span>
          </p>
          <button
            mat-raised-button
            type="button"
            color="primary"
            class="custom-button btn_withoutIcon primary"
            (click)="experimentStatusFunc('start')"
            [disabled]="experimentStartBtn"
            *ngIf="experimentStatus != 'Running'"
          >
            Start
          </button>
          <button
            *ngIf="experimentStatus == 'Running'"
            class="custom-button btn_withoutIcon secondary"
            mat-raised-button
            type="button"
            color="primary"
            (click)="experimentStatusFunc('stop')"
          >
            Pause
          </button>
        </div>
      </div>
    </div>
    <div class="custom_table-container">
      <table class="custom_table">
        <thead>
          <tr>
            <th class="minWidth60">Device</th>
            <th>Nudge ID</th>
            <th>RPV</th>
            <th>CR</th>
            <th>AOV</th>
            <th>Revenue Uplift</th>
            <th>Orders / Visits</th>
            <th class="text_align-center">AI Traffic Allocation</th>
            <th>Split Ratio</th>
            <th class="minWidth60 text_align-right">Status</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="!noTableData">
            <ng-container
              *ngFor="let platform of experimentPlatforms; let i = index"
            >
              <tr>
                <td
                  [attr.rowspan]="
                    experimentTableData[platform]?.ids &&
                    experimentTableData[platform]?.ids[1] &&
                    experimentTableData[platform]?.ids[1].incentive != '-'
                      ? 2
                      : 1
                  "
                  class="minWidth60"
                >
                  <span
                    *ngIf="platform == 'desktop'"
                    class="center-device-platform"
                  >
                    <mat-icon>desktop_windows</mat-icon>
                  </span>
                  <span
                    class="center-device-platform"
                    *ngIf="platform == 'mobile'"
                  >
                    <mat-icon>smartphone</mat-icon>
                  </span>
                </td>
                <td>
                  {{
                !experimentTableData?.[platform]?.ids[0]?.incentive?.includes("000")
                  ? experimentTableData[platform].ids[0].incentive
                  : "AI Variant"
                  }}
                </td>
                <td>
                  <div
                    class="experiment-cell"
                    [ngClass]="{
                      positive:
                        experimentTableData[platform].rpv[0].slice(0, 1) == '+',
                      negative:
                        experimentTableData[platform].rpv[0].slice(0, 1) == '-'
                    }"
                  >
                    <svg
                      _ngcontent-twl-c13=""
                      viewBox="0 0 384 512"
                      xmlns="http://www.w3.org/2000/svg"
                      ng-reflect-ng-class="[object Object]"
                      [ngClass]="{
                        positiveSvg:
                          experimentTableData[platform]?.rpv[0]?.slice(0, 1) ==
                          '+'
                      }"
                    >
                      <path
                        _ngcontent-twl-c13=""
                        d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
                      ></path>
                    </svg>

                    {{
                      experimentTableData[platform].rpv[0]
                        ?.replace("-", "")
                        .replace("+", "")
                    }}
                  </div>
                </td>
                <td>
                  <div
                    class="experiment-cell"
                    [ngClass]="{
                      positive:
                        experimentTableData[platform].cr[0]?.slice(0, 1) == '+',
                      negative:
                        experimentTableData[platform].cr[0]?.slice(0, 1) == '-'
                    }"
                  >
                    <svg
                      _ngcontent-twl-c13=""
                      viewBox="0 0 384 512"
                      xmlns="http://www.w3.org/2000/svg"
                      ng-reflect-ng-class="[object Object]"
                      [ngClass]="{
                        positiveSvg:
                          experimentTableData[platform].cr[0]?.slice(0, 1) ==
                          '+'
                      }"
                    >
                      <path
                        _ngcontent-twl-c13=""
                        d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
                      ></path>
                    </svg>

                    {{
                      experimentTableData[platform].cr[0]
                        ?.replace("-", "")
                        .replace("+", "")
                    }}
                  </div>
                </td>
                <td>
                  <div
                    class="experiment-cell"
                    [ngClass]="{
                      positive:
                        experimentTableData[platform].aov[0]?.slice(0, 1) ==
                        '+',
                      negative:
                        experimentTableData[platform].aov[0]?.slice(0, 1) == '-'
                    }"
                  >
                    <svg
                      _ngcontent-twl-c13=""
                      viewBox="0 0 384 512"
                      xmlns="http://www.w3.org/2000/svg"
                      ng-reflect-ng-class="[object Object]"
                      [ngClass]="{
                        positiveSvg:
                          experimentTableData[platform].aov[0]?.slice(0, 1) ==
                          '+'
                      }"
                    >
                      <path
                        _ngcontent-twl-c13=""
                        d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
                      ></path>
                    </svg>

                    {{
                      experimentTableData[platform].aov[0]
                        ?.replace("-", "")
                        .replace("+", "")
                    }}
                  </div>
                </td>
                <td>
                  <div
                    class="experiment-cell"
                    [ngClass]="{
                      positive:
                        experimentTableData[platform].revenueOverall[0]?.slice(
                          0,
                          1
                        ) == '+',
                      negative:
                        experimentTableData[platform].revenueOverall[0]?.slice(
                          0,
                          1
                        ) == '-'
                    }"
                  >
                    <svg
                      _ngcontent-twl-c13=""
                      viewBox="0 0 384 512"
                      xmlns="http://www.w3.org/2000/svg"
                      ng-reflect-ng-class="[object Object]"
                      [ngClass]="{
                        positiveSvg:
                          experimentTableData[
                            platform
                          ].revenueOverall[0]?.slice(0, 1) == '+'
                      }"
                    >
                      <path
                        _ngcontent-twl-c13=""
                        d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
                      ></path>
                    </svg>
                    {{
                      experimentTableData[platform].revenueOverall[0]
                        ?.replace("-", "")
                        .replace("+", "")
                    }}
                  </div>
                </td>
                <td>
                  {{ experimentTableData[platform].orders[0] }} /
                  {{ experimentTableData[platform].visits[0] }}
                </td>
                <td
                  [attr.rowspan]="
                    experimentTableData[platform]?.ids &&
                    experimentTableData[platform]?.ids[1] &&
                    experimentTableData[platform]?.ids[1].incentive != '-'
                      ? 2
                      : 1
                  "
                  class="text_align-center"
                >
                  <mat-slide-toggle
                    [checked]="experimentTableData[platform].currentTrafficType"
                    (change)="changeTrafficAllocation($event, platform)"
                  >
                  </mat-slide-toggle>
                </td>
                <td>
                  <div
                    class="split-ratio-container"
                    [ngClass]="{
                      disabled:
                        experimentTableData[platform]?.currentTrafficType
                    }"
                    (click)="openSplitRatioDialog(platform, 'default')"
                  >
                    {{ experimentTableData[platform].splitRatioDefault }}
                  </div>
                </td>
                <td class="minWidth60 text_align-right">
                  <mat-slide-toggle
                    (change)="
                      activeStatusChanged(
                        $event,
                        experimentTableData[platform].ids[0],
                        platform
                      )
                    "
                    [checked]="
                      experimentTableData[platform].ids[0] &&
                      experimentTableData[platform].ids[0].devices.includes(
                        platform
                      )
                    "
                  >
                  </mat-slide-toggle>
                </td>
              </tr>
              <tr
                *ngIf="
                  experimentTableData[platform]?.ids &&
                  experimentTableData[platform]?.ids[1] &&
                  experimentTableData[platform]?.ids[1].incentive != '-'
                "
              >
                <td>AI Variant</td>
                <td>
                  <div
                    class="experiment-cell"
                    [ngClass]="{
                      positive:
                        experimentTableData[platform].rpv[1]?.slice(0, 1) ==
                        '+',
                      negative:
                        experimentTableData[platform].rpv[1]?.slice(0, 1) == '-'
                    }"
                  >
                    <svg
                      _ngcontent-twl-c13=""
                      viewBox="0 0 384 512"
                      xmlns="http://www.w3.org/2000/svg"
                      ng-reflect-ng-class="[object Object]"
                      [ngClass]="{
                        positiveSvg:
                          experimentTableData[platform].rpv[1]?.slice(0, 1) ==
                          '+'
                      }"
                    >
                      <path
                        _ngcontent-twl-c13=""
                        d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
                      ></path>
                    </svg>
                    {{
                      experimentTableData[platform].rpv[1]
                        ?.replace("-", "")
                        .replace("+", "")
                    }}
                  </div>
                </td>
                <td>
                  <div
                    class="experiment-cell"
                    [ngClass]="{
                      positive:
                        experimentTableData[platform].cr[1]?.slice(0, 1) == '+',
                      negative:
                        experimentTableData[platform].cr[1]?.slice(0, 1) == '-'
                    }"
                  >
                    <svg
                      _ngcontent-twl-c13=""
                      viewBox="0 0 384 512"
                      xmlns="http://www.w3.org/2000/svg"
                      ng-reflect-ng-class="[object Object]"
                      [ngClass]="{
                        positiveSvg:
                          experimentTableData[platform].cr[1]?.slice(0, 1) ==
                          '+'
                      }"
                    >
                      <path
                        _ngcontent-twl-c13=""
                        d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
                      ></path>
                    </svg>
                    {{
                      experimentTableData[platform].cr[1]
                        ?.replace("-", "")
                        .replace("+", "")
                    }}
                  </div>
                </td>
                <td>
                  <div
                    class="experiment-cell"
                    [ngClass]="{
                      positive:
                        experimentTableData[platform].aov[1]?.slice(0, 1) ==
                        '+',
                      negative:
                        experimentTableData[platform].aov[1]?.slice(0, 1) == '-'
                    }"
                  >
                    <svg
                      _ngcontent-twl-c13=""
                      viewBox="0 0 384 512"
                      xmlns="http://www.w3.org/2000/svg"
                      ng-reflect-ng-class="[object Object]"
                      [ngClass]="{
                        positiveSvg:
                          experimentTableData[platform].aov[1]?.slice(0, 1) ==
                          '+'
                      }"
                    >
                      <path
                        _ngcontent-twl-c13=""
                        d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
                      ></path>
                    </svg>
                    {{
                      experimentTableData[platform].aov[1]
                        ?.replace("-", "")
                        .replace("+", "")
                    }}
                  </div>
                </td>
                <td>
                  <div
                    class="experiment-cell"
                    [ngClass]="{
                      positive:
                        experimentTableData[platform].revenueOverall[1]?.slice(
                          0,
                          1
                        ) == '+',
                      negative:
                        experimentTableData[platform].revenueOverall[1]?.slice(
                          0,
                          1
                        ) == '-'
                    }"
                  >
                    <svg
                      _ngcontent-twl-c13=""
                      viewBox="0 0 384 512"
                      xmlns="http://www.w3.org/2000/svg"
                      ng-reflect-ng-class="[object Object]"
                      [ngClass]="{
                        positiveSvg:
                          experimentTableData[
                            platform
                          ].revenueOverall[1]?.slice(0, 1) == '+'
                      }"
                    >
                      <path
                        _ngcontent-twl-c13=""
                        d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
                      ></path>
                    </svg>
                    {{
                      experimentTableData[platform].revenueOverall[1]
                        ?.replace("-", "")
                        .replace("+", "")
                    }}
                  </div>
                </td>
                <td>
                  {{ experimentTableData[platform].orders[1] }} /
                  {{ experimentTableData[platform].visits[1] }}
                </td>
                <td>
                  <div
                    class="split-ratio-container"
                    [ngClass]="{
                      disabled:
                        experimentTableData[platform]?.currentTrafficType
                    }"
                    (click)="openSplitRatioDialog(platform, 'ai')"
                  >
                    {{ experimentTableData[platform].splitRatioAI }}
                  </div>
                </td>
                <td class="minWidth60 text_align-right">
                  <mat-slide-toggle
                    (change)="
                      activeStatusChanged(
                        $event,
                        experimentTableData[platform].ids[1],
                        platform
                      )
                    "
                    [checked]="
                      experimentTableData[platform].ids[1] &&
                      experimentTableData[platform].ids[1].devices.includes(
                        platform
                      )
                    "
                  >
                  </mat-slide-toggle>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="noTableData">
            <tr>
              <td colspan="10">No Data</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div style="display: flex; justify-content: flex-end">
      <div class="custom_option_picker custom_option_picker_width60">
        <span class="custom_option_picker-label"> Rate: </span>
        <mat-form-field>
          <mat-select
            placeholder="Rate"
            [value]="rate"
            (selectionChange)="onRateChange($event)"
          >
            <mat-option value="hourly"> Hourly </mat-option>
            <mat-option value="day" [disabled]="isDayDisabled">
              Day
            </mat-option>
            <mat-option value="month" [disabled]="isMonthDisabled">
              Month
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Desktop Chart Line -->
    <div
      class="experiment-line-chart-container"
      style="min-height: 200px; margin-top: 20px; padding: 0px 15px"
      *ngIf="showDesktopLineChart && firstLoader"
    >
      <h2 class="custom_table-container-h2 subtitle-h2">Desktop Revenue</h2>
      <google-chart
        class="chart"
        [data]="desktopExperimentLineChart"
        (window:resize)="onResize($event)"
        *ngIf="desktopExperimentLineChart"
      >
      </google-chart>
    </div>
    <!-- Mobile Chart Line -->
    <div
      class="experiment-line-chart-container"
      style="min-height: 200px; margin-top: 20px; padding: 0px 15px"
      *ngIf="showMobileLineChart && firstLoader"
    >
      <h2 class="custom_table-container-h2 subtitle-h2">Mobile Revenue</h2>
      <google-chart
        class="chart"
        [data]="mobileExperimentLineChart"
        (window:resize)="onResize($event)"
        *ngIf="mobileExperimentLineChart"
      >
      </google-chart>
    </div>
    <!-- Desktop Chart Split Ratio Line -->
    <div
      class="experiment-line-chart-container"
      style="min-height: 200px; margin-top: 20px; padding: 0px 15px"
      *ngIf="showDesktopRatioChart && firstLoader"
    >
      <h2 class="custom_table-container-h2 subtitle-h2">
        Desktop Traffic Split Ratio
      </h2>
      <google-chart
        class="chart"
        [data]="desktopRatioExperimentLineChart"
        (window:resize)="onResize($event)"
        *ngIf="desktopRatioExperimentLineChart"
      >
      </google-chart>
    </div>
    <!-- Mobile Chart Split Ratio Line -->
    <div
      class="experiment-line-chart-container"
      style="min-height: 200px; margin-top: 20px; padding: 0px 15px"
      *ngIf="showMobileRatioChart && firstLoader"
    >
      <h2 class="custom_table-container-h2 subtitle-h2">
        Mobile Traffic Split Ratio
      </h2>
      <google-chart
        class="chart"
        [data]="mobileRatioExperimentLineChart"
        (window:resize)="onResize($event)"
        *ngIf="mobileRatioExperimentLineChart"
      >
      </google-chart>
    </div>
  </ng-container>

  <div class="loading-experiment-view-overlay" *ngIf="isLoading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <ng-container *ngIf="!firstLoader">
    <!-- datepicker loader -->
    <div style="margin-top: 20px">
      <div class="skeleton-box-square" fxLayoutAlign="end">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            height: '40px',
            width: '180px',
            outline: 'none',
            'background-color': '#37474f1a'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <!-- desktop chartline skeleton loader -->
    <div style="margin-top: 20px">
      <div class="skeleton-box-square">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            height: '220px',
            outline: 'none',
            'background-color': '#37474f1a'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div style="margin-top: 20px">
      <div class="skeleton-box-square">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            height: '220px',
            outline: 'none',
            'background-color': '#37474f1a'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </ng-container>
</div>
