import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TargetService {
  constructor(private http: HttpClient) {}

  getTargetList(): Observable<any> {
    return this.http.get<any>('/targets');
  }

  getCurrentUserRoles(email: any): Observable<any> {
    let params = 'email=' + email;
    if (localStorage.getItem('selectedTarget')) {
      params += '&target=' + localStorage.getItem('selectedTarget');
    }

    return this.http.get('/privileges/userPrivileges/user?' + params);
  }

  updateGa4Status(target: any, status: any) {
    return this.http.put('/change-ga4-status', {
      target: target,
      status: status,
    });
  }
}
