export const environment = {
  production: true,
  address: 'https://api.dashboard.behamics.com/api',
  statistics: 'https://statistics.behamics.com',
  bugsProject: 'https://bugs.behamics.com',
  emailserver: 'https://testemailserver.behamics.com',
  modelautomation: 'https://automation.behamics.com',
  autoscript: 'http://autoscript.behamics.com',
  imageOptimization: 'https://pixel.behamics.com',
  seo: 'https://seo1.behamics.com/',
};

export const environmentUS = {
  production: true,
  address: 'https://api.dashboardus.behamics.com/api',
  statistics: 'https://statisticsus.behamics.com',
  bugsProject: 'https://bugsus.behamics.com',
  emailserver: 'https://testemailserver.behamics.com',
  modelautomation: 'https://automation.behamics.com',
  autoscript: 'http://autoscript.behamics.com',
  imageOptimization: 'https://pixel.behamics.com',
  seo: 'https://seo1.behamics.com/',
};
