import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'src/app/services/pagetitle.service';
import { BackendMetricsService } from '../backend-metrics.service';

@Component({
  selector: 'app-backend-metrics',
  templateUrl: './backend-metrics.component.html',
  styleUrls: ['./backend-metrics.component.css'],
})
export class BackendMetricsComponent {
  backendMetrics: any;
  backendMetricsOld: any;
  firstLoad: boolean = true;
  isLoading: boolean = false;
  searchServerName: string = '';
  typesArray: any = [];

  api_interval: any;
  five_minutes = 5 * 60 * 1000;

  constructor(
    private pageTitle: PageTitleService,
    private metricsService: BackendMetricsService
  ) {}

  ngOnInit() {
    this.pageTitle.setTitle('Backend Metrics');
    this.getBackendMetrics();
    this.autoUpdate();
  }
  ngOnDestroy() {
    clearInterval(this.api_interval);
  }
  constructObject() {
    try {
      if (!this.backendMetrics || this.backendMetrics.length === 0) return;

      const returnStatusColor = (percentage: number) => {
        if (!percentage) return null;
        switch (true) {
          case percentage < 60:
            // green
            return '#16a34a';
          case percentage < 80:
            // yellow
            return '#facc15';
          default:
            // red
            return '#dc2626';
        }
      };

      this.backendMetrics.forEach((metric: any) => {
        metric['memory_data'] = {
          used:
            metric['memory_used_kilobytes'] != null
              ? metric['memory_used_kilobytes'].toFixed(2) + 'GB'
              : null,
          total:
            metric['memory_total_kilobytes'] != null
              ? metric['memory_total_kilobytes'].toFixed(2) + 'GB'
              : null,
          percentage:
            metric['memory_used_percentage'] != null
              ? metric['memory_used_percentage'].toFixed(2) + '%'
              : null,
          status: returnStatusColor(metric['memory_used_percentage']),
        };

        metric['storage_data'] = {
          used:
            metric['storage_used_kilobytes'] != null
              ? metric['storage_used_kilobytes'].toFixed(2) + 'GB'
              : null,
          total:
            metric['storage_available_kilobytes'] != null &&
            metric['storage_used_kilobytes'] != null
              ? (
                  metric['storage_available_kilobytes'] +
                  metric['storage_used_kilobytes']
                ).toFixed(2) + 'GB'
              : null,
          percentage:
            metric['storage_used_percentage'] != null
              ? metric['storage_used_percentage'].toFixed(2) + '%'
              : null,
          status: returnStatusColor(metric['storage_used_percentage']),
        };

        metric['cpu_data'] = {
          percentage:
            metric['cpu_usage_percentage'] != null
              ? metric['cpu_usage_percentage'].toFixed(2) + '%'
              : null,
          status: returnStatusColor(metric['cpu_usage_percentage']),
        };

        metric['show_date'] = new Date(metric['date']).toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
          day: 'numeric',
          month: 'short',
        });

        if (!this.typesArray.includes(metric.server_type)) {
          this.typesArray.push(metric.server_type);
        }
      });
      if (!this.typesArray.includes('Show All')) {
        this.typesArray.push('Show All');
      }
    } catch (e) {
      this.backendMetrics = [];
      console.log(e);
    }
  }
  getBackendMetrics(intervalCall: boolean = false) {
    this.isLoading = true;
    this.searchServerName = '';
    this.metricsService.getBackendMetrics().subscribe((data) => {
      this.backendMetrics = data;
      this.backendMetricsOld = [...this.backendMetrics];
      this.constructObject();

      this.isLoading = false;
      this.firstLoad = false;

      if (intervalCall) {
        this.sortByMemory();
      }
    });
  }
  sortByNameToogle: boolean = false;
  sortByName() {
    if (!this.backendMetrics || this.backendMetrics.length === 0) return;
    this.sortByMemoryToogle = false;
    this.sortByStorageToogle = false;
    this.sortByCpuToogle = false;
    this.sortByStatusToogle = false;

    if (this.sortByNameToogle) {
      this.backendMetrics.sort((a: any, b: any) => {
        if (!a.server_name) return 1;
        if (!b.server_name) return -1;
        return a.server_name.localeCompare(b.server_name);
      });
    } else {
      this.backendMetrics.sort((a: any, b: any) => {
        if (!a.server_name) return 1;
        if (!b.server_name) return -1;
        return b.server_name.localeCompare(a.server_name);
      });
    }
    this.sortByNameToogle = !this.sortByNameToogle;
  }
  sortByMemoryToogle: boolean = false;
  sortByMemory() {
    if (!this.backendMetrics || this.backendMetrics.length === 0) return;
    this.sortByNameToogle = false;
    this.sortByStorageToogle = false;
    this.sortByCpuToogle = false;
    this.sortByStatusToogle = false;
    if (this.sortByMemoryToogle) {
      this.backendMetrics.sort((a: any, b: any) => {
        if (!a.memory_used_percentage) return 1;
        if (!b.memory_used_percentage) return -1;
        return a.memory_used_percentage - b.memory_used_percentage;
      });
    } else {
      this.backendMetrics.sort((a: any, b: any) => {
        if (!a.memory_used_percentage) return 1;
        if (!b.memory_used_percentage) return -1;
        return b.memory_used_percentage - a.memory_used_percentage;
      });
    }
    this.sortByMemoryToogle = !this.sortByMemoryToogle;
  }
  sortByStorageToogle: boolean = false;
  sortByStorage() {
    if (!this.backendMetrics || this.backendMetrics.length === 0) return;
    this.sortByNameToogle = false;
    this.sortByMemoryToogle = false;
    this.sortByCpuToogle = false;
    this.sortByStatusToogle = false;
    if (this.sortByStorageToogle) {
      this.backendMetrics.sort((a: any, b: any) => {
        if (!a.storage_used_percentage) return 1;
        if (!b.storage_used_percentage) return -1;
        return a.storage_used_percentage - b.storage_used_percentage;
      });
    } else {
      this.backendMetrics.sort((a: any, b: any) => {
        if (!a.storage_used_percentage) return 1;
        if (!b.storage_used_percentage) return -1;
        return b.storage_used_percentage - a.storage_used_percentage;
      });
    }
    this.sortByStorageToogle = !this.sortByStorageToogle;
  }
  sortByCpuToogle: boolean = false;
  sortByCpu() {
    if (!this.backendMetrics || this.backendMetrics.length === 0) return;
    this.sortByNameToogle = false;
    this.sortByMemoryToogle = false;
    this.sortByStorageToogle = false;
    this.sortByStatusToogle = false;
    if (this.sortByCpuToogle) {
      this.backendMetrics.sort((a: any, b: any) => {
        if (!a.cpu_usage_percentage) return 1;
        if (!b.cpu_usage_percentage) return -1;
        return a.cpu_usage_percentage - b.cpu_usage_percentage;
      });
    } else {
      this.backendMetrics.sort((a: any, b: any) => {
        if (!a.cpu_usage_percentage) return 1;
        if (!b.cpu_usage_percentage) return -1;
        return b.cpu_usage_percentage - a.cpu_usage_percentage;
      });
    }
    this.sortByCpuToogle = !this.sortByCpuToogle;
  }
  sortByStatusToogle: boolean = false;
  sortByStatus() {
    if (!this.backendMetrics || this.backendMetrics.length === 0) return;
    this.sortByNameToogle = false;
    this.sortByMemoryToogle = false;
    this.sortByStorageToogle = false;
    this.sortByCpuToogle = false;
    if (this.sortByStatusToogle) {
      this.backendMetrics.sort((a: any, b: any) => {
        if (b.running_status) return 1;
        else return -1;
      });
    } else {
      this.backendMetrics.sort((a: any, b: any) => {
        if (b.running_status) return -1;
        else return 1;
      });
    }
    this.sortByStatusToogle = !this.sortByStatusToogle;
  }
  autoUpdate() {
    this.api_interval = setInterval(() => {
      this.getBackendMetrics(true);
    }, this.five_minutes);
  }
  searchByTarget(searchServerName: any) {
    if (!searchServerName || searchServerName.trim() === '') {
      this.backendMetrics = this.backendMetricsOld;
      this.constructObject();
    } else {
      this.backendMetrics = this.backendMetricsOld.filter((metric: any) =>
        metric.server_name
          .toLowerCase()
          .includes(searchServerName.toLowerCase())
      );
    }
  }
  searchByVMType(event: any) {
    if (!event.value || event.value === 'Show All') {
      this.backendMetrics = this.backendMetricsOld;
      this.constructObject();
    } else {
      this.backendMetrics = this.backendMetricsOld.filter((metric: any) =>
        metric.server_type.toLowerCase().includes(event.value.toLowerCase())
      );
    }
  }
}
