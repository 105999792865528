import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedIssueService {
  constructor(private _http: HttpClient) {}

  getSharedData(data: any): Observable<any> {
    if (data.includes('behamicsSample')) {
      data = data.replace('behamicsSample', 'gonser');
      data += '&behamicsSample=true';
    }
    return this._http.get(`/bugs/links?` + data);
  }
  traceErrorInfo(params: any): Observable<any> {
    if (params.includes('behamicsSample')) {
      params = params.replace('behamicsSample', 'gonser');
      params += '&behamicsSample=true';
    }
    return this._http.get('/bugs/trace-error-info?' + params);
  }
  getVisitDetails(data: any, token: string): Observable<any> {
    return this._http.post(
      `sharableBug/diagnostics/getVisitDetails?token=${token}`,
      data
    );
  }
  getErrorVisits(data: any, token: string): Observable<any> {
    return this._http.post(
      `sharableBug/diagnostics/getErrorVisits?token=${token}`,
      data
    );
  }
}
