import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExperimentsService } from '../experiments.service';
@Component({
  selector: 'app-edit-splitratio',
  templateUrl: './edit-splitratio.component.html',
  styleUrls: ['./edit-splitratio.component.css'],
})
export class EditSplitratioComponent implements OnInit {
  variantRatio: number;
  platform: string = this.data.platform;
  variant: string = this.data.isAI ? 'AI Variant' : 'Default Variant';

  constructor(
    public dialogRef: MatDialogRef<EditSplitratioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private experimentsService: ExperimentsService
  ) {}

  ngOnInit() {
    this.variantRatio = parseFloat((this.data.aiVariantRatio * 100).toFixed(4));
  }

  ratioChange() {
    if (this.variantRatio > 100) {
      this.variantRatio = 100;
    } else if (this.variantRatio < 0) {
      this.variantRatio = 0;
    }
  }

  updateSplitRatio() {
    let variants = [];
    for (let i = 0; i < this.data.variants.length; i++) {
      variants.push(this.data.variants[i].incentive.replaceAll('.', '-'));
    }
    let obj = {
      experimentID: this.data.experiment.experimentID,
      target: this.data.experiment.target,
      device: this.platform,
      variants: variants,
      split_ratio: this.data.isAI ? this.variantRatio : 100 - this.variantRatio,
      creationDate: this.data.creationDate,
      ruleID: this.data.experiment.ruleID.replaceAll('.', '-'),
      variantID: parseInt(this.data.experiment.incID.split('.')[3]),
    };
    obj.split_ratio = obj.split_ratio / 100;
    this.experimentsService.updateSplitRatio(obj).subscribe(
      (res) => {
        if (res?.message == 'success') {
          this.dialogRef.close(1);
        } else {
          this.dialogRef.close(2);
        }
      },
      (err) => {
        this.dialogRef.close(2);
      }
    );
  }
}
