import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Guards
import { AuthcheckerGuard } from './authentication/guard/authchecker.guard';
import { MasterGuardService } from './authentication/guard/masterchecker.guard';

// List of Components
import { SidenavComponent } from './sidenav/sidenav.component';
import { LoginComponent } from './session/login/login.component';
import { ResetpasswordComponent } from './session/resetpassword/resetpassword.component';
import { SetpasswordComponent } from './session/setpassword/setpassword.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OnboardingFreemiumComponent } from './seo/onboarding-freemium/onboarding-freemium.component';
import { ExperimentViewComponent } from './nudge/nudges/optimization/experiment-view/experiment-view.component';
import { SignupOrganicComponent } from './session/organic/signup/signup.component';
import { OrganicLoginComponent } from './session/organic/login/login.component';
import { DownloadServiceWorkerComponent } from './seo/download-service-worker/download-service-worker.component';
import { SharedIssueComponent } from './diagnostics/shared-issue/shared-issue.component';
import { BackendMetricsComponent } from './metrics/backend-metrics/backend-metrics.component';
import { UserPermissionsComponent } from './user-permissions/user-permissions.component';
import { UnderMaintenanceComponent } from './banners/under-maintenance/under-maintenance.component';
import { SetupComponent } from './ads/setup/setup.component';
import { PaymentConfirmedComponent } from './invoices/payment-confirmed/payment-confirmed.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'signup_organic',
    component: SignupOrganicComponent,
  },
  {
    path: 'login_organic',
    component: OrganicLoginComponent,
  },
  {
    path: 'resetpassword',
    component: ResetpasswordComponent,
  },
  {
    path: 'setpassword',
    component: SetpasswordComponent,
  },
  {
    path: 'share/issue',
    component: SharedIssueComponent,
  },
  {
    path: 'download-service-worker',
    component: DownloadServiceWorkerComponent,
  },
  {
    path: 'organic_onboarding',
    component: OnboardingFreemiumComponent,
    canActivate: [MasterGuardService],
    data: {
      privileges: 'seo_projects',
    },
  },
  {
    path: 'recording/heatmap',
    canActivate: [MasterGuardService],
    data: {
      privileges: 'recording_heatmap',
    },
    loadChildren: () =>
      import('./recordings/heatmaps/heatmaps.module').then(
        (m) => m.HeatmapsModule
      ),
  },
  {
    path: 'recording/:heatmapTarget/:id',
    canActivate: [MasterGuardService],
    data: {
      privileges: 'recording_overall',
    },
    loadChildren: () =>
      import('./recordings/player/player.module').then((m) => m.PlayerModule),
  },
  {
    path: 'ads-setup',
    component: SetupComponent,
    canActivate: [MasterGuardService],
    data: {
      privileges: 'masterDashboard',
    },
  },
  {
    path: 'confirmation/payment',
    component: PaymentConfirmedComponent,
  },
  {
    path: '',
    component: SidenavComponent,
    canActivate: [AuthcheckerGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'organic-maintenance',
        component: UnderMaintenanceComponent,
      },
      {
        path: 'master-experiment-view',
        component: ExperimentViewComponent,
        canActivate: [MasterGuardService],
        data: {
          privileges: 'masterAIExperiments',
        },
      },
      {
        path: 'metrics/backend',
        component: BackendMetricsComponent,
        canActivate: [MasterGuardService],
        data: {
          privileges: 'backendMetrics',
        },
      },
      {
        path: 'ads',
        loadChildren: () => import('./ads/ads.module').then((m) => m.AdsModule),
      },
      {
        path: 'merchandising',
        loadChildren: () =>
          import('./merchandise/merchandise.module').then(
            (m) => m.MerchandiseModule
          ),
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('./metrics/user-management/user-management.module').then(
            (m) => m.UserManagementModule
          ),
      },
      {
        path: 'master-preview',
        loadChildren: () =>
          import('./nudge/nudge.module').then((m) => m.NudgeModule),
      },
      {
        path: 'model-automation',
        loadChildren: () =>
          import('./model-automation/model-automation.module').then(
            (m) => m.ModelAutomationModule
          ),
      },
      {
        path: 'invoices',
        loadChildren: () =>
          import('./invoices/invoices.module').then((m) => m.InvoicesModule),
      },
      {
        path: 'diagnostic',
        loadChildren: () =>
          import('./diagnostics/diagnostics.module').then(
            (m) => m.DiagnosticsModule
          ),
      },
      {
        path: 'seo',
        loadChildren: () => import('./seo/seo.module').then((m) => m.SeoModule),
      },
      {
        path: 'recording',
        data: {
          privileges: 'recording_overall',
        },
        loadChildren: () =>
          import('./recordings/recordings.module').then(
            (m) => m.RecordingsModule
          ),
      },
      {
        path: 'automations',
        loadChildren: () =>
          import('./automations/automations.module').then(
            (m) => m.AutomationsModule
          ),
      },
      {
        path: 'user-permissions',
        component: UserPermissionsComponent,
        canActivate: [MasterGuardService],
        data: {
          privileges: 'userPermissions',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
