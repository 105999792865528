<div class="backend-metrics">
  <ng-container *ngIf="!firstLoad">
    <div class="backend-metrics-card-header">
      <!-- <h3>Metrics</h3> -->
      <label class="custom-input_field_withoutIcon maxWidth120">
        <input
          class="custom-input_field"
          placeholder="Search by Name"
          [(ngModel)]="searchServerName"
          (input)="searchByTarget(searchServerName)"
        />
      </label>
      <div
        class="custom_option_picker custom_option_picker_width130"
        style="margin-left: -33px"
      >
        <span class="custom_option_picker-label"> Type: </span>
        <mat-form-field>
          <mat-select
            matNativeControl
            (selectionChange)="searchByVMType($event)"
          >
            <mat-option
              class="backend-metrics-options"
              *ngFor="let type of typesArray"
              [value]="type"
            >
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="backend-metrics-card-header-buttons">
        <!-- <button
            mat-raised-button
            type="button"
            color="primary"
            (click)="getBackendMetrics()"
          >
            Refresh Data
          </button> -->
        <button
          class="btn_withoutIcon custom-button"
          mat-raised-button
          (click)="getBackendMetrics()"
        >
          Refresh Data
        </button>
      </div>
    </div>
    <!-- <div class="backend-metrics-card-body">
        <div class="backend-metrics-table">
          <div class="backend-metrics-table-header">
            <div class="backend-metrics-table-item">
              <p>
                VM
                <span class="material-icons swap_vert" (click)="sortByName()">
                  swap_vert
                </span>
              </p>
            </div>
            <div class="backend-metrics-table-item">
              <p>
                Memory
                <span class="material-icons swap_vert" (click)="sortByMemory()">
                  swap_vert
                </span>
              </p>
            </div>
            <div class="backend-metrics-table-item">
              <p>
                Storage
                <span
                  class="material-icons swap_vert"
                  (click)="sortByStorage()"
                >
                  swap_vert
                </span>
              </p>
            </div>
            <div class="backend-metrics-table-item">
              <p>
                CPU
                <span class="material-icons swap_vert" (click)="sortByCpu()">
                  swap_vert
                </span>
              </p>
            </div>
            <div
              class="backend-metrics-table-item backend-metrics-table-item-statusContainer"
            >
              <p>
                Status
                <span class="material-icons swap_vert" (click)="sortByStatus()">
                  swap_vert
                </span>
              </p>
            </div>
            <div class="backend-metrics-table-item">
              <p>Last Updated</p>
            </div>
          </div>
          <div class="backend-metrics-table-body">
            <ng-container *ngIf="backendMetrics && backendMetrics.length > 0">
              <div
                class="backend-metrics-table-row"
                *ngFor="let metric of backendMetrics"
              >
                <div class="backend-metrics-table-item">
                  <p>
                    <span
                      class="vm-icons"
                      *ngIf="metric?.server_type == 'server'"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="16"
                        width="16"
                        viewBox="0 0 512 512"
                        matTooltip="Server"
                        matTooltipPosition="left"
                      >
                        <path
                          d="M64 32C28.7 32 0 60.7 0 96v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm48 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM64 288c-35.3 0-64 28.7-64 64v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V352c0-35.3-28.7-64-64-64H64zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm56 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"
                        />
                      </svg>
                    </span>
                    <span
                      class="vm-icons"
                      *ngIf="metric?.server_type == 'database'"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="16"
                        width="14"
                        viewBox="0 0 448 512"
                        matTooltip="Database"
                        matTooltipPosition="left"
                      >
                        <path
                          d="M448 80v48c0 44.2-100.3 80-224 80S0 172.2 0 128V80C0 35.8 100.3 0 224 0S448 35.8 448 80zM393.2 214.7c20.8-7.4 39.9-16.9 54.8-28.6V288c0 44.2-100.3 80-224 80S0 332.2 0 288V186.1c14.9 11.8 34 21.2 54.8 28.6C99.7 230.7 159.5 240 224 240s124.3-9.3 169.2-25.3zM0 346.1c14.9 11.8 34 21.2 54.8 28.6C99.7 390.7 159.5 400 224 400s124.3-9.3 169.2-25.3c20.8-7.4 39.9-16.9 54.8-28.6V432c0 44.2-100.3 80-224 80S0 476.2 0 432V346.1z"
                        />
                      </svg>
                    </span>
                    {{ metric.server_name }}
                  </p>
                </div>
                <div class="backend-metrics-table-item">
                  <p
                    *ngIf="
                      metric?.memory_data?.percentage &&
                      metric?.memory_data?.used &&
                      metric?.memory_data?.total
                    "
                  >
                    <span
                      class="backend-metrics-table-item-status"
                      [style.background-color]="metric.memory_data.status"
                    >
                    </span>
                    {{ metric.memory_data.percentage }} -
                    {{ metric.memory_data.used }} /
                    {{ metric.memory_data.total }}
                  </p>
                </div>
                <div class="backend-metrics-table-item">
                  <p
                    *ngIf="
                      metric?.storage_data?.percentage &&
                      metric?.storage_data?.used &&
                      metric?.storage_data?.total
                    "
                  >
                    <span
                      class="backend-metrics-table-item-status"
                      [style.background-color]="metric.storage_data.status"
                    >
                    </span>
                    {{ metric.storage_data.percentage }} -
                    {{ metric.storage_data.used }} /
                    {{ metric.storage_data.total }}
                  </p>
                </div>
                <div class="backend-metrics-table-item">
                  <p *ngIf="metric?.cpu_data.percentage">
                    <span
                      class="backend-metrics-table-item-status"
                      [style.background-color]="metric.cpu_data.status"
                    >
                    </span>
                    {{ metric.cpu_data.percentage }}
                  </p>
                </div>
                <div
                  class="backend-metrics-table-item backend-metrics-table-item-statusContainer"
                >
                  <p>
                    <span
                      class="backend-metrics-table-item-status"
                      *ngIf="metric.running_status; else notRunning"
                    ></span>
                    <ng-template #notRunning>
                      <span class="backend-metrics-table-item-status red">
                      </span>
                    </ng-template>
                  </p>
                </div>
                <div class="backend-metrics-table-item">
                  <p>{{ metric.show_date }}</p>
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="!backendMetrics || backendMetrics.length === 0"
            >
              <div class="backend-metrics-table-row">
                <div class="backend-metrics-table-item">
                  <p>No data available</p>
                </div>
              </div>
              <div class="backend-metrics-table-row">
                <div class="backend-metrics-table-item">
                  <p>No data available</p>
                </div>
              </div>
              <div class="backend-metrics-table-row">
                <div class="backend-metrics-table-item">
                  <p>No data available</p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div> -->
  </ng-container>
  <div class="custom_table-container">
    <table class="custom_table">
      <thead>
        <tr>
          <th>
            <div>
              VM
              <svg
                (click)="sortByName()"
                class="custom_table_sort-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                [ngClass]="{
                  active: !sortByNameToogle
                }"
              >
                <g clip-path="url(#clip0_1676_4762)">
                  <path
                    d="M12 8V15"
                    stroke="#5f5d6e"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 12L12 8"
                    stroke="#5f5d6e"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 12L12 8"
                    stroke="#5f5d6e"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1676_4762">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </th>
          <th>
            <div>
              Memory
              <svg
                (click)="sortByMemory()"
                class="custom_table_sort-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                [ngClass]="{
                  active: !sortByMemoryToogle
                }"
              >
                <g clip-path="url(#clip0_1676_4762)">
                  <path
                    d="M12 8V15"
                    stroke="#5f5d6e"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 12L12 8"
                    stroke="#5f5d6e"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 12L12 8"
                    stroke="#5f5d6e"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1676_4762">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </th>
          <th>
            <div>
              Storage
              <svg
                (click)="sortByStorage()"
                class="custom_table_sort-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                [ngClass]="{
                  active: !sortByStorageToogle
                }"
              >
                <g clip-path="url(#clip0_1676_4762)">
                  <path
                    d="M12 8V15"
                    stroke="#5f5d6e"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 12L12 8"
                    stroke="#5f5d6e"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 12L12 8"
                    stroke="#5f5d6e"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1676_4762">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </th>
          <th>
            <div>
              CPU
              <svg
                (click)="sortByCpu()"
                class="custom_table_sort-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                [ngClass]="{
                  active: !sortByCpuToogle
                }"
              >
                <g clip-path="url(#clip0_1676_4762)">
                  <path
                    d="M12 8V15"
                    stroke="#5f5d6e"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 12L12 8"
                    stroke="#5f5d6e"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 12L12 8"
                    stroke="#5f5d6e"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1676_4762">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </th>
          <th>
            <div>
              Status
              <svg
                (click)="sortByStatus()"
                class="custom_table_sort-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                [ngClass]="{
                  active: !sortByStatusToogle
                }"
              >
                <g clip-path="url(#clip0_1676_4762)">
                  <path
                    d="M12 8V15"
                    stroke="#5f5d6e"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 12L12 8"
                    stroke="#5f5d6e"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 12L12 8"
                    stroke="#5f5d6e"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1676_4762">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </th>
          <th class="text_align-right"><div>Last Updated</div></th>
        </tr>
      </thead>
      <tbody>
        <ng-container>
          <tr *ngFor="let metric of backendMetrics">
            <td>
              <p>
                <span class="vm-icons" *ngIf="metric?.server_type == 'server'">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="16"
                    viewBox="0 0 512 512"
                    matTooltip="Server"
                    matTooltipPosition="left"
                  >
                    <path
                      d="M64 32C28.7 32 0 60.7 0 96v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm48 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM64 288c-35.3 0-64 28.7-64 64v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V352c0-35.3-28.7-64-64-64H64zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm56 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"
                    />
                  </svg>
                </span>
                <span
                  class="vm-icons"
                  *ngIf="metric?.server_type == 'database'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="14"
                    viewBox="0 0 448 512"
                    matTooltip="Database"
                    matTooltipPosition="left"
                  >
                    <path
                      d="M448 80v48c0 44.2-100.3 80-224 80S0 172.2 0 128V80C0 35.8 100.3 0 224 0S448 35.8 448 80zM393.2 214.7c20.8-7.4 39.9-16.9 54.8-28.6V288c0 44.2-100.3 80-224 80S0 332.2 0 288V186.1c14.9 11.8 34 21.2 54.8 28.6C99.7 230.7 159.5 240 224 240s124.3-9.3 169.2-25.3zM0 346.1c14.9 11.8 34 21.2 54.8 28.6C99.7 390.7 159.5 400 224 400s124.3-9.3 169.2-25.3c20.8-7.4 39.9-16.9 54.8-28.6V432c0 44.2-100.3 80-224 80S0 476.2 0 432V346.1z"
                    />
                  </svg>
                </span>
                {{ metric.server_name }}
              </p>
            </td>
            <td>
              <p
                *ngIf="
                  metric?.memory_data?.percentage &&
                  metric?.memory_data?.used &&
                  metric?.memory_data?.total
                "
              >
                <span
                  class="backend-metrics-table-item-status"
                  [style.background-color]="metric.memory_data.status"
                >
                </span>
                {{ metric.memory_data.percentage }} -
                {{ metric.memory_data.used }} /
                {{ metric.memory_data.total }}
              </p>
            </td>
            <td>
              <p
                *ngIf="
                  metric?.storage_data?.percentage &&
                  metric?.storage_data?.used &&
                  metric?.storage_data?.total
                "
              >
                <span
                  class="backend-metrics-table-item-status"
                  [style.background-color]="metric.storage_data.status"
                >
                </span>
                {{ metric.storage_data.percentage }} -
                {{ metric.storage_data.used }} /
                {{ metric.storage_data.total }}
              </p>
            </td>
            <td>
              <p *ngIf="metric?.cpu_data.percentage">
                <span
                  class="backend-metrics-table-item-status"
                  [style.background-color]="metric.cpu_data.status"
                >
                </span>
                {{ metric.cpu_data.percentage }}
              </p>
            </td>
            <td>
              <p>
                <span
                  class="backend-metrics-table-item-status"
                  *ngIf="metric.running_status; else notRunning"
                ></span>
                <ng-template #notRunning>
                  <span class="backend-metrics-table-item-status red"> </span>
                </ng-template>
              </p>
            </td>
            <td class="text_align-right">
              {{ metric.show_date }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div *ngIf="firstLoad" style="padding: 20px 16px">
    <div>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          height: '40px',
          width: '160px',
          outline: 'none',
          'background-color': '#37474f1a',
          'margin-bottom': '16px'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          height: '40px',
          outline: 'none',
          'background-color': '#37474f1a'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          height: '40px',
          outline: 'none',
          'background-color': '#37474f1a'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          height: '40px',
          outline: 'none',
          'background-color': '#37474f1a'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          height: '40px',
          outline: 'none',
          'background-color': '#37474f1a'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>

  <div class="loading-bug-data-overlay" *ngIf="isLoading && !firstLoad">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>
