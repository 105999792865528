import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download-service-worker',
  templateUrl: './download-service-worker.component.html',
  styleUrls: ['../../diagnostics/shared-issue/shared-issue.component.css'],
})
export class DownloadServiceWorkerComponent implements OnInit {
  downloaded: boolean = false;

  ngOnInit(): void {
    setTimeout(() => {
      this.downloadServiceWorker();
    }, 3000);
  }
  downloadServiceWorker() {
    const blob = new Blob(
      [
        'importScripts("https://d2os2z0w2fipcn.cloudfront.net/optimizer/resourceOptimizerSW.js");',
      ],
      { type: 'application/javascript' }
    );
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = 'resourceOptimizerSW.js';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    this.downloaded = true;
  }
}
