export const contriesWithLangs = [
  {
    country: {
      name: 'Afghanistan',
      code: 'AF',
    },
    lang: {
      value: 'prs',
      name: 'Dari',
    },
  },
  {
    country: {
      name: 'Albania',
      code: 'AL',
    },
    lang: {
      value: 'sqi',
      name: 'Albanian',
    },
  },
  {
    country: {
      name: 'Algeria',
      code: 'DZ',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'American Samoa',
      code: 'AS',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Andorra',
      code: 'AD',
    },
    lang: {
      value: 'cat',
      name: 'Catalan',
    },
  },
  {
    country: {
      name: 'Angola',
      code: 'AO',
    },
    lang: {
      value: 'por',
      name: 'Portuguese',
    },
  },
  {
    country: {
      name: 'Anguilla',
      code: 'AI',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Antarctica',
      code: 'AQ',
    },
    lang: {
      value: '',
      name: '',
    },
  },
  {
    country: {
      name: 'Antigua and Barbuda',
      code: 'AG',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Argentina',
      code: 'AR',
    },
    lang: {
      value: 'grn',
      name: 'Guaraní',
    },
  },
  {
    country: {
      name: 'Armenia',
      code: 'AM',
    },
    lang: {
      value: 'hye',
      name: 'Armenian',
    },
  },
  {
    country: {
      name: 'Aruba',
      code: 'AW',
    },
    lang: {
      value: 'nld',
      name: 'Dutch',
    },
  },
  {
    country: {
      name: 'Australia',
      code: 'AU',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Austria',
      code: 'AT',
    },
    lang: {
      value: 'de',
      name: 'German',
    },
  },
  {
    country: {
      name: 'Azerbaijan',
      code: 'AZ',
    },
    lang: {
      value: 'aze',
      name: 'Azerbaijani',
    },
  },
  {
    country: {
      name: 'Bahamas',
      code: 'BS',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Bahrain',
      code: 'BH',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Bangladesh',
      code: 'BD',
    },
    lang: {
      value: 'ben',
      name: 'Bengali',
    },
  },
  {
    country: {
      name: 'Barbados',
      code: 'BB',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Belarus',
      code: 'BY',
    },
    lang: {
      value: 'bel',
      name: 'Belarusian',
    },
  },
  {
    country: {
      name: 'Belgium',
      code: 'BE',
    },
    lang: {
      value: 'deu',
      name: 'German',
    },
  },
  {
    country: {
      name: 'Belize',
      code: 'BZ',
    },
    lang: {
      value: 'bjz',
      name: 'Belizean Creole',
    },
  },
  {
    country: {
      name: 'Benin',
      code: 'BJ',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Bermuda',
      code: 'BM',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Bhutan',
      code: 'BT',
    },
    lang: {
      value: 'dzo',
      name: 'Dzongkha',
    },
  },
  {
    country: {
      name: 'Bolivia',
      code: 'BO',
    },
    lang: {
      value: 'aym',
      name: 'Aymara',
    },
  },
  {
    country: {
      name: 'Bosnia and Herzegovina',
      code: 'BA',
    },
    lang: {
      value: 'bos',
      name: 'Bosnian',
    },
  },
  {
    country: {
      name: 'Botswana',
      code: 'BW',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Bouvet Island',
      code: 'BV',
    },
    lang: {
      value: 'nor',
      name: 'Norwegian',
    },
  },
  {
    country: {
      name: 'Brazil',
      code: 'BR',
    },
    lang: {
      value: 'por',
      name: 'Portuguese',
    },
  },
  {
    country: {
      name: 'British Indian Ocean Territory',
      code: 'IO',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'British Virgin Islands',
      code: 'VG',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Brunei',
      code: 'BN',
    },
    lang: {
      value: 'msa',
      name: 'Malay',
    },
  },
  {
    country: {
      name: 'Bulgaria',
      code: 'BG',
    },
    lang: {
      value: 'bul',
      name: 'Bulgarian',
    },
  },
  {
    country: {
      name: 'Burkina Faso',
      code: 'BF',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Burundi',
      code: 'BI',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Cambodia',
      code: 'KH',
    },
    lang: {
      value: 'khm',
      name: 'Khmer',
    },
  },
  {
    country: {
      name: 'Cameroon',
      code: 'CM',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Canada',
      code: 'CA',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Cape Verde',
      code: 'CV',
    },
    lang: {
      value: 'por',
      name: 'Portuguese',
    },
  },
  {
    country: {
      name: 'Caribbean Netherlands',
      code: 'BQ',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Cayman Islands',
      code: 'KY',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Central African Republic',
      code: 'CF',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Chad',
      code: 'TD',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Chile',
      code: 'CL',
    },
    lang: {
      value: 'spa',
      name: 'Spanish',
    },
  },
  {
    country: {
      name: 'China',
      code: 'CN',
    },
    lang: {
      value: 'zho',
      name: 'Chinese',
    },
  },
  {
    country: {
      name: 'Christmas Island',
      code: 'CX',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Cocos (Keeling) Islands',
      code: 'CC',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Colombia',
      code: 'CO',
    },
    lang: {
      value: 'spa',
      name: 'Spanish',
    },
  },
  {
    country: {
      name: 'Comoros',
      code: 'KM',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Cook Islands',
      code: 'CK',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Costa Rica',
      code: 'CR',
    },
    lang: {
      value: 'spa',
      name: 'Spanish',
    },
  },
  {
    country: {
      name: 'Croatia',
      code: 'HR',
    },
    lang: {
      value: 'hrv',
      name: 'Croatian',
    },
  },
  {
    country: {
      name: 'Cuba',
      code: 'CU',
    },
    lang: {
      value: 'spa',
      name: 'Spanish',
    },
  },
  {
    country: {
      name: 'Curaçao',
      code: 'CW',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Cyprus',
      code: 'CY',
    },
    lang: {
      value: 'ell',
      name: 'Greek',
    },
  },
  {
    country: {
      name: 'Czechia',
      code: 'CZ',
    },
    lang: {
      value: 'ces',
      name: 'Czech',
    },
  },
  {
    country: {
      name: 'Denmark',
      code: 'DK',
    },
    lang: {
      value: 'dan',
      name: 'Danish',
    },
  },
  {
    country: {
      name: 'Djibouti',
      code: 'DJ',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Dominica',
      code: 'DM',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Dominican Republic',
      code: 'DO',
    },
    lang: {
      value: 'spa',
      name: 'Spanish',
    },
  },
  {
    country: {
      name: 'DR Congo',
      code: 'CD',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Ecuador',
      code: 'EC',
    },
    lang: {
      value: 'spa',
      name: 'Spanish',
    },
  },
  {
    country: {
      name: 'Egypt',
      code: 'EG',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'El Salvador',
      code: 'SV',
    },
    lang: {
      value: 'spa',
      name: 'Spanish',
    },
  },
  {
    country: {
      name: 'Equatorial Guinea',
      code: 'GQ',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Eritrea',
      code: 'ER',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Estonia',
      code: 'EE',
    },
    lang: {
      value: 'est',
      name: 'Estonian',
    },
  },
  {
    country: {
      name: 'Eswatini',
      code: 'SZ',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Ethiopia',
      code: 'ET',
    },
    lang: {
      value: 'amh',
      name: 'Amharic',
    },
  },
  {
    country: {
      name: 'Falkland Islands',
      code: 'FK',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Faroe Islands',
      code: 'FO',
    },
    lang: {
      value: 'dan',
      name: 'Danish',
    },
  },
  {
    country: {
      name: 'Fiji',
      code: 'FJ',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Finland',
      code: 'FI',
    },
    lang: {
      value: 'fin',
      name: 'Finnish',
    },
  },
  {
    country: {
      name: 'France',
      code: 'FR',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'French Guiana',
      code: 'GF',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'French Polynesia',
      code: 'PF',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'French Southern and Antarctic Lands',
      code: 'TF',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Gabon',
      code: 'GA',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Gambia',
      code: 'GM',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Georgia',
      code: 'GE',
    },
    lang: {
      value: 'kat',
      name: 'Georgian',
    },
  },
  {
    country: {
      name: 'Germany',
      code: 'DE',
    },
    lang: {
      value: 'deu',
      name: 'German',
    },
  },
  {
    country: {
      name: 'Ghana',
      code: 'GH',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Gibraltar',
      code: 'GI',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Greece',
      code: 'GR',
    },
    lang: {
      value: 'ell',
      name: 'Greek',
    },
  },
  {
    country: {
      name: 'Greenland',
      code: 'GL',
    },
    lang: {
      value: 'kal',
      name: 'Greenlandic',
    },
  },
  {
    country: {
      name: 'Grenada',
      code: 'GD',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Guadeloupe',
      code: 'GP',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Guam',
      code: 'GU',
    },
    lang: {
      value: 'cha',
      name: 'Chamorro',
    },
  },
  {
    country: {
      name: 'Guatemala',
      code: 'GT',
    },
    lang: {
      value: 'spa',
      name: 'Spanish',
    },
  },
  {
    country: {
      name: 'Guernsey',
      code: 'GG',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Guinea',
      code: 'GN',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Guinea-Bissau',
      code: 'GW',
    },
    lang: {
      value: 'por',
      name: 'Portuguese',
    },
  },
  {
    country: {
      name: 'Guyana',
      code: 'GY',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Haiti',
      code: 'HT',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Heard Island and McDonald Islands',
      code: 'HM',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Honduras',
      code: 'HN',
    },
    lang: {
      value: 'spa',
      name: 'Spanish',
    },
  },
  {
    country: {
      name: 'Hong Kong',
      code: 'HK',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Hungary',
      code: 'HU',
    },
    lang: {
      value: 'hun',
      name: 'Hungarian',
    },
  },
  {
    country: {
      name: 'Iceland',
      code: 'IS',
    },
    lang: {
      value: 'isl',
      name: 'Icelandic',
    },
  },
  {
    country: {
      name: 'India',
      code: 'IN',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Indonesia',
      code: 'ID',
    },
    lang: {
      value: 'ind',
      name: 'Indonesian',
    },
  },
  {
    country: {
      name: 'Iran',
      code: 'IR',
    },
    lang: {
      value: 'fas',
      name: 'Persian (Farsi)',
    },
  },
  {
    country: {
      name: 'Iraq',
      code: 'IQ',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Ireland',
      code: 'IE',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Isle of Man',
      code: 'IM',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Israel',
      code: 'IL',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Italy',
      code: 'IT',
    },
    lang: {
      value: 'ita',
      name: 'Italian',
    },
  },
  {
    country: {
      name: 'Ivory Coast',
      code: 'CI',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Jamaica',
      code: 'JM',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Japan',
      code: 'JP',
    },
    lang: {
      value: 'jpn',
      name: 'Japanese',
    },
  },
  {
    country: {
      name: 'Jersey',
      code: 'JE',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Jordan',
      code: 'JO',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Kazakhstan',
      code: 'KZ',
    },
    lang: {
      value: 'kaz',
      name: 'Kazakh',
    },
  },
  {
    country: {
      name: 'Kenya',
      code: 'KE',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Kiribati',
      code: 'KI',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Kosova',
      code: 'XK',
    },
    lang: {
      value: 'sqi',
      name: 'Albanian',
    },
  },
  {
    country: {
      name: 'Kuwait',
      code: 'KW',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Kyrgyzstan',
      code: 'KG',
    },
    lang: {
      value: 'kir',
      name: 'Kyrgyz',
    },
  },
  {
    country: {
      name: 'Laos',
      code: 'LA',
    },
    lang: {
      value: 'lao',
      name: 'Lao',
    },
  },
  {
    country: {
      name: 'Latvia',
      code: 'LV',
    },
    lang: {
      value: 'lav',
      name: 'Latvian',
    },
  },
  {
    country: {
      name: 'Lebanon',
      code: 'LB',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Lesotho',
      code: 'LS',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Liberia',
      code: 'LR',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Libya',
      code: 'LY',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Liechtenstein',
      code: 'LI',
    },
    lang: {
      value: 'deu',
      name: 'German',
    },
  },
  {
    country: {
      name: 'Lithuania',
      code: 'LT',
    },
    lang: {
      value: 'lit',
      name: 'Lithuanian',
    },
  },
  {
    country: {
      name: 'Luxembourg',
      code: 'LU',
    },
    lang: {
      value: 'deu',
      name: 'German',
    },
  },
  {
    country: {
      name: 'Macau',
      code: 'MO',
    },
    lang: {
      value: 'por',
      name: 'Portuguese',
    },
  },
  {
    country: {
      name: 'Madagascar',
      code: 'MG',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Malawi',
      code: 'MW',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Malaysia',
      code: 'MY',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Maldives',
      code: 'MV',
    },
    lang: {
      value: 'div',
      name: 'Maldivian',
    },
  },
  {
    country: {
      name: 'Mali',
      code: 'ML',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Malta',
      code: 'MT',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Marshall Islands',
      code: 'MH',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Martinique',
      code: 'MQ',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Mauritania',
      code: 'MR',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Mauritius',
      code: 'MU',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Mayotte',
      code: 'YT',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Mexico',
      code: 'MX',
    },
    lang: {
      value: 'spa',
      name: 'Spanish',
    },
  },
  {
    country: {
      name: 'Micronesia',
      code: 'FM',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Moldova',
      code: 'MD',
    },
    lang: {
      value: 'ron',
      name: 'Romanian',
    },
  },
  {
    country: {
      name: 'Monaco',
      code: 'MC',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Mongolia',
      code: 'MN',
    },
    lang: {
      value: 'mon',
      name: 'Mongolian',
    },
  },
  {
    country: {
      name: 'Montenegro',
      code: 'ME',
    },
    lang: {
      value: 'cnr',
      name: 'Montenegrin',
    },
  },
  {
    country: {
      name: 'Montserrat',
      code: 'MS',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Morocco',
      code: 'MA',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Mozambique',
      code: 'MZ',
    },
    lang: {
      value: 'por',
      name: 'Portuguese',
    },
  },
  {
    country: {
      name: 'Myanmar',
      code: 'MM',
    },
    lang: {
      value: 'mya',
      name: 'Burmese',
    },
  },
  {
    country: {
      name: 'Namibia',
      code: 'NA',
    },
    lang: {
      value: 'afr',
      name: 'Afrikaans',
    },
  },
  {
    country: {
      name: 'Nauru',
      code: 'NR',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Nepal',
      code: 'NP',
    },
    lang: {
      value: 'nep',
      name: 'Nepali',
    },
  },
  {
    country: {
      name: 'Netherlands',
      code: 'NL',
    },
    lang: {
      value: 'nld',
      name: 'Dutch',
    },
  },
  {
    country: {
      name: 'New Caledonia',
      code: 'NC',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'New Zealand',
      code: 'NZ',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Nicaragua',
      code: 'NI',
    },
    lang: {
      value: 'spa',
      name: 'Spanish',
    },
  },
  {
    country: {
      name: 'Niger',
      code: 'NE',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Nigeria',
      code: 'NG',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Niue',
      code: 'NU',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Norfolk Island',
      code: 'NF',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'North Korea',
      code: 'KP',
    },
    lang: {
      value: 'kor',
      name: 'Korean',
    },
  },
  {
    country: {
      name: 'North Macedonia',
      code: 'MK',
    },
    lang: {
      value: 'mkd',
      name: 'Macedonian',
    },
  },
  {
    country: {
      name: 'Northern Mariana Islands',
      code: 'MP',
    },
    lang: {
      value: 'cal',
      name: 'Carolinian',
    },
  },
  {
    country: {
      name: 'Norway',
      code: 'NO',
    },
    lang: {
      value: 'nno',
      name: 'Norwegian Nynorsk',
    },
  },
  {
    country: {
      name: 'Oman',
      code: 'OM',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Pakistan',
      code: 'PK',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Palau',
      code: 'PW',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Palestine',
      code: 'PS',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Panama',
      code: 'PA',
    },
    lang: {
      value: 'spa',
      name: 'Spanish',
    },
  },
  {
    country: {
      name: 'Papua New Guinea',
      code: 'PG',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Paraguay',
      code: 'PY',
    },
    lang: {
      value: 'grn',
      name: 'Guaraní',
    },
  },
  {
    country: {
      name: 'Peru',
      code: 'PE',
    },
    lang: {
      value: 'aym',
      name: 'Aymara',
    },
  },
  {
    country: {
      name: 'Philippines',
      code: 'PH',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Pitcairn Islands',
      code: 'PN',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Poland',
      code: 'PL',
    },
    lang: {
      value: 'pol',
      name: 'Polish',
    },
  },
  {
    country: {
      name: 'Portugal',
      code: 'PT',
    },
    lang: {
      value: 'por',
      name: 'Portuguese',
    },
  },
  {
    country: {
      name: 'Puerto Rico',
      code: 'PR',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Qatar',
      code: 'QA',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Republic of the Congo',
      code: 'CG',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Romania',
      code: 'RO',
    },
    lang: {
      value: 'ron',
      name: 'Romanian',
    },
  },
  {
    country: {
      name: 'Russia',
      code: 'RU',
    },
    lang: {
      value: 'rus',
      name: 'Russian',
    },
  },
  {
    country: {
      name: 'Rwanda',
      code: 'RW',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Réunion',
      code: 'RE',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Saint Barthélemy',
      code: 'BL',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Saint Helena, Ascension and Tristan da Cunha',
      code: 'SH',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Saint Kitts and Nevis',
      code: 'KN',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Saint Lucia',
      code: 'LC',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Saint Martin',
      code: 'MF',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Saint Pierre and Miquelon',
      code: 'PM',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Saint Vincent and the Grenadines',
      code: 'VC',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Samoa',
      code: 'WS',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'San Marino',
      code: 'SM',
    },
    lang: {
      value: 'ita',
      name: 'Italian',
    },
  },
  {
    country: {
      name: 'Saudi Arabia',
      code: 'SA',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Senegal',
      code: 'SN',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Serbia',
      code: 'RS',
    },
    lang: {
      value: 'srp',
      name: 'Serbian',
    },
  },
  {
    country: {
      name: 'Seychelles',
      code: 'SC',
    },
    lang: {
      value: 'crs',
      name: 'Seychellois Creole',
    },
  },
  {
    country: {
      name: 'Sierra Leone',
      code: 'SL',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Singapore',
      code: 'SG',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Sint Maarten',
      code: 'SX',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Slovakia',
      code: 'SK',
    },
    lang: {
      value: 'slk',
      name: 'Slovak',
    },
  },
  {
    country: {
      name: 'Slovenia',
      code: 'SI',
    },
    lang: {
      value: 'slv',
      name: 'Slovene',
    },
  },
  {
    country: {
      name: 'Solomon Islands',
      code: 'SB',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Somalia',
      code: 'SO',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'South Africa',
      code: 'ZA',
    },
    lang: {
      value: 'afr',
      name: 'Afrikaans',
    },
  },
  {
    country: {
      name: 'South Georgia',
      code: 'GS',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'South Korea',
      code: 'KR',
    },
    lang: {
      value: 'kor',
      name: 'Korean',
    },
  },
  {
    country: {
      name: 'South Sudan',
      code: 'SS',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Spain',
      code: 'ES',
    },
    lang: {
      value: 'spa',
      name: 'Spanish',
    },
  },
  {
    country: {
      name: 'Sri Lanka',
      code: 'LK',
    },
    lang: {
      value: 'sin',
      name: 'Sinhala',
    },
  },
  {
    country: {
      name: 'Sudan',
      code: 'SD',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Suriname',
      code: 'SR',
    },
    lang: {
      value: 'nld',
      name: 'Dutch',
    },
  },
  {
    country: {
      name: 'Svalbard and Jan Mayen',
      code: 'SJ',
    },
    lang: {
      value: 'nor',
      name: 'Norwegian',
    },
  },
  {
    country: {
      name: 'Sweden',
      code: 'SE',
    },
    lang: {
      value: 'swe',
      name: 'Swedish',
    },
  },
  {
    country: {
      name: 'Switzerland',
      code: 'CH',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Syria',
      code: 'SY',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'São Tomé and Príncipe',
      code: 'ST',
    },
    lang: {
      value: 'por',
      name: 'Portuguese',
    },
  },
  {
    country: {
      name: 'Taiwan',
      code: 'TW',
    },
    lang: {
      value: 'zho',
      name: 'Chinese',
    },
  },
  {
    country: {
      name: 'Tajikistan',
      code: 'TJ',
    },
    lang: {
      value: 'rus',
      name: 'Russian',
    },
  },
  {
    country: {
      name: 'Tanzania',
      code: 'TZ',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Thailand',
      code: 'TH',
    },
    lang: {
      value: 'tha',
      name: 'Thai',
    },
  },
  {
    country: {
      name: 'Timor-Leste',
      code: 'TL',
    },
    lang: {
      value: 'por',
      name: 'Portuguese',
    },
  },
  {
    country: {
      name: 'Togo',
      code: 'TG',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Tokelau',
      code: 'TK',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Tonga',
      code: 'TO',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Trinidad and Tobago',
      code: 'TT',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Tunisia',
      code: 'TN',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Turkey',
      code: 'TR',
    },
    lang: {
      value: 'tur',
      name: 'Turkish',
    },
  },
  {
    country: {
      name: 'Turkmenistan',
      code: 'TM',
    },
    lang: {
      value: 'rus',
      name: 'Russian',
    },
  },
  {
    country: {
      name: 'Turks and Caicos Islands',
      code: 'TC',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Tuvalu',
      code: 'TV',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Uganda',
      code: 'UG',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Ukraine',
      code: 'UA',
    },
    lang: {
      value: 'ukr',
      name: 'Ukrainian',
    },
  },
  {
    country: {
      name: 'United Arab Emirates',
      code: 'AE',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'United Kingdom',
      code: 'GB',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'United States',
      code: 'US',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'United States Minor Outlying Islands',
      code: 'UM',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'United States Virgin Islands',
      code: 'VI',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Uruguay',
      code: 'UY',
    },
    lang: {
      value: 'spa',
      name: 'Spanish',
    },
  },
  {
    country: {
      name: 'Uzbekistan',
      code: 'UZ',
    },
    lang: {
      value: 'rus',
      name: 'Russian',
    },
  },
  {
    country: {
      name: 'Vanuatu',
      code: 'VU',
    },
    lang: {
      value: 'bis',
      name: 'Bislama',
    },
  },
  {
    country: {
      name: 'Vatican City',
      code: 'VA',
    },
    lang: {
      value: 'ita',
      name: 'Italian',
    },
  },
  {
    country: {
      name: 'Venezuela',
      code: 'VE',
    },
    lang: {
      value: 'spa',
      name: 'Spanish',
    },
  },
  {
    country: {
      name: 'Vietnam',
      code: 'VN',
    },
    lang: {
      value: 'vie',
      name: 'Vietnamese',
    },
  },
  {
    country: {
      name: 'Wallis and Futuna',
      code: 'WF',
    },
    lang: {
      value: 'fra',
      name: 'French',
    },
  },
  {
    country: {
      name: 'Western Sahara',
      code: 'EH',
    },
    lang: {
      value: 'ber',
      name: 'Berber',
    },
  },
  {
    country: {
      name: 'Yemen',
      code: 'YE',
    },
    lang: {
      value: 'ara',
      name: 'Arabic',
    },
  },
  {
    country: {
      name: 'Zambia',
      code: 'ZM',
    },
    lang: {
      value: 'eng',
      name: 'English',
    },
  },
  {
    country: {
      name: 'Zimbabwe',
      code: 'ZW',
    },
    lang: {
      value: 'bwg',
      name: 'Chibarwe',
    },
  },
  {
    country: {
      name: 'Åland Islands',
      code: 'AX',
    },
    lang: {
      value: 'swe',
      name: 'Swedish',
    },
  },
];
export const suggestedLanguages = [
  {
    value: 'de',
    name: 'German',
  },
  {
    value: 'eng',
    name: 'English',
  },
  {
    value: 'fra',
    name: 'French',
  },
  {
    value: 'ja',
    name: 'Japanese',
  },
  {
    value: 'ita',
    name: 'Italian',
  },
  {
    value: 'spa',
    name: 'Spanish',
  },
];
