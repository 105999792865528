<div class="create-recorder-heatmap recorder_modal add_keyword_modal">
  <p class="recorder_modal_title">
    {{ variant }} on <span>{{ platform }}</span>
  </p>
  <div class="recorder_modal_input_container">
    <label for="pageName">Edit Split Ratio</label>
    <input
      matInput
      class="recorder_input"
      type="number"
      [(ngModel)]="variantRatio"
      (keyup)="ratioChange()"
      (change)="ratioChange()"
      (keyup.enter)="updateSplitRatio()"
    />
  </div>
  <div class="button_container">
    <button
      mat-raised-button
      class="primary"
      (click)="updateSplitRatio()"
      mat-raised-button
      type="button"
      color="primary"
    >
      Save
    </button>
  </div>
</div>
