import { Component, OnDestroy, OnInit } from '@angular/core';
// import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../authentication/session.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MixpanelService } from 'src/app/utils/mixpanel.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.css',
    '../organic/signup/signup.component.css',
    '../organic/login/login.component.css',
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  // public moreInfo: string = '';
  public moreInfoResetSuccess: string = '';
  public moreInfoResetError: string = '';
  public logStatus: string = 'SIGN IN';
  // public forgotStatus: string = 'RESET PASSWORD';
  // public loginLoading: boolean = false;
  public resetLoading: boolean = false;
  public passwordForgotClick: boolean = false;
  public finishedSend: boolean = false;

  twoFactorBool: boolean = false;

  public errorBool: boolean = false;

  loginLoading: boolean = false;

  form = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });
  resetPasswordForm = this.fb.group({
    email: ['', Validators.required],
  });
  twoFactorForm = this.fb.group({
    twoFARequired: ['', [Validators.required, Validators.minLength(6)]],
  });
  moreInfo: string = '';

  resetPasswordBool: boolean = false;
  resetPasswordFinished: boolean = false;
  forgotStatus: string = 'Reset password';
  resetPasswordStatus: string =
    'Please type in your email address associated with your account.';

  // private routeData = null;
  // private status = {
  //   1: 'Incorrect Email or Password',
  //   2: "User doesn't exist!",
  //   3: 'Success',
  // };

  constructor(
    private snackBar: MatSnackBar,
    private sessionService: SessionService,
    private fb: FormBuilder, // private router: Router, // private route: ActivatedRoute
    private router: Router,
    private mixpanelService: MixpanelService,
    private titleService: Title
  ) {
    // this.routeData = this.route.snapshot.params['routeData'];
    this.loginSub = new Subscription();
    this.titleService.setTitle('Login - Behamics Dashboard');
  }
  loginSub: Subscription;

  ngOnInit() {
    document.body.classList.remove('dark-theme');
    if (localStorage.getItem('organicLogin')) {
      this.router.navigate(['login_organic']);
    }

    this.mixpanelService.track('Login Page Viewed');
    const getLoginMoreInfo = this.sessionService.getLoginMoreInfo().subscribe({
      next: (res) => {
        this.moreInfo = res;
        this.errorBool = true;
      },
    });
    this.loginSub.add(getLoginMoreInfo);
  }
  ngOnDestroy(): void {
    this.loginSub?.unsubscribe();
  }

  login() {
    this.errorBool = false;
    this.logStatus = 'SIGNING IN.. ';
    this.loginLoading = true;
    this.moreInfo = '';

    const authenticated = this.sessionService
      .authenticated(
        this.form.value?.email?.toLowerCase() || '',
        this.form.value?.password || ''
      )
      .subscribe({
        next: (data: any) => {
          if (data['twoFARequired']) {
            this.twoFactorBool = true;
            this.loginLoading = false;
            // this.snackBar.open('We sent a code to your email', 'Close', {
            //   duration: 3000,
            //   verticalPosition: 'top',
            //   horizontalPosition: 'center',
            // });
          } else {
            this.logStatus = 'SUCCESSFUL';
            this.sessionService.login(data);
            this.sessionService.navigate(data);
            this.handleKpiSymbol();
          }
        },
        error: (error) => {
          this.handleLoginError(error.error.message);
        },
      });
    // this.twoFactorBool = true;

    this.loginSub.add(authenticated);
  }

  submitTwoFactorCode() {
    this.errorBool = false;
    this.logStatus = 'VERIFYING CODE..';
    this.loginLoading = true;
    this.moreInfo = '';

    const email = this.form.value?.email?.toLowerCase() || '';
    const password = this.form.value?.password || '';
    const twoFARequired = this.twoFactorForm.value.twoFARequired || '';

    this.sessionService
      .authenticated(email, password, twoFARequired)
      .subscribe({
        next: (data: any) => {
          if (data['status'] === 3) {
            this.logStatus = 'SUCCESSFUL';
            this.sessionService.login(data);
            this.sessionService.navigate(data);
            this.twoFactorBool = true;
          } else {
            this.moreInfo = 'Invalid or expired 2FA code';
            this.errorBool = true;
            this.loginLoading = false;
          }
        },
        error: (err) => {
          if (
            err.error.message ===
            'Too many failed login attempts. Please try again later.'
          ) {
            this.moreInfo = err.error.message;
          } else {
            this.moreInfo = 'Invalid or expired 2FA code';
          }
          this.errorBool = true;
          this.loginLoading = false;
        },
      });
  }

  showErrorMessage(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
    });
  }

  private handleLoginError(message: string) {
    this.logStatus = 'SIGN IN';
    this.moreInfo = message;
    this.errorBool = true;
    this.loginLoading = false;
  }

  private handleKpiSymbol() {
    if (
      this.form.value?.email === 'faton.gjuka@gmail.com' ||
      this.form.value?.email === 'testarben@live.com'
    ) {
      localStorage.setItem('kpiSymbol', "#'$'");
    } else {
      localStorage.setItem('kpiSymbol', "#'€'");
    }
  }

  resetRequest() {
    let email = this.resetPasswordForm.value.email || '';
    if (!email || email == '') return;

    this.forgotStatus = 'REQUESTING...';
    this.resetLoading = true;
    this.moreInfoResetSuccess = '';
    this.moreInfoResetError = '';

    const resetRequest = this.sessionService
      .resetRequest(email.toLowerCase())
      .subscribe((data: any) => {
        if (data['status'] == 200) {
          this.forgotStatus = 'SENT';
          this.resetPasswordStatus =
            'The password reset verification has been sent, please check your e-mail address.';
          this.resetLoading = false;
          this.finishedSend = true;
        }
        if (data['status'] == 400) {
          this.forgotStatus = 'RESET PASSWORD';
          this.resetPasswordStatus =
            'There is no user account with that e-mail address.';
          this.resetLoading = false;
        }
      });
    this.loginSub.add(resetRequest);
  }

  loginWithMicrosoft(event: any) {
    event.preventDefault();

    const getMicrosoftLoginUrl = this.sessionService
      .getMicrosoftLoginUrl()
      .subscribe((response) => {
        const popup = window.open(
          response.authCodeUrl,
          'MicrosoftLogin',
          'popup=1,width=600,height=700'
        );
        window.addEventListener(
          'message',
          (event) => {
            if (event.data.type === 'MicrosoftLogin') {
              const { result } = event.data;
              if (result) {
                popup?.close();
                if (result.status === 3) {
                  this.sessionService.login(result);
                  this.sessionService.navigate();
                  this.moreInfo = '';
                  this.loginLoading = false;
                } else {
                  this.logStatus = 'SIGN IN';
                  this.moreInfo =
                    'Unauthorized: Microsoft account lacks login permissions.';
                  this.loginLoading = false;
                }
              }
            }
          },
          { once: true }
        );
      });
    this.loginSub.add(getMicrosoftLoginUrl);
  }

  passwordForgot() {
    this.passwordForgotClick = true;
    this.twoFactorBool = true;
  }
  resetPasswordFunc(state: boolean) {
    this.forgotStatus = 'Reset password';
    this.resetPasswordBool = state;
    this.resetPasswordFinished = false;
    this.twoFactorBool = false;
  }

  showPassword: boolean = false;
  toogleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  backLogin() {
    this.passwordForgotClick = false;
    this.twoFactorBool = false;
  }
}
