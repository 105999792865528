import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserPermissionsService } from '../../user-permissions.service';
import { TargetService } from 'src/app/sidenav/target.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map, Observable, of, Subscription } from 'rxjs';
import { SeoService } from 'src/app/seo/seo.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-action',
  templateUrl: './user-action.component.html',
  styleUrls: ['./user-action.component.css'],
})
export class UserPermissionsActionComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<UserPermissionsActionComponent>,
    private service: UserPermissionsService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private seoService: SeoService,
    public targetService: TargetService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {
    if (this.data.email != null) {
      this.form = this.fb.group({
        name: [this.data.name, Validators.required],
        email: [this.data.email, Validators.required],
        product: [this.data.product, Validators.required],
        seoProjects: [''],
        role: ['', Validators.required],
        targets: [this.selectedStore, Validators.required],
      });
    } else {
      this.form = this.fb.group({
        name: ['', Validators.required],
        email: ['', Validators.required],
        product: ['', Validators.required],
        seoProjects: [''],
        role: ['', Validators.required],
        targets: ['', Validators.required],
      });
    }

    this.userActionsSub = new Subscription();
  }

  @ViewChild('seoProjects') seoProjectsSelect: any;
  @ViewChild('product') productSelect: any;

  userActionsSub: Subscription;

  form: FormGroup;
  isNew: any;
  oldData: any;
  formValueChanged = false;
  roleList: any = [
    {
      value: 'seo_manager',
      viewValue: 'Manager',
      viewValueTranslated: 'words.manager',
    },
    {
      value: 'seo_content_writer',
      privileges: ['seo_getting_started', 'seo_content'],
      viewValue: 'Content Writer',
      viewValueTranslated: 'words.content_writer',
    },
    {
      value: 'seo_backlink_provider',
      viewValue: 'Backlink Provider',
      viewValueTranslated: 'words.backlink_provider',
    },
  ];
  modeList: any = ['invitation', 'instant'];
  productList: any = [];
  selectedProduct: string[] = [''];
  listOfSeoProjects: any = [];
  listOfSeoProjectsRAW: any = [];
  selectedSeoProjects: string[] = [''];
  productsLoading: boolean = true;
  selectedPrivileges: any;
  selectedStore: any;
  currentUserRoles: any;
  rolePageList: any;
  combinedRoleList: any;
  isMasterAcc: any;
  userTargets: any = localStorage.getItem('shopId_list') || [];

  rolePrivilegeOrganizer: any = [
    {
      name: 'Nudge',
      roles: [
        'masterDashboard',
        'masterPreview',
        'masterFrequencies',
        'masterPerformanceOverall',
        'masterPerformanceOverview',
        'masterPerformanceDetailed',
        'masterLongitudinalOverall',
        'masterLongitudinalDetailed',
      ],
    },
    {
      name: 'Diagnostic',
      roles: [
        'bugTrackingOverall',
        'bugTrackingDetailed',
        'imageOptimization',
        'bugTrackingMenagement',
        'traceError',
      ],
    },
    {
      name: 'Recording',
      roles: ['recording_overall', 'recording_heatmap', 'recording_campaign'],
    },
    {
      name: 'Merchandise',
      roles: [
        'merchandising',
        'merchandising_products',
        'merchandising_categories',
      ],
    },
    {
      name: 'Organic',
      roles: [
        'seo_getting_started',
        'seo_overall',
        'seo_projects',
        'seo_pages',
        'seo_ai-analysis',
        'seo_backlinks',
        'seo_content',
        'seo_backlinks_prospect-analysis',
        'seo_backlinks_ai-profile',
        'seo_backlinks_backlinks-list',
        'seo_backlinks_anchors',
        'seo_backlinks_referring-ips',
        'seo_backlinks_referring-domains',
        'seo_position-tracking',
        'seo_technicals',
        'seo_social',
        'seo_on-page',
        'seo_automations',
        'seo_scripts',
        'userPermissions',
        'seo_billing',
      ],
    },
    {
      name: 'Automations',
      roles: ['automationsLibrary', 'automationsOverview'],
    },
  ];

  isOrganicCategorySelected: boolean = false;

  flowStatus = false;
  // isPrivilege = false;
  selectedTargets: string[] = [];

  isProcessing: boolean = false;

  autherizationProvider: any = [
    { value: 'local', viewValue: 'Local' },
    { value: 'microsoft', viewValue: 'Microsoft' },
  ];

  user_seo_roles: any = [];

  disable_product_selector: boolean = false;
  disable_seoProjects_selector: boolean = false;

  seo_roles: any;

  ngOnInit() {
    if (localStorage.getItem('shopId_list')) {
      this.userTargets =
        JSON.parse(localStorage.getItem('shopId_list') || '{}') || [];
    }

    if (this.data.target) {
      this.selectedTargets = this.data.target.split(',');
    }

    this.user_seo_roles = JSON.parse(localStorage.getItem('seo_roles') || '{}');

    this.selectedStore = localStorage.getItem('selectedTarget');

    // if (this.data.isPrivilegeEdit) {
    //   this.isPrivilege = true;
    //   this.getAvailableRoles();
    // } else {
    if (this.data.email != null) {
      this.getCurrentUserRoles();
      this.isNew = false;

      // const selectedSeoRole = this.user_seo_roles?.[0].role;

      this.form.setValue({
        name: this.data.name,
        email: this.data.email,
        role: '',
        seoProjects: '',
        product: '',
        targets: ['default_target_value'],
        // authProvider: this.data.authProvider,
      });

      this.form.get('email')?.disable();

      this.oldData = this.form.value;

      const valueChanges = this.form.valueChanges.subscribe((data) => {
        if (JSON.stringify(this.oldData) === JSON.stringify(this.form.value)) {
          this.formValueChanged = false;
        } else {
          this.formValueChanged = true;
        }
      });
      this.userActionsSub.add(valueChanges);

      if (this.data.bugSubscription == 'Premium') {
        this.flowStatus = true;
      }
    } else {
      // this.getAvailableRoles();
      this.isNew = true;
      this.form.setValue({
        name: '',
        email: '',
        product: '',
        seoProjects: '',
        role: '',
        targets: '',
      });
      this.form.patchValue({
        mode: 'invitation',
        role: 'user',
        password: '1',
      });
    }

    if (localStorage.getItem('privileges')) {
      const privileges = localStorage.getItem('privileges')?.split(',');
      this.checkAvailableProducts(privileges);
    }

    this.form.patchValue({
      targets: this.selectedStore,
    });

    this.form.get('targets')?.disable();

    const valueChanges = this.form.valueChanges.subscribe((data) => {
      if (data.role == 'user' || data.role == 'admin') {
        this.isMasterAcc = false;
      } else {
        this.isMasterAcc = true;
      }
    });
    this.userActionsSub.add(valueChanges);

    if (this.data.role == 'user' || this.data.role == 'admin') {
      this.isMasterAcc = false;
    } else {
      this.isMasterAcc = true;
    }
    // }
  }
  ngOnDestroy(): void {
    this.userActionsSub?.unsubscribe();
  }

  checkAvailableProducts(res: any) {
    if (!res) return;

    let formattedProductList = [];
    let pushedValues = new Set();

    for (let i = 0; i < res.length; i++) {
      if (res[i].includes('seo_') && !pushedValues.has('Organic')) {
        formattedProductList.push('Organic');
        pushedValues.add('Organic');
      } else if (
        res[i].includes('erformanceOverall') &&
        !pushedValues.has('Nudge')
      ) {
        formattedProductList.push('Nudge');
        pushedValues.add('Nudge');
      } else if (
        res[i].includes('merchandising_') &&
        !pushedValues.has('Merchandise')
      ) {
        formattedProductList.push('Merchandise');
        pushedValues.add('Merchandise');
      } else if (
        res[i].includes('recording_') &&
        !pushedValues.has('Recording')
      ) {
        formattedProductList.push('Recording');
        pushedValues.add('Recording');
      } else if (
        res[i].includes('automationsLibrary') &&
        !pushedValues.has('Automations')
      ) {
        formattedProductList.push('Automations');
        pushedValues.add('Automations');
      } else if (
        res[i].includes('bugTrackingOverall') &&
        !pushedValues.has('Diagnostic')
      ) {
        formattedProductList.push('Diagnostic');
        pushedValues.add('Diagnostic');
      }
    }

    this.productList = formattedProductList;

    this.disable_product_selector = false;
    if (this.productList?.length === 1) {
      this.selectedProduct = this.productList;
      this.form.patchValue({
        product: this.selectedProduct,
      });
      this.form.get('product')?.disable();

      this.isOrganicCategorySelected =
        this.selectedProduct?.includes('Organic');

      this.disable_product_selector = true;

      this.selectedProduct.forEach((value: any) => {
        const selectedCategory = this.rolePrivilegeOrganizer.find(
          (item: any) => item.name === value
        );

        if (selectedCategory && selectedCategory.name !== 'Organic') {
          this.selectedPrivileges.push(...selectedCategory.roles);
        }

        if (value == 'Organic') {
          this.updateOrganicRoles(
            this.form.get('role')?.value,
            this.selectedPrivileges
          );
        }
      });
      this.selectedPrivileges = [...new Set(this.selectedPrivileges)];
    }

    this.userActionsSub.add(
      this.seoService.getProjects().subscribe({
        next: (response: any) => {
          for (let i = 0; i < response.length; i++) {
            this.listOfSeoProjects.push(response[i].domain);
          }

          this.listOfSeoProjects = this.listOfSeoProjects.sort(
            (a: any, b: any) => a.localeCompare(b)
          );

          this.listOfSeoProjectsRAW = [...this.listOfSeoProjects];

          this.selectedSeoProjects.push(this.listOfSeoProjects[0]);

          this.disable_seoProjects_selector = false;
          if (this.listOfSeoProjects?.length == 1) {
            this.form.patchValue({
              seoProjects: this.selectedSeoProjects,
            });
            this.form.get('seoProjects')?.disable();

            this.disable_seoProjects_selector = true;
          }
        },
        error: (err: any) => {
          console.log(err);
        },
      })
    );

    this.productsLoading = false;

    this.priviledgeFormatter();
  }

  returnProductsFromPrivileges(privileges: string[]) {
    const products: string[] = [];
    const user_organic_roles: string[] = [];
    // let user_role: string = '';

    this.rolePrivilegeOrganizer.forEach((organizer: any) => {
      // If user has privileges of other products than 'Organic'
      if (organizer?.name != 'Organic' && !products.includes(organizer?.name)) {
        if (organizer.roles.some((role: string) => privileges.includes(role))) {
          products.push(organizer?.name);
        }
      } else if (organizer?.name == 'Organic') {
        organizer.roles.forEach((orgRole: string) => {
          if (privileges.includes(orgRole)) {
            user_organic_roles.push(orgRole);
          }
        });
      }
    });

    const organic_roles = this.rolePrivilegeOrganizer.find(
      (role: any) => role.name == 'Organic'
    ).roles;
    const seo_content_roles = this.roleList.find(
      (role: any) => role.value == 'seo_content_writer'
    ).privileges;

    if (user_organic_roles.length == seo_content_roles.length) {
      // user_role = 'seo_content_writer';
      products.push('Organic');
    } else if (user_organic_roles.length > 0) {
      const backlink_privileges_length = privileges.filter(
        (privilege: string) => privilege.includes('seo_backlinks')
      ).length;

      if (user_organic_roles.length == backlink_privileges_length) {
        // user_role = 'seo_backlink_provider';
        products.push('Organic');
      } else if (organic_roles.length > backlink_privileges_length) {
        // user_role = 'seo_manager';
        products.push('Organic');
      }
    }

    this.form.patchValue({
      product: products,
      // role: user_role,
    });
  }

  updateOrganicRoles(value: any, selectedPrivileges: any) {
    const organicRoles = this.rolePrivilegeOrganizer.find(
      (obj: any) => obj.name === 'Organic'
    )?.roles;

    if (value === 'seo_manager') {
      for (let i = 0; i < organicRoles.length; i++) {
        selectedPrivileges.push(organicRoles[i]);
      }
    } else if (value === 'seo_content_writer') {
      selectedPrivileges.push('seo_content');
      selectedPrivileges.push('seo_getting_started');
    } else if (value === 'seo_backlink_provider') {
      for (let i = 0; i < organicRoles.length; i++) {
        if (organicRoles[i].includes('seo_backlinks')) {
          selectedPrivileges.push(organicRoles[i]);
        }
      }
    }
  }

  priviledgeFormatter() {
    const organicRoles = this.rolePrivilegeOrganizer.find(
      (obj: any) => obj.name === 'Organic'
    )?.roles;

    // this.userActionsSub.add(
    //   this.form.get('targets')?.valueChanges.subscribe((newValue) => {
    //     if (newValue) {
    //       let hasShopID = 0;
    //       for (let i = 0; i < this.user_seo_roles.length; i++) {
    //         if (
    //           newValue.includes(this.user_seo_roles[i].shopID) &&
    //           this.user_seo_roles[i].role === 'seo_manager'
    //         ) {
    //           hasShopID++;
    //         }
    //       }

    //       if (hasShopID === 0 && this.productList.includes('Organic')) {
    //         let indexOfOrganic = this.productList.indexOf('Organic');

    //         this.productList.splice(indexOfOrganic, 1);

    //         let products = this.form.get('product')?.value;

    //         try {
    //           products?.splice(indexOfOrganic, 1);
    //         } catch (e) {}

    //         this.isOrganicCategorySelected = false;

    //         this.form.patchValue({
    //           products: products,
    //         });
    //       } else if (hasShopID > 0 && !this.productList.includes('Organic')) {
    //         this.productList.push('Organic');
    //       }

    //       this.listOfSeoProjects = [];
    //       for (let i = 0; i < newValue.length; i++) {
    //         for (let j = 0; j < this.user_seo_roles.length; j++) {
    //           if (
    //             this.user_seo_roles[j].role == 'seo_manager' &&
    //             newValue[i] == this.user_seo_roles[j].shopID
    //           ) {
    //             this.listOfSeoProjects.push(this.user_seo_roles[j].project);
    //           }
    //         }
    //       }
    //     }
    //   })
    // );

    this.userActionsSub.add(
      this.form.get('product')?.valueChanges.subscribe((newValue) => {
        this.selectedPrivileges = [];
        if (newValue) {
          this.isOrganicCategorySelected = newValue.includes('Organic');

          newValue.forEach((value: any) => {
            const selectedCategory = this.rolePrivilegeOrganizer.find(
              (item: any) => item.name === value
            );

            if (selectedCategory && selectedCategory.name !== 'Organic') {
              this.selectedPrivileges.push(...selectedCategory.roles);
            }

            if (value == 'Organic') {
              this.updateOrganicRoles(
                this.form.get('role')?.value,
                this.selectedPrivileges
              );
            }
          });

          // if (newValue.length == 1 && newValue[0] == 'Organic') {
          //   this.updateOrganicRoles(
          //     this.form.get('role')?.value,
          //     this.selectedPrivileges
          //   );
          // } else {
          //   this.selectedPrivileges = [...new Set(this.selectedPrivileges)];
          // }
          this.selectedPrivileges = [...new Set(this.selectedPrivileges)];
        }

        if (this.productList?.length === 1) {
          this.productSelect?.close();
        }
      })
    );

    this.userActionsSub.add(
      // Listen to changes on the 'role' form control
      this.form.get('role')?.valueChanges.subscribe((newValue) => {
        if (this.selectedPrivileges) {
          for (let i = 0; i < this.selectedPrivileges.length; i++) {
            if (organicRoles.includes(this.selectedPrivileges[i])) {
              this.selectedPrivileges[i] = '';
            }
          }

          this.selectedPrivileges = this.selectedPrivileges.filter(
            (priv: any) => priv != ''
          );

          this.updateOrganicRoles(newValue, this.selectedPrivileges);
        }
      })
    );

    this.userActionsSub.add(
      // Listen to changes on the 'role' form control
      this.form.get('seoProjects')?.valueChanges.subscribe((newValue) => {
        if (this.listOfSeoProjects?.length === 1) {
          this.seoProjectsSelect?.close();
        }
      })
    );
  }

  getCurrentUserRoles() {
    const email =
      localStorage.getItem('email') == 'master@behamics.com'
        ? this.data.email
        : localStorage.getItem('email');

    const getCurrentUserRoles = this.service
      .getCurrentUserRoles(email)
      .subscribe({
        next: (res) => {
          this.currentUserRoles = res.privileges;

          const userProjects: string[] = [];
          let selected_user_role = '';

          if (res?.seo_roles) {
            for (let i = 0; i < res?.seo_roles?.length; i++) {
              if (res.seo_roles[i].shopID === this.selectedStore) {
                userProjects.push(res?.seo_roles[i].project);
              }
            }

            selected_user_role = res?.seo_roles.find(
              (role: any) => role.shopID === this.selectedStore
            )?.role;
          }

          this.form.patchValue({
            seoProjects: userProjects,
            role: selected_user_role,
          });

          const selectedProjectPriv = this.currentUserRoles.find(
            (store: any) => {
              return store.shopID == this.selectedStore;
            }
          ).priv;

          this.returnProductsFromPrivileges(selectedProjectPriv);
          // this.getAvailableRoles();
        },
        error: (err) => {
          // this.getAvailableRoles();
        },
      });
    this.userActionsSub.add(getCurrentUserRoles);
  }

  getAllRoles() {
    const getAllRoles = this.service.getRoleList().subscribe((res) => {
      if (res) {
        this.rolePageList = res;

        if (this.currentUserRoles != null) {
          for (var i = 0; i < this.rolePageList.length; i++) {
            for (var j = 0; j < this.currentUserRoles.length; j++) {
              if (this.rolePageList[i].value === this.currentUserRoles[j]) {
                this.rolePageList[i].checked = true;
                break;
              }
            }
          }
        }
      }
    });
    this.userActionsSub.add(getAllRoles);
  }

  // getAvailableRoles() {
  //   const getAvailableRoles = this.service.getRoles().subscribe((data) => {
  //     //this.roleList = data;
  //     if (!this.isNew) {
  //       // this.getAllRoles();
  //     }
  //   });
  //   this.userActionsSub.add(getAvailableRoles);
  // }

  addUser() {
    this.seo_roles =
      this.user_seo_roles.filter((role: any) => {
        return (
          this.form.getRawValue()['seoProjects'].includes(role.project) &&
          this.selectedStore === role.shopID
        );
      }) || [];

    if (this.seo_roles?.length == 0) {
      const selectedProjects = this.form
        .getRawValue()
        ['seoProjects']?.filter((p: any) => p != '');

      for (let i = 0; i < selectedProjects.length; i++) {
        this.seo_roles.push({
          project: selectedProjects[i],
          role: this.form.getRawValue()['role'],
          shopID: this.selectedStore,
        });
      }
    } else {
      for (let i = 0; i < this.seo_roles.length; i++) {
        this.seo_roles[i].role = this.form.getRawValue()['role'];
      }
    }

    this.isProcessing = true;
    const createUser = this.service
      .inviteUser({
        name: this.form.getRawValue()['name'],
        email: this.form.getRawValue()['email']?.toLowerCase(),
        target: this.selectedStore,
        role: 'sub-master',
        authProvider: 'local',
        privileges: this.selectedPrivileges,
        shopID: this.selectedStore,
        seo_roles: this.seo_roles,
        seoSubscription: localStorage.getItem('seoSubscription'),
        ...(localStorage.getItem('customerId') &&
        localStorage.getItem('customerId') != 'null'
          ? { customerId: localStorage.getItem('customerId') }
          : {}),
      })
      .subscribe((data) => {
        this.isProcessing = false;
        if (data.status == 400) {
          this.getTranslatedText(
            'a_user_with_this_email_already_exists',
            'phrases'
          ).subscribe((translatedText) => {
            this.openSnackBar(translatedText, 'x');
          });
        } else {
          if (data.mode == 'invitation') {
            this.dialogRef.close('invited');
          } else {
            this.dialogRef.close('added');
          }
        }
      });
    this.userActionsSub.add(createUser);
  }

  editUser() {
    this.seo_roles = this.user_seo_roles.filter((role: any) => {
      return (
        this.form.getRawValue()['seoProjects'].includes(role.project) &&
        this.selectedStore === role.shopID
      );
    });

    if (this.seo_roles?.length == 0) {
      const selectedProjects = this.form.getRawValue()['seoProjects'];
      for (let i = 0; i < selectedProjects.length; i++) {
        this.seo_roles.push({
          project: selectedProjects[i],
          role: this.form.getRawValue()['role'],
          shopID: this.selectedStore,
        });
      }
    }

    if (this.form.get('role')?.value) {
      this.seo_roles.forEach((tmp: any) => {
        tmp['role'] = this.form.get('role')?.value;
      });
    }

    this.isProcessing = true;
    const editUser = this.service
      .clientUpdateUser({
        name: this.form.getRawValue()['name'],
        email: this.form.getRawValue()['email']?.toLowerCase(),
        oldEmail: this.oldData.email,
        role: 'sub-master',
        shopID: this.selectedStore,
        authProvider: 'local',
        seo_roles: this.seo_roles,
        privileges: this.selectedPrivileges,
        ...(localStorage.getItem('customerId') &&
        localStorage.getItem('customerId') != 'null'
          ? { customerId: localStorage.getItem('customerId') }
          : {}),
      })
      .subscribe((data) => {
        this.isProcessing = false;
        this.dialogRef.close('updated');
      });
    this.userActionsSub.add(editUser);
  }
  getTranslatedText(text: string, type: string = 'words'): Observable<string> {
    return this.translate.get(`${type}.${text}`).pipe(
      map((translated: string) =>
        translated === `${type}.${text}` ? text : translated
      ),
      catchError(() => of(text))
    );
  }
  isFormValid(isEdit: boolean = false) {
    if (
      (isEdit && this.form.pristine) ||
      !this.form.valid ||
      this.isProcessing ||
      (this.form.getRawValue()['product'].includes('Organic') &&
        this.form.getRawValue()['role'] == 'user')
    ) {
      return false;
    }
    return true;
  }
  openSnackBar(a: string, b: string) {
    let panelClass = '';
    if (b == '') panelClass = 'snackbar-class-error';
    else panelClass = 'snackbar-class-success';
    this.snackBar.open(a, 'x', {
      duration: 3000,
      panelClass: panelClass,
    });
  }
}
