<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayout.sm="column"
  fxLayoutGap="10px"
>
  <div
    class="full-width"
    fxLayout="row wrap"
    style="margin-top: 20px; padding: 0px 0px"
  >
    <div class="gene-card-title" *ngIf="firstLoader">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h5 style="font-size: 16px; margin: 0px; color: #383838">
            Total Nudges
          </h5>
        </div>
        <span fxFlex></span>
      </div>
    </div>
    <div
      class="dtpickerall"
      style="
        margin-top: -28px;
        margin-bottom: 5px;
        flex: 1 1 100%;
        box-sizing: border-box;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      "
      *ngIf="firstLoader"
    >
      <div class="master_longitudinal_main-header">
        <div class="dtpicker rate">
          <mat-form-field
            class="bhm-option-container-1"
            style="--label-name: 'Rate:'; --form-field-infix-width: 100px"
          >
            <mat-select
              [value]="rate"
              (selectionChange)="onRateChangeLine($event)"
            >
              <mat-option class="bhm-option-select-option-1" value="hourly">
                Hourly
              </mat-option>
              <mat-option
                class="bhm-option-select-option-1"
                value="day"
                [disabled]="isDayDisabled"
              >
                Day
              </mat-option>
              <mat-option
                class="bhm-option-select-option-1"
                value="month"
                [disabled]="isMonthDisabled"
              >
                Month
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div
      style="height: 200px; overflow: hidden"
      fxFlex="100"
      *ngIf="firstLoader"
    >
      <div style="height: 200px">
        <google-chart
          class="chart"
          [data]="incentivesLineChart"
          (window:resize)="onResize($event)"
        >
        </google-chart>
      </div>
    </div>

    <div
      class="skeleton-fields"
      *ngIf="!firstLoader"
      style="display: flex; justify-content: space-between; gap: 20px"
    >
      <div>
        <ngx-skeleton-loader
          style="display: block !important; margin-bottom: 5px !important"
          count="1"
          [theme]="{
            height: '35px',
            outline: 'none',
            width: '130px',
            'background-color': '#37474f1a'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div>
        <ngx-skeleton-loader
          style="display: block !important; margin-bottom: 5px !important"
          count="1"
          [theme]="{
            height: '25px',
            outline: 'none',
            width: '130px',
            'background-color': '#37474f1a'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div
      class="skeleton-chart-square"
      *ngIf="!firstLoader"
      style="width: 100%; margin-right: 10px; margin-top: 5px !important"
    >
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          height: '220px',
          outline: 'none',
          width: '100%',
          'background-color': '#37474f1a'
        }"
      >
      </ngx-skeleton-loader>
    </div>
    <div
      class="skeleton-fields"
      *ngIf="!firstLoader"
      style="display: flex; justify-content: space-between; gap: 20px"
    >
      <div>
        <ngx-skeleton-loader
          style="display: block !important; margin-bottom: 5px !important"
          count="4"
          [theme]="{
            height: '35px',
            outline: 'none',
            width: '130px',
            'background-color': '#37474f1a',
            'margin-right': '15px'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div
      class="skeleton-chart-square"
      *ngIf="!firstLoader"
      style="width: 100%; margin-right: 10px; margin-top: 5px !important"
    >
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          height: '220px',
          outline: 'none',
          width: '100%',
          'background-color': '#37474f1a'
        }"
      >
      </ngx-skeleton-loader>
    </div>
    <div class="loading-data-overlay" *ngIf="!chartLoaderSecondary">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</div>

<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayout.sm="column"
  fxLayoutGap="10px"
  style="margin-top: 30px"
>
  <div class="full-width" fxLayout="row wrap" style="padding: 0px 0px">
    <div class="gene-card-title" *ngIf="firstLoader">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h5 style="font-size: 16px; margin: 0px; color: #383838">
            Nudge Frequencies
          </h5>
        </div>
        <span fxFlex></span>
      </div>
    </div>

    <div
      style="margin-top: 20px; display: flex; width: 100%; margin-bottom: 12px"
      *ngIf="firstLoader"
    >
      <div class="master_longitudinal_main-header">
        <mat-form-field
          class="bhm-option-container-1"
          style="--label-name: 'Device:'; --form-field-infix-width: 120px"
        >
          <mat-select
            (selectionChange)="selectionDeviceChanged($event)"
            [value]="selectedPlatform"
          >
            <mat-option class="bhm-option-select-option-1" value="overall">
              Overall
            </mat-option>
            <mat-option class="bhm-option-select-option-1" value="desktop">
              Desktop
            </mat-option>
            <mat-option class="bhm-option-select-option-1" value="tablet">
              Tablet
            </mat-option>
            <mat-option class="bhm-option-select-option-1" value="mobile">
              Mobile
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="bhm-option-container-1"
          style="--label-name: 'Nudge Sector:'; --form-field-infix-width: 200px"
        >
          <mat-select
            [(ngModel)]="incentiveBarChart.selectedIncentiveSector"
            name="incentiveSectorList"
            (selectionChange)="checkSectorFilter()"
          >
            <mat-option
              class="bhm-option-select-option-1"
              *ngFor="let sectorid of incentiveSectorList"
              [value]="sectorid"
            >
              {{ sectorid }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="bhm-option-container-1"
          style="--label-name: 'Page:'; --form-field-infix-width: 180px"
        >
          <mat-select
            [(ngModel)]="incentiveBarChart.selectedPageId"
            name="pageId"
            (selectionChange)="checkPageFilter()"
          >
            <mat-option
              class="bhm-option-select-option-1"
              *ngFor="let pageId of currentPageList"
              [value]="pageId"
            >
              {{ pageId }}
              <span *ngIf="pageId === 1"> - Product List</span>
              <span *ngIf="pageId === 2"> - Product Page</span>
              <span *ngIf="pageId === 4"> - Cart</span>
              <span *ngIf="pageId === 5"> - Delivery Address</span>
              <span *ngIf="pageId === 6"> - Order Confirmation</span>
              <span *ngIf="pageId === 7"> - My Account</span>
              <span *ngIf="pageId === 100"> - All Pages</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="bhm-option-container-1"
          style="--label-name: 'Filter by:'; --form-field-infix-width: 160px"
        >
          <mat-select
            [(ngModel)]="incentiveBarChart.selectedType"
            name="incentivefreqtype"
            (selectionChange)="checkFreqType()"
          >
            <mat-option
              class="bhm-option-select-option-1"
              *ngFor="let value of incentiveBarChart.incentivefreqtype"
              [value]="value"
            >
              {{ value }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="bhm-option-container-1"
          style="--label-name: 'Nudge ID:'; --form-field-infix-width: 150px"
          disabled="true"
        >
          <mat-select
            [(ngModel)]="incentiveBarChart.selectedRuleId"
            name="ruleId"
            (selectionChange)="checkRuleFilter()"
          >
            <mat-option
              class="bhm-option-select-option-1"
              *ngFor="let ruleId of currentRuleList"
              [value]="ruleId.name"
              [class.online-status-check]="ruleId.online"
            >
              {{ ruleId.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="frq-bar-charts-holder" *ngIf="firstLoader">
      <div class="frq-pageBarChart-container">
        <div class="frq-pageBarChart-axis">
          <div
            class="frq-pageBarChart-axis-inner"
            *ngFor="let axis of frequenciesChartCrAxis"
          >
            <p>{{ axis }}</p>
          </div>
        </div>
        <div class="frq-pageBarChart-main">
          <div
            class="frq-pageBarChart-main-charts"
            *ngFor="let bar of frequenciesChartCr; let i = index"
          >
            <div class="frq-pageBarChart-main-charts-bar-container">
              <div
                class="frq-pageBarChart-main-charts-bar"
                [ngClass]="{
                  defaultCol: bar.name == 'Control'
                }"
                [ngStyle]="{ height: bar.height + '%' }"
              >
                <div class="frq-pageBarChart-main-charts-bar-value">
                  {{ bar.value }}
                </div>
              </div>
            </div>
            <div class="frq-pageBarChart-main-charts-name">
              <p>{{ bar.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-data-overlay" *ngIf="!chartLoaderThird">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</div>
