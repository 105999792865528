import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SessionService } from '.././session.service';

@Injectable()
export class AuthcheckerGuard implements CanActivate {
  constructor(private authService: SessionService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let url: string = state.url;

    if (url.includes('organic-maintenance')) {
      return true;
    }

    const role = localStorage.getItem('privileges')?.split(',') || [];
    let privilegeRoute = this.authService.routesWithPrivileges.find(
      (route: any) => role.includes(route.privileges)
    )?.path;

    let tmpUrl = url.startsWith('/') ? url.replace('/', '') : url;

    if (tmpUrl?.includes('?')) {
      tmpUrl = tmpUrl.split('?')[0];
    }

    let currentRoutePrivilege = false;
    this.authService.routesWithPrivileges.forEach((route: any) => {
      if (route.path === tmpUrl && role.includes(route.privileges)) {
        currentRoutePrivilege = true;
      }
    });

    if (this.authService.isUserLoggedIn()) {
      // Check: if route is the same with privilegeRoute return true
      if (tmpUrl === privilegeRoute || currentRoutePrivilege) {
        return true;
      }

      url = '/' + privilegeRoute;
      if (privilegeRoute) {
        this.router.navigate([privilegeRoute]);
      } else {
        this.authService.logout();
      }
      return false;
    }
    this.authService.setRedirectUrl(url);
    this.router.navigate([this.authService.getLoginUrl()]);
    return false;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let url: string = state.url;
    if (this.authService.isUserLoggedIn()) {
      return true;
    }
    this.authService.setRedirectUrl(url);
    this.router.navigate([this.authService.getLoginUrl()]);
    return false;
  }
}
