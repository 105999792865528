import { Component } from '@angular/core';
import { SessionService } from '../../../authentication/session.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    '../signup/signup.component.css',
    '../../login/login.component.css',
    './login.component.css',
  ],
})
export class OrganicLoginComponent {
  constructor(
    private sessionService: SessionService,
    private fb: FormBuilder,
    private route: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle('Login - Organic');
  }

  public logStatus: string = 'SIGN IN';

  loginLoading: boolean = false;

  form = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });
  resetPasswordForm = this.fb.group({
    email: ['', Validators.required],
  });
  twoFactorForm = this.fb.group({
    twoFARequired: ['', [Validators.required, Validators.minLength(6)]],
  });

  twoFactorBool: boolean = false;

  public errorBool: boolean = false;

  moreInfo: string = '';

  resetPasswordBool: boolean = false;
  resetPasswordFinished: boolean = false;
  forgotStatus: string = 'Reset password';
  resetPasswordStatus: string =
    'Please type in your email address associated with your account.';

  ngOnInit() {
    document.body.classList.remove('dark-theme');
  }

  signin() {
    if (this.form.valid) {
      this.loginLoading = true;
      this.sessionService
        .authenticated(
          this.form.value?.email?.toLowerCase() || '',
          this.form.value?.password || ''
        )
        .subscribe({
          next: (data: any) => {
            if (data['twoFARequired']) {
              this.twoFactorBool = true;
              this.loginLoading = false;
            } else {
              this.logStatus = 'SUCCESSFUL';
              localStorage.setItem('organicLogin', 'true');
              this.sessionService.login(data);
              this.sessionService.navigate(data);
            }
          },
          error: (e) => {
            this.loginLoading = false;
            this.moreInfo = 'Something went wrong!';
          },
        });
    }
  }
  showPassword: boolean = false;
  toogleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  submitTwoFactorCode() {
    this.errorBool = false;
    this.logStatus = 'VERIFYING CODE..';
    this.loginLoading = true;
    this.moreInfo = '';

    const email = this.form.value?.email?.toLowerCase() || '';
    const password = this.form.value?.password || '';
    const twoFARequired = this.twoFactorForm.value.twoFARequired || '';

    this.sessionService
      .authenticated(email, password, twoFARequired)
      .subscribe({
        next: (data: any) => {
          if (data['status'] === 3) {
            this.logStatus = 'SUCCESSFUL';
            this.sessionService.login(data);
            this.sessionService.navigate(data);
            this.twoFactorBool = true;
          } else {
            this.moreInfo = 'Invalid or expired 2FA code';
            this.errorBool = true;
            this.loginLoading = false;
          }
        },
        error: (err) => {
          if (
            err.error.message ===
            'Too many failed login attempts. Please try again later.'
          ) {
            this.moreInfo = err.error.message;
          } else {
            this.moreInfo = 'Invalid or expired 2FA code';
          }
          this.errorBool = true;
          this.loginLoading = false;
        },
      });
  }

  loginWithGoogle(event: any) {
    event.preventDefault();
    const popup = window.open(
      'https://api.dashboard.behamics.com/api/auth/google?origin=' +
        location.origin,
      'googleAuth',
      'popup=1,width=600,height=700'
    );
    window.addEventListener(
      'message',
      (event) => {
        if (event.data.type === 'GoogleLogin') {
          const { result } = event.data;
          if (result) {
            popup?.close();
            if (result.status === 3) {
              localStorage.setItem('organicLogin', 'true');
              this.sessionService.login(result);
              this.sessionService.navigate(result);
              this.moreInfo = '';
              this.loginLoading = false;
            } else {
              this.moreInfo = result.message;
              this.loginLoading = false;
            }
          } else {
            console.log("Result doesn't exist: ", event);
          }
        } else {
          console.log("Failed event.data.type === 'GoogleLogin'");
          console.log('Event: ', event);
        }
      },
      { once: true }
    );
  }
  resetPassword() {
    if (this.resetPasswordForm.valid && !this.resetPasswordFinished) {
      let email = this.resetPasswordForm.value.email || '';
      this.sessionService
        .resetRequest(email.toLowerCase())
        .subscribe((data: any) => {
          this.resetPasswordFinished = true;
          if (data['status'] == 200) {
            this.forgotStatus = 'SENT';
            this.resetPasswordStatus =
              'The password reset verification has been sent, please check your e-mail address.';
          }
          if (data['status'] == 400) {
            this.forgotStatus = 'RESET PASSWORD';
            this.resetPasswordStatus =
              'There is no user account with that e-mail address.';
          }
        });
    }
  }
  redirectToSignup() {
    this.route.navigate(['signup_organic']);
  }
  resetPasswordFunc(state: boolean) {
    this.forgotStatus = 'Reset password';
    this.resetPasswordBool = state;
    this.resetPasswordFinished = false;
    this.twoFactorBool = false;
  }
}
