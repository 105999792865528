import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SidenavService } from '../sidenav/sidenav.service';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  constructor(private sidenavService: SidenavService) {}
  public title: BehaviorSubject<string> = new BehaviorSubject<string>('');

  setTitle(value: string) {
    this.title.next(value);

    this.sidenavService.triggerPageChange();
  }
}
