import { Injectable } from '@angular/core';

declare const Intercom: any;

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor() {}

  intercomData: any = {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'jdxwsqkm',
    name: window.localStorage.getItem('name'),
    email: window.localStorage.getItem('email'),
    created_at: Math.floor(Date.now() / 1000),
    user_id: '',
  };

  tourIds_languages: any = {
    de: {},
    ja: {
      592274: 593009,
      589800: 593012,
      589799: 593015,
      589796: 593017,
      589579: 593019,
      586610: 593020,
      586601: 593021,
      586598: 593023,
      586581: 593026,
      586580: 593028,
      586578: 593029,
      586360: 593031,
      586359: 593033,
      586352: 593034,
      586330: 593036,
    },
  };

  initIntercom(userId?: string) {
    if (typeof Intercom !== 'function') {
      console.warn('Intercom is not available.');
      return;
    }

    // Create user_id if it doesn't exist
    // if (!userId) {
    //   userId = window.localStorage.getItem('intercomUserId') || '';
    //   if (!userId || userId === '') {
    //     userId = Math.random().toString(36).substring(7);
    //     window.localStorage.setItem('intercomUserId', userId);
    //   }
    // }

    // this.intercomData.user_id = userId;

    // we will user user email as user_id
    this.intercomData.user_id = localStorage.getItem('email');
    this.intercomData.email = localStorage.getItem('email');
    this.intercomData.name = localStorage.getItem('name');

    Intercom('boot', this.intercomData);
  }

  startTour(tourId: number) {
    if (typeof Intercom !== 'function') {
      console.warn('⚠️ Intercom is not available.');
      return;
    }

    const language = localStorage.getItem('language');
    if (language && language != 'en') {
      tourId = this.tourIds_languages[language][tourId] || tourId;
    }

    // Shutdown Intercom to clear any active/invisible tours
    Intercom('shutdown');
    localStorage.removeItem('intercom.intercom-state');
    sessionStorage.removeItem('intercom.intercom-state');

    setTimeout(() => {
      Intercom('boot', this.intercomData);

      setTimeout(() => {
        Intercom('startTour', tourId);
      }, 100);
    }, 50);
  }

  shutdown() {
    Intercom('shutdown');
  }

  openChat() {
    Intercom('showNewMessage');
  }
}
