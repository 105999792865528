import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { IntercomService } from './services/intercom.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(
    private translate: TranslateService,
    private intercomService: IntercomService
  ) {
    const savedLang = localStorage.getItem('language') || 'en';
    this.translate.setDefaultLang('en');
    this.translate.use(savedLang);

    const params = new URLSearchParams(window.location.search);
    params.forEach((value, key) => {
      if (key === 'auth_key') {
        localStorage.setItem('auth_key', value);
      }
    });
  }

  title = 'behamicsDashboard';

  ngOnInit() {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }

    if (window.location.href.includes('?mailredirect')) {
      var url_string = window.location.href;
      var url = new URL(url_string);

      var data = {
        mailredirect: url.searchParams.get('mailredirect'),
        target: url.searchParams.get('target'),
        startDate: url.searchParams.get('startDate'),
        endDate: url.searchParams.get('endDate'),
      };

      window.localStorage.setItem('mailredirect', JSON.stringify(data));
    }

    // this.clearStorageOnFirstLoad();
  }

  ngAfterViewInit() {
    if (localStorage.getItem('auth_key')) {
      this.intercomService.initIntercom();
    }
  }

  clearStorageOnFirstLoad(): void {
    const keyExists = localStorage.getItem('allUserTargetPrivileges');
    const initialCheckDone = localStorage.getItem('initialCheckDone');

    if (keyExists && !initialCheckDone) {
      const darkMode = localStorage.getItem('darkMode') || '';
      const seenTours = JSON.parse(localStorage.getItem('seenTours') || '{}');

      localStorage.clear();
      sessionStorage.clear();

      localStorage.setItem('initialCheckDone', 'true');

      // Set darkmode and seenTours
      localStorage.setItem('darkMode', darkMode);
      localStorage.setItem('seenTours', JSON.stringify(seenTours));
    }
  }
}
