<div class="bhm-seo-freemium-banner">
  <div class="bhm-seo-freemium-banner-background"></div>
  <div class="bhm-seo-freemium-banner-content">
    <div class="bhm-seo-freemium-banner-icon">
      <mat-icon> info </mat-icon>
    </div>
    <p class="bhm-seo-freemium-banner-text">
      {{ "phrases.seo_premium_banner_translate" | translate }}
    </p>

    <div class="seo_position_tracking_main_header-atc">
      <button mat-raised-button type="button">
        <span
          style="position: relative; top: 0px"
          (click)="redirectToBilling()"
        >
          Upgrade to Premium
        </span>
      </button>
    </div>
  </div>
</div>
