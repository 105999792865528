import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css'],
})
export class SetupComponent implements OnInit, OnDestroy {
  constructor(private router: Router) {}

  setupSub: Subscription = new Subscription();

  setupSteps: any = [
    { label: 1, name: 'Account Setup' },
    { label: 2, name: 'Install Extension' },
  ];
  selectedStepIndex: number = 0;

  selectedEmail: string = '';
  customerAdsAccount: any = [];

  extensionInstalled: boolean = false;
  checkingExtensionStatus: boolean = false;

  checkingExtensionStatusInterval: any;
  checkingExtensionStatusIntervalTime: number = 1000;

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public updateStepIndex(index: number): void {
    if (this.selectedEmail == '') return;

    this.selectedStepIndex = index;
  }

  public goToStepIndex(): void {
    if (this.selectedEmail == '') return;
    if (this.selectedStepIndex + 1 >= this.customerAdsAccount.length) {
      return;
    }

    this.selectedStepIndex++;
  }

  public goToPreviousIndex(): void {
    this.selectedStepIndex--;
  }

  public emailSelected(account: any): void {
    this.selectedEmail = account.name;
    localStorage.setItem('bhm_ads_selected_acc', JSON.stringify(account));

    this.goToStepIndex();
  }

  public connectNewAccount(): void {
    this.selectedEmail = '';

    const popup = window.open(
      'https://ads.behamics.com/google-ads/auth',
      'googleAuth',
      'popup=1,width=600,height=700'
    );
    window.addEventListener(
      'message',
      (event) => {
        if (event.data.type === 'GoogleLogin') {
          const { result } = event.data;
          if (result) {
            this.customerAdsAccount = result?.customers || [];
            localStorage.setItem('bhm_ads_info', JSON.stringify(result));
            // popup?.close();
          }
        } else {
          console.log("Failed event.data.type === 'GoogleLogin'");
          console.log('Event: ', event);
        }
      },
      { once: true }
    );
  }

  public extensionButtonClicked(): void {
    // install extension
    if (!this.checkingExtensionStatus && !this.extensionInstalled) {
      window.open(
        'https://chromewebstore.google.com/category/extensions?utm_source=ext_sidebar&hl=en-US&pli=1',
        '_blank'
      );

      this.checkingExtensionStatus = true;
    }
    if (this.checkingExtensionStatus && !this.extensionInstalled) {
      // setTimeout(() => {
      //   this.checkingExtensionStatusInterval = setInterval(() => {
      //     this.checkExtensionStatus();
      //   }, this.checkingExtensionStatusIntervalTime);
      // }, 2000);

      // for testing
      setTimeout(() => {
        this.checkingExtensionStatus = false;
        this.extensionInstalled = true;

        setTimeout(() => {
          this.redirectToDashboard();
        }, 1000);
      }, 2000);
    }
    if (this.extensionInstalled) {
      clearInterval(this.checkingExtensionStatusInterval);
    }
  }

  public checkExtensionStatus(): void {
    fetch(
      'chrome-extension://amekeinjdhddedeimacphnkddaloojcb/behamics_check.json'
    )
      .then((response) => response.json())
      .then((data) => {
        console.log('✅ Extension is installed!', data);
        this.checkingExtensionStatus = false;
        this.extensionInstalled = true;
        clearInterval(this.checkingExtensionStatusInterval);
      })
      .catch(() => {
        console.log('❌ Extension is NOT installed.');
        this.checkingExtensionStatus = true;
        this.extensionInstalled = false;
      });
  }

  redirectToDashboard(): void {
    // sessionStorage.setItem('open_bhm_ads_modal', 'true');
    this.router.navigate(['ads/getting-started']);
  }
}
