import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DarkmodeService {
  private darkModeToogle;
  constructor() {
    const deviceMode = window.matchMedia('(prefers-color-scheme: dark)');

    if (localStorage.getItem('darkMode')) {
      let tmpBool = localStorage.getItem('darkMode') == 'true' ? true : false;
      this.darkModeToogle = new BehaviorSubject(tmpBool);
    } else {
      if (deviceMode.matches) {
        this.darkModeToogle = new BehaviorSubject(true);
      } else {
        this.darkModeToogle = new BehaviorSubject(false);
      }
    }
  }
  getDarkMode() {
    return this.darkModeToogle.asObservable();
  }
  updateDarkMode(state: boolean) {
    this.darkModeToogle.next(state);
  }
}
