import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '../material.module';

// Components
import { DashboardComponent } from '../dashboard/dashboard.component';
import { PerformanceComponent } from '../nudge/performance/performance/performance.component';
import { NudgesComponent } from '../nudge/nudges/nudges/nudges.component';
import { MainCausualImpactsGraphComponent } from '../utils/main-causual-impacts-graph/main-causual-impacts-graph.component';
import { ProgressChartComponent } from '../utils/progress-chart/progress-chart.component';
import { LibraryComponent } from '../automations/library/library.component';
import { SeoPremiumBannerComponent } from '../banners/seo-premium-banner/seo-premium-banner.component';

// Modals
import { ConfirmationComponent } from '../modals/confirmation/confirmation.component';
import { LibraryPageComponent } from '../automations/library-page/library-page/library-page.component';

// Translate module
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';
// import { HttpLoaderFactory } from '../core/translate/app.translate-loader';

import { CustomPaginatorIntl } from '../utils/custom-paginator-intl.service';
import { MatPaginatorIntl } from '@angular/material/paginator';

// Pipes
import { CurrencyFormatterPipe } from '../pipes/currency-formatter.pipe';
import { HttpLoaderFactory } from '../core/translate/app.translate-loader';

export class CustomMissingTranslationHandler
  implements MissingTranslationHandler
{
  handle(params: MissingTranslationHandlerParams) {
    // console.warn(`Missing translation for: ${params.key}`);
    return params.key;
  }
}

@NgModule({
  declarations: [
    DashboardComponent,
    PerformanceComponent,
    NudgesComponent,
    ConfirmationComponent,
    MainCausualImpactsGraphComponent,
    ProgressChartComponent,
    LibraryComponent,
    LibraryPageComponent,
    SeoPremiumBannerComponent,
    CurrencyFormatterPipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    Ng2GoogleChartsModule,
    NgxSkeletonLoaderModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
    }),
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [
    DashboardComponent,
    PerformanceComponent,
    NudgesComponent,
    CommonModule,
    MaterialModule,
    HttpClientModule,
    Ng2GoogleChartsModule,
    NgxSkeletonLoaderModule,
    ConfirmationComponent,
    MainCausualImpactsGraphComponent,
    ProgressChartComponent,
    LibraryComponent,
    LibraryPageComponent,
    SeoPremiumBannerComponent,
    TranslateModule,
    CurrencyFormatterPipe,
  ],
})
export class SharedModule {}
