import { Component, OnInit } from '@angular/core';
import { NudgeService } from './nudge.service';
import { GoogleChartInterface } from 'ng2-google-charts';
import { Subscription } from 'rxjs';
import { SelectionService } from 'src/app/services/selection.service';
import { DarkmodeService } from '../../../services/darkmode.service';
import { linechartColors } from '../../../utils/variables';

@Component({
  selector: 'app-nudges',
  templateUrl: './nudges.component.html',
  styleUrls: ['./nudges.component.css'],
})
export class NudgesComponent {
  constructor(
    private nudgeService: NudgeService,
    private selectorService: SelectionService,
    private darkModeService: DarkmodeService
  ) {}

  linechartsColors = linechartColors;
  today: any;
  yesterday: any;
  month: any;
  total: any;
  loading = true;
  freqLoaded = false;
  receivedInfoFreq: any;
  chartFreqData: any = [];
  incentiveSectorList: any = [];
  rules: any = [];
  oldincentiveTypeList: any = [];
  incentiveTypeList: any = [];
  currentRuleList: any = [];
  currentPageList: any = [];
  incentives: any[] = [];
  filteredIncentives: any[] = [];
  incentiveData: any;

  chartLoader = true;
  chartLoaderSecondary = true;
  chartLoaderThird = true;
  loadNrIndicator = 0;
  firstLoader = false;

  startDate: any;
  endDate: any;

  frequenciesChartCr: any = [];
  frequenciesChartCrAxis: any = [];

  incentiveBarChart = {
    run: function (transactionsListService: any) {
      this.transactionsListService = transactionsListService;
      var today = new Date();
      today.setHours(0, 0, 0);
    },
    ranges: [
      { value: 'custom', viewValue: 'Custom' },
      { value: 'today', viewValue: 'Today' },
      { value: 'yesterday', viewValue: 'Yesterday' },
      { value: 'thisweek', viewValue: 'This Week' },
      { value: 'last7days', viewValue: 'Last 7 days' },
      { value: 'last30days', viewValue: 'Last 30 days' },
      { value: 'thismonth', viewValue: 'This Month' },
      { value: 'thisyear', viewValue: 'This Year' },
    ],
    incentivefreqtype: ['Nudge Types', 'Nudge IDs'],
    selectedRangeValue: 'today',
    selectedPageId: 'Show All',
    selectedRuleId: 'Show All',
    selectedIncentiveSector: 'Show All',
    selectedType: 'Nudge Types',

    onRangeChange: function (value: any) {
      var stoday = new Date();
      var etoday = new Date();
      if (this.selectedRangeValue == 'today') {
        stoday.setHours(0, 0, 0);

        etoday.setHours(etoday.getHours(), 59, 59);
      } else if (this.selectedRangeValue == 'yesterday') {
        stoday.setDate(stoday.getDate() - 1);
        stoday.setHours(0, 0, 0);

        etoday.setDate(etoday.getDate() - 1);
        etoday.setHours(23, 59, 59);
      } else if (this.selectedRangeValue == 'thisweek') {
        var d = new Date();
        var day = d.getDay();
        var diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        stoday = new Date(d.setDate(diff));

        stoday.setHours(0, 0, 0);

        etoday.setHours(etoday.getHours(), 59, 59);
      } else if (this.selectedRangeValue == 'last7days') {
        stoday.setDate(stoday.getDate() - 6);
        stoday.setHours(0, 0, 0);
      } else if (this.selectedRangeValue == 'last30days') {
        stoday.setDate(stoday.getDate() - 29);
        stoday.setHours(0, 0, 0);
      } else if (this.selectedRangeValue == 'thismonth') {
        var tmpToday = new Date();
        var monthStartDate = new Date(
          tmpToday.getFullYear(),
          tmpToday.getMonth(),
          1
        );

        etoday.setHours(etoday.getHours(), 59, 59);
      } else if (this.selectedRangeValue == 'thisyear') {
        var thisyear = new Date(new Date().getFullYear(), 0, 1);

        etoday.setHours(etoday.getHours(), 59, 59);
      }

      if (
        this.selectedRangeValue == 'today' ||
        this.selectedRangeValue == 'yesterday'
      ) {
        this.rate = 'hourly';
      }
    },
    getStartDate: function (date: any) {
      this.selectedRangeValue = 'custom';

      this.minDate = date;
    },
    getEndDate: function (date: any) {
      this.selectedRangeValue = 'custom';
      this.maxDate = date;
      var nowDate = new Date();

      if (
        date.getFullYear() == nowDate.getFullYear() &&
        date.getMonth() == nowDate.getMonth() &&
        date.getDate() == nowDate.getDate()
      ) {
        date.setHours(nowDate.getHours(), 59, 59);
      } else {
        date.setHours(23, 59, 59);
      }
    },
    getStartDateFormat: function () {
      var today = new Date();
      today.setHours(0, 0, 0);

      return today;
    },
    maxDate: new Date(),
    endMaxDate: new Date(),
    minDate: new Date(),
    minDatestart: new Date('2018-12-20'),

    rate: 'hourly',
    onRateChange: function (value: any) {
      if (
        this.selectedRangeValue == 'today' ||
        this.selectedRangeValue == 'yesterday'
      ) {
        this.rate = 'hourly';
      } else {
        this.rate = value.value;
      }
    },
    transactionsListService: null,
  };

  lineRanges = [
    { value: 'custom', viewValue: 'Custom' },
    { value: 'today', viewValue: 'Today' },
    { value: 'yesterday', viewValue: 'Yesterday' },
    { value: 'thisweek', viewValue: 'This Week' },
    { value: 'last7days', viewValue: 'Last 7 days' },
    { value: 'last30days', viewValue: 'Last 30 days' },
    { value: 'thismonth', viewValue: 'This Month' },
    { value: 'thisyear', viewValue: 'This Year' },
  ];

  selectedRangeValue: any = 'today';
  endMaxDate = new Date();
  endMinDate = new Date();
  startMaxDate = new Date();
  rate = 'hourly';
  isMonthDisabled = true;
  isDayDisabled = false;
  lineLoaded = false;

  sub: Subscription;
  darkModeSub: Subscription;
  platforms = ['All', 'Desktop', 'Tablet', 'Mobile'];
  selectedPlatform = 'overall';

  getDayDifference(date1: any, date2: any) {
    const timestamp1 = date1.getTime();
    const timestamp2 = date2.getTime();

    const differenceInMilliseconds = Math.abs(timestamp2 - timestamp1);

    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    return Math.floor(differenceInDays);
  }

  ngOnInit() {
    this.previousSelectedStore = window.localStorage.getItem('selectedTarget');
    this.sub = this.selectorService.currentMessage.subscribe((message) => {
      this.selectedStore = message;
      this.rate = 'hourly';
      this.selectedRangeValue = window.localStorage.getItem('rangeValue');
      this.startDate = window.localStorage.getItem('startDate');
      this.endDate = window.localStorage.getItem('endDate');

      let date1 = new Date(this.startDate.split('T')[0]);
      let date2 = new Date(this.endDate.split('T')[0]);

      let dayDifference = this.getDayDifference(date1, date2);

      // If component has KPI with rate filter
      if (dayDifference > 60) {
        this.rate = 'month';
        this.isMonthDisabled = false;
      } else if (dayDifference > 2) {
        this.rate = 'day';
        this.isMonthDisabled = true;
      } else {
        this.rate = 'hourly';
        this.isMonthDisabled = true;
      }

      if (this.rate == 'hourly') {
        if (this.selectedRangeValue == 'today') {
          var startDate = new Date();
          startDate.setHours(0, 0, 0);
          this.startDate =
            startDate.toISOString() +
            '@' +
            Intl.DateTimeFormat().resolvedOptions().timeZone;
        } else if (this.selectedRangeValue == 'yestarday') {
          var startDate = new Date();
          startDate.setHours(0, 0, 0);
          startDate.setDate(startDate.getDate() - 1);
          this.startDate =
            startDate.toISOString() +
            '@' +
            Intl.DateTimeFormat().resolvedOptions().timeZone;

          var endDate = new Date();
          endDate.setHours(23, 59, 59);
          endDate.setDate(endDate.getDate() - 1);
          this.endDate =
            endDate.toISOString() +
            '@' +
            Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
      }

      if (this.rate == 'day') {
        if (this.selectedRangeValue == 'today') {
          var startDate = new Date();
          startDate.setHours(0, 0, 0);
          startDate.setDate(startDate.getDate() - 1);
          this.startDate =
            startDate.toISOString() +
            '@' +
            Intl.DateTimeFormat().resolvedOptions().timeZone;

          var endDate = new Date();
          this.endDate =
            endDate.toISOString() +
            '@' +
            Intl.DateTimeFormat().resolvedOptions().timeZone;
        } else if (this.selectedRangeValue == 'yestarday') {
          var startDate = new Date();
          startDate.setHours(0, 0, 0);
          startDate.setDate(startDate.getDate() - 2);
          this.startDate =
            startDate.toISOString() +
            '@' +
            Intl.DateTimeFormat().resolvedOptions().timeZone;

          var endDate = new Date();
          endDate.setHours(23, 59, 59);
          endDate.setDate(endDate.getDate() - 1);
          this.endDate =
            endDate.toISOString() +
            '@' +
            Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
      }

      if (
        this.previousSelectedStore ==
        window.localStorage.getItem('selectedTarget')
      ) {
        this.chartLoaderSecondary = false;
        this.chartLoaderThird = false;
      } else {
        this.chartLoader = false;
        this.chartLoaderSecondary = false;
        this.chartLoaderThird = false;
      }

      this.selectedRangeValue = 'today';
      this.incentiveBarChart.selectedRangeValue = 'today';
      this.incentiveBarChart.selectedPageId = 'Show All';
      this.incentiveBarChart.selectedRuleId = 'Show All';
      this.incentiveBarChart.selectedIncentiveSector = 'Show All';
      this.incentiveBarChart.selectedType = 'Nudge Types';

      this.loadNrIndicator = 0;
      this.runLineChart();
      this.getCardInformationsDate();
      // Load Incentives
      this.getIncentiveList();

      this.previousSelectedStore =
        window.localStorage.getItem('selectedTarget');
    });

    this.darkModeSub = this.darkModeService.getDarkMode().subscribe((event) => {
      if (event) {
        this.updateChartStyle('dark');
      } else {
        this.updateChartStyle('light');
      }
    });

    if (localStorage.getItem('darkMode') === 'true') {
      this.updateChartStyle('dark');
    } else if (localStorage.getItem('darkMode') === 'false') {
      this.updateChartStyle('light');
    } else {
      const deviceMode = window.matchMedia('(prefers-color-scheme: dark)');
      if (deviceMode.matches) {
        this.updateChartStyle('dark');
      }
    }

    this.chartLoader = true;
    this.chartLoaderSecondary = true;
    this.chartLoaderThird = true;
    this.lineLoaded = false;
  }

  updateChartStyle(theme: any) {
    if (theme == 'dark') {
      this.incentivesLineChart.options['backgroundColor'] = 'transparent';
      this.incentivesLineChart.options['legend'].textStyle.color = '#fff';
      this.incentivesLineChart.options['vAxis'].gridlineColor = '#3b3a3a';
      this.incentivesLineChart.options['vAxis'].baselineColor = '#3b3a3a';
      this.incentivesLineChart.options['vAxis'].textStyle.color = '#fff';
      this.incentivesLineChart.options['hAxis'].textStyle.color = '#fff';
      this.incentivesLineChart.options['crosshair'].color = '#3b3a3a';

      this.incentivesLineChart.component?.draw(this.incentivesLineChart);
    } else {
      this.incentivesLineChart.options['backgroundColor'] = 'transparent';
      this.incentivesLineChart.options['legend'].textStyle.color = '#666';
      this.incentivesLineChart.options['vAxis'].gridlineColor = '#c0c0c0';
      this.incentivesLineChart.options['vAxis'].baselineColor = '#ededed';
      this.incentivesLineChart.options['vAxis'].textStyle.color = '#666';
      this.incentivesLineChart.options['hAxis'].textStyle.color = '#666';
      this.incentivesLineChart.options['crosshair'].color = '#eeeeee';

      this.incentivesLineChart.component?.draw(this.incentivesLineChart);
    }
  }

  generateEvenPoints(data: any, numPoints: any) {
    const maxValue = Math.max(...data.map((item: any) => item.value));

    if (maxValue === 0) {
      return Array.from({ length: numPoints }, (_, i) => i.toLocaleString());
    }

    let tmpNumber = 1000;

    if (maxValue < 200) {
      tmpNumber = 40;
    } else if (maxValue < 500) {
      tmpNumber = 100;
    } else if (maxValue < 700) {
      tmpNumber = 200;
    } else if (maxValue < 2000) {
      tmpNumber = 500;
    }

    const roundedMax = Math.ceil(maxValue / tmpNumber) * tmpNumber;
    const interval =
      Math.ceil(roundedMax / (numPoints - 1) / tmpNumber) * tmpNumber;

    const points = [];

    for (let i = 0; i < numPoints; i++) {
      points.push((interval * i).toLocaleString());
    }

    return points;
  }

  setProportionalHeights(data: any, maxHeightPercentage: any) {
    let maxValue = Math.max(...data.map((item: any) => item.value));

    try {
      const frequenciesChartCrAxisMaxValue = parseFloat(
        this.frequenciesChartCrAxis[0].replace(',', '')
      );

      if (frequenciesChartCrAxisMaxValue > maxValue) {
        maxValue = frequenciesChartCrAxisMaxValue;
      }
    } catch (e) {
      console.log(e);
    }

    data.forEach((item: any) => {
      if (item.value === 0) {
        item.height = 3;
      } else {
        item.height = (item.value / maxValue) * maxHeightPercentage;
        item.height = Math.round(item.height * 100) / 100;
      }
    });

    return data;
  }

  runLineChart() {
    var today = new Date();
    today.setHours(0, 0, 0);

    this.fetchDataFromServer();
  }

  selectionDeviceChanged(event: any) {
    this.selectedPlatform = event.value;
    //   this.chartLoader = false;
    //   this.loadNrIndicator = 0;
    this.getChartInfo();
  }

  getStartDateLine(date: any) {
    this.selectedRangeValue = 'custom';
    var stoday = new Date();

    this.isMonthDisabled = false;
    this.endMinDate = date;

    this.fetchDataFromServer();
  }

  getEndDateLine(date: any) {
    var stoday = new Date();
    this.selectedRangeValue = 'custom';

    var nowDate = new Date();
    this.isMonthDisabled = false;

    if (
      date.getFullYear() == nowDate.getFullYear() &&
      date.getMonth() == nowDate.getMonth() &&
      date.getDate() == nowDate.getDate()
    ) {
      date.setHours(nowDate.getHours(), 59, 59);
    } else {
      date.setHours(23, 59, 59);
    }

    this.startMaxDate = date;

    this.fetchDataFromServer();
  }

  getStartDateFormat() {
    var today = new Date();
    today.setHours(0, 0, 0);

    return today;
  }

  onRateChangeLine(value: any) {
    this.selectedRangeValue = window.localStorage.getItem('rangeValue');

    if (value.value == 'hourly') {
      this.rate = 'hourly';
      if (this.selectedRangeValue == 'today') {
        var startDate = new Date();
        startDate.setHours(0, 0, 0);
        this.startDate =
          startDate.toISOString() +
          '@' +
          Intl.DateTimeFormat().resolvedOptions().timeZone;
      } else if (this.selectedRangeValue == 'yestarday') {
        var startDate = new Date();
        startDate.setHours(0, 0, 0);
        startDate.setDate(startDate.getDate() - 1);
        this.startDate =
          startDate.toISOString() +
          '@' +
          Intl.DateTimeFormat().resolvedOptions().timeZone;

        var endDate = new Date();
        endDate.setHours(23, 59, 59);
        endDate.setDate(endDate.getDate() - 1);
        this.endDate =
          endDate.toISOString() +
          '@' +
          Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    } else if (value.value == 'day') {
      this.rate = 'day';
      if (this.selectedRangeValue == 'today') {
        var startDate = new Date();
        startDate.setHours(0, 0, 0);
        startDate.setDate(startDate.getDate() - 1);
        this.startDate =
          startDate.toISOString() +
          '@' +
          Intl.DateTimeFormat().resolvedOptions().timeZone;

        var endDate = new Date();
        this.endDate =
          endDate.toISOString() +
          '@' +
          Intl.DateTimeFormat().resolvedOptions().timeZone;
      } else if (this.selectedRangeValue == 'yestarday') {
        var startDate = new Date();
        startDate.setHours(0, 0, 0);
        startDate.setDate(startDate.getDate() - 2);
        this.startDate =
          startDate.toISOString() +
          '@' +
          Intl.DateTimeFormat().resolvedOptions().timeZone;

        var endDate = new Date();
        endDate.setHours(23, 59, 59);
        endDate.setDate(endDate.getDate() - 1);
        this.endDate =
          endDate.toISOString() +
          '@' +
          Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    }
    this.rate = value.value;
    this.chartLoaderSecondary = false;
    this.fetchDataFromServer();
  }

  fetchDataFromServer() {
    var obj = {
      startDate: this.startDate,
      endDate: this.endDate,
      rate: this.rate,
      selectedRangeValue: this.selectedRangeValue,
      target: window.localStorage.getItem('selectedTarget'),
    };

    this.nudgeService.getIncentivesStats(obj).subscribe((data) => {
      this.incentiveData = data;
      this.updateIncentiveLineChart();
    });
  }

  updateIncentiveLineChart() {
    function formatNumber(num: any) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    function formatDateFull(date: any) {
      if (date.length > 11) {
        var x = date.slice(0, -3) + 'T' + date.slice(-2) + ':00:00';
        var MM = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        var xx = x.replace(
          /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}/,
          function ($0, $1, $2, $3, $4, $5) {
            var ampm12 = $4 >= 12 ? 'PM' : 'AM';
            $4 = $4 % 12;
            $4 = $4 ? $4 : 12;
            return MM[$2 - 1] + ' ' + $3 + ', ' + $1 + ' - ' + $4 + ampm12;
          }
        );
        return xx;
      } else if (date.length > 8) {
        var xd = date;
        var MMd = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        var xxd = xd.replace(
          /(\d{4})-(\d{2})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMd[$2 - 1] + ' ' + $3 + ', ' + $1;
          }
        );
        return xxd;
      } else {
        var xm = date;
        var MMm = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        var xxm = xm.replace(
          /(\d{4})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMm[$2 - 1] + ', ' + $1;
          }
        );
        return xxm;
      }
    }
    var triggerOne = false;
    function formatAMPM(date: any) {
      if (date.length > 11) {
        var x = date.slice(0, -3) + 'T' + date.slice(-2) + ':00:00';
        var MM = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        var xx = x.replace(
          /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}/,
          function ($0, $1, $2, $3, $4, $5) {
            var ampm12 = $4 >= 12 ? 'PM' : 'AM';
            $4 = $4 % 12;
            $4 = $4 ? $4 : 12;
            return $4 + ampm12;
          }
        );
        return xx;
      } else if (date.length > 8) {
        var xd = date;
        var MMd = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        var xxd = xd.replace(
          /(\d{4})-(\d{2})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMd[$2 - 1] + ' ' + $3;
          }
        );
        return xxd;
      } else {
        var xm = date;
        var MMm = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        var xxm = xm.replace(
          /(\d{4})-(\d{2})/,
          function ($0: any, $1: any, $2: any, $3: any) {
            return MMm[$2 - 1] + ', ' + $1;
          }
        );
        return xxm;
      }
    }

    //Update Incentive Line Chart
    var incLineData = [
      ['Time', 'Number of Nudges', { role: 'tooltip', p: { html: true } }],
    ];

    var i;
    for (i = 0; i < this.incentiveData['labels'].length; i++) {
      incLineData.push([
        {
          v: this.incentiveData['labels'][i],
          f: formatAMPM(this.incentiveData['labels'][i]),
        },
        this.incentiveData['data'][i],
        "<p class='tooltip-text'><b>" +
          formatDateFull(this.incentiveData['labels'][i]) +
          '</b></p>' +
          "<p class='tooltip-text'><b>Number of Nudges: </b>" +
          formatNumber(this.incentiveData['data'][i]) +
          '</p>',
      ]);
    }

    //Check max value, and if the val is 0 then update max val of chart to fixed
    var maxData: any = [];
    for (const val of incLineData) {
      if (val[1] !== 'Number of Nudges') {
        maxData.push(val[1]);
      }
    }

    this.incentivesLineChart.options['colors'] = this.linechartsColors;

    var maxVal = Math.max(...maxData);

    if (maxVal == 0) {
      this.incentivesLineChart.options['vAxis'].viewWindow.max = 10;
    } else {
      this.incentivesLineChart.options['vAxis'].viewWindow.max = maxData;
    }
    
    if (maxVal > 1000000) {
      this.incentivesLineChart.options['vAxis'].format = 'short';
    } else {
      this.incentivesLineChart.options['vAxis'].format = 'decimal';
    }

    var pointLength = this.incentiveData['labels'].length;
    if (pointLength >= 45) {
      this.incentivesLineChart.options['pointSize'] = 0;
    } else {
      this.incentivesLineChart.options['pointSize'] = 0;
    }

    this.incentivesLineChart.dataTable = incLineData;
    this.incentivesLineChart.component?.draw(this.incentivesLineChart);
    setTimeout(() => {
      this.chartLoaderSecondary = true;
    }, 400);

    this.combinedLoader(1);
  }

  selectedStore: any;
  previousSelectedStore: any;

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.darkModeSub.unsubscribe();
  }

  getCardInformationsDate() {
    this.loading = true;
    var currentTime = new Date();
    currentTime.setHours(0, 0, 0);
    var dateRange = {
      sDataRange:
        currentTime.toISOString() +
        '@' +
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      target: window.localStorage.getItem('selectedTarget'),
    };

    this.getCardInformations(dateRange);
  }

  getCardInformations(dateRange: any) {
    this.nudgeService.getNumberInfo(dateRange).subscribe((data: any) => {
      this.today = data['today'];
      this.yesterday = data['yesterday'];
      this.month = data['month'];
      this.total = data['total'];
      function formatNumber(num: any) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      }
      if (this.today.number != null) {
        this.today.number = formatNumber(this.today.number);
      }
      if (this.yesterday.number != null) {
        this.yesterday.number = formatNumber(this.yesterday.number);
      }
      if (this.month.number != null) {
        this.month.number = formatNumber(this.month.number);
      }
      if (this.total.number != null) {
        this.total.number = formatNumber(this.total.number);
      }
      this.combinedLoader(1);
      this.chartLoader = true;
    });
  }
  isNegativeNumber(nr: any) {
    if (nr < 0) return true;
    else return false;
  }

  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  getIncentiveList() {
    var selection = window.localStorage.getItem('selectedTarget');
    this.nudgeService
      .getAllIncentivesTarget(selection)
      .subscribe((incentives: any) => {
        this.incentives = incentives;

        // Load Filters
        this.getIncentiveSector();
        this.getIncentiveType();
        this.getRules();
        this.getSite();

        // Load Charts
        this.getChartInfo();
      });
  }
  getIncentiveType() {
    this.incentiveTypeList = [];
    for (const i of this.incentives) {
      if (i.incentiveType) {
        this.incentiveTypeList.push(i.incentiveType);
      }
    }

    var uniqueTypeList = this.incentiveTypeList.reduce(
      (unique: any, o: any) => {
        if (!unique.some((obj: any) => obj === o)) {
          unique.push(o);
        }
        return unique;
      },
      []
    );

    this.incentiveTypeList = uniqueTypeList;
    this.oldincentiveTypeList = this.incentiveTypeList;
    this.incentiveTypeList.push('Show All');
  }
  getIncentiveSector() {
    for (const i of this.incentives) {
      if (i.sector) {
        this.incentiveSectorList.push(i.sector);
      }
    }

    var uniqueSectorList = this.incentiveSectorList.reduce(
      (unique: any, o: any) => {
        if (!unique.some((obj: any) => obj === o)) {
          unique.push(o);
        }
        return unique;
      },
      []
    );

    this.incentiveSectorList = uniqueSectorList;
    this.incentiveSectorList.push('Show All');
  }
  getRules() {
    var selection = window.localStorage.getItem('selectedTarget');
    this.nudgeService
      .getCustomerRulesTarget(selection)
      .subscribe((rules: any) => {
        this.rules = rules;

        this.filterRules();
      });
  }

  filterRules() {
    this.currentRuleList = [];
    for (const i of this.rules) {
      this.currentRuleList.push({
        siteID: i.siteID,
        boxID: i.boxID,
        ruleID: i.ruleID,
        name: `${i.siteID}.${i.boxID}.${i.ruleID}`,
        online: i.online,
      });
    }
    var uniqueEntriesCurr = this.currentRuleList.reduce(
      (unique: any, o: any) => {
        if (!unique.some((obj: any) => obj.name === o.name)) {
          unique.push(o);
        }
        return unique;
      },
      []
    );
    uniqueEntriesCurr.push({
      siteID: '',
      boxID: '',
      ruleID: '',
      name: 'Show All',
      online: '',
    });
    this.currentRuleList = uniqueEntriesCurr;
    this.currentRuleList = this.currentRuleList.sort((a: any, b: any) =>
      a.name.localeCompare(b.name, undefined, { numeric: true })
    );
    this.currentRuleList = this.currentRuleList.sort((x: any, y: any) => {
      return Number(y.online) - Number(x.online);
    });
  }

  getSite() {
    this.currentPageList = [];
    for (const i of this.incentives) {
      if (i.siteID) {
        this.currentPageList.push(i.siteID);
      }
    }

    const uniqueSiteSet = new Set(this.currentPageList);
    const uniqueSiteList = Array.from(uniqueSiteSet);
    this.currentPageList = uniqueSiteList;
    this.currentPageList.sort(function (a: any, b: any) {
      return a - b;
    });
    this.currentPageList.push('Show All');
  }
  getChartInfo() {
    var selDevice: any;
    var selSector: any;
    var selPage: any;
    var selType: any;
    var selRule: any;
    if (this.incentiveBarChart.selectedRangeValue == 'today') {
      var stodaytime = new Date();
      stodaytime.setHours(0, 0, 0);
    }

    if (this.incentiveBarChart.selectedIncentiveSector == 'Show All') {
      selSector = '';
    } else {
      selSector = this.incentiveBarChart.selectedIncentiveSector;
    }

    if (this.selectedPlatform == 'overall') {
      selDevice = '';
    } else {
      selDevice = this.selectedPlatform;
    }

    if (this.incentiveBarChart.selectedPageId == 'Show All') {
      selPage = '';
    } else {
      selPage = this.incentiveBarChart.selectedPageId;
    }

    if (this.incentiveBarChart.selectedType == 'Nudge Types') {
      selType = this.incentiveTypeList;
      selType = selType.filter((e: any) => e !== 'Show All');
      selType.forEach(function (element: any, index: any) {
        if (element.includes('+')) {
          element = element.replace('+', '%2B');
        }
        selType[index] = element;
      });
      selRule = '';
    } else {
      if (this.incentiveBarChart.selectedRuleId == 'Show All') {
        selRule = this.currentRuleList;
        selRule = selRule.filter((e: any) => e.name !== 'Show All');
        selRule.forEach(function (element: any, index: any) {
          selRule[index] = element.name;
        });
        selType = '';
      } else {
        selRule = this.incentiveBarChart.selectedRuleId;
        selType = '';
      }
    }

    var dateRange = {
      startDate: this.startDate,
      endDate: this.endDate,
      incentiveSector: selSector,
      device: selDevice,
      siteID: selPage,
      incentiveTypes: selType,
      ruleIDs: selRule,
      target: window.localStorage.getItem('selectedTarget'),
    };
    this.getChartFreq(dateRange);
  }

  getChartFreq(dateRange: any) {
    this.freqLoaded = false;
    this.nudgeService.getFreqBarChart(dateRange).subscribe((info: any) => {
      this.receivedInfoFreq = info;

      this.frequenciesChartCr = Object.keys(info)
        .map((key: any) => ({
          name: key,
          value: info[key],
          height: 0,
        }))
        .sort((a, b) => b.value - a.value);

      this.frequenciesChartCrAxis = this.generateEvenPoints(
        this.frequenciesChartCr,
        5
      ).reverse();

      this.frequenciesChartCr = this.setProportionalHeights(
        this.frequenciesChartCr,
        90
      );

      this.frequenciesChartCr = this.frequenciesChartCr.slice(0, 12);

      //this.freqLoaded = true;
      setTimeout(() => {
        this.chartLoaderThird = true;
      }, 400);
      this.combinedLoader(1);
    });
  }

  checkSectorFilter() {
    this.chartLoaderThird = false;
    if (this.incentiveBarChart.selectedIncentiveSector !== 'Show All') {
      this.incentiveBarChart.selectedPageId = 'Show All';
      this.incentiveBarChart.selectedRuleId = 'Show All';
      this.filteredIncentives = [];
      for (const i of this.incentives) {
        if (i.sector == this.incentiveBarChart.selectedIncentiveSector) {
          this.filteredIncentives.push(i);
        }
      }
      //update site id
      this.currentPageList = [];
      for (const i of this.filteredIncentives) {
        this.currentPageList.push(i.siteID);
      }
      const uniqueSet = new Set(this.currentPageList);
      const uniqueValue = Array.from(uniqueSet);
      this.currentPageList = uniqueValue;
      this.currentPageList.sort(function (a: any, b: any) {
        return a - b;
      });
      this.currentPageList.push('Show All');

      //update rules
      this.currentRuleList = [];
      for (const i of this.filteredIncentives) {
        this.currentRuleList.push({
          siteID: i.siteID,
          boxID: i.boxID,
          ruleID: i.ruleID,
          name: `${i.siteID}.${i.boxID}.${i.ruleID}`,
        });
      }
      var uniqueRuleEntries = this.currentRuleList.reduce(
        (unique: any, o: any) => {
          if (!unique.some((obj: any) => obj.name === o.name)) {
            unique.push(o);
          }
          return unique;
        },
        []
      );
      this.currentRuleList = uniqueRuleEntries;
      this.currentRuleList = this.currentRuleList.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, { numeric: true })
      );
      this.currentRuleList.push({
        siteID: '',
        boxID: '',
        ruleID: '',
        name: 'Show All',
      });

      //update types
      this.filteredIncentives = this.filteredIncentives.filter(
        (obj) => obj.sector == this.incentiveBarChart.selectedIncentiveSector
      );
      // for each filtered incentive, get type
      this.incentiveTypeList = [];
      for (const i of this.filteredIncentives) {
        if (i.incentiveType) {
          this.incentiveTypeList.push(i.incentiveType);
        }
      }
      var uniqueEntries = this.incentiveTypeList.reduce(
        (unique: any, o: any) => {
          if (!unique.some((obj: any) => obj === o)) {
            unique.push(o);
          }
          return unique;
        },
        []
      );
      this.incentiveTypeList = uniqueEntries;
      this.incentiveTypeList.push('Show All');
      this.getChartInfo();
    } else {
      this.incentiveBarChart.selectedPageId = 'Show All';
      this.incentiveBarChart.selectedRuleId = 'Show All';
      this.getSite();
      this.getIncentiveType();
      this.filterRules();
      this.getChartInfo();
    }
  }

  checkPageFilter() {
    this.chartLoaderThird = false;
    //new
    this.incentiveBarChart.selectedRuleId = 'Show All';
    if (this.incentiveBarChart.selectedPageId !== 'Show All') {
      // put rule and type to "show all"
      // checks sector and based on sector & page id, updates rules and types
      if (this.incentiveBarChart.selectedIncentiveSector !== 'Show All') {
        // get filtered incentives by sector
        this.filteredIncentives = [];
        for (const i of this.incentives) {
          if (i.sector == this.incentiveBarChart.selectedIncentiveSector) {
            this.filteredIncentives.push(i);
          }
        }
        // filter them by page id
        this.filteredIncentives = this.filteredIncentives.filter(
          (obj) => obj.siteID == this.incentiveBarChart.selectedPageId
        );

        // get available rules from filtered incentives
        this.currentRuleList = [];
        for (const i of this.filteredIncentives) {
          this.currentRuleList.push({
            siteID: i.siteID,
            boxID: i.boxID,
            ruleID: i.ruleID,
            name: `${i.siteID}.${i.boxID}.${i.ruleID}`,
          });
        }
        var uniqueRuleEntries = this.currentRuleList.reduce(
          (unique: any, o: any) => {
            if (!unique.some((obj: any) => obj.name === o.name)) {
              unique.push(o);
            }
            return unique;
          },
          []
        );
        this.currentRuleList = uniqueRuleEntries;
        this.currentRuleList = this.currentRuleList.sort((a: any, b: any) =>
          a.name.localeCompare(b.name, undefined, { numeric: true })
        );
        this.currentRuleList.push({
          siteID: '',
          boxID: '',
          ruleID: '',
          name: 'Show All',
        });

        // get types based on the list
        this.incentiveTypeList = [];
        for (const i of this.filteredIncentives) {
          if (i.incentiveType) {
            this.incentiveTypeList.push(i.incentiveType);
          }
        }
        var uniqueTypeEntries = this.incentiveTypeList.reduce(
          (unique: any, o: any) => {
            if (!unique.some((obj: any) => obj === o)) {
              unique.push(o);
            }
            return unique;
          },
          []
        );
        // update type list
        this.incentiveTypeList = uniqueTypeEntries;
        this.incentiveTypeList.push('Show All');
      } else {
        // updated rules based on page id only because sector is show all
        this.filteredIncentives = [];
        for (const i of this.incentives) {
          if (i.siteID == this.incentiveBarChart.selectedPageId) {
            this.filteredIncentives.push(i);
          }
        }
        this.currentRuleList = [];
        for (const i of this.filteredIncentives) {
          this.currentRuleList.push({
            siteID: i.siteID,
            boxID: i.boxID,
            ruleID: i.ruleID,
            name: `${i.siteID}.${i.boxID}.${i.ruleID}`,
          });
        }
        var uniqueRuleEntries = this.currentRuleList.reduce(
          (unique: any, o: any) => {
            if (!unique.some((obj: any) => obj.name === o.name)) {
              unique.push(o);
            }
            return unique;
          },
          []
        );
        this.currentRuleList = uniqueRuleEntries;
        this.currentRuleList = this.currentRuleList.sort((a: any, b: any) =>
          a.name.localeCompare(b.name, undefined, { numeric: true })
        );
        this.currentRuleList.push({
          siteID: '',
          boxID: '',
          ruleID: '',
          name: 'Show All',
        });

        //update types based on page id only
        this.incentiveTypeList = [];
        for (const i of this.filteredIncentives) {
          if (i.incentiveType) {
            this.incentiveTypeList.push(i.incentiveType);
          }
        }
        var uniqueTypeEntries = this.incentiveTypeList.reduce(
          (unique: any, o: any) => {
            if (!unique.some((obj: any) => obj === o)) {
              unique.push(o);
            }
            return unique;
          },
          []
        );
        // update type list
        this.incentiveTypeList = uniqueTypeEntries;
        this.incentiveTypeList.push('Show All');
      }
    } else {
      if (this.incentiveBarChart.selectedIncentiveSector !== 'Show All') {
        //if sector is not show all, then update rules based on sector and not id
        this.filteredIncentives = [];
        for (const i of this.incentives) {
          if (i.sector == this.incentiveBarChart.selectedIncentiveSector) {
            this.filteredIncentives.push(i);
          }
        }
        this.currentRuleList = [];
        for (const i of this.filteredIncentives) {
          this.currentRuleList.push({
            siteID: i.siteID,
            boxID: i.boxID,
            ruleID: i.ruleID,
            name: `${i.siteID}.${i.boxID}.${i.ruleID}`,
          });
        }
        var uniqueRuleEntries = this.currentRuleList.reduce(
          (unique: any, o: any) => {
            if (!unique.some((obj: any) => obj.name === o.name)) {
              unique.push(o);
            }
            return unique;
          },
          []
        );
        this.currentRuleList = uniqueRuleEntries;
        this.currentRuleList = this.currentRuleList.sort((a: any, b: any) =>
          a.name.localeCompare(b.name, undefined, { numeric: true })
        );
        this.currentRuleList.push({
          siteID: '',
          boxID: '',
          ruleID: '',
          name: 'Show All',
        });
        // update types here
        this.incentiveTypeList = [];
        for (const i of this.filteredIncentives) {
          if (i.incentiveType) {
            this.incentiveTypeList.push(i.incentiveType);
          }
        }
        var uniqueTypeEntries = this.incentiveTypeList.reduce(
          (unique: any, o: any) => {
            if (!unique.some((obj: any) => obj === o)) {
              unique.push(o);
            }
            return unique;
          },
          []
        );
        // update type list
        this.incentiveTypeList = uniqueTypeEntries;
        this.incentiveTypeList.push('Show All');
      } else {
        this.filterRules();
        this.getIncentiveType();
      }
    }
    this.getChartInfo();
  }

  checkFreqType() {
    this.chartLoaderThird = false;
    this.getChartInfo();
  }

  checkRuleFilter() {
    this.chartLoaderThird = false;
    if (this.incentiveBarChart.selectedRuleId !== 'Show All') {
      var selectedRuleBoxID = this.currentRuleList.filter(
        (obj: any) => obj.name == this.incentiveBarChart.selectedRuleId
      );
      this.incentiveTypeList = [];

      //check if sector is show all or not and filter the types
      if (this.incentiveBarChart.selectedIncentiveSector !== 'Show All') {
        this.filteredIncentives = [];
        for (const i of this.incentives) {
          if (i.sector == this.incentiveBarChart.selectedIncentiveSector) {
            this.filteredIncentives.push(i);
          }
        }
        if (this.incentiveBarChart.selectedPageId !== 'Show All') {
          this.filteredIncentives = this.filteredIncentives.filter(
            (obj) => obj.siteID == this.incentiveBarChart.selectedPageId
          );
        }
        this.filteredIncentives = this.filteredIncentives.filter(
          (obj: any) =>
            obj.boxID == selectedRuleBoxID[0].boxID &&
            obj.ruleID == selectedRuleBoxID[0].ruleID
        );
      } else {
        this.filteredIncentives = this.incentives;
        if (this.incentiveBarChart.selectedPageId !== 'Show All') {
          this.filteredIncentives = this.filteredIncentives.filter(
            (obj: any) => obj.siteID == this.incentiveBarChart.selectedPageId
          );
        }
        this.filteredIncentives = this.filteredIncentives.filter(
          (obj: any) =>
            obj.siteID == selectedRuleBoxID[0].siteID &&
            obj.boxID == selectedRuleBoxID[0].boxID &&
            obj.ruleID == selectedRuleBoxID[0].ruleID
        );
      }
      // for each filtered incentive, get type
      for (const i of this.filteredIncentives) {
        if (i.incentiveType) {
          this.incentiveTypeList.push(i.incentiveType);
        }
      }
      var uniqueEntries = this.incentiveTypeList.reduce(
        (unique: any, o: any) => {
          if (!unique.some((obj: any) => obj === o)) {
            unique.push(o);
          }
          return unique;
        },
        []
      );
      this.incentiveTypeList = uniqueEntries;
      this.incentiveTypeList.push('Show All');
    } else {
      // check if sector is not show all
      if (this.incentiveBarChart.selectedIncentiveSector !== 'Show All') {
        this.filteredIncentives = [];
        for (const i of this.incentives) {
          if (i.sector == this.incentiveBarChart.selectedIncentiveSector) {
            this.filteredIncentives.push(i);
          }
        }
        if (this.incentiveBarChart.selectedPageId !== 'Show All') {
          this.filteredIncentives = this.filteredIncentives.filter(
            (obj) => obj.siteID == this.incentiveBarChart.selectedPageId
          );
        }
      } else {
        this.filteredIncentives = this.incentives;
        if (this.incentiveBarChart.selectedPageId !== 'Show All') {
          this.filteredIncentives = this.filteredIncentives.filter(
            (obj) => obj.siteID == this.incentiveBarChart.selectedPageId
          );
        }
      }
      // update types
      this.incentiveTypeList = [];
      for (const i of this.filteredIncentives) {
        if (i.incentiveType) {
          this.incentiveTypeList.push(i.incentiveType);
        }
      }
      var uniqueEntries = this.incentiveTypeList.reduce(
        (unique: any, o: any) => {
          if (!unique.some((obj: any) => obj === o)) {
            unique.push(o);
          }
          return unique;
        },
        []
      );
      this.incentiveTypeList = uniqueEntries;
      this.incentiveTypeList.push('Show All');
    }
    this.chartLoaderThird = false;
    this.getChartInfo();
  }

  getStartDateFreq(event: any) {
    this.incentiveBarChart.getStartDate(event);
    this.chartLoaderThird = false;
    this.getChartInfo();
  }
  getEndDateFreq(event: any) {
    this.incentiveBarChart.getEndDate(event);
    this.chartLoaderThird = false;
    this.getChartInfo();
  }
  rangeChangedFreq(event: any) {
    this.incentiveBarChart.onRangeChange(event);
    this.chartLoaderThird = false;
    this.getChartInfo();
  }

  updateBarsFreq() {
    this.chartDetailedFreq.options['colors'] = this.linechartsColors;
    function formatZeroValues(num: any) {
      if (
        num == 3.333 ||
        num == 2.222 ||
        num == 0.888 ||
        num == 0.666 ||
        num == 0.333 ||
        num == 0.111
      ) {
        return 0;
      } else {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      }
    }
    this.chartFreqData = [];

    Object.keys(this.receivedInfoFreq).forEach((key) => {
      if (this.receivedInfoFreq[key] == null) {
        this.receivedInfoFreq[key] = 0;
      }
    });

    var tmpData: any = [];

    Object.keys(this.receivedInfoFreq).forEach((key) => {
      if (this.receivedInfoFreq[key] == null) {
        this.receivedInfoFreq[key] = 0;
      }
      tmpData.push(this.receivedInfoFreq[key]);
    });

    this.chartFreqData = tmpData;

    tmpData.sort((a: any, b: any) => a - b);
    tmpData = tmpData.filter((val: any) => val > 0);
    if (tmpData[0] == null) {
      var secondMin = 0;
    } else {
      var secondMin: number = tmpData[0];
    }

    var zeroValueHeight: any;
    if (secondMin >= 100) {
      zeroValueHeight = 3.333;
    } else if (secondMin < 100 && secondMin > 40) {
      zeroValueHeight = 2.222;
    } else if (secondMin <= 40 && secondMin >= 5) {
      zeroValueHeight = 0.888;
    } else if (secondMin <= 5 && secondMin >= 3.7) {
      zeroValueHeight = 0.666;
    } else if (secondMin <= 3.7 && secondMin != 0) {
      zeroValueHeight = 0.333;
    } else if (secondMin == 0) {
      zeroValueHeight = 0.111;
    }
    Object.keys(this.receivedInfoFreq).forEach((key) => {
      if (this.receivedInfoFreq[key] == 0) {
        this.receivedInfoFreq[key] = zeroValueHeight;
      }
    });

    if (this.receivedInfoFreq) {
      var dataValues = [
        [
          'Type',
          'Value',
          { role: 'style' },
          { role: 'tooltip', p: { html: true } },
        ],
      ];
      Object.keys(this.receivedInfoFreq).forEach((key) => {
        dataValues.push([
          key,
          this.receivedInfoFreq[key],
          '#42a2d7',
          "<p class='tooltip-text'><b>" +
            key +
            '</b>: ' +
            formatZeroValues(this.receivedInfoFreq[key]),
        ]);
      });

      dataValues.sort((a, b) => (a[1] < b[1] ? 1 : b[1] < a[1] ? -1 : 0));
      var i;
      for (i = 1; i < dataValues.length; i++) {
        dataValues[i][2] = this.linechartsColors[0];
      }

      this.chartDetailedFreq.dataTable = dataValues;
    }
    this.freqLoaded = true;
    this.updateOptionsFreq();
  }

  updateOptionsFreq() {
    var barLength = Object.keys(this.receivedInfoFreq).length;
    if (barLength < 3) {
      this.chartDetailedFreq.options['bar'].groupWidth = '50%';
      this.chartDetailedFreq.options['hAxis'].slantedText = false;
      this.chartDetailedFreq.options['hAxis'].viewWindow.min = 0;
      this.chartDetailedFreq.options['hAxis'].viewWindow.max = 6;
    } else if (barLength >= 3 && barLength <= 6) {
      this.chartDetailedFreq.options['bar'].groupWidth = '50%';
      this.chartDetailedFreq.options['hAxis'].viewWindow.min = 0;
      this.chartDetailedFreq.options['hAxis'].viewWindow.max = 10;
      this.chartDetailedFreq.options['hAxis'].slantedText = true;
    } else if (barLength >= 6 && barLength <= 10) {
      this.chartDetailedFreq.options['bar'].groupWidth = '60%';
      this.chartDetailedFreq.options['hAxis'].viewWindow.min = 0;
      this.chartDetailedFreq.options['hAxis'].viewWindow.max = 13;
      this.chartDetailedFreq.options['hAxis'].slantedText = true;
    } else if (barLength >= 10 && barLength <= 17) {
      this.chartDetailedFreq.options['bar'].groupWidth = '75%';
      this.chartDetailedFreq.options['hAxis'].viewWindow.min = 0;
      this.chartDetailedFreq.options['hAxis'].viewWindow.max = '';
      this.chartDetailedFreq.options['hAxis'].slantedText = true;
    } else {
      this.chartDetailedFreq.options['bar'].groupWidth = '80%';
      this.chartDetailedFreq.options['chartArea'].width = '90%';
      this.chartDetailedFreq.options['hAxis'].slantedText = true;
      this.chartDetailedFreq.options['hAxis'].viewWindow.min = 0;
      this.chartDetailedFreq.options['hAxis'].viewWindow.max = '';
    }
    if (Math.max(...this.chartFreqData) == 0) {
      this.chartDetailedFreq.options['vAxes'][0].viewWindow.max = 3;
    } else {
      this.chartDetailedFreq.options['vAxes'][0].viewWindow.max = '';
    }

    this.chartDetailedFreq.component?.draw(this.chartDetailedFreq);
    setTimeout(() => {
      this.chartLoaderThird = true;
    }, 400);

    this.combinedLoader(1);
  }

  combinedLoader(num: any) {
    this.loadNrIndicator = this.loadNrIndicator + num;
    if (this.loadNrIndicator == 3) {
      setTimeout(() => {
        this.firstLoader = true;
      }, 400);
    }
  }

  public chartDetailedFreq: GoogleChartInterface = {
    chartType: 'ColumnChart',
    dataTable: [],
    options: {
      //focusTarget: 'category',
      forceIFrame: false,
      isStacked: true,
      bar: { groupWidth: '85%' },
      colors: this.linechartsColors,
      legend: 'none',
      height: 400,
      vAxis: {
        minValue: 0,
        baseline: 0,
        gridlineColor: '#ededed',
        baselineColor: '#c0c0c0',
        textStyle: {
          fontSize: 13,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
      },
      vAxes: {
        0: {
          baseline: 0,
          viewWindow: {
            min: 0,
          },
        },
      },
      tooltip: {
        textStyle: {
          fontSize: 12,
        },
        isHtml: true,
      },
      hAxis: {
        slantedText: true,
        slantedTextAngle: 55,
        textStyle: {
          fontSize: 12,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        viewWindow: {
          min: 0,
        },
      },
      chartArea: {
        height: '60%',
        top: 20,
        left: 60,
        width: '90%',
      },
    },
  };

  public incentivesLineChart: GoogleChartInterface = {
    chartType: 'LineChart',
    dataTable: [],
    options: {
      legend: {
        position: 'top',
        maxLines: 2,
        alignment: 'center',
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
      },
      pointSize: 0,
      crosshair: {
        trigger: 'focus',
        orientation: 'vertical',
        color: '#eeeeee',
      },
      series: {
        0: {
          targetAxisIndex: 0,
          areaOpacity: 0.1,
          color: this.linechartsColors[0],
          type: 'area',
        },
      },
      forceIFrame: false,
      focusTarget: 'category',
      colors: this.linechartsColors,
      height: 200,
      vAxis: {
        minValue: 0,
        baseline: 0,
        viewWindow: {
          min: 0,
        },
        baselineColor: '#c0c0c0',
        gridlineColor: '#ededed',
        textStyle: {
          fontSize: 13,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
      },
      tooltip: {
        textStyle: {
          fontSize: 12,
        },
        isHtml: true,
      },
      hAxis: {
        textStyle: {
          fontSize: 11,
          fontName: 'Roboto, sans-serif',
          color: '#666',
        },
        viewWindow: {
          min: 0,
        },
      },
      chartArea: {
        height: '90%',
        top: 20,
        left: 60,
        right: 20,
        bottom: 55,
        width: '90%',
      },
    },
  };
  onResize(event: any) {
    // Rebuild charts so they are responsive
    this.chartDetailedFreq.component?.draw(this.chartDetailedFreq);
    this.incentivesLineChart.component?.draw(this.incentivesLineChart);
  }
}
