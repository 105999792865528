import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsService {
  constructor(private http: HttpClient) {}

  getUsers(target: any): Observable<any> {
    var params = '?target=' + target;
    return this.http.get('/getUsers' + params);
  }

  getRoles(): Observable<any> {
    return this.http.get('/getUserRoles');
  }

  resendEmail(email: any): Observable<any> {
    return this.http.post('/resendEmail', {
      email: email,
    });
  }

  getRoleList(): Observable<any> {
    return this.http.get('/privileges/rolePrivileges');
  }

  getCurrentUserRoles(email: any): Observable<any> {
    return this.http.get('/privileges/userPrivileges/user?email=' + email);
  }

  addNewRolePrivileges(data: any): Observable<any> {
    return this.http.post('/privileges/rolePrivileges', data);
  }

  editUserRolePrivileges(data: any): Observable<any> {
    return this.http.put('/privileges/userPrivileges/user', {
      email: data.email,
      privileges: data.privileges,
      shopID: data.shopID,
    });
  }

  clientUpdateUser(data: any): Observable<any> {
    return this.http.post('/client-updateUser', data);
  }

  updateAllUsersPrivileges(data: any): Observable<any> {
    return this.http.put('/privileges/userPrivileges/multiple', data);
  }

  deleteRolePrivilege(data: any): Observable<any> {
    return this.http.delete('/privileges/rolePrivileges/' + data);
  }

  updateBugTrackingStatus(data: any): Observable<any> {
    return this.http.put('/privileges/bugSubscription/user', data);
  }

  updateBugTrackingStatusAll(data: any): Observable<any> {
    return this.http.put('/privileges/bugSubscription/users', data);
  }

  createUser(data: any): Observable<any> {
    if (data.mode == 'instant') {
      return this.http.post('/createUser?instant=true', {
        username: data.name,
        name: data.name,
        id: data.target,
        email: data.email,
        password: data.password,
        shopID: data.target,
        role: data.role,
        authProvider: data.authProvider,
      });
    } else {
      return this.http.post('/createUser', {
        username: data.name,
        name: data.name,
        id: data.target,
        email: data.email,
        shopID: data.target,
        role: data.role,
        authProvider: data.authProvider,
      });
    }
  }

  inviteUser(data: any): Observable<any> {
    return this.http.post('/client-createUser', data);
  }

  editUser(data: any): Observable<any> {
    return this.http.post('/updateUser', {
      email: data.email,
      name: data.name,
      oldEmail: data.oldEmail,
      role: data.role,
      shopID: data.shopID,
      authProvider: data.authProvider,
    });
  }

  deleteUser(data: any): Observable<any> {
    return this.http.post('/deleteUser', data);
  }

  getProductRetrivalConfig(): Observable<any> {
    return this.http.get('/productRetrievalConfig');
  }

  returnDataRetrivalConfig(): Observable<any> {
    return this.http.get('/returnDataRetrievalConfig');
  }

  editProductRetrivalConfig(data: any): Observable<any> {
    return this.http.put('/productRetrievalConfig', data);
  }

  editReturnRetrivalConfig(data: any): Observable<any> {
    return this.http.put('/returnDataRetrievalConfig', data);
  }

  createNewClient(data: any): Observable<any> {
    return this.http.post('/createNewClient', data);
  }

  getSettings(target: any) {
    var params = '?target=' + target;
    return this.http.get<any>('/settings' + params);
  }

  updateSettings(data: any, oldData: any, target: any) {
    var obj = {
      newObject: {
        value: data['value'],
        name: data['name'],
        description: data['description'],
        live: data['live'],
        currency: data['currency'],
      },
      oldObject: {
        value: oldData['value'],
        name: oldData['name'],
        description: oldData['description'],
        live: oldData['live'],
        currency: oldData['currency'],
      },
    };
    return this.http.post('/updateSettings?target=' + target, obj);
  }

  getGoLiveObject(data: any): Observable<any> {
    return this.http.post('/get_target_connections', data);
  }
  setGoLiveProcess(data: any): Observable<any> {
    return this.http.post('/golive_process', data);
  }
}
