<div class="recorder_modal user-action_modal">
  <form [formGroup]="form" novalidate>
    <p class="recorder_modal_title">
      {{
        isNew
          ? ("words.invite_user" | translate)
          : ("words.edit_user" | translate)
      }}
    </p>
    <label class="custom-input_field_withoutIcon">
      <span class="custom-input_field-label">
        {{ "words.name" | translate }}
      </span>
      <input
        class="custom-input_field"
        matInput
        #name
        formControlName="name"
        type="text"
      />
    </label>
    <label class="custom-input_field_withoutIcon">
      <span class="custom-input_field-label">
        {{ "words.email" | translate }}
      </span>
      <input
        class="custom-input_field"
        matInput
        #email
        formControlName="email"
        type="email"
      />
    </label>
    <label class="custom-input_field_withoutIcon" style="display: none">
      <span class="custom-input_field-label">
        {{ "words.store" | translate }}
      </span>
      <input
        class="custom-input_field"
        matInput
        #email
        formControlName="targets"
        type="text"
      />
    </label>
    <!-- <div
      class="custom_option_picker_with_absolute_label"
      *ngIf="userTargets && userTargets.length > 1"
    >
      <span class="custom_option_picker-label"> Store </span>
      <div class="custom_option_picker">
        <mat-form-field class="full-wid">
          <mat-select
            #targets
            [multiple]="true"
            #multiSelect
            formControlName="targets"
          >
            <mat-option
              *ngFor="let target of userTargets"
              [value]="target?.target"
            >
              {{ target.target }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div> -->
    <div
      class="custom_option_picker_with_absolute_label"
      *ngIf="!productsLoading"
      [class.user_permission_disable_selector]="disable_product_selector"
    >
      <span class="custom_option_picker-label">
        {{ "words.product" | translate }}
      </span>
      <div class="custom_option_picker">
        <mat-form-field class="full-wid">
          <mat-select
            #product
            formControlName="product"
            [multiple]="true"
            [value]="selectedProduct"
          >
            <mat-option *ngFor="let l of productList" [value]="l">
              {{ l }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="productsLoading">
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          height: '42px',
          outline: 'none',
          'margin-bottom': '0px',
          'background-color': '#37474f1a'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div
      class="custom_option_picker_with_absolute_label"
      *ngIf="isOrganicCategorySelected && listOfSeoProjectsRAW?.length > 0"
      [class.user_permission_disable_selector]="disable_seoProjects_selector"
    >
      <span class="custom_option_picker-label">
        {{ "words.organic_projects" | translate }}
      </span>
      <div class="custom_option_picker">
        <mat-form-field class="full-wid">
          <mat-select
            #seoProjects
            formControlName="seoProjects"
            [multiple]="true"
            [value]="selectedSeoProjects"
          >
            <mat-option *ngFor="let l of listOfSeoProjects" [value]="l">
              {{ l }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div
      class="custom_option_picker_with_absolute_label"
      *ngIf="
        (isOrganicCategorySelected &&
          form.get('seoProjects')?.value?.length > 0) ||
        (isOrganicCategorySelected && listOfSeoProjectsRAW?.length == 0)
      "
    >
      <span class="custom_option_picker-label">
        {{ "words.organic_roles" | translate }}
      </span>
      <div class="custom_option_picker">
        <mat-form-field class="full-wid">
          <mat-select #role formControlName="role">
            <mat-option *ngFor="let l of roleList" [value]="l.value">
              {{ l.viewValueTranslated | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="user_charges_info" *ngIf="data?.chargesPerUser">
    <h1>Each additional user costs {{ data?.chargesPerUser }}</h1>
    <p>You will be charged per additional member.</p>
  </div>

  <div class="button_container">
    <!-- <ng-container *ngIf="!isPrivilege"> -->
    <button mat-raised-button (click)="dialogRef.close()">
      {{ "words.cancel" | translate }}
    </button>
    <button
      mat-raised-button
      class="primary"
      (click)="addUser()"
      [disabled]="!isFormValid()"
      *ngIf="this.isNew"
    >
      {{
        !isProcessing
          ? ("words.invite_user" | translate)
          : ("words.processing" | translate) + "..."
      }}
    </button>
    <button
      mat-raised-button
      class="primary"
      (click)="editUser()"
      *ngIf="!this.isNew"
      [disabled]="!isFormValid(true)"
    >
      {{
        !isProcessing
          ? ("words.update" | translate)
          : ("words.updating" | translate) + "..."
      }}
    </button>
    <!-- </ng-container> -->
    <!-- <ng-container *ngIf="isPrivilege">
      <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
      <button type="submit" class="primary" mat-button (click)="editUserPriv()">
        Update
      </button>
    </ng-container> -->
  </div>
</div>
