import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormatter',
  pure: false,
})
export class CurrencyFormatterPipe implements PipeTransform {
  private rightAlignedCurrencies = ['EUR', 'CHF', 'CZK', 'PLN'];

  transform(
    value: number,
    showPlusSign: boolean = false,
    currencyCode: string = localStorage.getItem('currency') || 'USD',
    locale: string = 'en-US'
  ): string {
    if (value == null) value = 0;
    if (typeof value == 'string') value = parseFloat(value);
    if (currencyCode === 'undefined') currencyCode = 'USD';

    // Get currency from service if not provided
    let currency = currencyCode.toLocaleUpperCase();

    let fractionDigits = 0;
    if (
      value < 100 &&
      value > -100 &&
      value !== 0 &&
      value.toFixed(2).split('.')[1] != '00'
    ) {
      fractionDigits = 2;
    }

    const formattedValue = new Intl.NumberFormat(locale, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
      useGrouping: true,
    }).format(value);

    let returnValue = '';

    // Australian dollar
    if (currency === 'AUD') {
      returnValue = `A$ ${formattedValue}`;
    } else if (currency === 'CAD') {
      returnValue = `CA$ ${formattedValue}`;
    } else if (this.rightAlignedCurrencies.includes(currency)) {
      // Check if the currency should be on the right side
      returnValue = `${formattedValue} ${this.getSymbol(currency)}`;
    } else {
      returnValue = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: fractionDigits,
        useGrouping: true,
      }).format(value);
    }

    if (showPlusSign && value > 0) {
      returnValue = '+ ' + returnValue;
    }
    if (returnValue?.includes('-')) {
      returnValue = returnValue.replace('-', '- ');
    }

    return returnValue;
  }

  private getSymbol(currencyCode: string): string {
    const currencySymbols: { [key: string]: string } = {
      EUR: '€',
      CHF: 'CHF',
      CZK: 'Kč',
      PLN: 'zł',
    };

    return currencySymbols[currencyCode] || currencyCode;
  }
}
