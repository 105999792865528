import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private _http: HttpClient) {}

  private allKeywordData = new BehaviorSubject<string | null>(null); // Initialize with null or default value
  allValue$ = this.allKeywordData.asObservable();

  setAllValue(value: string) {
    this.allKeywordData.next(value);
  }

  private keywordData = new BehaviorSubject<string | null>(null); // Initialize with null or default value
  value$ = this.keywordData.asObservable();

  setValue(value: string) {
    this.keywordData.next(value);
  }

  // selectedKeyword: any
  public selectedKeyword = new BehaviorSubject<string | null>(null);
  selectedValue$ = this.selectedKeyword.asObservable();

  updateSelectedObject(newObject: any) {
    this.selectedKeyword.next(newObject);
  }

  onboardNewTarget(data: any): Observable<any> {
    return this._http.post(`/seo/onboard/new-target`, data);
  }
  addCompetitors(data: any): Observable<any> {
    return this._http.post(`/seo/onboard/add-competitors`, data);
  }
  backlinksExtractSummary(url: string): Observable<any> {
    return this._http.post(`/seo/backlinks/get-summary`, { url: url });
  }
  rawBacklinksExtract(url: string): Observable<any> {
    return this._http.post(`/seo/backlinks/get-raw-backlinks`, { url: url });
  }
  extractPages(url: string): Observable<any> {
    return this._http.post(`/seo/pages/extract-pages`, { url: url });
  }
  getPages(data: any): Observable<any> {
    return this._http.post(`/seo/pages/get-pages`, data);
  }
  getPagesMonth(data: any): Observable<any> {
    return this._http.post(`/seo/content/get-pages-per-month`, data);
  }
  getSummaryVariables(data: any): Observable<any> {
    return this._http.post(`/seo/content/get-content-summary-variables`, data);
  }
  getNewContentPage(data: any): Observable<any> {
    return this._http.post(`/seo/content/new-content-page`, data);
  }
  removeKeywordFromDraft(data: any): Observable<any> {
    return this._http.post(`/seo/content/remove-keyword-from-draft`, data);
  }
  addKeywordFromDraft(data: any): Observable<any> {
    return this._http.post(`/seo/content/add-keyword-from-draft`, data);
  }
  getProjects(): Observable<any> {
    return this._http.get(`/seo/general/get-projects`);
  }
  selectedTargetCompetitor(data: any): Observable<any> {
    return this._http.post(`/seo/onboard/select-target-competitor`, data);
  }
  getSelectedKeywords(data: any): Observable<any> {
    return this._http.post(`/seo/keywords/select-keywords`, data);
  }

  extractOnPageFactors(data: any): Observable<any> {
    return this._http.post(`/seo/factors/extract-onpage`, data);
  }
  getOnPageFactors(data: any): Observable<any> {
    return this._http.post(`/seo/factors/get-onpage`, data);
  }
  extractTechnicalFactors(data: any): Observable<any> {
    return this._http.post(`/seo/factors/extract-technical`, data);
  }
  getTechnicalFactors(data: any): Observable<any> {
    return this._http.post(`/seo/factors/get-technical`, data);
  }
  extractBacklinkFactors(data: any): Observable<any> {
    return this._http.post(`/seo/backlinks/extract-backlink-factors`, data);
  }
  getBacklinkFactors(data: any): Observable<any> {
    return this._http.post(`/seo/backlinks/get-backlink-factors`, data);
  }
  extractSocialFactors(data: any): Observable<any> {
    return this._http.post(`/seo/factors/extract-social`, data);
  }
  getSocialFactors(data: any): Observable<any> {
    return this._http.post(`/seo/factors/get-social`, data);
  }
  keywordAnalyzerAnalyzeKeyword(data: any): Observable<any> {
    return this._http.post(`/seo/keywordanalyzer/analyze-keyword`, data);
  }
  getKeywords(obj: any): Observable<any> {
    let tmpObj = JSON.parse(sessionStorage.getItem('selectedProject') || '{}');

    return this._http.post(`/seo/keywords/get-analyzed-keywords`, {
      ...obj,
      country: tmpObj?.country,
    });
  }
  getAnalyse(obj: any) {
    return this._http.post(`/seo/keywords/get-analyse`, obj);
  }
  getOrganicKeywords(data: any): Observable<any> {
    return this._http.post(`/seo/keywords/get-related-keywords`, data);
  }
  extractRelatedKeywords(data: any): Observable<any> {
    return this._http.post(`/seo/keywords/extract-related-keywords`, data);
  }
  getUrlAnalyses(data: any): Observable<any> {
    return this._http.post(`/seo/analyses/get-url-analyse`, data);
  }
  getTechnicals(data: any): Observable<any> {
    return this._http.post(`/seo/analyses/get-technical`, data);
  }
  getOnPage(data: any): Observable<any> {
    return this._http.post(`/seo/analyses/get-onpage`, data);
  }
  getSocial(data: any): Observable<any> {
    return this._http.post(`/seo/analyses/get-social`, data);
  }
  getBacklinksList(data: any): Observable<any> {
    return this._http.post(`/seo/backlinks/get-backlinks-list`, data);
  }
  getAnchors(data: any): Observable<any> {
    return this._http.post(`/seo/backlinks/get-anchors-list`, data);
  }
  getReferringDomains(data: any): Observable<any> {
    return this._http.post(`/seo/backlinks/get-referring-domains`, data);
  }
  getBacklinksAIProfile(data: any): Observable<any> {
    return this._http.post(`/seo/backlinks/ai-profile`, data);
  }
  getBacklinkInformation(data: any): Observable<any> {
    return this._http.post(`/seo/backlinks/get-backlink-information`, data);
  }
  getBacklinkData(data: any): Observable<any> {
    return this._http.post(`/seo/analyses/get-backlink-data`, data);
  }
  // old endpoint getAnalyseBacklinkValues(data: any, sort: string): Observable<any> {
  //   return this._http.post(
  //     `/seo/backlinks/get-analyse-backlink-values?sort=${sort}`,
  //     data
  //   );
  // }
  getAnalyseBacklinkValues(data: any, sort: string): Observable<any> {
    return this._http.post(
      `/seo/keywords/get-summary-values?sort=${sort}`,
      data
    );
  }
  measureRelevancy(data: any): Observable<any> {
    return this._http.post(`/seo/analyses/measure-relevancy`, data);
  }
  saveKeywordsForAnalyse(data: any): Observable<any> {
    return this._http.post(`/seo/keywords/save-keywords-for-analyse`, data);
  }
  createNewKeywordForAnalyse(data: any): Observable<any> {
    return this._http.post(
      `/seo/keywords/create-new-keyword-for-analyse`,
      data
    );
  }
  onboardDeleteTarget(data: any): Observable<any> {
    return this._http.post(`/seo/onboard/delete-target`, data);
  }
  getProspectingBacklinks(data: any): Observable<any> {
    return this._http.post(`/seo/backlinks/get-prospecting-backlinks`, data);
  }
  getRelatedKeywordsLandingPages(data: any): Observable<any> {
    return this._http.post(
      `/seo/keywords/get-related-keywords-landing-pages`,
      data
    );
  }
  getKeywordsDateRangepositions(data: any): Observable<any> {
    return this._http.post(
      `/seo/keywords/get-keywords-date-range-positions`,
      data
    );
  }
  serpUpdateRanking(data: any): Observable<any> {
    return this._http.post(`/seo/serp/update-ranking`, data);
  }
  deleteAnalyse(data: any): Observable<any> {
    return this._http.post(`/seo/keywords/delete-analyzed-keywords`, data);
  }
  analysesAnalyseInformation(data: any): Observable<any> {
    return this._http.post(`/seo/analyses/analyse-information`, data);
  }
  automationsOptimizationStatusChanger(data: any): Observable<any> {
    return this._http.post(
      `/seo/automations/optimization-status-changer`,
      data
    );
  }
  automationsOnpageHealthChecker(data: any): Observable<any> {
    return this._http.post(`/seo/automations/onpage-health-checker`, data);
  }
  automationsScriptStatus(data: any): Observable<any> {
    return this._http.post(`/seo/automations/script-status`, data);
  }
  automationsUpdateAutomation(data: any): Observable<any> {
    return this._http.post(`/seo/automations/update-automation`, data);
  }
  getHtmlDrafts(data: any): Observable<any> {
    return this._http.post(`/seo/content/get-content-drafts`, data);
  }
  getDraftVersion(data: any): Observable<any> {
    return this._http.post(`/seo/content/get-draft-version`, data);
  }
  getRestoredDraft(data: any): Observable<any> {
    return this._http.post(`/seo/content/restore-draft-version`, data);
  }
  deleteHtmlDraft(id: any): Observable<any> {
    return this._http.post(`/seo/content/delete-draft`, {
      id: id,
    });
  }
  getHtmlData(url: any, domain: any, id: any): Observable<any> {
    return this._http.post(`/seo/content/get-html`, {
      url: url,
      domain: domain,
      id: id,
    });
  }
  updateHtmlData(data: any): Observable<any> {
    return this._http.post(`/seo/content/update-html`, {
      url: data.url,
      html: data.html,
      domain: data.domain,
      title: data.title,
      AI_score: data.AI_score,
      id: data.id,
    });
  }
  measureDraft(data: any): Observable<any> {
    return this._http.post(`/seo/content/measure-draft`, data);
  }
  getKeywordsData(data: any): Observable<any> {
    return this._http.post(`/seo/keywords/get-variables-summary`, data);
  }
  getListOfPages(data: any): Observable<any> {
    return this._http.post(`/seo/content/get-content-pages`, data);
  }
  addToPositionContent(data: any): Observable<any> {
    return this._http.post(
      `/seo/keywords/save-keyword-content-for-analyse`,
      data
    );
  }
  getContentChangeHistory(data: any): Observable<any> {
    return this._http.post(`/seo/content/get-draft-versions`, data);
  }

  getSuggestionsForContent(data: any): Observable<any> {
    return this._http.post(`/seo/content/suggest-relevant-content`, data);
  }

  getStatusOfProject(data: any): Observable<any> {
    return this._http.post(`/seo/analyses/get-progress-of-bulk`, data);
  }

  uploadKeywordsCsv(formData: FormData, domain: string): Observable<any> {
    const params = new HttpParams().set('domain', domain);

    return this._http.post('/seo/keywords/upload-csv', formData, { params });
  }
  chooseMethodOfKeywords(data: any): Observable<any> {
    return this._http.post('/seo/onboard/choose-method-of-keywords', data);
  }
  getKeywordResearch(data: any): Observable<any> {
    return this._http.post(`/seo/keywords/get-keyword-research`, data);
  }
  exportKeywordResearch(data: any): Observable<any> {
    return this._http.post(`/seo/keywords/export-keyword-research`, data);
  }
  backlinksGetSuggestions(data: any): Observable<any> {
    return this._http.post(`/seo/backlinks/get-suggestions`, data);
  }
  backlinksGetFinishedPredictions(data: any): Observable<any> {
    return this._http.post(`/seo/backlinks/get-finished-predictions`, data);
  }
  backlinksStartBacklinkPrediction(data: any): Observable<any> {
    return this._http.post(`/seo/backlinks/start-backlink-prediction`, data);
  }
  backlinksDeleteFinishedPredictions(data: any): Observable<any> {
    return this._http.post(`/seo/backlinks/delete-finished-predictions`, data);
  }
  backlinksGetFinishedAnalyse(data: any): Observable<any> {
    return this._http.post(`/seo/backlinks/get-finished-analyse`, data);
  }
  backlinksExportBacklinkPredictions(data: any): Observable<any> {
    return this._http.post(`/seo/backlinks/export-backlink-predictions`, data);
  }
  backlinksUploadCSV(formData: FormData, domain: string): Observable<any> {
    const params = new HttpParams().set('domain', domain);

    return this._http.post(`/seo/backlinks/backlinks-upload-csv`, formData, {
      params,
    });
  }
  getTasksAllTasks(data: any): Observable<any> {
    return this._http.post(`/seo/tasks/all-tasks`, data);
  }
  getCompetitors(data: any): Observable<any> {
    return this._http.post(`/seo/competitors/get`, data);
  }
  removeCompetitors(data: any): Observable<any> {
    return this._http.post(`/seo/competitors/remove`, data);
  }
  addCompetitorsPage(data: any): Observable<any> {
    return this._http.post(`/seo/competitors/add`, data);
  }
  checkTaskFunction(data: any): Observable<any> {
    return this._http.post(`/seo/tasks/check-task-status`, data);
  }
  getContentScoresBins(data: any): Observable<any> {
    return this._http.post(`/seo/content/get-content-scores-bins`, data);
  }
  getStripeCustomer(data: any): Observable<any> {
    let params = new HttpParams();
    Object.keys(data).forEach((key) => {
      params = params.append(key, data[key]);
    });
    return this._http.get(`/stripe/get-customer`, { params });
  }
  getStripeAllInvoices(data: any): Observable<any> {
    let params = new HttpParams();
    Object.keys(data).forEach((key) => {
      params = params.append(key, data[key]);
    });
    return this._http.get(`/stripe/all-invoices`, { params });
  }
  getStripeCreateSubscription(data: any): Observable<any> {
    return this._http.post(`/stripe/create-subscription`, data);
  }
  getStripeUpdateSubscription(data: any): Observable<any> {
    return this._http.post(`/stripe/update-subscription`, data);
  }
  getStripeCancelSubscription(data: any): Observable<any> {
    return this._http.post(`/stripe/cancel-subscription`, data);
  }
  getStripeMemberships(packageId: any): Observable<any> {
    return this._http.get(`/stripe/memberships?packageId=${packageId}`);
  }
  getStripeUserSubscriptions(): Observable<any> {
    return this._http.get(
      `/stripe/user-subscriptions?target=${localStorage.getItem(
        'selectedTarget'
      )}&email=${localStorage.getItem('email')}`
    );
  }
  addExistingUrl(data: any): Observable<any> {
    if (data.formData) {
      return this._http.post(`/seo/content/add-existing-url`, data.formData);
    } else {
      return this._http.post(`/seo/content/add-existing-url`, data);
    }
  }
}
