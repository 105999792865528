export const darkTheme = true;

export const linechartColors = ['#5469D4', '#A3ACB9'];
export const piechartColors = [
  '#E1FFCA',
  '#CAECFF',
  '#5E4FFF',
  '#C8C3FF',
  '#4DA18C',
  '#0099c6',
  '#dd4477',
  '#66aa00',
  '#b82e2e',
];
export const neutralColors = [
  '#A8A8A8',
  '#A9A9A9',
  '#B0B0B0',
  '#B8B8B8',
  '#BEBEBE',
  '#C0C0C0',
  '#C8C8C8',
  '#D0D0D0',
  '#D3D3D3',
];

// when we use pagetype as a tag this function will return the color of the tag
export const getPagetypeTagsColors = (pagetype: string) => {
  if (pagetype === undefined || pagetype === null || pagetype === '') {
    return {
      page: 'other',
      color: '',
      cssClass: '',
      chartColor: '',
    };
  }
  let obj = [
    {
      page: 'homepage',
      color: '#e28888',
      cssClass: 'pagetype_tag',
      chartColor: '#e28888',
    },
    {
      page: 'productlist',
      color: '#97e6bf',
      cssClass: 'pagetype_tag',
      chartColor: '#97e6bf',
    },
    {
      page: 'productpage',
      color: '#ffd289',
      cssClass: 'pagetype_tag',
      chartColor: '#ffd289',
    },
    {
      page: 'cart',
      color: '#91bcdd',
      cssClass: 'pagetype_tag',
      chartColor: '#91bcdd',
    },
    {
      page: 'myaccount',
      color: '#f3997e',
      cssClass: 'pagetype_tag',
      chartColor: '#f3997e',
    },
    {
      page: 'orderconfirmation',
      color: '#c786b7',
      cssClass: 'pagetype_tag',
      chartColor: '#c786b7',
    },
    {
      page: 'deliveryaddress',
      color: '#7cc4c7',
      cssClass: 'pagetype_tag',
      chartColor: '#7cc4c7',
    },
    {
      page: 'other',
      color: '#bababa',
      cssClass: 'pagetype_tag',
      chartColor: '#bababa',
    },
  ];
  let returnObj = obj.find(
    (o) => o.page === pagetype.toLowerCase().replace(/ /g, '')
  );
  // if (returnObj === undefined) {
  return {
    page: 'other',
    color: '#dddcdc',
    cssClass: 'pagetype_tag',
    chartColor: '#bababa',
  };
  // }
  // return returnObj;
};
