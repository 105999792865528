import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../authentication/session.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: [
    '../organic/signup/signup.component.css',
    '../login/login.component.css',
    '../organic/login/login.component.css',
  ],
})
export class ResetpasswordComponent implements OnInit {
  // password: string = '';
  // confirmPassword: string = '';
  token: string;
  userID: string;

  public resetInformation: string =
    "Please choose a password that you'll remember, ensuring it's at least 9 characters long for your security";
  public resetInformationError: string;
  public warningMatch: string = 'Your password does not match!';
  public resetStatus: string = 'SUBMIT';
  public resetLoading: boolean = false;
  public finishedSend: boolean = false;
  public passwordValidation: boolean = false;
  public passwordValidationWarning: number;

  constructor(
    private authService: SessionService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.token = params['token'];
      this.userID = params['userId'];
    });
  }

  form = this.fb.group({
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required],
  });

  ngOnInit() {}

  resetPassword() {
    if (this.form.invalid) return;

    let password = this.form.value.password || '';
    let confirmPassword = this.form.value.confirmPassword || '';

    if (password == confirmPassword) {
      this.resetStatus = 'REQUESTING...';
      this.resetLoading = true;
      // this.resetInformation = '';
      this.resetInformationError = '';
      this.authService
        .resetPassword(password, this.token, this.userID)
        .subscribe((data: any) => {
          if (data['status'] == 3) {
            this.resetStatus = 'LOGGING IN...';
            // this.resetInformation =
            //   'Your password has been successfully set, you can now sign in with your new information.';

            // Login user if password reset is successful
            this.authService.login(data);
            this.authService.navigate(data);
          } else {
            this.resetStatus = 'SENT';
            this.resetInformationError =
              'The link has expired, please send another password reset request.';
            this.resetLoading = false;
            this.finishedSend = true;
          }
        });
    } else {
      this.passwordValidationWarning = 1;
      this.warningMatch = 'Your password does not match!';
      this.passwordValidation = false;
    }
  }
  validationChecker() {
    let password = this.form.value.password || '';
    let confirmPassword = this.form.value.confirmPassword || '';

    if (password.length > 0 && confirmPassword.length > 0) {
      if (password.length >= 9 && confirmPassword.length >= 9) {
        this.passwordValidation = true;
        this.passwordValidationWarning = 0;
      } else {
        this.passwordValidationWarning = 1;
        this.warningMatch = 'Your password should be 9 characters or more.';
      }
    } else if (password.length == 0 && confirmPassword.length == 0) {
      this.passwordValidationWarning = 0;
    }
  }
  showPassword: boolean = false;
  toogleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
