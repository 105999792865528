<div class="dashboard_container_main">
  <ng-container *ngIf="showNewDashboardView">
    <div class="dashboard-header-intro" products-intro-container>
      <div class="dashboard-header-intro-inner" product-intro="nudge">
        <div
          class="dashboard-header-intro-title"
          (click)="redirectToUrl('/master-preview', 'masterPreview')"
        >
          <h1>Revenue Optimization</h1>
          <img
            src="../../assets/img/nudge_icon.svg"
            style="height: 18px; width: 18px"
          />
        </div>
        <h2
          *ngIf="!nudgeDataFirstLoad"
          [ngClass]="{ blurred_header: nudge_blurred_show }"
        >
          {{ revUplift | currencyFormatter : true }}
        </h2>
        <div
          class="skeleton-fields"
          style="margin: 0; padding-bottom: 5px !important"
          *ngIf="nudgeDataFirstLoad"
        >
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              height: '44px',
              outline: 'none',
              'margin-right': '0px',
              'margin-bottom': '0px',
              'background-color': '#37474f1a'
            }"
          ></ngx-skeleton-loader>
        </div>
        <p>Revenue Uplift</p>
      </div>
      <div class="dashboard-header-intro-inner" product-intro="diagnostics">
        <div
          class="dashboard-header-intro-title"
          (click)="redirectToUrl('/diagnostic', 'bugTrackingOverall')"
        >
          <h1>Site Performance</h1>
          <img
            src="../../assets/img/diagnostic_icon.svg"
            style="height: 18px; width: 18px"
          />
        </div>
        <h2
          *ngIf="!diagnosticsDataFirstLoad"
          [ngClass]="{ blurred_header: chartDataBugs_blurred_show }"
        >
          {{ returnLossTotal | currencyFormatter }}
        </h2>
        <div
          class="skeleton-fields"
          style="margin: 0; padding-bottom: 5px !important"
          *ngIf="diagnosticsDataFirstLoad"
        >
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              height: '44px',
              outline: 'none',
              'margin-right': '0px',
              'margin-bottom': '0px',
              'background-color': '#37474f1a'
            }"
          ></ngx-skeleton-loader>
        </div>
        <p>Total Revenue Loss</p>
      </div>
      <div class="dashboard-header-intro-inner" product-intro="merchandise">
        <div
          class="dashboard-header-intro-title"
          (click)="redirectToUrl('/merchandising', 'merchandising')"
        >
          <h1>Product Performance</h1>
          <img
            src="../../assets/img/merchandising_icon.svg"
            style="height: 16px; margin-left: 2px; width: 16px"
          />
        </div>
        <h2
          *ngIf="!merchandiseDataFirstLoad"
          [ngClass]="{ blurred_header: merchandiseProducts_blurred_show }"
        >
          {{ unitSold }}
        </h2>
        <div
          class="skeleton-fields"
          style="margin: 0; padding-bottom: 5px !important"
          *ngIf="merchandiseDataFirstLoad"
        >
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              height: '44px',
              outline: 'none',
              'margin-right': '0px',
              'margin-bottom': '0px',
              'background-color': '#37474f1a'
            }"
          ></ngx-skeleton-loader>
        </div>
        <p>Unit Sold</p>
      </div>
      <div class="dashboard-header-intro-inner" product-intro="organic">
        <div
          class="dashboard-header-intro-title"
          (click)="redirectToUrl(organic_link, 'organic')"
        >
          <h1>SEO Performance</h1>
          <img
            src="../../assets/img/organic_icon.svg"
            style="height: 19px; width: 19px"
          />
        </div>
        <h2 [ngClass]="{ blurred_header: organicData_blurred_show }">
          {{ organicAllKpis }}
        </h2>
        <p>Ranking Impact</p>
      </div>
      <div class="dashboard-header-intro-inner" product-intro="automations">
        <div
          class="dashboard-header-intro-title"
          (click)="redirectToUrl('/automations', 'automationsOverview')"
        >
          <h1>Automations</h1>
          <img
            src="../../assets/img/automations_icon_1.svg"
            style="height: 19px; width: 19px"
          />
        </div>
        <h2
          *ngIf="!automationsDataFirstLoad"
          [ngClass]="{ blurred_header: automationsPrivilege_blurred_show }"
        >
          {{ automationsRevenue | currencyFormatter }}
        </h2>
        <div
          class="skeleton-fields"
          style="margin: 0; padding-bottom: 5px !important"
          *ngIf="automationsDataFirstLoad"
        >
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              height: '44px',
              outline: 'none',
              'margin-right': '0px',
              'margin-bottom': '0px',
              'background-color': '#37474f1a'
            }"
          ></ngx-skeleton-loader>
        </div>
        <p>Revenue Uplift</p>
      </div>
      <div
        class="loading-bug-data-overlay"
        *ngIf="
          (!nudgeDataFirstLoad && nudgeDataLoading) ||
          (!diagnosticsDataFirstLoad && diagnosticsDataLoading) ||
          (!merchandiseDataFirstLoad && merchandiseDataLoading) ||
          (!organicDataFirstLoad && organicDataLoading)
        "
      ></div>
    </div>
    <div class="dashboard-products" products-container>
      <div class="dashboard-product-card" product-card="nudge">
        <div class="card-title-container">
          <h1>Revenue Optimization</h1>
          <div
            class="card-title-container-product"
            [ngClass]="{ disabled: !checkRoleGuard('masterPreview') }"
            (click)="redirectToUrl('/master-preview', 'masterPreview')"
          >
            <img
              src="../../assets/img/nudge_icon.svg"
              style="height: 14px; width: 14px"
            />
            <h1>Nudge</h1>
          </div>
        </div>
        <div
          class="card-content"
          *ngIf="!nudgeDataFirstLoad"
          [ngClass]="{ blur_active: nudge_blurred_show }"
        >
          <!-- <div class="dtpickerall">
            <div
              class="master_longitudinal_main-header master_performance_main-header"
            >
              <mat-form-field
                class="bhm-option-container-1"
                style="--label-name: 'Device:'; --form-field-infix-width: 140px"
              >
                <mat-select
                  (selectionChange)="selectionDeviceChanged($event)"
                  [(ngModel)]="incentiveBarChart.selectedPlatform"
                >
                  <mat-option
                    class="bhm-option-select-option-1"
                    value="overall"
                  >
                    Overall
                  </mat-option>
                  <mat-option
                    class="bhm-option-select-option-1"
                    value="desktop"
                  >
                    Desktop
                  </mat-option>
                  <mat-option class="bhm-option-select-option-1" value="tablet">
                    Tablet
                  </mat-option>
                  <mat-option class="bhm-option-select-option-1" value="mobile">
                    Mobile
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                class="bhm-option-container-1"
                style="--label-name: 'Visits:'; --form-field-infix-width: 160px"
              >
                <mat-select
                  (selectionChange)="filterVisits($event)"
                  [value]="incentiveBarChart.selectedFilter"
                >
                  <mat-option
                    class="bhm-option-select-option-1"
                    value="overall"
                  >
                    Overall
                  </mat-option>
                  <mat-option
                    class="bhm-option-select-option-1"
                    value="newVisit"
                  >
                    New Visits
                  </mat-option>
                  <mat-option
                    class="bhm-option-select-option-1"
                    value="returningVisit"
                  >
                    Returning Visits
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                class="bhm-option-container-1"
                style="
                  --label-name: 'Outliers:';
                  --form-field-infix-width: 140px;
                "
              >
                <mat-select
                  [(ngModel)]="selectedOutlier"
                  (selectionChange)="onOutlierChange($event)"
                >
                  <mat-option
                    class="bhm-option-select-option-1"
                    *ngFor="let outlier of outliers"
                    [value]="outlier"
                  >
                    {{ outlier }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div> -->
          <div class="performance-box-orders_content">
            <ng-container>
              <div class="performance-box-orders_table">
                <div class="performance-box-orders_table-header">
                  <div class="performance-box-orders_table-item width80">
                    <p></p>
                  </div>
                  <div class="performance-box-orders_table-item width120">
                    <p
                      matTooltip="Visits who have seen at least one nudge"
                      matTooltipPosition="above"
                    >
                      Nudges Overall
                    </p>
                  </div>
                  <div
                    class="performance-box-orders_table-item width70"
                    *ngIf="!nudgeLite"
                  >
                    <p
                      matTooltip="Visits who would have seen at least one nudge but were in control. Control group sees no nudges at all."
                      matTooltipPosition="above"
                    >
                      Control
                    </p>
                  </div>
                  <div class="performance-box-orders_table-item">
                    <p *ngIf="!nudgeLite">Revenue Uplift</p>
                    <p *ngIf="nudgeLite">Revenue</p>
                  </div>
                </div>
                <div class="performance-box-orders_table-body">
                  <div class="performance-box-orders_table-row">
                    <div class="performance-box-orders_table-item width80">
                      <p
                        matTooltip="A visit is a session starting when the browser enters the shop and ends when the user exits the shop, i.e. closes the shop tab on browser. The visit lasts as long as the browser is open. Multiple tabs count as new visit."
                        matTooltipPosition="above"
                      >
                        Visits
                      </p>
                    </div>
                    <div class="performance-box-orders_table-item width120">
                      <p *ngIf="this.chartDataCr[0].data[0] == 0.222">0</p>
                      <p *ngIf="this.chartDataCr[0].data[0] != 0.222">
                        {{
                          incentiveBarChart.crorderAmount.incentivesAmount
                            .visits
                        }}
                      </p>
                    </div>
                    <div
                      class="performance-box-orders_table-item width70"
                      *ngIf="!nudgeLite"
                    >
                      <p *ngIf="this.chartDataCr[0].data[0] == 0.222">0</p>
                      <p *ngIf="this.chartDataCr[0].data[0] != 0.222">
                        {{
                          incentiveBarChart.crorderAmount.controlAmount.visits
                        }}
                      </p>
                    </div>
                    <div class="performance-box-orders_table-item">
                      <p
                        [ngClass]="{
                          'uplift-green': revUplift_color === true,
                          'uplift-red': revUplift_color === false,
                          'uplift-none': revUplift_color === 0
                        }"
                        style="white-space: nowrap"
                      >
                        {{ revUplift | currencyFormatter }}
                      </p>
                    </div>
                  </div>
                  <div class="performance-box-orders_table-row">
                    <div class="performance-box-orders_table-item width80">
                      <p>Orders</p>
                    </div>
                    <div class="performance-box-orders_table-item width120">
                      <p *ngIf="this.chartDataCr[0].data[0] == 0.222">0</p>
                      <p *ngIf="this.chartDataCr[0].data[0] != 0.222">
                        {{
                          incentiveBarChart.crorderAmount.incentivesAmount
                            .orders
                        }}
                      </p>
                    </div>
                    <div
                      class="performance-box-orders_table-item width70"
                      *ngIf="!nudgeLite"
                    >
                      <p *ngIf="this.chartDataCr[0].data[0] == 0.222">0</p>
                      <p *ngIf="this.chartDataCr[0].data[0] != 0.222">
                        {{
                          incentiveBarChart.crorderAmount.controlAmount.orders
                        }}
                      </p>
                    </div>
                    <div class="performance-box-orders_table-item">
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div
                class="performance-box-orders_content-divider"
                *ngIf="!nudgeLite"
              ></div>
              <div class="performance-box-orders_headless_table">
                <div
                  class="performance-box-orders_headless_table-item"
                  *ngIf="!nudgeLite"
                >
                  <p><b>Share of Total Revenue</b></p>
                  <p class="performance-box-orders_headless_table-value">
                    {{ formatPercentage(this.totalInfo.share.revenueShare) }}
                  </p>
                  <div class="rev-share-hidden">
                    <p>
                      The Share of Total Revenue is the Revenue Uplift driven by
                      behamics divided by the Total Revenue:
                    </p>
                    <p>
                      <span
                        [ngClass]="{
                          'uplift-green':
                            checkUpliftColor(this.revUplift) === true,
                          'uplift-red':
                            checkUpliftColor(this.revUplift) === false,
                          'uplift-none': checkUpliftColor(this.revUplift) === 0
                        }"
                        >{{ this.revUplift }}</span
                      >
                      /
                      {{
                        this.isNaN(this.totalInfo.total.totalRevenue)
                          ? 0
                          : this.totalInfo.total.totalRevenue
                      }}
                      =
                      {{
                        this.getFormattedValue(
                          this.totalInfo.share.revenueShare
                        )
                      }}
                    </p>
                  </div>
                </div>
                <div
                  class="performance-box-orders_headless_table-item"
                  *ngIf="!nudgeLite"
                >
                  <p><b>Share of Traffic</b></p>
                  <p class="performance-box-orders_headless_table-value">
                    {{ formatPercentage(this.totalInfo.share.trafficShare) }}
                  </p>
                  <div class="rev-share-hidden">
                    <p>
                      The Share of Traffic is the sum of the visits in the Nudge
                      and Control Group divided by the total number of visits:
                    </p>
                    <p>
                      {{
                        this.safeSum(
                          this.totalInfo.difference.visitsIncentive,
                          this.totalInfo.difference.visitsControl
                        )
                      }}
                      /
                      {{
                        this.safeSum(
                          this.totalInfo.difference.v,
                          this.totalInfo.difference.vgc
                        )
                      }}
                      =
                      {{ this.getTrafficShare() }}
                    </p>
                  </div>
                </div>
                <div
                  class="performance-box-orders_headless_table-item"
                  *ngIf="!nudgeLite"
                >
                  <p><b>Share of Orders</b></p>
                  <p class="performance-box-orders_headless_table-value">
                    {{ formatPercentage(this.totalInfo.share.ordersShare) }}
                  </p>
                  <div class="rev-share-hidden">
                    <p>
                      The Share of Orders is the sum of the orders in the Nudge
                      and Control Group divided by the total number of orders:
                    </p>
                    <p>
                      {{
                        this.safeSum(
                          this.totalInfo.difference.ordersIncentive,
                          this.totalInfo.difference.ordersControl
                        )
                      }}
                      /
                      {{
                        this.safeSum(
                          this.totalInfo.difference.o,
                          this.totalInfo.difference.ogc
                        )
                      }}
                      =
                      {{ this.getOrdersShare() }}
                    </p>
                  </div>
                </div>
              </div> -->
            </ng-container>
          </div>
          <div
            class="pov-bar-charts-holder"
            [ngClass]="{
              nudgeLite: nudgeLite
            }"
          >
            <div class="pov-pageBarChart-container">
              <div class="pov-pageBarChart-axis">
                <h5>CR</h5>
                <div
                  class="pov-pageBarChart-axis-inner"
                  *ngFor="let axis of barChartCrAxis"
                >
                  <p>{{ axis }}%</p>
                </div>
              </div>
              <div class="pov-pageBarChart-main">
                <div
                  class="pov-pageBarChart-main-charts"
                  *ngFor="let bar of barChartCr; let i = index"
                  [ngClass]="{
                    controlBar: bar.name == 'Control'
                  }"
                >
                  <div class="pov-pageBarChart-main-charts-bar-container">
                    <div
                      class="pov-pageBarChart-main-charts-bar"
                      [ngClass]="{
                        defaultCol: bar.name == 'Control'
                      }"
                      [ngStyle]="{ height: bar.height + '%' }"
                    >
                      <div
                        [ngClass]="{ zeroBarVal: bar.value == 0 }"
                        class="pov-pageBarChart-main-charts-bar-innervalue"
                      >
                        <div>{{ bar.value }}%</div>
                        <div
                          *ngIf="
                            bar.name != 'Control' && bar.diff && !nudgeLite
                          "
                        >
                          ({{ bar.diff }})
                        </div>
                      </div>
                      <div
                        class="pov-pageBarChart-main-charts-bar-tooltip"
                        *ngIf="bar.tooltip && !nudgeLite"
                      >
                        <b>Sig. Control:</b> {{ bar.tooltip }}
                      </div>
                    </div>
                  </div>
                  <div class="pov-pageBarChart-main-charts-name">
                    <p>{{ bar.name }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="pov-pageBarChart-container">
              <div class="pov-pageBarChart-axis">
                <h5>RPV</h5>
                <div
                  class="pov-pageBarChart-axis-inner"
                  *ngFor="let axis of barChartRpvAxis"
                >
                  <p>{{ displayAxisValSymbol(axis) }}</p>
                </div>
              </div>
              <div class="pov-pageBarChart-main">
                <div
                  class="pov-pageBarChart-main-charts"
                  *ngFor="let bar of barChartRpv; let i = index"
                  [ngClass]="{
                    controlBar: bar.name == 'Control'
                  }"
                >
                  <div class="pov-pageBarChart-main-charts-bar-container">
                    <div
                      class="pov-pageBarChart-main-charts-bar"
                      [ngClass]="{
                        defaultCol: bar.name == 'Control'
                      }"
                      [ngStyle]="{ height: bar.height + '%' }"
                    >
                      <div
                        [ngClass]="{ zeroBarVal: bar.value == 0 }"
                        class="pov-pageBarChart-main-charts-bar-innervalue"
                      >
                        {{ bar.value | currencyFormatter }}
                        <div
                          *ngIf="
                            bar.name != 'Control' && bar.diff && !nudgeLite
                          "
                        >
                          ({{ bar.diff }})
                        </div>
                      </div>

                      <div
                        class="pov-pageBarChart-main-charts-bar-tooltip"
                        *ngIf="bar.tooltip && !nudgeLite"
                      >
                        <b>Sig. Control:</b> {{ bar.tooltip }}
                      </div>
                    </div>
                  </div>
                  <div class="pov-pageBarChart-main-charts-name">
                    <p>{{ bar.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <p
            *ngIf="!nudgeDataFirstLoad && !nudge_blurred_show"
            class="view-more-button"
            (click)="redirectToUrl('/master-preview', 'masterPreview')"
          >
            View more
          </p>
        </div>

        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 40px;
          "
          *ngIf="nudgeDataFirstLoad"
        >
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
              height: 65px;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '65px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div style="display: flex; gap: 20px">
            <div
              class="skeleton-fields"
              style="
                margin: 0 !important;
                padding: 0 !important;
                border-radius: 6px;
                overflow: hidden;
                width: 100%;
                height: 235px;
              "
            >
              <ngx-skeleton-loader
                count="1"
                [theme]="{
                  height: '235px',
                  outline: 'none',
                  'margin-right': '0px',
                  'margin-bottom': '0px',
                  'background-color': '#37474f1a'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div
              class="skeleton-fields"
              style="
                width: 100%;
                margin: 0 !important;
                padding: 0 !important;
                border-radius: 6px;
                overflow: hidden;
                height: 235px;
              "
            >
              <ngx-skeleton-loader
                count="1"
                [theme]="{
                  height: '235px',
                  outline: 'none',
                  'margin-right': '0px',
                  'margin-bottom': '0px',
                  'background-color': '#37474f1a'
                }"
              ></ngx-skeleton-loader>
            </div>
          </div>
        </div>

        <div
          class="loading-bug-data-overlay"
          *ngIf="!nudgeDataFirstLoad && nudgeDataLoading"
        >
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
      <div class="dashboard-product-card" product-card="diagnostics">
        <div class="card-title-container">
          <h1>Site Performance</h1>
          <div
            class="card-title-container-product"
            [ngClass]="{ disabled: !checkRoleGuard('bugTrackingOverall') }"
            (click)="redirectToUrl('/diagnostic', 'bugTrackingOverall')"
          >
            <img
              src="../../assets/img/diagnostic_icon.svg"
              style="height: 14px; width: 14px"
            />
            <h1>Diagnostic</h1>
          </div>
        </div>
        <div class="card-subtitle-container" *ngIf="!diagnosticsDataFirstLoad">
          <p>Revenue Loss Reasons</p>
        </div>
        <div
          class="card-content"
          *ngIf="!diagnosticsDataFirstLoad"
          [ngClass]="{ blur_active: chartDataBugs_blurred_show }"
        >
          <div class="hover-unhide" *ngIf="chartDataBugs?.length > 0">
            <div class="chart-main-container">
              <div
                class="chart-item-container"
                *ngFor="let bug of chartDataBugs; let i = index"
                [ngClass]="{ active: i === 0 }"
              >
                <!-- (click)="bugBarChartSelect(bug.type, i)" -->
                <div
                  class="chart-item-title"
                  [ngClass]="{
                    premiumRestricted: bug.type.includes('Premium')
                  }"
                >
                  <p
                    class="cursor_pointer"
                    (click)="redirectErrorDetailed(bug.bugName, bug.pageName)"
                  >
                    <span
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        flex-wrap: wrap;
                        gap: 4px;
                      "
                    >
                      <span
                        *ngIf="bug.bug_category"
                        class="chart-item-title_pagename pagetype_tag chart-item-title_category"
                      >
                        {{ bug.bug_category }}
                      </span>
                      <span
                        *ngIf="bug.type.split(' on ').length == 2"
                        [class]="'chart-item-title_pagename ' + bug.cssClass"
                        [style.background-color]="bug.inlineStyle"
                      >
                        {{ bug.type.split(" on ")[1] }}
                      </span>
                    </span>
                    <span
                      class="chart-item-title_bugname"
                      [matTooltip]="
                        chartDataBugs_blurred_show
                          ? ''
                          : bug.type.split(' on ')[0]
                      "
                      matTooltipPosition="above"
                    >
                      {{ bug.type.split(" on ")[0] }}
                    </span>
                  </p>
                </div>
                <div class="chart-item-bar">
                  <div
                    class="chart-item-bar-inner"
                    [ngStyle]="{ width: bug.percentage + '%' }"
                  >
                    <p
                      *ngIf="!showPercentages"
                      class="chart-item-bar-value"
                      [ngClass]="{
                        addResponsive:
                          bug.revenueLoss.length > 8 && bug.percentage > 70,
                        neutral: bug.revenueLoss == 0
                      }"
                    >
                      {{ bug.revenueLoss | currencyFormatter }}
                    </p>
                    <p
                      *ngIf="showPercentages"
                      class="chart-item-bar-value"
                      [ngClass]="{
                        addResponsive:
                          bug.revenueLoss.length > 8 && bug.percentage > 70,
                        neutral: bug.revenueLoss == 0
                      }"
                    >
                      {{ bug.TRL_Percentage }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="general-hide-scrollbar"></div>
          </div>

          <div
            style="margin: auto"
            *ngIf="!chartDataBugs || chartDataBugs.length == 0"
          >
            <p>No Data</p>
          </div>
        </div>
        <div
          class="card-footer"
          *ngIf="
            !diagnosticsDataFirstLoad &&
            chartDataBugs?.length > 0 &&
            !chartDataBugs_blurred_show
          "
        >
          <p
            class="view-more-button"
            (click)="redirectToUrl('/diagnostic', 'bugTrackingOverall')"
          >
            View more
          </p>
        </div>

        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 60px;
          "
          *ngIf="diagnosticsDataFirstLoad"
        >
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '48px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '48px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '48px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '48px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '48px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>

        <div
          class="loading-bug-data-overlay"
          *ngIf="!diagnosticsDataFirstLoad && diagnosticsDataLoading"
        >
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
      <div class="dashboard-product-card" product-card="merchandise">
        <div class="card-title-container">
          <h1>Product Performance</h1>
          <div
            class="card-title-container-product"
            [ngClass]="{ disabled: !checkRoleGuard('merchandising') }"
            (click)="redirectToUrl('/merchandising', 'merchandising')"
          >
            <img
              src="../../assets/img/merchandising_icon.svg"
              style="height: 14px; width: 14px"
            />
            <h1>Merchandise</h1>
          </div>
        </div>
        <div
          class="card-content"
          *ngIf="!merchandiseDataFirstLoad"
          [ngClass]="{ blur_active: merchandiseProducts_blurred_show }"
        >
          <div class="hover-unhide" *ngIf="merchandiseProducts?.length > 0">
            <div class="custom_table-container dashboard-merch-table">
              <table class="custom_table">
                <thead>
                  <tr>
                    <th class="width50 minWidth20"><div>Product</div></th>
                    <!-- <th class="width50 minWidth80"><div>Id</div></th> -->
                    <th class="width50 minWidth80">
                      <div>Revenue</div>
                    </th>
                    <th class="width50 minWidth80">
                      <div>Revenue &Delta;</div>
                    </th>
                    <th class="width50 minWidth80">
                      <div>CR</div>
                    </th>
                    <th class="width50 minWidth80">
                      <div>CR &Delta;</div>
                    </th>
                    <th class="width50 minWidth20">
                      <div>Units Sold</div>
                    </th>
                    <th class="width50 minWidth80">
                      <div>
                        Price
                        <!-- <svg
              (click)="sortByPrice(); loadProducts()"
              class="custom_table_sort-icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              [ngClass]="{
                active: !sortByPriceToogle()
              }"
            >
              <g clip-path="url(#clip0_1676_4762)">
                <path
                  d="M12 8V15"
                  stroke="#5f5d6e"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 12L12 8"
                  stroke="#5f5d6e"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 12L12 8"
                  stroke="#5f5d6e"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1676_4762">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg> -->
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let product of merchandiseProducts">
                    <tr>
                      <!-- (click)="redirectToProductDetails(product)" -->
                      <td>
                        <div class="dashboard-merchandise-image">
                          <img
                            [src]="product.sanitized_url"
                            alt="Product Image"
                          />
                        </div>
                      </td>
                      <!-- <td>
                        {{ product.truncated_product_id }}
                      </td> -->
                      <td>
                        {{ product.total_revenue | currencyFormatter }}
                      </td>
                      <td>
                        <div
                          [ngClass]="{
                            negative: product.total_revenue_diff < 0,
                            positive: product.total_revenue_diff >= 0
                          }"
                        >
                          {{ product.total_revenue_diff | number : "1.2-2" }}%
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="{
                            negative: product.conversion_rate < 0,
                            positive: product.conversion_rate >= 0
                          }"
                        >
                          {{ product["conversion_rate"] | number : "1.2-2" }}%
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="{
                            negative: product.conversion_rate_diff < 0,
                            positive: product.conversion_rate_diff >= 0
                          }"
                        >
                          {{
                            product["conversion_rate_diff"] | number : "1.2-2"
                          }}%
                        </div>
                      </td>
                      <td>
                        {{ product["total_units_sold"] | number : "1.0-0" }}
                      </td>
                      <td>
                        {{ product.price | currencyFormatter }}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="general-hide-scrollbar"></div>
          </div>
          <div
            style="margin: auto"
            *ngIf="!merchandiseProducts || merchandiseProducts?.length == 0"
          >
            <p>No Data</p>
          </div>
        </div>
        <div
          class="card-footer"
          *ngIf="
            !merchandiseDataFirstLoad &&
            merchandiseProducts?.length > 0 &&
            !merchandiseProducts_blurred_show
          "
        >
          <p
            class="view-more-button"
            (click)="redirectToUrl('/merchandising', 'merchandising')"
          >
            View more
          </p>
        </div>

        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 60px;
          "
          *ngIf="merchandiseDataFirstLoad"
        >
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '48px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '48px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '48px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '48px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '48px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>

        <div
          class="loading-bug-data-overlay"
          *ngIf="!merchandiseDataFirstLoad && merchandiseDataLoading"
        >
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
      <div class="dashboard-product-card" product-card="organic">
        <div class="card-title-container">
          <h1>SEO Performance</h1>
          <div
            class="card-title-container-product"
            [ngClass]="{ disabled: !organic_role }"
            (click)="redirectToUrl(organic_link, 'organic')"
          >
            <img
              src="../../assets/img/organic_icon.svg"
              style="height: 14px; width: 14px"
            />
            <h1>Organic</h1>
          </div>
        </div>
        <div class="card-subtitle-container" *ngIf="!organicDataFirstLoad">
          <p>Main issues impacting SEO</p>
        </div>
        <div
          class="card-content"
          *ngIf="!organicDataFirstLoad"
          [ngClass]="{ blur_active: organicData_blurred_show }"
        >
          <div
            class="ai_analysis-custom-barchart-dataholder"
            style="margin-top: 5px"
            *ngIf="organicData?.length > 0"
          >
            <div class="ai_analysis_gr_table">
              <div class="ai_analysis_gr_table_header">
                <div class="ai_analysis_gr_table_grp1">
                  <h3>Factor</h3>
                </div>
                <div class="ai_analysis_gr_table_grp2">
                  <h3>Keywords</h3>
                </div>
                <div class="ai_analysis_gr_table_grp3">
                  <h3>Ranking impact</h3>
                </div>
              </div>
              <div class="ai_analysis_gr_table_content_scroll">
                <div
                  class="ai_analysis_gr_table_content"
                  *ngFor="let chartData of organicData; let i = index"
                >
                  <div class="ai_analysis_gr_table_grp1">
                    <h1>
                      {{ chartData.tmp_renamed_name }}
                    </h1>
                  </div>
                  <div
                    class="ai_analysis_gr_table_grp2"
                    style="padding-left: 5px; box-sizing: border-box"
                  >
                    <span>{{ chartData.variable_labels }}</span>
                  </div>
                  <div
                    class="ai_analysis_gr_table_grp3"
                    style="padding-left: 5px; box-sizing: border-box"
                  >
                    <div
                      class="percentage"
                      [ngClass]="{
                        positive: chartData?.value > 0,
                        negative: chartData?.value < 0,
                        neutral: chartData?.value == 0
                      }"
                    >
                      {{ chartData.formatted_value }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style="margin: auto"
            *ngIf="!organicData || organicData?.length == 0"
          >
            <p>No Data</p>
          </div>
        </div>
        <div
          class="card-footer"
          *ngIf="
            !organicDataFirstLoad &&
            organicData?.length > 0 &&
            !organicData_blurred_show
          "
        >
          <p
            class="view-more-button"
            (click)="redirectToUrl(organic_link, 'organic')"
          >
            View more
          </p>
        </div>

        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 60px;
          "
          *ngIf="organicDataFirstLoad"
        >
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '48px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '48px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '48px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '48px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div
            class="skeleton-fields"
            style="
              margin: 0 !important;
              padding: 0 !important;
              border-radius: 6px;
              overflow: hidden;
            "
          >
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '48px',
                outline: 'none',
                'margin-right': '0px',
                'margin-bottom': '0px',
                'background-color': '#37474f1a'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>

        <div
          class="loading-bug-data-overlay"
          *ngIf="!organicDataFirstLoad && organicDataLoading"
        >
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!showNewDashboardView">
    <!-- Performance Bars -->
    <app-performance></app-performance>
    <!-- Frequencies -->
    <app-nudges></app-nudges>
  </ng-container>
</div>
