// export const name = "test";
function displayCurrency() {
  var currency = window.localStorage.getItem('currency');

  switch (currency) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    case 'CHF':
      return 'CHF ';
    case 'GBP':
      return '£';
    default:
      return '$';
  }
}
export const listOfNames: any = {
  mobile: {
    name: 'Mobile users',
    description: 'The percentage of mobile users',
    b_name: 'Mobile',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users shopping using mobile devices',
    info: 'Daily average of users shopping using mobile devices',
  },
  desktop: {
    name: 'Desktop users',
    description: 'The percentage of desktop users',
    b_name: 'Desktop',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users shopping using desktop devices',
    info: 'Daily average of users shopping using desktop devices',
  },
  cart_items_on_return: {
    name: 'Cart items on return',
    description:
      "The average number of products in the user's cart when they came back",
    b_name: 'Cart items on return',
    value: 'relative_value',
    sign: '',
    new_description:
      'of the average number of products in cart when users return',
    info: 'Daily average number of products in cart when users return',
  },
  // in_discount_perc: {
  // name: "Products in discount",
  // description: "The percentage of products visited that were in discount",
  // b_name: "Products in discount",
  // value: "relative_value",
  // sign: "%",
  // new_description: "of the visited products that were in discount",
  // },
  sizes_perc_mean: {
    name: 'Available sizes',
    description: 'The percentage of product sizes that are available',
    b_name: 'Available sizes',
    value: 'relative_value',
    sign: '%',
    new_description: 'of the percentage of product sizes that are available',
    info: 'Daily average of product sizes that were available',
  },
  total_time_search_used: {
    name: 'Search filter',
    description: 'The percentage use of the Search filter',
    b_name: 'Search Filter',
    value: 'relative_value',
    sign: '%',
    new_description:
      'of the number of times search filter was used on Product Lists',
    info: 'Daily average number of times the Search filter was used on Product Lists',
  },
  total_time_color_filter_used: {
    name: 'Color filter',
    description: 'The percentage use of the Color filter',
    b_name: 'Color Filter',
    value: 'relative_value',
    sign: '%',
    new_description:
      'of the number of times color filter was used on Product Lists',
    info: 'Daily average number of times the Color filter was used on Product Lists',
  },
  total_time_price_filter_used: {
    name: 'Price filter',
    description: 'The percentage use of the Price filter',
    b_name: 'Price Filter',
    value: 'relative_value',
    sign: '%',
    new_description:
      'of the number of times price filter was used on Product Lists',
    info: 'Daily average number of times the Price filter was used on Product Lists',
  },
  total_time_size_filter_used: {
    name: 'Size filter',
    description: 'The percentage use of the Size filter',
    b_name: 'Size Filter',
    value: 'relative_value',
    sign: '%',
    new_description:
      'of the number of times size filter was used on Product Lists',
    info: 'Daily average number of times the Size filter was used on Product Lists',
  },
  total_time_sort_used: {
    name: 'Sort filter',
    description: 'The percentage use of the Sort filter',
    b_name: 'Sort Filter',
    value: 'relative_value',
    sign: '%',
    new_description: 'of number of times Sort filter was used on Product Lists',
    info: 'Daily average number of times the Sort filter was used on Product Lists',
  },
  total_time_sale_used: {
    name: 'Sale filter',
    description: 'The percentage use of the Sale filter',
    b_name: 'Sale Filter',
    value: 'relative_value',
    sign: '%',
    new_description:
      'of number of times sale filter usage was used on Product Lists',
    info: 'Daily average number of times the Sale filter was used on Product Lists',
  },
  total_time_new_used: {
    name: 'New filter',
    description: 'The percentage use of the New filter',
    b_name: 'New Filter',
    value: 'relative_value',
    sign: '%',
    new_description: 'of number of times new filter was used on Product Lists',
    info: 'Daily average number of times the New filter was used on Product Lists',
  },
  added_to_cart_price_mean: {
    name: 'Products price added to cart',
    description: 'The average price of all the items added to the cart',
    b_name: 'Products price added to Cart',
    value: 'relative_value',
    sign: displayCurrency(),
    new_description: 'of the average price of all items added to the cart',
    info: 'Daily average price of all items that were added to the cart',
  },
  PP_total_price: {
    name: 'Average price of visited products',
    description: 'Average price for visited products',
    b_name: 'Average price of visited products',
    value: 'absolute_value',
    sign: displayCurrency(),
    new_description: 'of the average price for visited products',
    info: 'Daily average price of visited products',
  },
  PP_total_discount: {
    name: 'Average discount of visited products',
    description: 'Average discount value for products visited',
    b_name: 'Average discount of visited products',
    value: 'absolute_value',
    sign: displayCurrency(),
    new_description: 'of the average discount value of clicked products',
    info: 'Daily average discount value of visited products',
  },
  product_ratings: {
    name: 'Products ratings',
    description: 'The average product ratings',
    b_name: 'Products Ratings',
    value: 'relative_value',
    sign: '',
    new_description: 'of the average number of ratings of clicked products',
    info: 'Daily average number of ratings of visited products',
  },
  product_reviews: {
    name: 'Products Reviews',
    description: 'The average product reviews',
    b_name: 'Products Reviews',
    value: 'relative_value',
    sign: '',
    new_description: 'of the average number of reviews of clicked products',
    info: 'Daily average reviews of visited products',
  },
  LANDING_REFERRER_MAIL: {
    name: 'Landing from Mail',
    description: 'The percentage of users that came from the Mail site',
    b_name: 'Mail',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users that came through emails',
    info: 'Daily average users that came through emails',
  },
  LANDING_REFERRER_DIRECTLY_ON_TARGET: {
    name: 'Landing directly on target',
    description: 'The percentage of users that came directly to the store',
    b_name: 'Direct',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users that came directly on the shop',
    info: 'Daily average users that landed directly on the shop',
  },
  LANDING_REFERRER_OTHER: {
    name: 'Landing from other',
    description: 'The percentage of users that came from other sites',
    b_name: 'Other',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users that came from other sites',
    info: 'Daily average users that came from other sites',
  },
  LANDING_REFERRER_bing: {
    name: 'Landing from Bing',
    description: 'The percentage of users that came from the Bing site',
    b_name: 'Bing',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users that came through Bing',
    info: 'Daily average users that came through Bing',
  },
  LANDING_REFERRER_facebook: {
    name: 'Landing from Facebook',
    description: 'The percentage of users that came from the Facebook site',
    b_name: 'Facebook',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users that came through Facebook',
    info: 'Daily average users that came through Facebook',
  },
  LANDING_REFERRER_google: {
    name: 'Landing from Google',
    description: 'The percentage of users that came from the Google site',
    b_name: 'Google',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users that came through Google',
    info: 'Daily average users that came through Google',
  },
  Chrome: {
    name: 'Chrome users',
    description: 'The percentage of Chrome browser users',
    b_name: 'Chrome',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users shopping using Chrome browser',
    info: 'Daily average users shopping using Chrome browser',
  },
  Firefox: {
    name: 'Firefox users',
    description: 'The percentage of Firefox browser users',
    b_name: 'Firefox',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users shopping using Firefox browser',
    info: 'Daily average users shopping using Firefox browser',
  },
  Safari: {
    name: 'Safari users',
    description: 'The percentage of Safari browser users',
    b_name: 'Safari',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users shopping using Safari browser',
    info: 'Daily average users shopping using Safari browser',
  },
  Other_browser: {
    name: 'Other browsers users',
    description: 'The percentage of Other browser users',
    b_name: 'Other',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users shopping using other browsers',
    info: 'Daily average users shopping using other browsers',
  },
  Android: {
    name: 'Android users',
    description: 'The percentage of Android platform users',
    b_name: 'Android',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users shopping using Android platforms',
    info: 'Daily average users shopping using Android platforms',
  },
  iOS: {
    name: 'iOS users',
    description: 'The percentage of iOS platform users',
    b_name: 'iOS',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users shopping using iOS platforms',
    info: 'Daily average users shopping using iOS platforms',
  },
  Windows: {
    name: 'Windows users',
    description: 'The percentage of Windows platform users',
    b_name: 'Windows',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users shopping using Windows platforms',
    info: 'Daily average users shopping using Windows platforms',
  },
  'Mac OS': {
    name: 'Mac OS users',
    description: 'The percentage of MAC OS platform users',
    b_name: 'Mac OS',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users shopping using MacOS platforms',
    info: 'Daily average users shopping using MacOS platforms',
  },
  Linux: {
    name: 'Linux users',
    description: 'The percentage of Linux platform users',
    b_name: 'Linux',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users shopping using Linux platforms',
    info: 'Daily average users shopping using Linux platforms',
  },
  Other_platform: {
    name: 'Other platform users',
    description: 'The percentage of Other platform users',
    b_name: 'Other',
    value: 'relative_value',
    sign: '%',
    new_description: 'of users shopping using other platforms',
    info: 'Daily average users shopping using other platforms',
  },
  in_discount_mean: {
    name: 'Percentage of products in discount',
    description: 'The percentage of products visited that were in discount',
    b_name: 'Percentage of products in discount',
    value: 'relative_value',
    sign: '%',
    new_description:
      'of the percentage of clicked products that were in discount',
    info: 'Daily average of clicked products that were in discount',
  },
  nr_not_availability_mean: {
    name: 'Products out of stock',
    description: 'The percentage of products visited that were out of stock',
    b_name: 'Products out of stock',
    value: 'relative_value',
    sign: '%',
    new_description:
      'of the percentage of clicked products that were out of stock',
    info: 'Daily average number of clicked products that were out of stock',
  },
  visits: {
    name: 'Number of visits',
    description: 'Number of visits',
    b_name: 'Visits',
    value: 'absolute_value',
    sign: '',
    new_description: '',
  },
  hour: {
    name: 'Hour',
    description: 'Hour',
    b_name: 'Hour',
    value: 'absolute_value',
    sign: '',
    new_description: '',
  },
  day: {
    name: 'Day of week',
    description: 'Day of week',
    b_name: 'Day of week',
    value: 'absolute_value',
    sign: '',
    new_description: '',
  },
  on_sale_Product_List: {
    name: 'Product List on sale',
    description: 'Percentage of Product List on sale',
    b_name: 'Product Lists on Sale',
    value: 'relative_value',
    sign: '%',
    new_description:
      'of the percentage of clicked Product Lists pages that were on sale',
    info: 'Daily average number of visited Product Lists pages that were on sale',
  },
  domInteractive_ca: {
    name: 'Cart Page Load Time',
    description: 'Cart Page load duration',
    b_name: 'Cart Page Load Time',
    value: 'relative_value',
    sign: 'ms',
    new_description: 'of the time Cart Page took to load fully',
    info: 'Daily average time the Cart Page took to load',
  },
  resources_ca: {
    name: 'Total Resources on Cart Page',
    description: 'Number of Total Resources on Cart Page',
    b_name: 'Total Resources on Cart Page',
    value: 'relative_value',
    sign: '',
    new_description: 'of the number of Total Resources on Cart Page',
    info: 'Daily average number of Total Resources on Cart Page',
  },
  domInteractive_pp: {
    name: 'Product Page Load Time',
    description: 'Product Page load duration',
    b_name: 'Product Page Load Time',
    value: 'relative_value',
    sign: 'ms',
    new_description: 'of the time Product Page took to load fully',
    info: 'Daily average time the Product Page took to load',
  },
  resources_pp: {
    name: 'Total Resources on Product Page',
    description: 'Number of Total Resources on Product Page',
    b_name: 'Total Resources on Product Page',
    value: 'relative_value',
    sign: '',
    new_description: 'of the number of Total Resources on Product Page',
    info: 'Daily average number of Total Resources on Product Page',
  },
  domInteractive_pl: {
    name: 'Product List Load Time',
    description: 'Product List load duration',
    b_name: 'Product List Load Time',
    value: 'relative_value',
    sign: 'ms',
    new_description: 'of the time Product List took to load fully',
    info: 'Daily average time the Product List took to load',
  },
  resources_pl: {
    name: 'Total Resources on Product List',
    description: 'Number of Total Resources on Product List',
    b_name: 'Total Resources on Product List',
    value: 'relative_value',
    sign: '',
    new_description: 'of the number of Total Resources on Product List',
    info: 'Daily average number of Total Resources on Product List',
  },
  domInteractive_hp: {
    name: 'Home Page Load Time',
    description: 'Home Page load duration',
    b_name: 'Home Page Load Time',
    value: 'relative_value',
    sign: 'ms',
    new_description: 'of the time Home Page took to load fully',
    info: 'Daily average time the Home Page took to load',
  },
  resources_hp: {
    name: 'Total Resources on Home Page',
    description: 'Number of Total Resources on Home Page',
    b_name: 'Total Resources on Home Page',
    value: 'relative_value',
    sign: '',
    new_description: 'of the number of Total Resources on Home Page',
    info: 'Daily average number of Total Resources on Home Page',
  },
  domInteractivepayment: {
    name: 'Payment Page Load Time',
    description: 'Payment Page load duration',
    b_name: 'Payment Page Load Time',
    value: 'relative_value',
    sign: 'ms',
    new_description: 'of the time Payment Page took to load fully',
    info: 'Daily average time the Payment Page took to load',
  },
  resources_payment: {
    name: 'Total Resources on Payment Page',
    description: 'Number of Total Resources on Payment Page',
    b_name: 'Total Resources on Payment Page',
    value: 'relative_value',
    sign: '',
    new_description: 'of the number of Total Resources on Payment Page',
    info: 'Daily average number of Total Resources on Payment Page',
  },
};
export const listOfNames2: any = {
  img_size_mean: {
    showName: 'Image Size',
    description: 'mean size of images in Product Pages',
  },
  domInteractive_ca: {
    showName: 'domInteractive CART',
    description: 'Cart Page load duration',
  },
  resources_ca: {
    showName: 'resources CART',
    description: 'Number of Total Resources on Cart Page',
  },
  domInteractive_pl: {
    showName: 'Loading Time: Product List',
    description: 'Product List load duration',
  },
  resources_pl: {
    showName: 'resources PL',
    description: 'Number of Total Resources on Product List',
  },
  domInteractive_pp: {
    showName: 'Loading Time: Product Page',
    description: 'Product Page load duration',
  },
  resources_pp: {
    showName: 'resources PDP',
    description: 'Number of Total Resources on Product Page',
  },
  domInteractive_hp: {
    showName: 'domInteractive HP',
    description: 'Home Page load duration',
  },
  resources_hp: {
    showName: 'resources HP',
    description: 'Number of Total Resources on Home Page',
  },
  domInteractive_payment: {
    showName: 'domInteractive Payment',
    description: 'Payment Page load duration',
  },
  resources_payment: {
    showName: 'resources Payment',
    description: 'Number of Total Resources on Payment Page',
  },
};

export const isMasterDashboard = () => {
  if (
    localStorage.getItem('role') == 'master' ||
    localStorage.getItem('role') == 'schulte' ||
    localStorage.getItem('role') == 'ochsnersport' ||
    localStorage.getItem('master') == 'true'
  ) {
    return true;
  }
  return false;
};
export const isDemoAccount = () => {
  let tmpDemoEmails = ['demo@behamics.com'];

  if (tmpDemoEmails.includes(localStorage.getItem('email') || '')) {
    return true;
  }
  return false;
};
