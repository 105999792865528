import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  date = new Date();
  private messageSource;
  currentMessage;
  private messageSourceCompare;
  currentMessageCompare;

  constructor() {
    this.messageSource = new BehaviorSubject(this.date);
    this.currentMessage = this.messageSource.asObservable();

    this.messageSourceCompare = new BehaviorSubject(this.date);
    this.currentMessageCompare = this.messageSourceCompare.asObservable();
  }

  selectedDate(message: any) {
    this.messageSource.next(message);
  }

  compareStatus(message: any) {
    this.messageSourceCompare.next(message);
  }
}
